import i18next from 'i18next';
import {
  components,
  observable,
  Observable,
  pureComputed,
  PureComputed,
  validation
} from 'knockout';

import { ComponentDependencies, HumanName } from '../../interfaces';
import { BaseWidgetViewModel } from '../BaseWidgetViewModel';

type GivenNameOrInitials = 'givenName' | 'initials' | 'both';

function hasValueOrBoth(
  value: Exclude<GivenNameOrInitials, 'both'>,
  option: GivenNameOrInitials | undefined
) {
  return option && [value, 'both'].includes(option);
}

export interface HumanNameViewModelParams extends components.ViewModelParams {
  value$?: Observable<HumanName>;
  isValid$?: Observable<boolean>;

  readOnly?: boolean;
  genderReadOnly?: boolean;
  initialsReadOnly?: boolean;
  givenNameReadOnly?: boolean;
  familyNamePrefixReadOnly?: boolean;
  familyNameReadOnly?: boolean;

  availableGenders?: HumanName['gender'][];
  genderInputType?: 'radio' | 'select';

  givenNameOrInitialsEnabled?: GivenNameOrInitials;
  givenNameOrInitialsRequired?: GivenNameOrInitials;

  texts?: {
    genderLabel?: string;
    initialsLabel?: string;
    initialsValidationMessage?: string;
    givenNameLabel?: string;
    familyNamePrefixLabel?: string;
    familyNameLabel?: string;
    genderNames?: {
      [K in HumanName['gender']]?: string;
    };
    genderPrefixes?: {
      [K in HumanName['gender']]?: string;
    };
  };
}

export class HumanNameViewModel extends BaseWidgetViewModel {
  public readonly readOnly: boolean;
  public readonly genderReadOnly: boolean;
  public readonly givenNameReadOnly: boolean;
  public readonly initialsReadOnly: boolean;
  public readonly familyNamePrefixReadOnly: boolean;
  public readonly familyNameReadOnly: boolean;

  public readonly initialsEnabled: boolean = false;
  public readonly givenNameEnabled: boolean = false;
  public readonly initialsRequired: boolean = false;
  public readonly givenNameRequired: boolean = false;

  public readonly texts: {
    genderLabel: string;
    initialsLabel: string;
    initialsValidationMessage: string;
    givenNameLabel: string;
    familyNamePrefixLabel: string;
    familyNameLabel: string;
    genderNames: {
      [K in HumanName['gender']]: string;
    };
    genderPrefixes: {
      [K in HumanName['gender']]: string;
    };
  };

  public readonly labeledAvailableGenders: {
    value: HumanName['gender'];
    label: string;
  }[];

  public readonly availableGenders: HumanName['gender'][] = ['male', 'female', 'other'];
  public readonly genderInputType: 'radio' | 'select' = 'radio';

  public readonly value$: PureComputed<HumanName>;
  public readonly genderEmpty$: PureComputed<boolean>;

  public readonly gender$ = observable<HumanName['gender']>('unknown');
  public readonly prefixedTitles$: Observable<string> = observable('');
  public readonly initials$: Observable<string> = observable('');
  public readonly givenName$: Observable<string> = observable('');
  public readonly middleNames$: Observable<string> = observable('');
  public readonly familyNamePrefix$: Observable<string> = observable('');
  public readonly familyName$: Observable<string> = observable('');
  public readonly suffixedTitles$: Observable<string> = observable('');

  constructor(deps: ComponentDependencies, params?: HumanNameViewModelParams) {
    super(deps);

    this.readOnly = typeof params?.readOnly === 'boolean' ? params.readOnly : false;

    this.genderReadOnly = Boolean(params?.genderReadOnly) || this.readOnly;
    this.givenNameReadOnly = Boolean(params?.givenNameReadOnly) || this.readOnly;
    this.initialsReadOnly = Boolean(params?.initialsReadOnly) || this.readOnly;
    this.familyNamePrefixReadOnly = Boolean(params?.familyNamePrefixReadOnly) || this.readOnly;
    this.familyNameReadOnly = Boolean(params?.familyNameReadOnly) || this.readOnly;

    if (params && 'useGivenNameOrInitials' in params) {
      throw new Error(
        `'useGivenNameOrInitials' param is no longer supported, use 'givenNameOrInitialsRequired' and 'givenNameOrInitialsEnabled' instead.`
      );
    }

    this.givenNameEnabled = hasValueOrBoth('givenName', params?.givenNameOrInitialsEnabled) ?? true;

    this.initialsEnabled = hasValueOrBoth('initials', params?.givenNameOrInitialsEnabled) ?? false;

    this.givenNameRequired =
      hasValueOrBoth('givenName', params?.givenNameOrInitialsRequired) ?? this.givenNameEnabled;

    this.initialsRequired =
      hasValueOrBoth('initials', params?.givenNameOrInitialsRequired) ?? this.initialsEnabled;

    this.texts = {
      genderLabel: i18next.t('components.contactName.genderLabel', 'Gender'),
      initialsLabel: i18next.t('components.contactName.initialsLabel', 'Initials'),
      initialsValidationMessage: i18next.t('components.contactName.initialsValidationMessage'),
      givenNameLabel: i18next.t('components.contactName.givenNameLabel', 'Given name'),
      familyNamePrefixLabel: i18next.t(
        'components.contactName.familyNamePrefixLabel',
        'Family name prefix'
      ),
      familyNameLabel: i18next.t('components.contactName.familyNameLabel', 'Family name'),
      ...params?.texts,
      genderNames: {
        unknown: i18next.t('components.contactName.genderNames.unknown', 'Unknown'),
        female: i18next.t('components.contactName.genderNames.female', 'Ms.'),
        male: i18next.t('components.contactName.genderNames.male', 'Mr.'),
        other: i18next.t('components.contactName.genderNames.other', 'Other'),
        ...params?.texts?.genderNames
      },
      genderPrefixes: {
        unknown: i18next.t('components.contactName.genderPrefixes.unknown', ''),
        female: i18next.t('components.contactName.genderPrefixes.female', 'Ms.'),
        male: i18next.t('components.contactName.genderPrefixes.male', 'Mr.'),
        other: i18next.t('components.contactName.genderPrefixes.other', ''),
        ...params?.texts?.genderPrefixes
      }
    };

    this.availableGenders = params?.availableGenders ?? this.availableGenders;
    this.genderInputType = params?.genderInputType ?? this.genderInputType;

    this.labeledAvailableGenders = this.availableGenders.map(value => {
      return { value, label: this.texts?.genderNames[value] ?? value };
    });

    this.value$ = pureComputed({
      read(): HumanName {
        const givenName = this.normalizeName(this.givenName$()),
          familyNamePrefix = this.familyNamePrefix$().trim(),
          familyName = this.normalizeName(this.familyName$()),
          splitPrefixedTitles = this.prefixedTitles$()
            .split(' ')
            .map(item => item.trim())
            .filter(Boolean),
          splitInitials = this.initials$()
            .toUpperCase()
            .split('')
            .filter(char => char !== '.' && Boolean(char)),
          splitMiddleNames = this.middleNames$()
            .split(' ')
            .map(item => this.normalizeName(item))
            .filter(Boolean),
          splitSuffixedTitles = this.suffixedTitles$()
            .split(' ')
            .map(item => item.trim())
            .filter(Boolean);

        return {
          gender: this.gender$(),
          prefixedTitles: splitPrefixedTitles,
          initials: splitInitials,
          givenName,
          middleNames: splitMiddleNames,
          familyNamePrefix,
          familyName,
          suffixedTitles: splitSuffixedTitles
        };
      },
      write(value: HumanName) {
        this.gender$(value.gender);
        this.prefixedTitles$(
          value.prefixedTitles.length ? value.prefixedTitles.map(val => val.trim()).join(' ') : ''
        );
        this.initials$(value.initials.length ? value.initials.join('.') + '.' : '');
        this.givenName$(value.givenName);
        this.middleNames$(
          value.middleNames.length ? value.middleNames.map(val => val.trim()).join(' ') : ''
        );
        this.familyNamePrefix$(value.familyNamePrefix);
        this.familyName$(value.familyName);
        this.suffixedTitles$(
          value.suffixedTitles.length ? value.suffixedTitles.map(val => val.trim()).join(' ') : ''
        );
      },
      owner: this
    });

    this.genderEmpty$ = pureComputed(() => {
      return !this.gender$() || this.gender$() === 'unknown';
    });

    if (params?.value$) {
      this.syncObservables(params.value$, this.value$);
    }

    if (params?.isValid$) {
      this.syncObservables(this.isValid$, params.isValid$);
    }

    if (!this.readOnly) {
      this.initializeValidations();
    }
  }

  private normalizeName(name: string): string {
    const trimmedName = name.trim();

    return trimmedName.length > 0 ? trimmedName[0].toUpperCase() + trimmedName.slice(1) : '';
  }

  protected initializeValidations(): void {
    this.gender$
      .extend({
        required: true
      })
      .extend({
        validation: {
          validator: value => {
            return this.availableGenders.includes(value);
          },
          message: validation.rules.required.message
        }
      });

    if (this.initialsEnabled) {
      this.initials$.extend({
        required: this.initialsRequired,
        pattern: {
          params: /^([a-zA-Z]\.?)+$/,
          message: this.texts.initialsValidationMessage
        }
      });
    }

    if (this.givenNameEnabled) {
      this.givenName$.extend({
        required: this.givenNameRequired,
        minLength: 1
      });
    }

    this.familyNamePrefix$.extend({
      required: false,
      minLength: 1
    });

    this.familyName$.extend({
      required: true,
      validation: {
        // Family name must be at least 2 characters, with "A" being the only exception.
        // These are the same rules as enforced by iZZi tasks.
        validator: value => value?.toUpperCase() === 'A' || value?.length >= 2,
        message: validation.rules.minLength.message,
        params: 2
      }
    });
  }

  public onBlur(this: Observable<never>): void {
    validation.group(this).showAllMessages(true);
  }
}

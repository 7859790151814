import { Observable, observable, PureComputed, pureComputed } from 'knockout';
import { Action } from 'redux';

import { ComponentDependencies, CountryCode } from '../../../interfaces';
import {
  setAdditionalContactMobilePhone,
  setAdditionalContactPhone
} from '../../../store/additionalContacts/actions';
import { TelephoneTextInputViewModelParams } from '../../../ui_widgets/telephone-text-input/TelephoneTextInputViewModel';
import { BaseComponentViewModel } from '../../base-component';

type RequiredFieldsType = 'all' | 'any' | 'none';

export interface AdditionalContactTelephoneViewModelParams {
  readOnly?: boolean;
  additionalContactRef?: string;
  phoneReadOnly?: boolean;
  mobileReadOnly?: boolean;
  phoneEnabled?: boolean;
  mobileEnabled?: boolean;
  required?: RequiredFieldsType;
  mobileTexts?: {
    label?: string;
  };
  texts?: {
    label?: string;
    neitherFilledErrorMessage?: string;
  };
}

export class AdditionalContactTelephoneViewModel extends BaseComponentViewModel {
  public readonly phoneReadOnly: boolean;
  public readonly mobileReadOnly: boolean;

  public readonly mobileEnabled: boolean;
  public readonly phoneEnabled: boolean;

  public readonly eitherFilledValidationActive: boolean = false;
  public readonly eitherFilledValidation$: PureComputed<boolean> | undefined;

  public readonly texts: {
    neitherFilledErrorMessage: string;
  };

  readonly phoneNumber$ = observable(null);
  readonly phoneNumberMobile$ = observable(null);

  readonly phoneWidgetParams: TelephoneTextInputViewModelParams;
  readonly mobileWidgetParams: TelephoneTextInputViewModelParams;

  constructor(deps: ComponentDependencies, params?: AdditionalContactTelephoneViewModelParams) {
    super(deps);

    this.mobileReadOnly = Boolean(params?.mobileReadOnly) || Boolean(params?.readOnly);
    this.phoneReadOnly = Boolean(params?.phoneReadOnly) || Boolean(params?.readOnly);

    this.phoneEnabled = params?.phoneEnabled ?? true;
    this.mobileEnabled = params?.mobileEnabled ?? false;

    let required: RequiredFieldsType =
      typeof params?.required === 'boolean'
        ? params?.required
          ? 'all'
          : 'none' // for backwards compatibility: map true/false to all/none
        : params?.required ?? 'none';

    // special case: if required is set to 'any', and exactly one field is enabled,
    // then change it to 'all' so that the required property is set to true on the widget
    if (required === 'any' && this.phoneEnabled !== this.mobileEnabled) {
      required = 'all';
    }

    this.texts = {
      neitherFilledErrorMessage:
        params?.texts?.neitherFilledErrorMessage ??
        this.i18next.t(
          'components.additionalContactTelephone.neitherFilledErrorMessage',
          'Fill any phone field'
        )
    };

    const countryCodesForPhone$ = observable([] as CountryCode[]);
    this.bindObservableToStore(
      countryCodesForPhone$,
      '$.user.restrictions.rules.allowedCountries.phone'
    );

    this.phoneWidgetParams = {
      value$: this.phoneNumber$,
      countryCodes$: countryCodesForPhone$,
      required: required === 'all',
      readOnly: this.phoneReadOnly,
      texts: {
        label: this.i18next.t('components.additionalContactTelephone.label', 'Phone'),
        ...params?.texts
      },
      validateMobile: false
    };

    this.mobileWidgetParams = {
      value$: this.phoneNumberMobile$,
      countryCodes$: countryCodesForPhone$,
      required: required === 'all',
      readOnly: this.mobileReadOnly,
      texts: {
        label: this.i18next.t('components.additionalContactTelephone.mobileLabel', 'Mobile'),
        ...params?.mobileTexts
      },
      validateMobile: true
    };

    const additionalContactRef = params?.additionalContactRef;

    if (this.mobileReadOnly || this.phoneReadOnly) {
      this.bindObservableToStore(
        this.phoneNumber$,
        `$.additionalContacts['${additionalContactRef}'].phoneNumber`
      );
      this.bindObservableToStore(
        this.phoneNumberMobile$,
        `$.additionalContacts['${additionalContactRef}'].phoneNumberMobile`
      );
    } else {
      if (this.phoneEnabled) {
        const phoneStoreActionCreator: (
          phoneNumber: string | null
        ) => Action<unknown> = phoneNumber =>
          setAdditionalContactPhone(additionalContactRef as string, phoneNumber);
        this.bindObservableToStore(
          this.phoneNumber$,
          `$.additionalContacts['${additionalContactRef}'].phoneNumber`,
          phoneStoreActionCreator
        );
      }
      if (this.mobileEnabled) {
        this.bindObservableToStore(
          this.phoneNumberMobile$,
          `$.additionalContacts['${additionalContactRef}'].phoneNumberMobile`,
          phoneNumberMobile =>
            setAdditionalContactMobilePhone(additionalContactRef as string, phoneNumberMobile)
        );
      }
      if (required === 'any') {
        this.eitherFilledValidation$ = pureComputed(() =>
          Boolean(this.phoneNumber$() || this.phoneNumberMobile$())
        );
        this.eitherFilledValidation$.extend({
          equal: {
            params: true,
            message: this.texts.neitherFilledErrorMessage
          }
        } as any);
        this.eitherFilledValidationActive = true;
      }

      this.initializeStateUpdates();
    }
  }
}

import { readPendingOrder } from '../helpers';
import { Order } from '../interfaces';
import { ProductsState } from '../store/products/reducers';

type RuleType = 'some' | 'some-not' | 'all' | 'none';

export class BackorderService {
  public checkIfBackorder(
    order: Order,
    products: ProductsState,
    rule: RuleType = 'some',
    ifOrderEmpty: boolean | undefined = undefined
  ): boolean {
    const orderItems = Object.values(readPendingOrder(order).items);

    if (!orderItems.length && ifOrderEmpty !== undefined) {
      return ifOrderEmpty;
    }

    const isBackorder = item => products[item.sku].backorder;
    const notBackorder = item => !isBackorder(item);

    switch (rule) {
      case 'some':
        return orderItems.some(isBackorder);
      case 'some-not':
        return orderItems.some(notBackorder);
      case 'all':
        return orderItems.every(isBackorder);
      case 'none':
        return orderItems.every(notBackorder);
      default:
        return false;
    }
  }
}

import { components, observable, Observable, pureComputed, PureComputed } from 'knockout';
import { ActiveStageState, ComponentDependencies } from '../../interfaces';

import { BaseComponentViewModel } from '../base-component';

export interface IfStageViewModelParams extends components.ViewModelParams {
  context?: Record<string, any>;
  stages?:
    | string
    | string[]
    | {
        [stageIdentifier: string]: unknown; // for backwards compatibility, when params="stages: { 'name-of-stage': {} }"
      };
}

export class IfStageViewModel extends BaseComponentViewModel {
  public context: Record<string, any>;
  public readonly stages: string[];

  public readonly activeStage$: Observable<ActiveStageState | undefined> = observable();
  public readonly visible$: PureComputed<boolean>;

  constructor(deps: ComponentDependencies, params?: IfStageViewModelParams) {
    super(deps);

    this.context = params?.context || {};

    if (typeof params?.stages === 'string') {
      this.stages = [params.stages];
    } else if (params?.stages instanceof Array) {
      this.stages = params.stages;
    } else if (typeof params?.stages === 'object') {
      this.stages = Object.keys(params.stages);
    } else {
      this.stages = [];
    }

    this.bindObservableToStore(this.activeStage$, '$.app.activeStage');

    this.visible$ = pureComputed(() => {
      const activeStage = this.activeStage$();
      return activeStage ? this.stages.includes(activeStage.stageIdentifier) : false;
    });
  }
}

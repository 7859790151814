import { Action } from 'redux';
import { IdentityOrigin, UserIdentity } from '../../interfaces/UserIdentity';
import { SetAddressAction } from '../contact/actions';

export const SET_VIP_CARD = 'SET_VIP_CARD';
export const DELETE_VIP_CARD = 'DELETE_VIP_CARD';
export const SET_VIP_CARD_VALIDATED = 'SET_VIP_CARD_VALIDATED';

export interface SetVipcardAction extends Action<typeof SET_VIP_CARD> {
  type: typeof SET_VIP_CARD;
  payload: {
    vipcardnumber: UserIdentity;
    identityOrigin: IdentityOrigin;
  };
}

export interface DeleteVipcardAction extends Action<typeof DELETE_VIP_CARD> {
  type: typeof DELETE_VIP_CARD;
  payload: undefined;
}

export interface SetVipcardValidatedAction extends Action<typeof SET_VIP_CARD_VALIDATED> {
  type: typeof SET_VIP_CARD_VALIDATED;
  payload: {
    isValid: boolean;
  };
}

export type UserActionTypes =
  | SetVipcardAction
  | DeleteVipcardAction
  | SetVipcardValidatedAction
  | SetAddressAction;

export function setVipcard(identity: UserIdentity): SetVipcardAction {
  return {
    type: SET_VIP_CARD,
    payload: {
      vipcardnumber: identity,
      identityOrigin: 'input'
    }
  };
}

export function setVipcardFromBackend(identity: UserIdentity): SetVipcardAction {
  return {
    type: SET_VIP_CARD,
    payload: {
      vipcardnumber: identity,
      identityOrigin: 'backend'
    }
  };
}

export function deleteVipcard(): DeleteVipcardAction {
  return {
    type: DELETE_VIP_CARD,
    payload: undefined
  };
}

export function setVipcardValidated(isValid = true): SetVipcardValidatedAction {
  return {
    type: SET_VIP_CARD_VALIDATED,
    payload: {
      isValid
    }
  };
}

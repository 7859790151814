import { AnyContact, ShopContact } from '../../interfaces';
import { State } from '../../../../../store/reducers';
import { CountryCode, GenderIdentifiers } from '../../../../../interfaces';

/*eslint complexity:off */
export function contactStateTranslator(config: AnyContact): State['contact'] {
  const fallbackGenderIdentifier = config.IDTitle ? 'other' : 'unknown';

  const contact: State['contact'] = {
    identifiers: {},
    name: {
      gender: ([1, 2].includes(config.IDTitle)
        ? ['male', 'female'][config.IDTitle - 1]
        : fallbackGenderIdentifier) as GenderIdentifiers,
      prefixedTitles: [],
      initials:
        config.Initials?.split('.')
          .map(item => item.trim())
          .filter(Boolean) ?? [],
      givenName: config.FirstName || '',
      middleNames: [],
      familyNamePrefix: config.NamePrefix || '',
      familyName: config.LastName || '',
      suffixedTitles: []
    },
    formattedName: config.FullName || '',
    address: {
      houseNumber: config.HouseNumber ? String(config.HouseNumber) : '',
      houseNumberExtension: config.HouseNumberExtension ? config.HouseNumberExtension : undefined,
      street: config.Street ? config.Street : '',
      postalCode: config.ZipCode ? config.ZipCode : '',
      city: config.City ? config.City : '',
      'countryCodeISO3166-1': (config.CountryCode as CountryCode | null) ?? 'NL'
    },
    emailAddress: config.RegistrationEmailAddress || null,
    emailConfirmation: null,
    //match phonenumbers with + and numbers
    phoneNumber: config.PhoneNumber1?.match(/(^\+|[\d])/g)?.join('') || null,
    phoneNumberMobile: config.PhoneNumberMobile?.match(/(^\+|[\d])/g)?.join('') || null,
    birthDate: config.BirthDate ? config.BirthDate.split('T')[0] : undefined,
    isTest: false
  };

  if (config.NameOrganisation) {
    contact.organization = {
      name: config.NameOrganisation,
      vatNumber: null
    };
  }

  if (config.OOIDCU) {
    contact.identifiers.primaryIdentifier = config.OOIDCU;
  }

  return contact;
}

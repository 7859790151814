import { v4 as uuidv4 } from 'uuid';
import DOMPurify from 'dompurify';

type NativeDialogExtensionsProxy = HTMLDialogElement;

interface DefaultDialogElementOptions {
  actions?: [actionName: string, actionLabel: string][];
  classes?: string[];
  closeButtonContentHtml?: string;
  titleHtml?: string;
  contentHtml?: string;
}

type DefaultDialogActionProcessor = (action: string, returnValue?: string) => Promise<string>;

type FireDefaultDialogOptions = DefaultDialogElementOptions & {
  processors?: {
    processAction?: DefaultDialogActionProcessor;
  };
  hooks?: {
    beforeOpen?: () => Promise<void>;
    afterOpen?: () => Promise<void>;
    afterAction?: (returnValue: string) => Promise<void>;
    afterClose?: () => Promise<void>;
  };
};

export class NativeDialogExtensions {
  private static dialogObjectMap: WeakMap<HTMLDialogElement, NativeDialogExtensionsProxy> =
    new WeakMap();

  public static getProxy(elem?: HTMLDialogElement | null): NativeDialogExtensionsProxy | null {
    if (!elem) {
      return null;
    }

    const existingProxy = NativeDialogExtensions.dialogObjectMap.get(elem);

    if (existingProxy) {
      return existingProxy;
    }

    const newProxy = new Proxy(elem, {
      get(target, prop) {
        if (prop === 'close') {
          return NativeDialogExtensions.closeProxyMethod.bind(target);
        }

        if (typeof target[prop] === 'function') {
          return target[prop].bind(target);
        }

        return target[prop];
      }
    });

    NativeDialogExtensions.addEventListenersToDialog(elem);
    NativeDialogExtensions.dialogObjectMap.set(elem, newProxy);

    return newProxy;
  }

  private static addEventListenersToDialog(elem: HTMLDialogElement): void {
    elem.addEventListener('click', event => {
      NativeDialogExtensions.handleBackdropClick(elem, event);
    });
  }

  private static handleBackdropClick(elem: HTMLDialogElement, event: MouseEvent) {
    const targetRect = elem.getBoundingClientRect();

    if (
      targetRect &&
      elem.open &&
      (event.clientX < targetRect.left ||
        event.clientX > targetRect.right ||
        event.clientY < targetRect.top ||
        event.clientY > targetRect.bottom)
    ) {
      NativeDialogExtensions.getProxy(elem)?.close('native_dialog_extensions_backdrop_close');
    }
  }

  private static closeProxyMethod(this: HTMLDialogElement, returnValue?: string): void {
    let animationStarted = false;

    this.dataset.closing = '1';

    const animationStartListener = () => {
      animationStarted = true;

      this.addEventListener(
        'animationend',
        () => {
          this.close(returnValue);

          delete this.dataset.closing;

          if (this.dataset.destroyAfterClose) {
            this.remove();
          }
        },
        { once: true }
      );
    };

    this.addEventListener('animationstart', animationStartListener, { once: true });

    window.setTimeout(() => {
      if (!animationStarted) {
        this.removeEventListener('animationstart', animationStartListener);
        this.close(returnValue);

        if (this.dataset.destroyAfterClose) {
          this.remove();
        }
      }
    }, 100);
  }
}

export class DefaultDialog {
  public static async fire(options: FireDefaultDialogOptions): Promise<void> {
    const mergedOptions: FireDefaultDialogOptions = {
      actions: [['close', 'Ok']],
      ...options
    };

    const elem = DefaultDialog.createElement(
      mergedOptions,
      mergedOptions.processors?.processAction
    );

    await mergedOptions.hooks?.beforeOpen?.();

    NativeDialogExtensions.getProxy(elem)?.showModal();

    await mergedOptions.hooks?.afterOpen?.();
  }

  private static createElement(
    options: FireDefaultDialogOptions,
    actionProcessor?: DefaultDialogActionProcessor
  ): HTMLDialogElement {
    // Dialog element

    const elem = document.createElement('dialog');

    elem.id = uuidv4();
    elem.dataset.destroyAfterClose = '1';
    elem.classList.add(...[...(options.classes ?? []), 'native-dialog-extensions-default-dialog']);

    if (options.hooks?.afterClose) {
      elem.addEventListener('close', options.hooks.afterClose);
    }

    // Header

    const header = document.createElement('header'),
      closeButton = document.createElement('button');

    if (options.closeButtonContentHtml) {
      DefaultDialog.addSanitizedHtmlToElement(closeButton, options.closeButtonContentHtml);
    } else {
      const closeButtonContent = document.createElement('span');

      closeButton.classList.add('native-dialog-extensions-default-dialog__close--default');
      closeButtonContent.innerText = 'Close';

      closeButton.appendChild(closeButtonContent);
    }

    closeButton.classList.add('native-dialog-extensions-default-dialog__close');
    closeButton.addEventListener('click', () => {
      NativeDialogExtensions.getProxy(elem)?.close('native_dialog_extensions_default_dialog_close');
    });

    if (options.titleHtml) {
      const heading = document.createElement('h2');

      DefaultDialog.addSanitizedHtmlToElement(heading, options.titleHtml);
      heading.classList.add('native-dialog-extensions-default-dialog__title');

      header.appendChild(heading);
    }

    header.appendChild(closeButton);
    elem.appendChild(header);

    // Content

    if (options.contentHtml) {
      const contentSection = document.createElement('section');

      DefaultDialog.addSanitizedHtmlToElement(contentSection, options.contentHtml);
      contentSection.classList.add('native-dialog-extensions-default-dialog__content');

      elem.appendChild(contentSection);
    }

    // Footer

    if (options.actions) {
      const footer = document.createElement('footer'),
        footerActionsSection = document.createElement('section');

      DefaultDialog.addActionButtonsToElement(
        elem,
        footerActionsSection,
        options.actions,
        actionProcessor
      );

      footerActionsSection.classList.add('native-dialog-extensions-default-dialog__actions');

      footer.appendChild(footerActionsSection);
      elem.appendChild(footer);
    }

    // Insert dialog element into DOM

    document.body.appendChild(elem);

    return elem;
  }

  private static addActionButtonsToElement(
    dialogElem: HTMLDialogElement,
    elem: Element,
    actions: [actionName: string, actionLabel: string][],
    actionProcessor?: DefaultDialogActionProcessor
  ): void {
    actions.forEach(action => {
      const actionButton = document.createElement('button'),
        buttonTextElement = document.createElement('span');

      actionButton.value = action[0];
      buttonTextElement.innerText = action[1];

      if (action[0] === 'submit' || action[0] === 'close') {
        actionButton.addEventListener('click', () => {
          (async () => {
            try {
              let returnValue = actionButton.value;

              if (actionProcessor) {
                returnValue = await actionProcessor(action[0], returnValue);
              }

              NativeDialogExtensions.getProxy(dialogElem)?.close(returnValue);
            } catch (err) {
              console.error(err);
            }
          })();
        });
      }

      actionButton.appendChild(buttonTextElement);
      elem.appendChild(actionButton);
    });
  }

  private static addSanitizedHtmlToElement(elem: Element, html: string): void {
    elem.innerHTML = DOMPurify.sanitize(html);
  }
}

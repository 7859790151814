import { ComponentConfig } from '../../interfaces';

import template from './template.pug';
import { BrowserWarningViewModel } from './BrowserWarningViewModel';

const config: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new BrowserWarningViewModel(deps, params)
  }
};

export default config;

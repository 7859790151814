import { State } from '../store/reducers';

export function componentsValidator(state: State): boolean {
  const componentStates = state.app.componentStates;

  for (const id in componentStates) {
    if (componentStates[id].state !== 'ok') {
      return false;
    }
  }

  return true;
}

import { readPendingOrder } from '../../../helpers';
import { OrderLineMutation } from '../../../interfaces';
import { AppStore } from '../../../store';

export class SingleSkuTransferQuantityRestriction {
  constructor(private store: AppStore) {}

  public enforce(mutationData: OrderLineMutation): OrderLineMutation {
    const storeState = this.store.getState();

    if (storeState.user.restrictions.rules.singleSku !== true) {
      return mutationData;
    }

    const orderItems = readPendingOrder(this.store.getState().order).items;
    const [currentOrderItem] = Object.values(orderItems);

    //Do nothing when selection is not changed or when it is a new order.
    if (currentOrderItem?.sku === mutationData.sku) {
      return mutationData;
    }

    const currentOrderItemQuantity = currentOrderItem?.quantity ?? 0;
    const newOrderItemMaximumQuantity =
      storeState.products[mutationData.sku].maximums.quantity ?? Number.MAX_SAFE_INTEGER;

    const newQuantity =
      currentOrderItemQuantity > 0
        ? Math.min(currentOrderItemQuantity, newOrderItemMaximumQuantity)
        : 1;

    return {
      ...mutationData,
      quantity: newQuantity,
      operation: 'SET'
    };
  }
}

import { components, Observable, observable, PureComputed, pureComputed } from 'knockout';
import { OrderTotalsCalculationHelper } from '../../helpers';
import { User, Product, Order, ComponentDependencies } from '../../interfaces';
import { BaseComponentViewModel } from '../base-component';
import i18next from 'i18next';
import { BeforeStageNavigationEvent } from '../../events';

type Texts = {
  errorNotification: string;
  errorNotificationCloseButtonLabel: string;
};

export interface RemainingCreditWarningViewModelParams extends components.ViewModelParams {
  requireUseAllCredits?: boolean;
  showDialogOnNavigation?: boolean;
  texts?: Partial<Texts>;
}

export class RemainingCreditWarningViewModel extends BaseComponentViewModel {
  public readonly context: any;

  public readonly products$: Observable<{ [key: string]: Product }> = observable({});
  public readonly order$: Observable<Order | undefined> = observable();
  public readonly user$: Observable<User | undefined> = observable();

  public readonly showDialogOnNavigation: boolean = false;
  public readonly requireUseAllCredits: RemainingCreditWarningViewModelParams['requireUseAllCredits'];
  public readonly texts: Texts;

  public readonly userHasCredit$: PureComputed<boolean>;

  constructor(
    deps: ComponentDependencies,
    params?: RemainingCreditWarningViewModelParams,
    componentInfo?: components.ComponentInfo
  ) {
    super(deps);

    this.requireUseAllCredits = params?.requireUseAllCredits ?? false;
    this.showDialogOnNavigation = params?.showDialogOnNavigation ?? false;

    this.texts = {
      errorNotification: getErrorNotificationText(),
      errorNotificationCloseButtonLabel:
        params?.texts?.errorNotificationCloseButtonLabel ??
        i18next.t('components.remainingCreditWarning.errorNotificationCloseButtonLabel', '')
    };

    function getErrorNotificationText() {
      // if content for dialog is explicitly provided, use as provided
      if (params?.texts?.errorNotification) {
        return params.texts.errorNotification;
      }

      // if there are any content children, use that (same content as the message itself).
      if (componentInfo?.templateNodes.length) {
        const dialogContent = document.createElement('div');
        for (const node of componentInfo.templateNodes) {
          dialogContent.appendChild(node.cloneNode());
        }
        return dialogContent.innerHTML;
      }

      // otherwise, fall back to the default text
      return i18next.t('components.remainingCreditWarning.errorNotification');
    }

    this.bindObservableToStore(this.user$, '$.user');
    this.bindObservableToStore(this.products$, '$.products');
    this.bindObservableToStore(this.order$, '$.order');

    this.userHasCredit$ = pureComputed(() => {
      return OrderTotalsCalculationHelper.userHasCredit(
        this.order$(),
        this.products$(),
        this.user$()
      );
    });

    if (this.requireUseAllCredits) {
      this.userHasCredit$.extend({ equal: false } as any);
    }

    if (this.showDialogOnNavigation) {
      this.disposalFunctions.push(
        deps.appEventManager.on(BeforeStageNavigationEvent, ev => {
          if (ev.direction === 'forward' && this.userHasCredit$() && this.requireUseAllCredits) {
            this.notifications.notify({
              class: 'error',
              message: this.texts.errorNotification,
              actions: [{ code: 'close', label: this.texts.errorNotificationCloseButtonLabel }],
              extraData: {
                source: 'RemainingCreditWarning'
              }
            });
          }
        })
      );
    }

    this.initializeStateUpdates();
  }
}

import { RouteName } from '../interfaces/Routes';

export function setAnalyticsStages(map: Record<RouteName, string>) {
  const mapping = (window.$skxPageTypeMapping ??= {});
  Object.assign(mapping, map);
}

export function sendPageEvent(routeName: RouteName) {
  if (window.$skxRouterEvent) window.$skxRouterEvent(routeName);
}

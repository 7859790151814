import { ComponentConfig } from '../../interfaces';

import template from './template.pug';
import { OrderRemarksViewModel } from './OrderRemarksViewModel';

const config: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new OrderRemarksViewModel(deps, params)
  }
};

export default config;

/**
 * Trims any excess characters surrounding a Json string
 */
export function trimToJson(value: string) {
  if (!value) {
    return value;
  }

  const firstBracketIndex: number = value.indexOf('{');
  const lastBracketIndex: number = value.lastIndexOf('}');

  if (firstBracketIndex === -1 || lastBracketIndex === -1 || lastBracketIndex < firstBracketIndex) {
    // These checks do not guarantee valid Json per se, but we know it is definitely not valid if it does not follow these conditions.
    throw new Error('No valid Json was found in the given string.');
  }

  return value.substring(firstBracketIndex, lastBracketIndex + 1); // +1 to include the bracket
}

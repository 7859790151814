/*
  1) Remove visibility: hidden and display:none from html, body and main elements.
  2) Replace all app-not-ready classes with app-ready classes.

 This can be used to counter the flash of unstyled content.
 */
export function switchAppReadyStyles(): void {
  const notReadyClass = 'app-not-ready';
  const readyClass = 'app-ready';

  for (const el of document.querySelectorAll<HTMLElement>('.' + notReadyClass)) {
    el.classList.replace(notReadyClass, readyClass);
  }

  for (const el of document.querySelectorAll<HTMLElement>('html, body, main')) {
    if (el.style.visibility === 'hidden') el.style.visibility = '';
    if (el.style.display === 'none') el.style.display = '';
  }
}

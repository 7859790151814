import { ComponentConfig } from '../../../interfaces';
import template from './template.html';
import { ProductFilterViewModel } from './ProductFilterViewModel';

export const productFilterComponent: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params?) => new ProductFilterViewModel(deps, params)
  }
};

export function filterObject<T extends object>(
  input: T,
  predicate: <K extends keyof T, V extends T[K]>(key: K, value: V) => boolean
): Partial<T> {
  const output: Partial<T> = {};
  for (const k of Object.keys(input) as Array<keyof T>) {
    const v = input[k];
    if (predicate(k, v)) {
      output[k] = v;
    }
  }
  return output;
}

import { observable, pureComputed, PureComputed, Observable } from 'knockout';

import { ComponentDependencies } from '../../../interfaces';
import { BaseComponentViewModel } from '../../base-component';
import { componentsValidator } from '../../../validators';
import { UserIdentificationService } from '../../../services';

export class VipcardSubmitButtonViewModel extends BaseComponentViewModel {
  public enabled$: PureComputed<boolean>;
  public submitting$ = observable(false);

  private userIdentification: UserIdentificationService;
  constructor(deps: ComponentDependencies) {
    super(deps);
    this.userIdentification = deps.userIdentification;

    const componentStates$ = observable();

    this.enabled$ = pureComputed(() => {
      // Make sure this computed observable is updated when dependencies change.
      void componentStates$();
      return !this.submitting$() && componentsValidator(this.store.getState());
    });

    this.bindObservableToStore(componentStates$, '$.app.componentStates');
    this.bindObservableToStore(this.submitting$, '$.order._submitting.vipcard');
  }

  onClickSubmit = () => {
    void this.userIdentification.validateVipcard();
  };
}

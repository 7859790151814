import i18next from 'i18next';
import { components, Observable, observable, PureComputed, pureComputed } from 'knockout';

import { ComponentDependencies, PaymentOption } from '../../interfaces';
import { BaseComponentViewModel } from '../base-component';
import { setPaymentOptionCode } from '../../store/order/actions';
import { paymentOptionCodeValidator } from '../../validators/paymentOptionValidator';

export interface PaymentOptionSelectorViewModelParams extends components.ViewModelParams {
  texts?: {
    label?: string;
  };
}

type PaymentOptionSelectorOption = {
  code: PaymentOption['code'];
  label: string;
};

export class PaymentOptionSelectorViewModel extends BaseComponentViewModel {
  public readonly countryCode$: Observable<string | undefined> = observable();

  public readonly texts: {
    label: string;
  };

  public readonly paymentOptions$ = observable<PaymentOption[]>([]);
  public readonly value$: Observable<PaymentOption['code'] | null> = observable(null);

  public readonly options$: PureComputed<PaymentOptionSelectorOption[]>;

  constructor(deps: ComponentDependencies, params?: PaymentOptionSelectorViewModelParams) {
    super(deps);

    this.texts = {
      label: i18next.t('components.paymentOptionSelector.label', 'Select a payment option'),
      ...params?.texts
    };

    this.options$ = pureComputed(() => {
      const paymentOptions = this.paymentOptions$(),
        normalizedCodes: Set<string> = new Set();

      return paymentOptions.reduce((options: PaymentOptionSelectorOption[], paymentOption) => {
        if (!normalizedCodes.has(paymentOption.code.toLowerCase())) {
          options.push({
            code: paymentOption.code,
            label: i18next.t(`paymentOptions.${paymentOption.code}`, paymentOption.code)
          });

          normalizedCodes.add(paymentOption.code.toLowerCase());
        }

        return options;
      }, []);
    });

    this.value$.extend({
      required: true,
      minLength: 1
    });

    this.initializeStateUpdates();

    this.bindObservableToStore(this.paymentOptions$, '$.payment.paymentOptions');
    this.bindObservableToStore(this.value$, '$.order.payment.paymentOptionCode', value => {
      if (value && paymentOptionCodeValidator(this.store.getState(), value)) {
        return setPaymentOptionCode(value);
      }

      return undefined;
    });
  }
}

import { HumanName } from '..';

export class HumanNameHelper {
  static formatName(name: HumanName): string {
    const formattedInitials = name.initials.length ? this.formatInitials(name) : '';

    const formattedGivenName = name.givenName ? name.givenName + ' ' : '';

    const formattedFamilyNamePrefix = name.familyNamePrefix ? name.familyNamePrefix + ' ' : '';

    const formattedFamilyName = name.familyName ? name.familyName : '';

    return `${
      formattedInitials || formattedGivenName
    }${formattedFamilyNamePrefix}${formattedFamilyName}`;
  }

  static formatInitials(name: HumanName): string {
    return name.initials.length
      ? name.initials.join('.') + '.'
      : name.givenName.toUpperCase().substring(0, 1);
  }
}

import { Observable, observable } from 'knockout';
import { ComponentDependencies } from '../../../interfaces';
import { setAdditionalField } from '../../../store/order/actions';
import { QuantitySpinnerViewModelParams } from '../../../ui_widgets/quantity-spinner/QuantitySpinnerViewModel';
import { BaseComponentViewModel } from '../../base-component';

export interface AdditionalQuantityFieldViewModelParams extends QuantitySpinnerViewModelParams {
  min?: number;
  max?: number;
  step?: number;
  allowTyping?: boolean;
  additionalFieldKey?: string;
  texts?: QuantitySpinnerViewModelParams['texts'];
}

export class AdditionalQuantityFieldViewModel extends BaseComponentViewModel {
  readonly value$: Observable<number>;
  readonly widgetParams: QuantitySpinnerViewModelParams;

  private readOnly: boolean;

  constructor(deps: ComponentDependencies, params?: AdditionalQuantityFieldViewModelParams) {
    super(deps);

    this.readOnly = params?.readOnly ?? false;
    this.value$ = observable() as Observable<number>;

    this.widgetParams = {
      value$: this.value$,
      readOnly: this.readOnly,
      min: params?.min,
      max: params?.max,
      step: params?.step,
      allowTyping: params?.allowTyping,
      texts: params?.texts
    };

    if (params?.additionalFieldKey) {
      const pathInStore = '$.order.additionalFields.' + params.additionalFieldKey;
      const storeActionCreator = value =>
        setAdditionalField({
          key: params.additionalFieldKey as string,
          value
        });

      if (this.readOnly) {
        this.bindObservableToStore(this.value$, pathInStore);
      } else {
        this.bindObservableToStore(this.value$, pathInStore, storeActionCreator);
      }
    }
    this.initializeStateUpdates();
  }
}

import { User } from '../../interfaces';

export const initialState: User = {
  identities: {},
  restrictions: {
    amounts: {
      currency: {},
      tokens: {}
    },
    rules: {
      allowedCountries: {
        contact: ['NL'],
        delivery: ['NL'],
        phone: ['NL']
      }
    }
  },
  features: {
    orderRemarks: true,
    deliveryAddress: true
  }
};

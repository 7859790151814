import { Contact } from '../../interfaces';
import { initialState } from './initialState';
import {
  ContactActionTypes,
  DELETE_CONTACT_IDENTIFIER,
  DELETE_ORGANIZATION,
  SET_ADDRESS,
  SET_BIRTH_DATE,
  SET_CONTACT_IDENTIFIER,
  SET_EMAIL,
  SET_EMAIL_CONFIRMATION,
  SET_IS_TEST,
  SET_MOBILE_PHONE,
  SET_NAME,
  SET_ORGANIZATION,
  SET_PHONE,
  SET_GENDER
} from './actions';
import { HumanNameHelper } from '../../helpers/HumanNameHelper';

export type ContactIdentifiersState = Contact['identifiers'];

export interface ContactIdentifier {
  key: keyof Contact['identifiers'];
  value: string;
}

export function contactReducer(state = initialState, action: ContactActionTypes): Contact {
  /* eslint complexity: ["warn", 30] */

  switch (action.type) {
    case SET_CONTACT_IDENTIFIER: {
      return {
        ...state,
        identifiers: {
          ...state.identifiers,
          [action.payload.key]: action.payload.value
        }
      };
    }

    case DELETE_CONTACT_IDENTIFIER: {
      const identifiersState: ContactIdentifiersState = {
        ...state.identifiers
      };

      delete identifiersState[action.meta.key];

      return {
        ...state,
        identifiers: identifiersState
      };
    }

    case SET_EMAIL: {
      return {
        ...state,
        emailAddress: action.payload
      };
    }

    case SET_EMAIL_CONFIRMATION: {
      return {
        ...state,
        emailConfirmation: action.payload
      };
    }

    case SET_PHONE: {
      return {
        ...state,
        phoneNumber: action.payload
      };
    }

    case SET_MOBILE_PHONE: {
      return {
        ...state,
        phoneNumberMobile: action.payload
      };
    }

    case SET_NAME: {
      return {
        ...state,
        name: {
          ...action.payload
        },
        formattedName: HumanNameHelper.formatName(action.payload)
      };
    }

    case SET_GENDER: {
      return {
        ...state,
        name: {
          ...state.name,
          gender: action.payload
        }
      };
    }

    case SET_ADDRESS: {
      return {
        ...state,
        address: {
          ...action.payload
        }
      };
    }

    case SET_ORGANIZATION: {
      const modifiedState = { ...state },
        name = action.payload?.name || null,
        vatNumber = action.payload?.vatNumber || null;

      if (typeof action.payload === 'undefined' || (name === null && vatNumber === null)) {
        delete modifiedState.organization;

        return modifiedState;
      }

      modifiedState.organization = {
        name: action.payload.name || null,
        vatNumber: action.payload.vatNumber || null
      };

      return modifiedState;
    }

    case DELETE_ORGANIZATION: {
      const modifiedState = { ...state };

      delete modifiedState.organization;

      return modifiedState;
    }

    case SET_BIRTH_DATE: {
      const modifiedState = { ...state };

      if (typeof action.payload === 'undefined' || action.payload === null) {
        delete modifiedState.birthDate;

        return modifiedState;
      }

      try {
        modifiedState.birthDate = new Date(action.payload).toISOString().split('T')[0];
      } catch (err) {
        console.error(err);
      }

      return modifiedState;
    }

    case SET_IS_TEST: {
      return {
        ...state,
        isTest: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

import { PaymentOption } from '../../interfaces';
import { initialState } from './initialState';
import { ADD_PAYMENT_OPTION, DELETE_PAYMENT_OPTION, PaymentActionTypes } from './actions';

export type PaymentState = {
  paymentOptions: PaymentOption[];
};

export function paymentReducer(state = initialState, action: PaymentActionTypes): PaymentState {
  switch (action.type) {
    case ADD_PAYMENT_OPTION: {
      if (
        state.paymentOptions.find(paymentOption => {
          return paymentOption.code.toLowerCase() === action.payload.code.toLowerCase();
        })
      ) {
        return state;
      }

      return {
        ...state,
        paymentOptions: [...state.paymentOptions, action.payload]
      };
    }

    case DELETE_PAYMENT_OPTION: {
      const paymentOptionIndex = state.paymentOptions.findIndex(paymentOption => {
        return paymentOption.code.toLowerCase() === action.meta.code.toLowerCase();
      });

      if (paymentOptionIndex < 0) {
        return state;
      }

      const newState = {
        ...state,
        paymentOptions: [...state.paymentOptions]
      };

      newState.paymentOptions.splice(paymentOptionIndex, 1);

      return newState;
    }

    default: {
      return state;
    }
  }
}

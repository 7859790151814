import template from './template.pug';
import { ComponentConfig } from '../../../interfaces';
import { IfOptedInViewModel } from './IfOptedInViewModel';

const config: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new IfOptedInViewModel(deps, params)
  }
};

export default config;

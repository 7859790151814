import { AllowedEvent, AnyContact, ProductGrouping } from '../../interfaces';
import { State } from '../../../../../store/reducers';
import { Product } from '../../../../../interfaces';
import { allowedEventsSorter } from './utils';
import { allowedEventIsOptIn } from './optInsStateTranslator';

export function productsStateTranslator(config: AnyContact): State['products'] {
  /* eslint-disable complexity */

  const products: State['products'] = {},
    variantGroupParentSkus: Map<ProductGrouping['Code'], Product['sku']> = new Map();

  if (Array.isArray(config.AllowedEvents) && config.AllowedEvents.length) {
    config.AllowedEvents.sort(allowedEventsSorter);

    config.AllowedEvents.filter(allowedEvent => !allowedEventIsOptIn(allowedEvent)).forEach(
      (allowedEvent: AllowedEvent, index) => {
        if (!allowedEvent?.IDCampagneEvent) {
          return;
        }

        const product: Product = {
          title: allowedEvent.DescriptionUsingTemplate
            ? allowedEvent.DescriptionUsingTemplate
            : allowedEvent.Description,
          sku: String(allowedEvent.IDCampagneEvent),
          variantName:
            allowedEvent.VariantDescription ??
            allowedEvent.Texts?.find(text => text.Code === 'VariantDescription')?.Text ??
            undefined,
          sequenceNumber: allowedEvent.EventNumber,
          location: allowedEvent.Location ?? undefined,
          amounts: {
            tokens: {
              izzi_points: allowedEvent.PointsValue
            },
            currency: translateCurrencyAmounts(allowedEvent)
          },
          maximums: {
            quantity:
              typeof allowedEvent.MaxNumberPerRegistration === 'number' &&
              allowedEvent.MaxNumberPerRegistration > 0
                ? allowedEvent.MaxNumberPerRegistration
                : undefined,
            tokens: {},
            currency: {}
          },
          minimums: {
            quantity:
              typeof allowedEvent.MinNumberPerRegistration === 'number'
                ? allowedEvent.MinNumberPerRegistration
                : 0
          },
          tags: allowedEvent.Tags.map(tag => tag.Name),
          content: {
            texts: {},
            customTexts: {},
            images: [
              {
                description: allowedEvent.Description,
                url: allowedEvent.ThumbnailUrl
              }
            ],
            pages: [],
            videos: {}
          },
          delivery: {
            supportsPhysicalDelivery: Boolean(allowedEvent.WillTriggerShipment)
          },
          backorder: Boolean(allowedEvent.IsOnWaitingList)
        };

        if (allowedEvent.MinDeliveryDate) {
          try {
            product.delivery.deliverableFrom = new Date(allowedEvent.MinDeliveryDate).toISOString();
          } catch (err) {
            console.error(err);
          }
        }

        if (allowedEvent.MaxDeliveryDate) {
          try {
            product.delivery.deliverableUntil = new Date(
              allowedEvent.MaxDeliveryDate
            ).toISOString();
          } catch (err) {
            console.error(err);
          }
        }

        if (product.delivery.deliverableFrom && product.delivery.deliverableUntil) {
          product.delivery.deliveryWindows = [
            {
              start: product.delivery.deliverableFrom,
              end: product.delivery.deliverableUntil
            }
          ];
        }

        if (allowedEvent.ProductGrouping?.Code) {
          const productGroupingCode: ProductGrouping['Code'] = allowedEvent.ProductGrouping?.Code;

          if (variantGroupParentSkus.has(productGroupingCode)) {
            product.parentSku = variantGroupParentSkus.get(productGroupingCode);
          } else {
            variantGroupParentSkus.set(productGroupingCode, product.sku);
          }
        }

        // --- Hacky implementation for gameshows ---
        // Make all products a variant of each other. This makes it easier to leverage certain
        // framework options, such as the dropdown rendering of the ProductSummary component.
        // This can be safely done, because when MultipleItemsPerContact is false, this implies
        // that the campaign also does not have Main/Sub-arrangements, but instead only uses
        // CampaignEvents, and only a single CampaignEvent can be chosen.
        if (!config.MultipleItemsPerContact && index > 0) {
          product.parentSku = String(config.AllowedEvents[0].IDCampagneEvent);
        }

        if (allowedEvent.Texts) {
          product.content.customTexts = allowedEvent.Texts.reduce((acc, item) => {
            acc[item.Code] = item.Text;
            return acc;
          }, {});
        }

        if (allowedEvent.LongDescription) {
          product.content.texts.listing_description = allowedEvent.LongDescription;
        }

        if (allowedEvent.ContentDescription) {
          product.content.texts.long_description = allowedEvent.ContentDescription;
        }

        if (allowedEvent.ContentUrl) {
          try {
            const url = new URL(allowedEvent.ContentUrl, window?.location.origin);

            if (url.pathname !== '/') {
              product.content.pages.push({
                type: 'modal',
                url: allowedEvent.ContentUrl
              });
            }
          } catch (err) {
            console.error(err);
          }
        }

        if (allowedEvent.EventDate) {
          product.dateTime = {
            start: new Date(allowedEvent.EventDate).toISOString()
          };
        }

        products[allowedEvent.IDCampagneEvent] = product;
      }
    );
  }

  return products;

  /* eslint-enable complexity */
}

function translateCurrencyAmounts(allowedEvent: AllowedEvent): Product['amounts']['currency'] {
  if (
    allowedEvent.Price ||
    allowedEvent.Price === 0 ||
    (Array.isArray(allowedEvent.TierPrices) && allowedEvent.TierPrices.length)
  ) {
    return {
      EUR: {
        base: allowedEvent.Price ?? 0,
        tiers: allowedEvent.TierPrices.filter(tierPrice => tierPrice.IDTierPriceRole === 5).map(
          tierPrice => {
            return {
              minQuantity: tierPrice.Quantity,
              value: tierPrice.Price
            };
          }
        )
      }
    };
  }

  return {};
}

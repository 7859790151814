import { User } from '../../interfaces';
import {
  DELETE_VIP_CARD,
  DeleteVipcardAction,
  SET_VIP_CARD,
  SET_VIP_CARD_VALIDATED,
  SetVipcardAction,
  SetVipcardValidatedAction,
  UserActionTypes
} from './actions';
import { initialState } from './initialState';
import { SET_ADDRESS, SetAddressAction } from '../contact/actions';

export function userReducer(state = initialState, action: UserActionTypes): User {
  switch (action.type) {
    case SET_VIP_CARD:
      return setVipcardState(state, action);
    case DELETE_VIP_CARD:
      return deleteVipcardState(state, action);
    case SET_VIP_CARD_VALIDATED:
      return setVipcardValidatedState(state, action);
    case SET_ADDRESS: {
      return setAddressState(state, action);
    }
  }
  return state;
}

function setVipcardState(state: User, action: SetVipcardAction): User {
  return {
    ...state,
    identities: {
      ...state.identities,
      vipcardnumber: {
        ...action.payload.vipcardnumber,
        validated: false,
        identityOrigin: action.payload.identityOrigin
      }
    }
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function deleteVipcardState(state: User, action: DeleteVipcardAction): User {
  return {
    ...state,
    identities: {}
  };
}

function setVipcardValidatedState(state: User, action: SetVipcardValidatedAction): User {
  if (!state.identities.vipcardnumber) {
    return state;
  }

  return {
    ...state,
    identities: {
      vipcardnumber: {
        ...state.identities.vipcardnumber,
        validated: action.payload.isValid
      }
    }
  };
}

function setAddressState(state: User, action: SetAddressAction) {
  return {
    ...state,
    restrictions: {
      ...state.restrictions,
      rules: {
        ...state.restrictions.rules,
        allowedCountries: {
          ...state.restrictions.rules.allowedCountries,
          phone: [...new Set(['NL', action.payload['countryCodeISO3166-1'] || 'NL'])]
        } as any
      }
    }
  };
}

import { Contact } from '../../interfaces';

export const initialState: Contact = {
  identifiers: {},
  name: {
    gender: 'unknown',
    prefixedTitles: [],
    initials: [],
    givenName: '',
    middleNames: [],
    familyNamePrefix: '',
    familyName: '',
    suffixedTitles: []
  },
  formattedName: '',
  address: {
    houseNumber: '',
    street: '',
    postalCode: '',
    city: '',
    'countryCodeISO3166-1': 'NL'
  },
  emailAddress: null,
  emailConfirmation: null,
  phoneNumber: null,
  phoneNumberMobile: null,
  isTest: false
};

Object.freeze(initialState);

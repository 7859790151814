import { CampagneCategory, AnyContact } from '../../interfaces';
import { State } from '../../../../../store/reducers';
import { Category } from '../../../../../interfaces';

export function categoriesStateTranslator(config: AnyContact): State['categories'] {
  const categories: State['categories'] = {};

  if (Array.isArray(config.CampagneCategories) && config.CampagneCategories.length) {
    config.CampagneCategories?.forEach((campagneCategory: CampagneCategory) => {
      if (!campagneCategory.IDCampagneCategory) {
        return;
      }

      campagneCategory.CampagneEventMappings.sort((a, b) => a.SequenceNumber - b.SequenceNumber);

      const category: Category = {
        identifier: String(campagneCategory.IDCampagneCategory),
        name: campagneCategory.Description,
        skus: campagneCategory.CampagneEventMappings.map(mapping =>
          String(mapping.IDCampagneEvent)
        ),
        sequenceNumber: campagneCategory.SequenceNumber ?? 0,
        minNumberOfItemsPerRegistration: campagneCategory.MinNumberOfItemsPerRegistration ?? null,
        maxNumberOfItemsPerRegistration: campagneCategory.MaxNumberOfItemsPerRegistration ?? null
      };

      categories[String(campagneCategory.IDCampagneCategory)] = category;
    });
  }

  return categories;
}

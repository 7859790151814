import { observable, Observable, pureComputed, PureComputed, components } from 'knockout';
import i18next from 'i18next';

import { User, Product, Order, ComponentDependencies } from '../../interfaces';
import { OrderTotalsCalculationHelper } from '../../helpers';

import { BaseComponentViewModel } from '../base-component';

export interface CreditCounterViewModelParams extends components.ViewModelParams {
  texts?: {
    prefix?: string;
    suffix?: string;
  };
}

export class CreditCounterViewModel extends BaseComponentViewModel {
  public readonly texts: {
    prefix?: string;
    suffix?: string;
    prefix$: PureComputed<string>;
    suffix$: PureComputed<string>;
  };

  public readonly products$: Observable<{ [key: string]: Product }> = observable({});
  public readonly order$: Observable<Order | undefined> = observable();
  public readonly user$: Observable<User | undefined> = observable();

  public readonly credit$: PureComputed<number>;
  public readonly displayCredit$: PureComputed<string>;

  constructor(deps: ComponentDependencies, params?: CreditCounterViewModelParams) {
    super(deps);

    this.texts = {
      ...params?.texts,
      prefix$: pureComputed(() => {
        return (
          params?.texts?.prefix ??
          i18next.t('components.creditCounter.prefix', 'You have got', {
            count: this.credit$()
          }) ??
          'You have got'
        );
      }),
      suffix$: pureComputed(() => {
        return (
          params?.texts?.suffix ??
          i18next.t('components.creditCounter.suffix', 'credits left', {
            count: this.credit$()
          }) ??
          'credits left'
        );
      })
    };

    this.displayCredit$ = pureComputed(() => {
      return i18next.t('components.creditCounter.value', '{{count}}', {
        count: this.credit$()
      });
    });

    this.bindObservableToStore(this.user$, '$.user');
    this.bindObservableToStore(this.products$, '$.products');
    this.bindObservableToStore(this.order$, '$.order');

    this.credit$ = pureComputed(() => {
      const order = this.order$(),
        products = this.products$(),
        user = this.user$();

      const orderTokenTotals =
        order && products ? OrderTotalsCalculationHelper.calculateTokenTotals(order, products) : {};

      let credit = 0;

      if (!user || typeof user.restrictions.amounts.tokens.izzi_points !== 'number') {
        return credit;
      }

      credit = user.restrictions.amounts.tokens.izzi_points;

      if (orderTokenTotals.izzi_points) {
        credit -= orderTokenTotals.izzi_points;
      }

      return credit;
    });
  }
}

import i18next from 'i18next';
import {
  components,
  observable,
  Observable,
  PureComputed,
  pureComputed,
  validation
} from 'knockout';
import { ValidationExtendOptions } from 'knockout.validation';
import { ComponentDependencies, UserIdentificationMode } from '../../interfaces';
import { LoginRequirements, VerificationChallengeFormat } from '../../interfaces/LoginRequirements';
import { UserIdentityWithValidationState } from '../../interfaces/UserIdentity';
import { LoginCredentials, UserIdentificationService } from '../../services';
import { SegmentedTextInputViewModelParams } from '../../ui_widgets/segmented-text-input/SegmentedTextInputViewModel';

import { BaseComponentViewModel } from '../base-component';

const validationsPerFormat: Record<VerificationChallengeFormat, ValidationExtendOptions> = {
  postalcode: {
    pattern: /^[0-9]{4}[ ]?[A-Z]{2}$/
  },
  housenumber: {
    digit: true
  }
};

export interface LoginViewModelParams extends components.ViewModelParams {
  mode?: UserIdentificationMode;
  segments?: SegmentedTextInputViewModelParams['segments'];
  minLength?: number; // if not specified, default to total segments length
  maxLength?: number; // if not specified, default to minLength
  prefix?: string; //prefix in front of identifier
  prefill?: string;
  texts?: {
    identifierLabel?: string;
    identifierPlaceholder?: string;
    identifierExplanation?: string;
    verificationChallengeExplanation?: string;
    verificationChallengeLabel1?: string;
    verificationChallengePlaceholder1?: string;
    verificationChallengeExplanation1?: string;
    verificationChallengeLabel2?: string;
    verificationChallengePlaceholder2?: string;
    verificationChallengeExplanation2?: string;
    login?: string;
  };
}

export class LoginViewModel extends BaseComponentViewModel {
  private readonly mode: UserIdentificationMode;
  private readonly userIdentificationService: UserIdentificationService;
  private readonly prefix: string;

  public readonly texts: {
    identifierLabel: string;
    identifierPlaceholder: string;
    identifierExplanation: string;
    verificationChallengeLabel1: string;
    verificationChallengePlaceholder1: string;
    verificationChallengeExplanation1: string;
    verificationChallengeLabel2: string;
    verificationChallengePlaceholder2: string;
    verificationChallengeExplanation2: string;
    login: string;
  };

  public readonly formElements: {
    identifier$: Observable<string | null | undefined>;
    verificationChallenge1$: PureComputed<string | undefined> &
      validation.ObservableValidationExtension;
    verificationChallenge2$: Observable<string | undefined> &
      validation.ObservableValidationExtension;
    widgetIsValid$: Observable<boolean>;
  };

  public readonly validated$: Observable<boolean> = observable(false);
  public readonly busy$: Observable<boolean> = observable(false);

  public readonly loggedIn$: PureComputed<boolean>;
  public readonly enableSubmitButton$: PureComputed<boolean>;

  public readonly widgetParams!: SegmentedTextInputViewModelParams;

  public readonly loginRequirements$ = observable() as Observable<LoginRequirements>;

  constructor(deps: ComponentDependencies, params?: LoginViewModelParams) {
    super(deps);

    this.formElements = {
      identifier$: observable(),
      verificationChallenge1$: undefined as any,
      verificationChallenge2$: undefined as any,
      widgetIsValid$: observable(false)
    };

    if (
      params &&
      ('requestVerificationChallenge1' in params ||
        'requestVerificationChallenge2' in params ||
        'verificationChallenge1Format' in params ||
        'verificationChallenge2Format' in params ||
        'forceVerificationChallenge1Uppercase' in params)
    ) {
      throw new Error(
        '<login> component no longer supports explicit verificationChallenge related params.'
      );
    }

    if (params?.mode === 'vipcardnumber') {
      // for vipcard, hardcode the requirements
      this.loginRequirements$({
        requestVerificationChallenge1: true,
        requestVerificationChallenge2: true,
        verificationChallenge1Format: 'postalcode',
        verificationChallenge2Format: 'housenumber',
        forceVerificationChallenge1Uppercase: true
      });
    } else {
      // otherwise, use dynamic values based on campaign settings
      this.bindObservableToStore(this.loginRequirements$, '$.storeBackend.loginRequirements');
    }

    if (params?.mode === 'vipcardnumber') {
      const vipcardValidationState$ = observable<UserIdentityWithValidationState | undefined>();
      this.subscriptions.push(
        vipcardValidationState$.subscribe(userIdentity => {
          this.validated$(userIdentity?.validated ?? false);
        })
      );
      this.bindObservableToStore(vipcardValidationState$, '$.user.identities.vipcardnumber');
    }

    this.mode = params?.mode ?? 'login';
    this.userIdentificationService = deps.userIdentification;
    this.prefix = params?.prefix ?? '';

    this.texts = {
      identifierLabel: i18next.t('components.login.identifierLabel', 'Login code'),
      identifierPlaceholder: i18next.t('components.login.identifierPlaceholder', 'Login code'),
      identifierExplanation: i18next.t('components.login.identifierExplanation', ''),
      login: i18next.t('components.login.login', 'Log in'),
      verificationChallengeExplanation: i18next.t(
        'components.login.verificationChallengeExplanation',
        ''
      ),
      verificationChallengeLabel1: i18next.t(
        'components.login.verificationChallengeLabel1',
        'Verification'
      ),
      verificationChallengePlaceholder1: i18next.t(
        'components.login.verificationChallengePlaceholder1',
        'Verification'
      ),
      verificationChallengeExplanation1: i18next.t(
        'components.login.verificationChallengeExplanation1',
        ''
      ),
      verificationChallengeLabel2: i18next.t(
        'components.login.verificationChallengeLabel2',
        'Verification'
      ),
      verificationChallengePlaceholder2: i18next.t(
        'components.login.verificationChallengePlaceholder2',
        'Verification'
      ),
      verificationChallengeExplanation2: i18next.t(
        'components.login.verificationChallengeExplanation2',
        ''
      ),
      ...params?.texts
    };

    if (params?.prefill) {
      this.formElements.identifier$(params.prefill);
    }

    const predefinedIdentifier = this.userIdentificationService.getPredefinedIdentifier();
    if (predefinedIdentifier) {
      this.formElements.identifier$(predefinedIdentifier);
    }

    this.loggedIn$ = pureComputed(() => {
      return !this.busy$() && this.validated$();
    }).extend({ equal: true } as never);

    const verificationChallenge1Raw$ = observable();
    this.formElements.verificationChallenge1$ = pureComputed<string | undefined>({
      read(): string {
        return verificationChallenge1Raw$();
      },
      write(value) {
        verificationChallenge1Raw$(
          this.forceVerificationChallenge1Uppercase ? value?.toUpperCase() : value
        );
      },
      owner: this
    }).extend({
      required: this.requestVerificationChallenge1,
      ...validationsPerFormat[this.verificationChallenge1Format ?? '']
    });

    this.formElements.verificationChallenge2$ = observable().extend({
      required: this.requestVerificationChallenge2,
      ...validationsPerFormat[this.verificationChallenge2Format ?? '']
    });

    this.enableSubmitButton$ = pureComputed(() => {
      return (
        !this.busy$() &&
        this.formElements.widgetIsValid$() &&
        this.formElements.verificationChallenge1$.isValid() &&
        this.formElements.verificationChallenge2$.isValid()
      );
    });

    this.widgetParams = this.createWidgetParams(params);

    this.initializeStateUpdates();
  }

  private createWidgetParams(params?: LoginViewModelParams): SegmentedTextInputViewModelParams {
    const segments = params?.segments ?? [
      { size: 255, placeholder: this.texts.identifierPlaceholder }
    ];

    const sumOfSegmentSizes = segments.reduce((acc, { size }) => acc + (size ?? 0), 0);
    const minLength = params?.minLength ?? (this.mode === 'login' ? 0 : sumOfSegmentSizes);
    const maxLength = params?.maxLength ?? sumOfSegmentSizes;

    return {
      value$: this.formElements.identifier$,
      isValid$: this.formElements.widgetIsValid$,
      readOnly: false,
      required: true,
      segments,
      minLength,
      maxLength,
      preferNumericKeyboard: this.mode === 'vipcardnumber',
      texts: {
        label: this.texts.identifierLabel
      }
    };
  }

  public submit: () => void = () => {
    if (this.busy$()) {
      return;
    }

    this.validated$(false);
    this.busy$(true);

    const credentials: LoginCredentials = {
      identifier: this.formElements.identifier$() ?? ''
    };

    if (!credentials.identifier) {
      return;
    }

    if (this.prefix) {
      credentials.identifier = this.prefix + credentials.identifier;
    }

    if (document.documentElement.dataset?.xsrfToken) {
      credentials.xsrfToken = document.documentElement.dataset?.xsrfToken;
    }

    if (this.requestVerificationChallenge1) {
      credentials.verificationChallenge1 = this.formElements.verificationChallenge1$() ?? undefined;
    }

    if (this.requestVerificationChallenge2) {
      credentials.verificationChallenge2 = this.formElements.verificationChallenge2$() ?? undefined;
    }

    (async () => {
      try {
        const result = await this.userIdentificationService.identifyUser(this.mode, credentials);

        if (this.mode === 'login' && result.isOk) {
          // for other modes (eg. vipcardnumber) the validated state is set by subscribing to the store
          this.validated$(true);
        }
      } finally {
        this.busy$(false);
      }
    })();
  };

  public get requestVerificationChallenge1() {
    return this.loginRequirements$()?.requestVerificationChallenge1;
  }

  public get requestVerificationChallenge2() {
    return this.loginRequirements$()?.requestVerificationChallenge2;
  }

  public get verificationChallenge1Format() {
    return this.loginRequirements$()?.verificationChallenge1Format;
  }

  public get verificationChallenge2Format() {
    return this.loginRequirements$()?.verificationChallenge2Format;
  }

  public get forceVerificationChallenge1Uppercase() {
    return this.loginRequirements$()?.forceVerificationChallenge1Uppercase;
  }

  public get forceVerificationChallenge2Numeric() {
    return this.loginRequirements$()?.verificationChallenge2Format === 'housenumber';
  }
}

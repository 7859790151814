import { components, observable, pureComputed, PureComputed } from 'knockout';

import { Category, ComponentDependencies } from '../../../interfaces';
import { setAppActiveCategory } from '../../../store/app/actions';
import { BaseComponentViewModel } from '../../base-component';

export interface CategorySelectorViewModelParams extends components.ViewModelParams {
  showAs?: 'list' | 'dropdown';
}

export class CategorySelectorViewModel extends BaseComponentViewModel {
  public readonly showAs: string;
  public readonly categoryList$: PureComputed<Category[]>;
  public readonly activeCategory$: PureComputed<Category | undefined>;

  constructor(deps: ComponentDependencies, params?: CategorySelectorViewModelParams) {
    super(deps);

    this.showAs = params?.showAs ?? 'list';

    const activeCategoryIdentifier$ = observable<string | undefined>();
    const categories$ = observable<Record<string, Category>>({});

    this.bindObservableToStore(
      activeCategoryIdentifier$,
      '$.app.activeCategory',
      setAppActiveCategory
    );

    this.activeCategory$ = pureComputed<Category | undefined>({
      read: () => {
        const id = activeCategoryIdentifier$();
        return id ? categories$()[id] : undefined;
      },
      write: value => {
        activeCategoryIdentifier$(value?.identifier);
      }
    });

    this.bindObservableToStore(categories$, '$.categories');

    this.categoryList$ = pureComputed(() => {
      const categories = categories$();
      return Object.values(categories).sort((a, b) => a.sequenceNumber - b.sequenceNumber);
    });
  }
}

import { ComponentConfig } from '../../interfaces';

import template from '../../ui_widgets/text-input/template.pug';
import { TelephoneTextInputViewModel } from './TelephoneTextInputViewModel';
export {
  TelephoneTextInputViewModel,
  TelephoneTextInputViewModelParams
} from './TelephoneTextInputViewModel';

const config: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new TelephoneTextInputViewModel(deps, params)
  }
};

export default config;

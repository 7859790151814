import { Order } from '../interfaces';
import { initialState } from '../store/order/initialState';

export function readPendingOrder(state: Order): Order {
  const pendingOrderItems = state._queues.orderItems.hasPendingItems
    ? state._queues.orderItems.pendingItems.slice(-1)[0].data
    : state.items;

  return {
    ...state,
    items: pendingOrderItems,
    _queues: {
      ...state._queues,
      orderItems: initialState._queues.orderItems
    }
  };
}

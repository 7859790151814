/*
 * Throttle
 *
 * A throttled function, when called many times in a short period of time, will be executed at most once
 * per <time period>. Any calls made in between will be ignored.
 *
 * Example usage:
 *
 *   Given the this function:
 *     function f(x: string): string {
 *       return "Value from f: " + x;
 *     }
 *
 *  You can create a throttles version using (3000 is 3 seconds):
 *   const f2: (x: string): void = throttle(outputValue, 3000);
 */
type AnyFunction = (...args: never[]) => unknown;

interface ThrottleConfig {
  leading: boolean;
  trailing: boolean;
}

const defaultThrottleConfig: ThrottleConfig = {
  leading: true,
  trailing: true
};

export function throttle<T extends AnyFunction>(
  fn: T,
  timeout: number,
  config: ThrottleConfig = defaultThrottleConfig
): (...args: Parameters<T>) => void {
  let busy = false;
  let pendingCall: Parameters<T> | undefined;

  const { leading, trailing } = { ...defaultThrottleConfig, ...config };

  function startThrottling() {
    busy = true;
    setTimeout(endThrottling, timeout);
  }

  function endThrottling() {
    busy = false;
    if (pendingCall !== undefined) {
      const args = pendingCall;
      pendingCall = undefined;
      fn(...args);
    }
  }

  return (...args): void => {
    if (busy) {
      if (trailing) {
        pendingCall = args;
      }
      return;
    }

    startThrottling();

    if (leading) {
      fn(...args);
    } else {
      pendingCall = args;
    }
  };
}

import template from './template.html';
import { ComponentConfig } from '../../../interfaces';
import { IfVipcardValidatedInputViewModel } from './IfVipcardValidatedInputViewModel';

export const ifVipcardValidatedComponent: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new IfVipcardValidatedInputViewModel(deps, params)
  }
};

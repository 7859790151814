import { ComponentConfig } from '../../interfaces';

import template from '../../ui_widgets/text-input/template.pug';
import { EmailTextInputViewModel } from './EmailTextInputViewModel';
export { EmailTextInputViewModel, EmailTextInputViewModelParams } from './EmailTextInputViewModel';

const config: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new EmailTextInputViewModel(deps, params)
  }
};

export default config;

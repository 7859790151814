import { components, observable, pureComputed, PureComputed } from 'knockout';
import { ComponentDependencies, OptInId, OptInType } from '../../../interfaces';
import { BaseComponentViewModel } from '../../base-component';

export interface IfOptedInViewModelParams extends components.ViewModelParams {
  optInId?: OptInId;
  optInType?: OptInType;
  context?: Record<string, unknown>;
  ifSelected?: boolean; // set to false to render if opt-in is NOT selected (default true)
}

export class IfOptedInViewModel extends BaseComponentViewModel {
  public context: Record<string, unknown>;
  public shouldRenderContents$: PureComputed<boolean>;

  constructor(deps: ComponentDependencies, params?: IfOptedInViewModelParams) {
    super(deps);
    this.context = params?.context ?? {};

    const ifSelected = Boolean(params?.ifSelected ?? true);

    const optInId: OptInId =
      params?.optInId ?? deps.optIns.firstIdOfType(params?.optInType ?? '') ?? '';

    const selectedOptIns$ = observable<OptInId[]>([]);

    this.shouldRenderContents$ = pureComputed(
      () => selectedOptIns$().includes(optInId) === ifSelected
    );

    this.bindObservableToStore(selectedOptIns$, '$.order.selectedOptIns');
  }
}

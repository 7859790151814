import { observable, pureComputed, PureComputed } from 'knockout';
import { BaseComponentViewModel } from '../../base-component';
import { Product, ComponentDependencies, OrderLine } from '../../../interfaces';

type BySku<T> = Record<string, T>;

interface DataForChild {
  product: Product;
  quantity: number;
}

export class SelectedProductsViewModel extends BaseComponentViewModel {
  public dataForChild$: PureComputed<DataForChild[]>;

  public constructor(deps: ComponentDependencies) {
    super(deps);

    const allProducts$ = observable<BySku<Product>>({});
    const orderItems$ = observable<BySku<OrderLine>>({});

    this.dataForChild$ = pureComputed(() => {
      const orderItems = Object.values(orderItems$());
      const allProducts = allProducts$();
      return orderItems.map(item => ({
        product: allProducts[item.sku],
        quantity: item.quantity
      }));
    });

    this.bindObservableToStore(allProducts$, '$.products');
    this.bindObservableToStore(orderItems$, '$.order.items');
  }
}

import i18next from 'i18next';
import {
  applyBindings,
  BindingHandler,
  bindingHandlers,
  components,
  options as knockoutOptions,
  validation
} from 'knockout';
import 'knockout.validation';
import globalConfig from './config';

import { sendPageEvent } from './helpers/analytics';
import { AppInterface, LinearRoute, Routes, StoreBackendService, Translations } from './interfaces';
import { appEventManager as globalAppEventManager } from './services/event-manager/appEventManager';

import { AppStore, defineRoutes, setAppReadyState, setState, store as defaultStore } from './store';
import { State } from './store/reducers';
import { clearState } from './store/storage';
import * as defaultBindingHandlers from './bindings';
import defaultComponentList from './ui_components';
import defaultWidgetList from './ui_widgets';
import { BaseWidgetViewModel } from './ui_widgets/BaseWidgetViewModel';
import { LoginAppConfig } from './interfaces/App';
import {
  HtmlDataThemeConfigProvider,
  StageNavigationService,
  ThemeConfigService,
  UserIdentificationService
} from './services';
import { switchAppReadyStyles } from './helpers';
import { StageNavigationEvent } from './events';
import { NotificationService } from './services/NotificationService';

const domContentLoaded: Promise<Event> = new Promise(resolve => {
  return window.addEventListener('DOMContentLoaded', resolve);
});

class DefaultViewModel {}

export class LoginApp implements AppInterface {
  public readonly service: StoreBackendService;
  public readonly store: AppStore;
  public readonly themeConfigService: ThemeConfigService;
  public readonly userIdentificationService: UserIdentificationService;
  public readonly stageNavigationService: StageNavigationService;
  public readonly notificationService: NotificationService;

  config: LoginAppConfig;

  constructor(
    config: Partial<LoginApp['config']>,
    service: StoreBackendService,
    store: AppStore = defaultStore
  ) {
    if (!service) {
      throw new Error(
        'No StoreBackendService set.\nPlease provide an instance of StoreBackendService as an argument to the LoginApp constructor call.'
      );
    }

    this.config = {
      bindingElem: document.body,
      ViewModel: DefaultViewModel,
      stages: [],
      routes: config.routes
        ? config.routes
        : this.convertLegacyStagesToRoutes(config.stages ?? ['login']),
      storageIdentifier: 'state',
      translations: {},
      language: undefined,
      ...config,
      componentDictionary: {
        ...defaultComponentList,
        ...defaultWidgetList,
        ...(config.componentDictionary ?? {})
      }
    };

    this.service = service;
    this.store = store;
    this.notificationService = new NotificationService(store, globalAppEventManager);
    this.userIdentificationService = new UserIdentificationService(
      store,
      service,
      globalAppEventManager,
      this.notificationService
    );

    this.themeConfigService = new ThemeConfigService(
      new HtmlDataThemeConfigProvider(config.themeConfigNormalizer),
      config.defaultThemeConfig
    );

    this.stageNavigationService = new StageNavigationService(store, globalAppEventManager);
  }

  private convertLegacyStagesToRoutes(stages: Array<string | string[]> | undefined): Routes {
    const toRoute = (stageIdentifier: unknown): LinearRoute => {
      if (typeof stageIdentifier !== 'string') {
        throw new Error(
          `Invalid stages config. StageIdentifier extected to be a string, but got an ${typeof stageIdentifier}`
        );
      }
      return {
        name: stageIdentifier,
        component: `${stageIdentifier}-page`
      };
    };

    if (stages instanceof Array) {
      return stages.map(stageOrArray =>
        stageOrArray instanceof Array ? stageOrArray.map(toRoute) : toRoute(stageOrArray)
      );
    }
    return [];
  }

  async start(): Promise<void> {
    try {
      knockoutOptions.deferUpdates = true;

      this.clearStorage();

      await Promise.all([domContentLoaded, this.loadState()]);

      this.initTranslations([globalConfig.i18n, this.config.translations]);
      this.initValidations();
      this.initBindingHandlers(defaultBindingHandlers);
      this.initBindings(this.config.ViewModel, this.config.bindingElem);
      this.initStageNavigation();
      this.initNotifications();

      this.store.dispatch(setAppReadyState(true));
    } finally {
      switchAppReadyStyles();
    }
  }

  private async loadState() {
    const serviceState = await this.service.getInitialLoginState();

    const state: State = {
      ...serviceState,
      app: {
        activeStage: undefined,
        activeCategory: undefined,
        ready: false,
        componentStates: {},
        visibilityOptionGroups: {},
        routes: []
      },
      notifications: []
    };

    this.store.dispatch(setState(state));
  }

  private initTranslations(translationBundles: Translations[]) {
    i18next.init({
      fallbackLng: 'en',
      lng: this.getLanguage(),
      defaultNS: 'translation',
      initImmediate: false
    });

    translationBundles.forEach(translationBundle => {
      for (const languageCode in translationBundle) {
        for (const namespace in translationBundle[languageCode]) {
          i18next.addResourceBundle(
            languageCode,
            namespace,
            translationBundle[languageCode][namespace],
            true,
            true
          );
        }
      }
    });
  }

  private initBindingHandlers(handlers: { [key: string]: BindingHandler }) {
    for (const bindingName in handlers) {
      bindingHandlers[bindingName] = handlers[bindingName];
    }
  }

  private initBindings(ViewModel: new (...args: any[]) => any, elem: HTMLElement) {
    const viewModel = new ViewModel();

    for (const tagName in this.config.componentDictionary) {
      components.register(tagName, {
        ...this.config.componentDictionary[tagName],
        viewModel: {
          /* eslint-disable-next-line no-loop-func */
          createViewModel: (
            params: components.ViewModelParams,
            componentInfo: components.ComponentInfo
          ): BaseWidgetViewModel => {
            const componentViewModel: BaseWidgetViewModel = this.config.componentDictionary[
              tagName
            ].viewModel.createViewModel(
              {
                cart: undefined as never,
                backorder: undefined as never,
                appEventManager: globalAppEventManager,
                stageNavigation: this.stageNavigationService,
                notifications: this.notificationService,
                optIns: undefined as never,
                service: this.service,
                store: this.store,
                selectors: undefined as never,
                themeConfig: this.themeConfigService,
                userIdentification: this.userIdentificationService
              },
              params,
              componentInfo
            );

            const componentElement = componentInfo.element as HTMLElement;

            if (componentViewModel.id && componentElement.dataset) {
              componentElement.dataset.componentId = componentViewModel.id;
            }

            return componentViewModel;
          }
        }
      });
    }

    applyBindings(viewModel, elem);
  }

  private initStageNavigation() {
    this.store.dispatch(defineRoutes(this.config.routes));

    this.stageNavigationService.init();

    // send events for analytics
    sendPageEvent('login');
    globalAppEventManager.on(StageNavigationEvent, ev => sendPageEvent(ev.targetRoute));
  }

  private initNotifications() {
    this.notificationService.initialize();

    this.notificationService.onNotification(
      this.notificationService.showNotificationInDefaultDialog,
      {
        priority: 0,
        clearImmediately: false
      }
    );
  }

  private clearStorage() {
    clearState(this.config.storageIdentifier);
  }

  private initValidations() {
    this.initKnockoutValidationTranslations();

    validation.init({
      errorMessageClass: 'validation-message',
      errorElementClass: 'validation-failed',
      errorsAsTitle: false,
      messagesOnModified: true
    });

    const locale = this.getLocale();

    if (locale) {
      try {
        validation.locale(locale);
      } catch (err) {
        console.error(err);
      }
    }
  }

  private initKnockoutValidationTranslations() {
    if (!i18next.exists('knockoutValidation')) {
      return;
    }

    const translations = Object.keys(validation.rules).reduce(
      (
        result: {
          [key: string]: string;
        },
        key
      ) => {
        result[key] = i18next.t(`knockoutValidation.${key}`) ?? validation.rules[key].message ?? '';

        return result;
      },
      {}
    );

    validation.defineLocale(this.getLocale(), translations);
  }

  private getLocale(): string {
    return this.config.language ? this.config.language : 'nl-NL';
  }

  private getLanguage() {
    return this.getLocale().substring(0, 2);
  }
}

import { components, PureComputed, pureComputed } from 'knockout';
import { ComponentDependencies, Product } from '../../../interfaces';
import { BaseComponentViewModel } from '../../base-component';

export interface ProductFilterViewModelParams extends components.ViewModelParams {
  skus?: string[];
  inActiveCategory?: boolean;
  minimumInCart?: number;
  maximumInCart?: number;
  isMainProduct?: boolean;
  isVariantOf?: string;
  customFilter?: (product: Product) => boolean;
}

export class ProductFilterViewModel extends BaseComponentViewModel {
  public readonly products$: PureComputed<Product[]>;
  public readonly skus$: PureComputed<string[]>;

  constructor(deps: ComponentDependencies, params?: ProductFilterViewModelParams) {
    super(deps);

    this.products$ = pureComputed<Product[]>(() => {
      let result: Product[];

      // filter by skus
      result =
        params?.skus instanceof Array
          ? params.skus.map(sku => deps.selectors.products$()[sku])
          : deps.selectors.productList$();

      // filter by active category
      if (typeof params?.inActiveCategory === 'boolean') {
        const skusInActiveCategory = deps.selectors.skusInActiveCategory$();
        result = result.filter(
          product => skusInActiveCategory.includes(product.sku) === params.inActiveCategory
        );
      }

      // filter by in cart (and quantity)
      if (typeof params?.minimumInCart === 'number' || typeof params?.maximumInCart === 'number') {
        const orderItems = deps.selectors.orderItemsList$();
        const minInCart = params?.minimumInCart ?? 0;
        const maxInCart = params?.maximumInCart ?? Number.MAX_SAFE_INTEGER;

        if (maxInCart < minInCart) {
          throw new Error('minimumInCart cannot be larger than maximumInCart');
        }

        result = result.filter(product => {
          const storeItem = orderItems.find(item => item.sku === product.sku);
          if (!storeItem) {
            return minInCart === 0;
          }

          return storeItem.quantity >= minInCart && storeItem.quantity <= maxInCart;
        });
      }

      // filter by isMainProduct
      if (typeof params?.isMainProduct === 'boolean') {
        result = result.filter(
          product => (product.parentSku === undefined) === params.isMainProduct
        );
      }

      // filter by isVariantOf
      if (params?.isVariantOf !== undefined) {
        result = result.filter(
          product => product.sku === params.isVariantOf || product.parentSku === params.isVariantOf
        );
      }

      // apply custom filter
      if (typeof params?.customFilter === 'function') {
        result = result.filter(params.customFilter);
      }

      return result;
    });

    this.skus$ = pureComputed(() => this.products$()?.map(pr => pr.sku));
  }
}

import { Reducer, combineReducers } from 'redux';

import { Contact, Order, User } from '../interfaces';

import { additionalContactsReducer, AdditionalContactsState } from './additionalContacts/reducers';
import { appReducer, AppState } from './app/reducers';
import { categoriesReducer, CategoriesState } from './categories/reducers';
import { contactReducer } from './contact/reducers';
import { notificationsReducer, NotificationsState } from './notifications/reducers';
import { orderReducer } from './order/reducers';
import { paymentReducer, PaymentState } from './payment/reducers';
import { productsReducer, ProductsState } from './products/reducers';
import { userReducer } from './user/reducers';

import { ActionTypes, SliceActionTypes, SET_STATE } from './actions';
import { optInsReducer, OptInsState } from './optIns/reducers';
import { storeBackendReducer, StoreBackendState } from './storeBackend/reducers';

export interface State {
  additionalContacts: AdditionalContactsState;
  app: AppState;
  categories: CategoriesState;
  contact: Contact;
  notifications: NotificationsState;
  order: Order;
  optIns: OptInsState;
  payment: PaymentState;
  products: ProductsState;
  storeBackend: StoreBackendState;
  user: User;
}

export const combinedReducer: Reducer<State, SliceActionTypes> = combineReducers({
  additionalContacts: additionalContactsReducer,
  app: appReducer,
  categories: categoriesReducer,
  contact: contactReducer,
  notifications: notificationsReducer,
  order: orderReducer,
  payment: paymentReducer,
  products: productsReducer,
  optIns: optInsReducer,
  storeBackend: storeBackendReducer,
  user: userReducer
});

export const rootReducer: Reducer<State, ActionTypes> = (state, action: ActionTypes): State => {
  switch (action.type) {
    case SET_STATE: {
      return {
        ...action.payload,
        app: {
          ...action.payload.app,
          componentStates: state?.app.componentStates ?? {}
        }
      };
    }

    default:
      return combinedReducer(state, action);
  }
};

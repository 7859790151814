import { AnyContact } from './interfaces';

export function getWebtextValue(config: AnyContact, webtextCode: string, fallback: ''): string {
  return config?.CustomTexts?.find(customText => customText.Code === webtextCode)?.Text ?? fallback;
}

export function getWebflowUrlPart(config: AnyContact): string | undefined {
  return (
    config?.Campagne?.Settings?.find(setting => setting.Key === 'WebflowUrlPart')?.Value ??
    undefined
  );
}

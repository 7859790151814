import { components, observable, pureComputed, PureComputed } from 'knockout';

import { ComponentDependencies } from '../../../interfaces';
import { BaseComponentViewModel } from '../../base-component';

export interface IfOptInsSubmittingViewModelParams extends components.ViewModelParams {
  whenSubmitting?: 'show' | 'hide';
}

export class IfOptInsSubmittingViewModel extends BaseComponentViewModel {
  public visible$: PureComputed<boolean>;

  constructor(deps: ComponentDependencies, params?: IfOptInsSubmittingViewModelParams) {
    super(deps);

    const whenSubmitting = params?.whenSubmitting ?? 'show';
    const submitting$ = observable(false);

    this.visible$ = pureComputed(() =>
      whenSubmitting === 'show' ? submitting$() : !submitting$()
    );

    this.bindObservableToStore(submitting$, '$.order._submitting.optIns');
  }
}

import { components, observable, Observable, validation } from 'knockout';

import { ComponentDependencies, Order } from '../../interfaces';
import { setChoice } from '../../store/order/actions';
import { BaseComponentViewModel } from '../base-component';

export interface CheckboxViewModelParams extends components.ViewModelParams {
  context?: any;
  choiceId?: '1' | '2' | '3';
  required?: boolean;
}

export class CheckboxViewModel extends BaseComponentViewModel {
  public readonly context: any;
  public readonly choiceId: keyof Order['choices'];
  public readonly required: boolean;

  public readonly value$ = observable<boolean | null>(false);

  constructor(deps: ComponentDependencies, params?: CheckboxViewModelParams) {
    super(deps);

    this.context = params?.context;
    this.choiceId =
      params?.choiceId && ['1', '2', '3'].includes(params.choiceId) ? params.choiceId : '1';
    this.required = typeof params?.required === 'boolean' ? params.required : false;

    this.value$.extend({
      validation: {
        validator: (value: boolean | null) => {
          if (!this.required || value === true) {
            return true;
          }

          return false;
        },
        message: validation.rules.required.message
      }
    });

    this.initializeStateUpdates();

    this.bindObservableToStore(this.value$, '$.order.choices.' + this.choiceId, value =>
      setChoice({ key: this.choiceId, value })
    );
  }
}

import template from './AddToCartButton.html';
import { AddToCartButtonViewModel } from './AddToCartButtonViewModel';
import { ComponentConfig } from '../../interfaces';

const config: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new AddToCartButtonViewModel(deps, params)
  }
};

export default config;

import {
  components,
  observable,
  Observable,
  PureComputed,
  pureComputed,
  validation
} from 'knockout';

import { ComponentDependencies, Order } from '../../interfaces';
import { setChoice } from '../../store/order/actions';
import { BaseComponentViewModel } from '../base-component';

export interface ChoiceRadioToggleViewModelParams extends components.ViewModelParams {
  choiceId?: '1' | '2' | '3';
  required?: boolean;
  requiredOn?: boolean;
  texts?: {
    label?: string;
    onLabel?: string;
    offLabel?: string;
  };
}

export class ChoiceRadioToggleViewModel extends BaseComponentViewModel {
  public readonly choiceId: keyof Order['choices'];
  public readonly required: boolean;
  public readonly requiredOn: boolean;
  public readonly texts: {
    label: string;
    onLabel: string;
    offLabel: string;
  };

  public readonly stringValue$: PureComputed<string>;
  public readonly value$ = observable<boolean | null>(false);

  constructor(deps: ComponentDependencies, params?: ChoiceRadioToggleViewModelParams) {
    super(deps);

    this.choiceId =
      params?.choiceId && ['1', '2', '3'].includes(params.choiceId) ? params.choiceId : '1';
    this.required = typeof params?.required === 'boolean' ? params.required : false;
    this.requiredOn = typeof params?.requiredOn === 'boolean' ? params.requiredOn : false;

    this.texts = {
      label: this.i18next.t('components.choiceRadioToggle.label', 'Make a choice'),
      onLabel: this.i18next.t('components.choiceRadioToggle.onLabel', 'On'),
      offLabel: this.i18next.t('components.choiceRadioToggle.offLabel', 'Off'),
      ...params?.texts
    };

    this.stringValue$ = pureComputed({
      read(): string {
        switch (this.value$()) {
          case true:
            return 'on';
          case false:
            return 'off';
          case null:
          default:
            return 'undecided';
        }
      },
      write(value: string) {
        switch (value) {
          case 'on':
            this.value$(true);
            break;
          case 'off':
            this.value$(false);
            break;
          case 'undecided':
          default:
            this.value$(null);
            break;
        }
      },
      owner: this
    });

    this.value$.extend({
      validation: {
        validator: (value: boolean | null) => {
          if (this.requiredOn && value !== true) {
            return false;
          }

          if (this.required && value !== true && value !== false) {
            return false;
          }

          return true;
        },
        message: validation.rules.required.message
      }
    });

    this.initializeStateUpdates();

    this.bindObservableToStore(this.value$, '$.order.choices.' + this.choiceId, value =>
      setChoice({ key: this.choiceId, value })
    );
  }
}

import { ComponentConfig } from '../../../interfaces';
import { VipcardSubmitButtonViewModel } from './VipcardSubmitButtonViewModel';

import template from './template.html';

export const vipcardSubmitButton: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: deps => new VipcardSubmitButtonViewModel(deps)
  }
};

import { AllowedEvent } from '../../interfaces';

export function allowedEventsSorter(a: AllowedEvent, b: AllowedEvent): number {
  return a.EventNumber - b.EventNumber;
}

export function sequenceNumberSorter(
  a: { sequenceNumber: number },
  b: { sequenceNumber: number }
): number {
  return a.sequenceNumber - b.sequenceNumber;
}

export function indexArrayByKey<T>(array: T[], key: keyof T): { [key: string]: T } {
  return array.reduce((acc, item) => {
    acc[item[key as string]] = item;
    return acc;
  }, {} as { [key: string]: T });
}

import { StoreBackendError } from '../interfaces/StoreBackendService';
import { NotificationService } from '../services';

type DefaultErrorMessages = Record<StoreBackendError['code'], StoreBackendError['message']>;

interface ErrorNotificationOptions {
  messages?: DefaultErrorMessages;
  defaultMessage?: string;
  buttonLabel?: string;
  extraData?: Record<string, unknown>;
}

export function showStorebackendError(
  err: StoreBackendError,
  options: ErrorNotificationOptions,
  notificationService: NotificationService
): void {
  let message: string;
  if (err.forEndUser) {
    message = err.message;
  } else {
    const defaults = options.defaultMessage ?? {};
    message = defaults[err.code] || options.defaultMessage || 'Unknown error';
  }

  const buttonLabel = options.buttonLabel || 'OK';

  notificationService.notify({
    message,
    actions: [{ code: 'close', label: buttonLabel }],
    extraData: options.extraData || {}
  });
}

import i18next from 'i18next';
import { observable } from 'knockout';
import { ComponentDependencies } from '../../../interfaces';
import { EmailTextInputViewModelParams } from '../../../ui_widgets/email-text-input';
import { BaseComponentViewModel } from '../../base-component';

export interface AdditionalContactEmailConfirmationViewModelParams {
  texts?: EmailTextInputViewModelParams['texts'] & {
    valuesDifferMessage?: string;
  };
  additionalContactRef?: string;
}

export class AdditionalContactEmailConfirmationViewModel extends BaseComponentViewModel {
  readonly value$ = observable(null);
  readonly widgetParams: EmailTextInputViewModelParams;

  readonly texts: EmailTextInputViewModelParams['texts'] & {
    valuesDifferMessage: string;
  };

  constructor(
    deps: ComponentDependencies,
    params?: AdditionalContactEmailConfirmationViewModelParams
  ) {
    super(deps);

    this.texts = {
      label: i18next.t('components.emailConfirmationTextInput.label', '[label]'),
      valuesDifferMessage: i18next.t(
        'components.emailConfirmationTextInput.valuesDifferMessage',
        '[valuesDifferMessage]'
      ),
      ...params?.texts
    };

    this.widgetParams = {
      ...params,
      value$: this.value$,
      required: true,
      texts: this.texts,
      readOnly: false
    };

    const compareToValue$ = observable<string>();

    const additionalContactRef = params?.additionalContactRef;
    const storeDataJsonPath = `$.additionalContacts['${additionalContactRef}'].emailAddress`;
    this.bindObservableToStore(compareToValue$, storeDataJsonPath);

    this.value$.extend({
      required: true,
      email: true,
      validation: {
        validator: value => value === compareToValue$(),
        message: this.texts.valuesDifferMessage
      }
    });
    this.initializeStateUpdates();
  }
}

import { Action } from 'redux';

import { OptIn, OptInId, Order, OrderLine, OrderLineMutation } from '../../interfaces';
import { BulkOrderItemMutation } from '../../interfaces/BulkOrderItemMutation';
import { SetProductsAction, DELETE_PRODUCTS, DeleteProductsAction } from '../products/actions';
import { AdditionalField, Choice } from './reducers';

export const SET_ADDITIONAL_FIELD = 'SET_ADDITIONAL_FIELD',
  SET_CHOICE = 'SET_CHOICE',
  PROCESS_ORDER_ITEM_MUTATION = 'PROCESS_ORDER_ITEM_MUTATION',
  BULK_ORDER_ITEMS_MUTATION = 'BULK_ORDER_ITEMS_MUTATION',
  FINALIZE_PENDING_MUTATION = 'FINALIZE_PENDING_MUTATION',
  SET_OPT_IN_SELECTION = 'SET_OPT_IN_SELECTION',
  CLEAR_ORDER_ITEMS = 'CLEAR_ORDER_ITEMS',
  SET_USE_CONTACT_ADDRESS_SETTING = 'SET_USE_CONTACT_ADDRESS_SETTING',
  SET_RECIPIENT_NAME = 'SET_RECIPIENT_NAME',
  SET_DELIVERY_ADDRESS = 'SET_DELIVERY_ADDRESS',
  SET_ORDER_REMARKS = 'SET_ORDER_REMARKS',
  SET_PAYMENT_OPTION_CODE = 'SET_PAYMENT_OPTION_CODE',
  CLEAR_PAYMENT_OPTION_CODE = 'CLEAR_PAYMENT_OPTION_CODE',
  MARK_ORDER_CONFIRMED = 'MARK_ORDER_CONFIRMED',
  MARK_SUBMITTING = 'MARK_SUBMITTING',
  MARK_SUBMITTING_DONE = 'MARK_SUBMITTING_DONE';

export interface SetAdditionalFieldAction extends Action<typeof SET_ADDITIONAL_FIELD> {
  type: typeof SET_ADDITIONAL_FIELD;
  payload: {
    key: AdditionalField['key'];
    value?: AdditionalField['value'] | null;
  };
}

export interface SetChoiceAction extends Action<typeof SET_CHOICE> {
  type: typeof SET_CHOICE;
  payload: Choice;
}

export interface ProcessOrderItemMutationAction extends Action<typeof PROCESS_ORDER_ITEM_MUTATION> {
  type: typeof PROCESS_ORDER_ITEM_MUTATION;
  payload: OrderLineMutation;
}

export interface BulkOrderItemsMutationAction extends Action<typeof BULK_ORDER_ITEMS_MUTATION> {
  type: typeof BULK_ORDER_ITEMS_MUTATION;
  payload: BulkOrderItemMutation;
}

export interface FinalizePendingMutationAction extends Action<typeof FINALIZE_PENDING_MUTATION> {
  type: typeof FINALIZE_PENDING_MUTATION;
  payload: {
    sequenceNumber: number;
    commit: boolean;
  };
}

export interface SetOptInSelectionAction extends Action<typeof SET_OPT_IN_SELECTION> {
  type: typeof SET_OPT_IN_SELECTION;
  payload: {
    optInId: OptIn['optInId'];
    isSelected: boolean;
  };
}

export interface ClearOrderItemsAction extends Action<typeof CLEAR_ORDER_ITEMS> {
  type: typeof CLEAR_ORDER_ITEMS;
  meta: { skus?: OrderLine['sku'][] };
}

export interface SetUseContactAddressSettingAction
  extends Action<typeof SET_USE_CONTACT_ADDRESS_SETTING> {
  type: typeof SET_USE_CONTACT_ADDRESS_SETTING;
  payload: Order['useContactAddress'];
}

export interface SetRecipientNameAction extends Action<typeof SET_RECIPIENT_NAME> {
  type: typeof SET_RECIPIENT_NAME;
  payload: Order['recipientName'];
}

export interface SetDeliveryAddressAction extends Action<typeof SET_DELIVERY_ADDRESS> {
  type: typeof SET_DELIVERY_ADDRESS;
  payload: Order['deliveryAddress'];
}

export interface SetOrderRemarksAction extends Action<typeof SET_ORDER_REMARKS> {
  type: typeof SET_ORDER_REMARKS;
  payload: Order['remarks'];
}

export interface SetPaymentOptionCodeAction extends Action<typeof SET_PAYMENT_OPTION_CODE> {
  type: typeof SET_PAYMENT_OPTION_CODE;
  payload: Order['payment']['paymentOptionCode'];
}

export interface ClearPaymentOptionCodeAction extends Action<typeof CLEAR_PAYMENT_OPTION_CODE> {
  type: typeof CLEAR_PAYMENT_OPTION_CODE;
}

export interface MarkOrderConfirmedAction extends Action<typeof MARK_ORDER_CONFIRMED> {
  type: typeof MARK_ORDER_CONFIRMED;
}

export interface MarkSubmittingAction extends Action<typeof MARK_SUBMITTING> {
  type: typeof MARK_SUBMITTING;
  payload: {
    subject: keyof Order['_submitting'];
  };
}

export interface MarkSubmittingDoneAction extends Action<typeof MARK_SUBMITTING_DONE> {
  type: typeof MARK_SUBMITTING_DONE;
  payload: {
    subject: keyof Order['_submitting'];
  };
}

export type OrderActionTypes =
  | SetAdditionalFieldAction
  | SetChoiceAction
  | ProcessOrderItemMutationAction
  | BulkOrderItemsMutationAction
  | FinalizePendingMutationAction
  | SetOptInSelectionAction
  | ClearOrderItemsAction
  | SetUseContactAddressSettingAction
  | SetRecipientNameAction
  | SetDeliveryAddressAction
  | SetOrderRemarksAction
  | SetPaymentOptionCodeAction
  | ClearPaymentOptionCodeAction
  | MarkOrderConfirmedAction
  | MarkSubmittingAction
  | MarkSubmittingDoneAction
  // from other slices:
  | SetProductsAction
  | DeleteProductsAction;

export function setAdditionalField(additionalField: {
  key: AdditionalField['key'];
  value?: AdditionalField['value'] | null;
}): SetAdditionalFieldAction {
  return {
    type: SET_ADDITIONAL_FIELD,
    payload: additionalField
  };
}

export function setChoice(choice: Choice): SetChoiceAction {
  return {
    type: SET_CHOICE,
    payload: choice
  };
}

export function processOrderItemMutation(
  mutation: OrderLineMutation
): ProcessOrderItemMutationAction {
  return {
    type: PROCESS_ORDER_ITEM_MUTATION,
    payload: mutation
  };
}

export function bulkOrderItemsMutation(
  bulkMutation: BulkOrderItemsMutationAction['payload']
): BulkOrderItemsMutationAction {
  return {
    type: BULK_ORDER_ITEMS_MUTATION,
    payload: bulkMutation
  };
}

export function finalizePendingMutation(
  commit: boolean,
  sequenceNumber: number
): FinalizePendingMutationAction {
  return {
    type: FINALIZE_PENDING_MUTATION,
    payload: {
      sequenceNumber,
      commit
    }
  };
}

export function setOptInSelection(optInId: OptInId, isSelected: boolean): SetOptInSelectionAction {
  return {
    type: SET_OPT_IN_SELECTION,
    payload: {
      optInId,
      isSelected
    }
  };
}

export function clearOrderItems(skus?: OrderLine['sku'][]): ClearOrderItemsAction {
  return {
    type: CLEAR_ORDER_ITEMS,
    meta: { skus }
  };
}

export function setUseContactAddressSetting(
  useContactAddressSetting: Order['useContactAddress']
): SetUseContactAddressSettingAction {
  return {
    type: SET_USE_CONTACT_ADDRESS_SETTING,
    payload: useContactAddressSetting
  };
}

export function setRecipientName(recipientName: Order['recipientName']): SetRecipientNameAction {
  return {
    type: SET_RECIPIENT_NAME,
    payload: recipientName
  };
}

export function setDeliveryAddress(
  deliveryAddress: Order['deliveryAddress']
): SetDeliveryAddressAction {
  return {
    type: SET_DELIVERY_ADDRESS,
    payload: deliveryAddress
  };
}

export function setOrderRemarks(remarks: Order['remarks']): SetOrderRemarksAction {
  return {
    type: SET_ORDER_REMARKS,
    payload: remarks
  };
}

export function setPaymentOptionCode(
  code: Order['payment']['paymentOptionCode']
): SetPaymentOptionCodeAction {
  return {
    type: SET_PAYMENT_OPTION_CODE,
    payload: code
  };
}

export function clearPaymentOptionCode(): ClearPaymentOptionCodeAction {
  return {
    type: CLEAR_PAYMENT_OPTION_CODE
  };
}

export function markOrderConfirmed(): MarkOrderConfirmedAction {
  return {
    type: MARK_ORDER_CONFIRMED
  };
}

export function markSubmitting(
  subject: MarkSubmittingAction['payload']['subject']
): MarkSubmittingAction {
  return {
    type: MARK_SUBMITTING,
    payload: {
      subject
    }
  };
}

export function markSubmittingDone(
  subject: MarkSubmittingDoneAction['payload']['subject']
): MarkSubmittingDoneAction {
  return {
    type: MARK_SUBMITTING_DONE,
    payload: {
      subject
    }
  };
}

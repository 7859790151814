import { observable, Observable, components } from 'knockout';
import i18next from 'i18next';

import { Product, OrderLine, ComponentDependencies } from '../../../interfaces';
import { BaseComponentViewModel } from '../../base-component';

export interface CartNotificationViewModelParams extends components.ViewModelParams {
  texts?: {
    title?: string;
  };
}

export class CartNotificationViewModel extends BaseComponentViewModel {
  public readonly texts: {
    title: string;
  };

  public readonly products$: Observable<{ [key: string]: Product }> = observable({});
  public readonly orderItems$: Observable<{ [key: string]: OrderLine }> = observable({});

  public readonly orderItem$: Observable<OrderLine | undefined> = observable();

  private timeoutId?: number;
  private previousOrderItems: { [key: string]: OrderLine } = {};

  constructor(deps: ComponentDependencies, params?: CartNotificationViewModelParams) {
    super(deps);

    this.texts = {
      title: i18next.t('components.cartNotification.title', ''),
      ...params?.texts
    };

    this.bindObservableToStore(this.products$, '$.products');
    this.bindObservableToStore(this.orderItems$, '$.order.items');

    this.previousOrderItems = this.orderItems$();

    this.subscriptions.push(
      this.orderItems$.subscribe(orderItems => {
        for (const sku in orderItems) {
          if (
            !this.previousOrderItems[sku] ||
            this.previousOrderItems[sku].quantity < orderItems[sku].quantity
          ) {
            if (this.timeoutId) {
              window.clearTimeout(this.timeoutId);
            }

            this.orderItem$(orderItems[sku]);

            this.timeoutId = window.setTimeout(() => {
              this.orderItem$(undefined);
            }, 3000);

            this.timeouts.push(this.timeoutId);
          }
        }

        this.previousOrderItems = orderItems;
      })
    );
  }
}

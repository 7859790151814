import { ComponentConfig } from '../../interfaces';

import template from './template.pug';
import { SegmentedTextInputViewModel } from './SegmentedTextInputViewModel';

const config: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params, componentInfo) =>
      new SegmentedTextInputViewModel(deps, params, componentInfo)
  }
};

export default config;

import { Action } from 'redux';
import { OptIn } from '../../interfaces';
import { initialState } from './initialState';

export interface OptInsState {
  [index: string]: OptIn;
}

export type OptInsActionTypes = Action<never>;

export function optInsReducer(state = initialState, action: OptInsActionTypes): OptInsState {
  switch (action.type) {
    default:
      return state;
  }
}

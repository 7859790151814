import i18next from 'i18next';
import { components, observable, Observable } from 'knockout';
import { ComponentDependencies } from '../../../interfaces';
import { setAdditionalField } from '../../../store/order/actions';
import { BaseComponentViewModel } from '../../base-component';

export interface AdditionalDropdownFieldViewModelParams extends components.ViewModelParams {
  additionalFieldKey?: string;
  options?: Array<string | { code: string; display: string }>;
  addPlaceholder?: boolean;
  required?: boolean;
  texts?: {
    label?: string;
    placeholder?: string;
  };
}

class AdditionalDropdownFieldViewModel extends BaseComponentViewModel {
  public readonly texts: {
    label: string;
    placeholder: string;
  };

  public readonly addPlaceholder: boolean;
  public readonly required: boolean;
  public readonly options: Array<{ code: string; display: string }>;
  public readonly value$: Observable<string | undefined>;

  public constructor(deps: ComponentDependencies, params?: AdditionalDropdownFieldViewModelParams) {
    super(deps);

    if (params?.options instanceof Array) {
      this.options = params.options.map(optionValue =>
        typeof optionValue === 'string'
          ? { code: optionValue, display: optionValue }
          : {
              code: optionValue.code,
              display: optionValue.display ?? optionValue.code
            }
      );
    } else {
      this.options = [];
    }
    this.value$ = observable();

    if (params?.additionalFieldKey) {
      const pathInStore = '$.order.additionalFields.' + params.additionalFieldKey;
      this.bindObservableToStore(this.value$, pathInStore, value =>
        setAdditionalField({
          key: params.additionalFieldKey as string,
          value
        })
      );
    }

    this.addPlaceholder =
      params?.addPlaceholder !== undefined ? Boolean(params.addPlaceholder) : true;
    this.required = params?.required !== undefined ? Boolean(params.required) : false;

    this.texts = {
      label: i18next.t('components.additionalDropdownField.label', ''),
      placeholder: i18next.t('components.additionalDropdownField.label', ''),
      ...params?.texts
    };

    this.initializeValidations();
    this.initializeStateUpdates();
  }

  protected initializeValidations(): void {
    if (this.required && this.options.length > 0) {
      this.value$.extend({
        required: true
      });
    }
  }
}

export default AdditionalDropdownFieldViewModel;

import i18next from 'i18next';
import { components, observable, Observable, pureComputed, PureComputed } from 'knockout';
import {
  ActiveStageState,
  ComponentDependencies,
  ComponentState,
  LinearRoute,
  Routes
} from '../../interfaces';
import { StageNavigationService } from '../../services';

import { BaseComponentViewModel } from '../base-component';

export interface ProgressIndicatorViewModelParams extends components.ViewModelParams {
  stageLabels?: {
    [key: string]: string;
  };
  dummyStages?: Array<{
    stageIdentifier?: string;
    position?: 'before' | 'after';
    label?: string;
  }>;
  allowNavigation?: boolean;
}

interface DisplayStage {
  stageIdentifier: string;
  label: string;
  active: boolean;
  canNavigate: boolean;
}

type StageDisplayData = Array<{
  active: boolean;
  childStages: Array<DisplayStage>;
}>;

export class ProgressIndicatorViewModel extends BaseComponentViewModel {
  public readonly activeStage$: Observable<ActiveStageState | undefined> = observable();
  public readonly routes$: Observable<Routes | undefined> = observable();
  public readonly componentStates$ = observable<{ [key: string]: ComponentState } | undefined>({});

  public readonly activeStageIdentifier$: PureComputed<string | undefined>;
  public readonly stageDisplayData$: PureComputed<StageDisplayData>;
  private readonly allowNavigation: boolean;

  private stageNavigation: StageNavigationService;

  constructor(deps: ComponentDependencies, params?: ProgressIndicatorViewModelParams) {
    super(deps);
    this.stageNavigation = deps.stageNavigation;

    this.allowNavigation = Boolean(params?.allowNavigation);

    this.activeStageIdentifier$ = pureComputed(() => {
      return this.activeStage$()?.stageIdentifier;
    });

    function getStageLabel(route: LinearRoute) {
      return (
        route.progressIndicator?.label ??
        params?.stageLabels?.[route.name] ??
        (i18next.t(
          `components.progressIndicator.stageLabels.${route.name}`,
          route.name
        ) as unknown as string)
      );
    }

    function getDummyStageLabel(stageIdentifier) {
      return (
        params?.dummyStages?.find(x => x.stageIdentifier === stageIdentifier)?.label ??
        (i18next.t(
          `components.progressIndicator.stageLabels.${stageIdentifier}`,
          stageIdentifier
        ) as unknown as string)
      );
    }

    function createDummystageDisplayData(stageIdentifier: string): StageDisplayData[0] {
      return {
        active: false,
        childStages: [
          {
            stageIdentifier,
            active: false,
            label: getDummyStageLabel(stageIdentifier),
            canNavigate: false
          }
        ]
      };
    }

    function getDummyStages(position: string): StageDisplayData {
      if (params?.dummyStages) {
        return params?.dummyStages
          ?.filter(x => x.position === position)
          ?.map(dummystage => createDummystageDisplayData(dummystage.stageIdentifier as string));
      }
      return [];
    }

    const dummyStagesBefore = getDummyStages('before');
    const dummyStagesAfter = getDummyStages('after');

    this.stageDisplayData$ = pureComputed(() => {
      // Make sure this computed observable is updated when dependencies change.
      const activeStage = this.activeStage$();
      const routes = this.routes$() ?? [];
      void this.componentStates$();

      const createStageDisplayData = (route: LinearRoute): DisplayStage => ({
        stageIdentifier: route.name,
        active: activeStage?.stageIdentifier === route.name,
        canNavigate: this.stageNavigation.canNavigate(route.name),
        label: getStageLabel(route)
      });

      const displayStages = routes
        .map(route => {
          const normalized = route instanceof Array ? route : [route];
          const childStages = normalized
            .filter(x => x.progressIndicator?.include !== false)
            .map(createStageDisplayData);
          return {
            active: childStages.some(x => x.active),
            childStages
          };
        })
        .filter(x => x.childStages.length > 0);

      return [...dummyStagesBefore, ...displayStages, ...dummyStagesAfter];
    });

    this.bindObservableToStore(this.routes$, '$.app.routes');
    this.bindObservableToStore(this.activeStage$, '$.app.activeStage');
    this.bindObservableToStore(this.componentStates$, '$.app.componentStates');
  }

  public onClickStage = (data: DisplayStage, ev: MouseEvent) => {
    if (!this.allowNavigation) {
      return;
    }

    ev.stopPropagation();

    if (this.stageNavigation.canNavigate(data.stageIdentifier)) {
      this.stageNavigation.navigate(data.stageIdentifier);
    }
  };
}

import i18next from 'i18next';
import qs from 'qs';
import 'whatwg-fetch';

import { appEventManager as globalAppEventManagerInstance, AppEventManagerInterface } from '../../';
import {
  BeforeLogoutEvent,
  LoggedOutEvent,
  OrderSubmittedEvent,
  StoreProductsRefreshedEvent
} from '../../../events';
import { Err, Ok, Result, readPendingOrder } from '../../../helpers';

import {
  Address,
  StoreBackendService,
  StoreBackendServiceState,
  StoreBackendServiceValidationErrorCode
} from '../../../interfaces';
import { StoreBackendError } from '../../../interfaces/StoreBackendService';
import { State } from '../../../store/reducers';

import { emptyState } from './emptyState';
import { getWebflowUrlPart, getWebtextValue } from './helpers';

import {
  AnyContact,
  GameShowContact,
  IzziError,
  LogOnModel,
  RegistrationContact,
  ShopContact,
  WebflowTypeID
} from './interfaces';
import { WEBFLOW_TYPE_GAMESHOW, WEBFLOW_TYPE_REGISTRATION } from './interfaces/WebflowTypeID';
import * as fromBackendTranslators from './translators/from_backend';
import { contactTranslator } from './translators/to_backend/contactTranslator';
import { ProductsOutOfStockEvent } from '../../../events/ProductsOutOfStockEvent';

function randomDelay(min = 50, max = 300): number {
  return Math.round(Math.random() * (max - min) + min);
}

export type IzziWebflowController = 'home' | 'shop' | 'gameshow' | 'optinorout';

// IzziCampaignSettingsFromApp specifies settings that should be configured in iZZi-Response,
// but are not yet configurable in iZZi.
export interface IzziCampaignSettingsFromApp {
  // The singleSku option is similar to the MultipleItemsPerContact option of iZZi-Response.
  // Unfortunately, in iZZi-Response we cannot set MultipleItemsPerContact to false without
  // also changing the campaign structure from Main/Sub-arrangements to simply CampainEvents.
  // To make singlesku mode possible, this option is implemented client-side for now.
  singleSku: boolean;
  additionalFields: Array<string>;
}

export interface IzziServiceOptions {
  campaignId?: number;
  loginUrl?: string;
  loginConfigDataSource?: string;
  configDataSource?: string;
  preSubmitModifier?: (
    state: State,
    controller: IzziWebflowController,
    data: Partial<AnyContact>,
    context: { action: string }
  ) => Partial<AnyContact>;
  campaignSettings?: Partial<IzziCampaignSettingsFromApp>;
}

export class IzziInvalidOrderError extends Error {
  constructor(message?: string | undefined) {
    super(message);

    this.message = message || 'Order invalid';
  }
}

export class IzziAddressSearchError extends Error {
  constructor(message?: string | undefined) {
    super(message);

    this.message = message || 'Error searching address';
  }
}

export class IzziService implements StoreBackendService {
  readonly persist = true;
  campaignId?: number;
  loginUrl: URL;
  loginConfigDataSource: string;
  configDataSource: string;
  campaignSettingsFromApp: IzziCampaignSettingsFromApp;
  preSubmitModifier?: IzziServiceOptions['preSubmitModifier'];

  private orderSubmitted = false;
  private firedReportProgressCalls: Array<Promise<void>> = [];

  constructor(
    options?: IzziServiceOptions,
    private appEventManager: AppEventManagerInterface = globalAppEventManagerInstance
  ) {
    this.campaignId = options?.campaignId;
    this.loginConfigDataSource = options?.loginConfigDataSource ?? window.location.href;
    this.configDataSource = options?.configDataSource ?? window.location.href;
    this.campaignSettingsFromApp = {
      // default settings
      singleSku: false,
      additionalFields: [],
      // override with provided settings
      ...(options?.campaignSettings ?? {})
    };
    this.preSubmitModifier = options?.preSubmitModifier;

    if (options?.loginUrl) {
      try {
        this.loginUrl = new URL(options.loginUrl, window?.location?.origin);
      } catch (err) {
        this.loginUrl = new URL('/', window?.location?.origin);
      }
    } else {
      this.loginUrl = new URL('/', window?.location?.origin);
    }
  }

  // eslint-disable-next-line complexity
  async login(credentials: {
    identifier: string;
    xsrfToken?: string;
    verificationChallenge1?: string;
    verificationChallenge2?: string;
  }): Promise<Result<URL | undefined, StoreBackendError>> {
    const config = await (this.getConfig(this.loginConfigDataSource) as Promise<LogOnModel>);

    if (!config) {
      return Err({ code: 'unknown', message: 'Config not found', forEndUser: false });
    }

    const response: Response = await fetch(this.loginUrl.toString(), {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      redirect: 'follow',
      body: qs.stringify({
        __RequestVerificationToken: credentials.xsrfToken || undefined,
        IDCustomer: String(config.IDCustomer),
        IDCampagne: String(config.IDCampagne),
        IDCampagneType: String(config.IDCampagneType),
        LoginCode: credentials.identifier || undefined,
        ZipCode:
          config.UseIDCampagneFieldAndZipCodeAndHouseNumberForLogin ||
          config.UseIDCampagneFieldAndZipCodeForLogin ||
          config.UseCustomerContactIdForLogin ||
          config.UseIDContactForLogin
            ? credentials.verificationChallenge1?.toUpperCase() || undefined
            : undefined,
        HouseNumber: config.UseIDCampagneFieldAndZipCodeAndHouseNumberForLogin
          ? credentials.verificationChallenge2 || undefined
          : undefined,
        GUID: config.GUID ? String(config.GUID) : undefined,
        UseCustomerContactIdForLogin: config.UseCustomerContactIdForLogin
          ? String(config.UseCustomerContactIdForLogin)
          : undefined,
        UseIDCampagneFieldAndZipCodeForLogin: config.UseIDCampagneFieldAndZipCodeForLogin
          ? String(config.UseIDCampagneFieldAndZipCodeForLogin)
          : undefined,
        UseIDCampagneFieldAndZipCodeAndHouseNumberForLogin:
          config.UseIDCampagneFieldAndZipCodeAndHouseNumberForLogin
            ? String(config.UseIDCampagneFieldAndZipCodeAndHouseNumberForLogin)
            : undefined,
        UseIDContactForLogin: config.UseIDContactForLogin
          ? String(config.UseIDContactForLogin)
          : undefined
      })
    });

    const responseData: {
      Contact?: AnyContact;
      GameShowContact?: GameShowContact;
      RedirectUrl?: string;
      Error?: IzziError;
    } = await response.json();

    if (response.status >= 300) {
      if (responseData.Error) {
        return Err({
          code: String(responseData.Error.Id),
          message: responseData.Error.ErrorMessage || responseData.Error.ErrorMessageHtml,
          forEndUser: true
        });
      }
      return Err({
        code: 'Unexpected',
        message: `The request to iZZi returned an unexpected status code: ${response.status}.`,
        forEndUser: false
      });
    }

    if (responseData?.RedirectUrl) {
      return Ok(new URL(responseData.RedirectUrl, window?.location?.origin));
    }

    const contact = responseData.Contact;

    const webflowUrlPart = contact?.Campagne?.Settings?.find(
      setting => setting.Key === 'WebflowUrlPart'
    )?.Value;

    const webflowType = IzziService.parseWebflowType(contact?.Campagne?.WebflowType);

    let redirectUrl: string;
    switch (webflowType) {
      case WEBFLOW_TYPE_REGISTRATION:
      case WEBFLOW_TYPE_GAMESHOW:
        redirectUrl = `${webflowUrlPart}/registratie`;
        break;
      default:
        redirectUrl = `${webflowUrlPart}/`;
        break;
    }
    return Ok(new URL(`${redirectUrl}?language=nl`, window?.location?.origin));
  }

  public async logout(): Promise<URL> {
    this.appEventManager.emit(new BeforeLogoutEvent());

    const response = await fetch(`/logout`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    });

    if (response.status >= 300) {
      throw new Error(
        `The request to iZZi returned an unexpected status code: ${response.status}.`
      );
    }

    const responseData: { Redirect: string } = await response.json();

    if (!responseData?.Redirect) {
      throw new Error('GENERAL_ERROR');
    }

    this.appEventManager.emit(new LoggedOutEvent());

    return new URL(responseData.Redirect, window?.location?.origin);
  }

  async getConfig(location: string): Promise<AnyContact | LogOnModel | undefined> {
    const response: Response = await fetch(location, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      redirect: 'follow'
    });

    const config = await response.json();

    if (!config.IsValid && typeof config.Redirect === 'string') {
      try {
        const url = new URL(config.Redirect, window?.location?.origin);
        window.location.replace(url);
        return new Promise(() => {
          /* will never resolve */
        });
      } catch (ex) {
        // probably a malformed url?
        console.error(ex);
        return undefined;
      }
    }

    const defaultConfig = config as AnyContact;
    const anyContact = config as { Contact: AnyContact };
    const gameShowContactConfig = config as { GameShowContact: GameShowContact };
    const shopContactConfig = config as { ShopContact: ShopContact };
    const registrationContactConfig = config as { Contact: RegistrationContact };
    const logOnConfig = config as { Model: LogOnModel };
    const modelConfig = config as { Model: AnyContact };

    return modelConfig.Model ?? logOnConfig.Model ?? anyContact.Contact ?? defaultConfig;
  }

  async getInitialLoginState(): Promise<StoreBackendServiceState> {
    const loginConfig = await (this.getConfig(this.loginConfigDataSource) as Promise<LogOnModel>);
    return {
      ...emptyState,
      storeBackend: fromBackendTranslators.storeBackendTranslator(undefined, loginConfig)
    };
  }

  async getInitialState(): Promise<StoreBackendServiceState> {
    const config = await (this.getConfig(this.configDataSource) as Promise<AnyContact>);

    if (!config) {
      return emptyState;
    }

    return {
      additionalContacts: fromBackendTranslators.additionalContactsStateTranslator(config),
      categories: fromBackendTranslators.categoriesStateTranslator(config),
      contact: fromBackendTranslators.contactStateTranslator(config),
      order: fromBackendTranslators.orderStateTranslator(config, this.campaignSettingsFromApp),
      payment: fromBackendTranslators.paymentStateTranslator(config),
      products: fromBackendTranslators.productsStateTranslator(config),
      storeBackend: fromBackendTranslators.storeBackendTranslator(config, undefined),
      optIns: fromBackendTranslators.optInsStateTranslator(config),
      user: fromBackendTranslators.userStateTranslator(config, this.campaignSettingsFromApp)
    };
  }

  async updateOrder(state: State): Promise<boolean> {
    if (this.orderSubmitted) {
      // ignore updateOrder calls that were triggered after submitting the order
      return false;
    }

    let reportProgressFinished: () => void;
    this.firedReportProgressCalls.push(
      new Promise(resolve => {
        reportProgressFinished = resolve;
      })
    );

    try {
      return await this.reportProgress(state);
    } finally {
      // setTimeout is needed to make sure that the reportProgressFinished variable
      // is not being called before it is initialized within the "running" promise.
      setTimeout(() => reportProgressFinished(), 0);
    }
  }

  private async reportProgress(state: State): Promise<boolean> {
    if (['development', 'test'].includes(document.documentElement.dataset?.mode ?? '')) {
      const delayMs = randomDelay();
      console.info(
        '[IzziService#updateOrder] Test mode, returning true after a randomized delay of ' +
          delayMs +
          'ms',
        {
          lastProcessedItemSequenceNumber: state.order._queues.orderItems.lastItemSequenceNumber
        }
      );
      return new Promise(resolve => {
        setTimeout(resolve, delayMs, true);
      });
    }

    const config = await (this.getConfig(this.configDataSource) as Promise<AnyContact>);

    if (!config) {
      return false;
    }

    const webflowControllers: IzziWebflowController[] = ['home', 'shop', 'gameshow'],
      controller: IzziWebflowController =
        webflowControllers[IzziService.parseWebflowType(config?.Campagne?.WebflowType) - 1];

    const nextState: State = {
      ...state,
      order: readPendingOrder(state.order)
    };

    let data = this.convertState(nextState, controller, config);

    if (this.preSubmitModifier) {
      data = this.preSubmitModifier(nextState, controller, data, { action: 'reportprogress' });
    }

    const response = await fetch(
      `/${controller}/reportprogress/?idcampagne=${data.IDCampagne}&refreshProducts=true`,
      {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(data)
      }
    );

    if (response.status >= 300) {
      throw new Error(
        `The request to iZZi returned an unexpected status code: ${response.status}.`
      );
    }

    const responseData = await response.json();

    if (!responseData) {
      return false;
    }

    // note: AllowedEvents can even exist if IsValid = false
    if (responseData.AllowedEvents) {
      // Hacky: in case of an ProductNotActive error, mutate the server response before parsing
      // so that we can treat the requested product as "IsOnWaitingList"
      if (responseData.Code === 'ProductNotActive') {
        for (const event of responseData.AllowedEvents) {
          if (event.IDCampagneEvent === data.IDCampagneEvent) {
            event.IsOnWaitingList = true;
          }
        }
      }

      const newProducts = fromBackendTranslators.productsStateTranslator({
        ...config,
        AllowedEvents: responseData.AllowedEvents
      });

      this.appEventManager.emit(new StoreProductsRefreshedEvent(newProducts));
    }

    if (!responseData.IsValid && typeof responseData.Redirect === 'string') {
      try {
        const url = new URL(responseData.Redirect, window?.location?.origin);
        window.location.replace(url);
      } catch (ex) {
        // probably a malformed url?
        console.error(ex);
      }
      return false;
    }

    if (responseData.Code === 'ProductNotActive') {
      const additionalValues = responseData.AdditionalValues ?? [];
      const inactiveProductSkus: string[] = [];

      for (const additionalValue of additionalValues) {
        if (additionalValue.Key === 'IdProduct') {
          inactiveProductSkus.push(additionalValue.Value);
        }
      }

      if (inactiveProductSkus.length) {
        this.appEventManager.emit(new ProductsOutOfStockEvent(inactiveProductSkus));
      }
    }

    if (!responseData.IsValid) {
      if (responseData.Errors) {
        console.error(responseData.Errors);
      }

      return false;
    }

    return true;
  }

  public async saveOptInOrOutChoices(state: State): Promise<void> {
    if (['development', 'test'].includes(document.documentElement.dataset?.mode ?? '')) {
      const delayMs = randomDelay();
      console.info(
        `[IzziService#saveOptInOrOutChoices] Test mode, returning after a randomized delay of ${delayMs} ms`
      );
      return new Promise(resolve => setTimeout(resolve, delayMs));
    }

    const config = await (this.getConfig(this.configDataSource) as Promise<AnyContact>);

    if (!config) {
      return;
    }

    const controller: IzziWebflowController = 'optinorout';
    const fullData = this.convertState(state, controller, config);

    let data: Partial<AnyContact> = { OptInOrOutAnswers: fullData.OptInOrOutAnswers };

    if (this.preSubmitModifier) {
      data = this.preSubmitModifier(state, controller, data, { action: 'saveOptInOrOutChoices' });
    }

    const response = await fetch(`/optinorout/savechoice`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      throw new Error('Error saving optInOrOut choices');
    }
  }

  async validateOrder(state: State): Promise<StoreBackendServiceValidationErrorCode | null> {
    if (['development', 'test'].includes(document.documentElement.dataset?.mode ?? '')) {
      const delayMs = randomDelay();

      console.info(
        '[IzziService#validateOrder] Test mode, returning null after a randomized delay of ' +
          delayMs +
          'ms'
      );

      return new Promise(resolve => {
        setTimeout(resolve, delayMs, null);
      });
    }

    const config = await (this.getConfig(this.configDataSource) as Promise<AnyContact>);

    const webflowUrlPart = getWebflowUrlPart(config);

    if (!config || !webflowUrlPart) {
      return 'GENERAL_ERROR';
    }

    const webflowControllers: IzziWebflowController[] = ['home', 'shop', 'gameshow'],
      controller: IzziWebflowController =
        webflowControllers[IzziService.parseWebflowType(config?.Campagne?.WebflowType) - 1];

    if (controller !== 'shop') {
      return null;
    }

    let data = this.convertState(state, controller, config);

    if (this.preSubmitModifier) {
      data = this.preSubmitModifier(state, controller, data, { action: 'validateregistration' });
    }

    const response = await fetch(`/${webflowUrlPart}/validateregistration/`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(data)
    });

    if (response.status >= 300) {
      throw new Error(
        `The request to iZZi returned an unexpected status code: ${response.status}.`
      );
    }

    const responseData = await response.json();

    if (!responseData) {
      return 'GENERAL_ERROR';
    }

    if (!responseData.IsValid) {
      let errorCode: StoreBackendServiceValidationErrorCode = 'GENERAL_ERROR';

      if (Array.isArray(responseData.Errors)) {
        console.error(responseData.Errors);

        const errorCodes = responseData.Errors.map(error => error.Code);

        if (errorCodes.includes('MaxRegistrationsPerPersonReached')) {
          errorCode = 'ORDER_LIMIT';
        } else if (
          errorCodes.includes('MaxNumberPerProductReached') ||
          errorCodes.includes('MaxRegistrationsPerProductReached')
        ) {
          errorCode = 'PRODUCT_QUANTITY_LIMIT';
        } else if (errorCodes.includes('ProductNotActive')) {
          errorCode = 'PRODUCT_INACTIVE';
        } else if (errorCodes.find(error => error.includes('card'))) {
          errorCode = 'CARD_INVALID';
        }
      }

      return errorCode;
    }

    return null;
  }

  async validateExtraIdentifier(state: State): Promise<Result<undefined, StoreBackendError>> {
    //<editor-fold desc="Workaround for T8051">
    if (Object.keys(state.order.items).length === 0) {
      // Izzi-Response will return an error if we try to validate an order with no items.
      // Therefore, temporarily add a single product to the validate request...
      // It won't work without this. ️©️Rick.
      const [firstProductSku] = Object.keys(state.products);
      state = {
        ...state,
        order: {
          ...state.order,
          items: {
            [firstProductSku]: {
              sku: firstProductSku,
              quantity: 1
            }
          }
        }
      };
    }
    //</editor-fold>

    const response = await this.validateOrder(state);

    const cardErrors = [
      'ORDER_LIMIT',
      'PRODUCT_QUANTITY_LIMIT',
      'CARD_INVALID',
      'PRODUCT_INACTIVE'
    ];

    if (cardErrors.includes(response as string)) {
      return Err({
        code: response!,
        message: i18next.t(
          'components.login.vipcardnumberAlreadyUsedNotification',
          'Vipcard number is already used.'
        ),
        forEndUser: true
      });
    }

    return Ok(undefined);
  }

  async submitOrder(state: State): Promise<URL | void> {
    // mark the order as submitted to prevent new report progress calls from being made
    this.orderSubmitted = true;
    let succesfullySubmitted = false;

    try {
      // wait for running report progress calls (if any) to be finished before firing confirmRegistration call
      await Promise.allSettled(this.firedReportProgressCalls);

      const config = await (this.getConfig(this.configDataSource) as Promise<AnyContact>);

      if (!config) {
        return undefined;
      }

      const webflowControllers: IzziWebflowController[] = ['home', 'shop', 'gameshow'],
        controller: IzziWebflowController =
          webflowControllers[IzziService.parseWebflowType(config?.Campagne?.WebflowType) - 1];

      let data = this.convertState(state, controller, config);

      if (this.preSubmitModifier) {
        data = this.preSubmitModifier(state, controller, data, { action: 'confirmregistration' });
      }

      const IDCampaign = state.storeBackend.izzi?.IDCampaign;

      const response = await fetch(`/${controller}/confirmregistration/?idcampagne=${IDCampaign}`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(data)
      });

      const errorDuringSubmit = response.status >= 300,
        responseData = await response.json();

      this.appEventManager.emit(new OrderSubmittedEvent(!errorDuringSubmit));

      if (responseData) {
        if (responseData.Redirect) {
          return new URL(responseData.Redirect, window?.location?.origin);
        }

        if (responseData.IsValid) {
          succesfullySubmitted = true;
        } else if (!responseData.IsValid) {
          throw new IzziInvalidOrderError();
        }
      }

      if (errorDuringSubmit) {
        throw new Error(
          `The request to iZZi returned an unexpected status code: ${response.status}.`
        );
      }
    } finally {
      this.orderSubmitted = succesfullySubmitted;
    }

    return undefined;
  }

  searchAddress(address: Partial<Address>): {
    abortController: AbortController;
    promise: Promise<Address | undefined>;
  } {
    const abortController = new AbortController();

    if (['development', 'test'].includes(document.documentElement.dataset?.mode ?? '')) {
      const delayMs = randomDelay();
      console.info(
        '[IzziService#searchAddress] Test mode, returning an address after a randomized delay of ' +
          delayMs +
          'ms'
      );

      const resultingAddress =
        address.postalCode && address.postalCode > '3000'
          ? {
              ...address,
              street: 'Autostraat',
              city: 'Autocity'
            }
          : undefined;

      return {
        abortController,
        promise: new Promise(resolve => {
          setTimeout(resolve, delayMs, resultingAddress);
        })
      };
    }

    const promise = (async () => {
      if (
        !address.houseNumber ||
        !address.postalCode ||
        !address.postalCode.replace(/\s/g, '') ||
        !Number.parseInt(address.houseNumber, 10)
      ) {
        return undefined;
      }

      const returnAddress: Address = {
        ...address,
        postalCode: address.postalCode,
        houseNumber: address.houseNumber,
        street: address.street ?? '',
        city: address.city ?? '',
        'countryCodeISO3166-1': address['countryCodeISO3166-1'] ?? 'NL'
      };

      if (address['countryCodeISO3166-1'] && address['countryCodeISO3166-1'] !== 'NL') {
        return returnAddress;
      }

      const queryString = qs.stringify({
        houseNumber: Number.parseInt(address.houseNumber, 10),
        zipCode: address.postalCode.replace(/\s/g, '').toUpperCase()
      });

      const response = await fetch(`/validation/CheckAddress/?${queryString}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        signal: abortController.signal
      });

      if (response.status >= 300) {
        throw new IzziAddressSearchError();
      }

      const responseData = await response.json();

      if (
        !responseData ||
        !responseData.IsValid ||
        typeof responseData.Street !== 'string' ||
        typeof responseData.City !== 'string'
      ) {
        return undefined;
      }

      return {
        ...returnAddress,
        ...{
          street: responseData.Street,
          city: responseData.City
        }
      };
    })();

    return { abortController, promise };
  }

  public async validateVipCardNumber(
    vipCardNumber: string,
    postalCode?: string,
    houseNumber?: string
  ): Promise<Result<undefined, StoreBackendError>> {
    const data = {
      VipCardNumber: vipCardNumber?.replace(/\D/g, ''),
      PostalCode: postalCode,
      HouseNumber: houseNumber
    };

    if (['development', 'test'].includes(document.documentElement.dataset?.mode ?? '')) {
      const delayMs = randomDelay();

      console.info(
        '[IzziService#validateVipCardNumber] Test mode, returning true after a randomized delay of ' +
          delayMs +
          'ms',
        data
      );

      return new Promise(resolve => {
        const result = /(\d{7})?810[1-4]\d{8}/.test(vipCardNumber)
          ? Ok(undefined)
          : Err({
              code: 'unknown',
              message: 'Vipcard number did not match the regex of the mock service.',
              forEndUser: true
            });
        setTimeout(resolve, delayMs, result);
      });
    }

    const config = await (this.getConfig(this.configDataSource) as Promise<AnyContact>);

    const webflowUrlPart = getWebflowUrlPart(config);

    if (!webflowUrlPart) {
      return Err({
        code: 'unknown',
        message: 'Unable to validate vipcard: no webFlowUrlPart could be determined.',
        forEndUser: false
      });
    }

    const response = await fetch(`/${webflowUrlPart}/checkvipcardnumber`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      return Err({
        code: 'Unexpected',
        message: `The request to iZZi returned an unexpected status code: ${response.status}.`,
        forEndUser: false
      });
    }

    const responseData = await response.json();

    if (responseData?.IsValid !== true) {
      // is it a known error code for which the error message is based on a webtext?
      const forEndUser = [
        'val_vipcard_not_for_employees_and_partners',
        'val_vipcard_temporary_card_number_not_allowed',
        'val_vipcard_not_allowed',
        'val_vipcard_invalid',
        'val_vipcard_invalid_relation',
        'val_vipcard_expired',
        'val_vipcard_expired_relation'
      ].includes(responseData?.ErrorCode);

      return Err({
        code: responseData?.ErrorCode ?? 'unknown',
        message: getWebtextValue(config, responseData.ErrorCode, responseData.ErrorMessage),
        forEndUser
      });
    }

    return Ok(undefined);
  }

  convertState(
    state: State,
    controller: IzziWebflowController,
    config: AnyContact
  ): Partial<AnyContact> {
    state = JSON.parse(JSON.stringify(state));

    return contactTranslator(state, config, controller);
  }

  static parseWebflowType(webflowType: unknown): WebflowTypeID {
    let parsedWebflowType: WebflowTypeID;

    if (webflowType) {
      switch (typeof webflowType) {
        case 'string': {
          const index = ['response', 'shop', 'gameshow'].indexOf(webflowType.toLowerCase());
          parsedWebflowType = (index >= 0 ? index + 1 : 1) as WebflowTypeID;
          break;
        }
        case 'number': {
          parsedWebflowType = ([1, 2, 3].includes(webflowType) ? webflowType : 1) as WebflowTypeID;
          break;
        }
        default: {
          parsedWebflowType = 1;
        }
      }

      return parsedWebflowType;
    }

    return 1;
  }

  public canUseStates(state1: StoreBackendServiceState, state2: StoreBackendServiceState): boolean {
    return state1.storeBackend.izzi?.IDCampaign === state2.storeBackend.izzi?.IDCampaign;
  }
}

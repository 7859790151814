import { components, observable, pureComputed, PureComputed } from 'knockout';

import { ComponentDependencies } from '../../../interfaces';
import { IdentityOrigin, UserIdentityWithValidationState } from '../../../interfaces/UserIdentity';
import { BaseComponentViewModel } from '../../base-component';

export interface IfVipcardSubmittingViewModelParams extends components.ViewModelParams {
  whenSubmitting?: 'show' | 'hide';
  origin?: IdentityOrigin | 'any';
}

export class IfVipcardSubmittingViewModel extends BaseComponentViewModel {
  public visible$: PureComputed<boolean>;

  constructor(deps: ComponentDependencies, params?: IfVipcardSubmittingViewModelParams) {
    super(deps);

    const whenSubmitting = params?.whenSubmitting ?? 'show';
    const checkOrigin = params?.origin ?? 'any';

    const submitting$ = observable(false);
    const vipcardnumber$ = observable<UserIdentityWithValidationState>();

    this.visible$ = pureComputed(() => {
      const sameOrigin = checkOrigin === 'any' || checkOrigin === vipcardnumber$()?.identityOrigin;
      const isSubmitting = sameOrigin && submitting$();
      return (whenSubmitting === 'show') === isSubmitting;
    });

    this.bindObservableToStore(submitting$, '$.order._submitting.vipcard');
    this.bindObservableToStore(vipcardnumber$, '$.user.identities.vipcardnumber');
  }
}

import template from './template.pug';
import { ComponentConfig } from '../../../interfaces';
import { OptInViewModel } from './OptInViewModel';

const config: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new OptInViewModel(deps, params)
  }
};

export default config;

import { ComponentConfig } from '../../interfaces';

import template from './template.pug';
import { ChoiceRadioToggleViewModel } from './ChoiceRadioToggleViewModel';

const config: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new ChoiceRadioToggleViewModel(deps, params)
  }
};

export default config;

import { observable, Observable } from 'knockout';
import { Action } from 'redux';

import { ComponentDependencies, HumanName } from '../../../interfaces';
import { setAdditionalContactName } from '../../../store/additionalContacts/actions';
import { HumanNameViewModelParams } from '../../../ui_widgets/human-name/HumanNameViewModel';

import { BaseComponentViewModel } from '../../base-component';

export interface AdditionalContactNameViewModelParams
  extends Omit<HumanNameViewModelParams, 'value$'> {
  additionalContactRef?: string;
}

export class AdditionalContactNameViewModel extends BaseComponentViewModel {
  public readonly readOnly: boolean;
  public readonly value$: Observable<HumanName>;
  public readonly widgetIsValid$ = observable(false);
  public readonly widgetParams: HumanNameViewModelParams;

  constructor(deps: ComponentDependencies, params?: AdditionalContactNameViewModelParams) {
    super(deps);

    this.readOnly = typeof params?.readOnly === 'boolean' ? params.readOnly : false;

    this.value$ = observable(undefined as unknown as HumanName);
    this.widgetIsValid$.extend({ equal: true } as any);

    this.widgetParams = {
      ...params,
      value$: this.value$,
      isValid$: this.widgetIsValid$
    };

    if (!params?.additionalContactRef) {
      throw new Error(
        "'additionalContactRef' parameter is required in additional-contact-name compoment."
      );
    }

    const additionalContactRef = params?.additionalContactRef;
    const storeDataJsonPath = `$.additionalContacts['${additionalContactRef}'].name`;
    const storeAction: (name: HumanName) => Action<unknown> = name =>
      setAdditionalContactName(additionalContactRef as string, name);

    if (this.readOnly) {
      this.bindObservableToStore(this.value$, storeDataJsonPath);
    } else {
      this.initializeStateUpdates();
      this.bindObservableToStore(this.value$, storeDataJsonPath, storeAction);
    }
  }
}

import { ComponentConfig } from '../../../interfaces';
import { SelectedProductsViewModel } from './SelectedProductsViewModel';

import template from './template.pug';

const config: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: deps => new SelectedProductsViewModel(deps)
  }
};

export default config;

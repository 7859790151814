import {
  StoreBackendService,
  StoreBackendServiceState,
  StoreBackendServiceValidationErrorCode
} from '../../../interfaces';
import { StoreBackendError } from '../../../interfaces/StoreBackendService';

import { emptyState } from './emptyState';
import { Err, Ok, Result } from '../../../helpers';

export class DummyService implements StoreBackendService {
  readonly persist = false;
  constructor() {
    console.warn('Currently using DummyService instead of an actual store backend!');
  }
  async login(): Promise<Result<URL | undefined, StoreBackendError>> {
    return Ok(undefined);
  }
  async logout(): Promise<URL> {
    return undefined as unknown as URL;
  }
  async getInitialLoginState(): Promise<StoreBackendServiceState> {
    return emptyState;
  }
  async getInitialState(): Promise<StoreBackendServiceState> {
    return emptyState;
  }
  searchAddress(): undefined {
    return undefined;
  }
  async validateVipCardNumber(): Promise<Result<undefined, StoreBackendError>> {
    return Err({
      code: 'dummy',
      message: 'Cannot validate vipcard with dummy service',
      forEndUser: true
    });
  }
  async updateOrder(): Promise<boolean> {
    return true;
  }
  async validateOrder(): Promise<StoreBackendServiceValidationErrorCode | null> {
    return 'GENERAL_ERROR';
  }
  async validateExtraIdentifier(): Promise<Result<undefined, StoreBackendError>> {
    return Ok(undefined);
  }
  async submitOrder(): Promise<void> {
    return undefined;
  }
  async saveOptInOrOutChoices(): Promise<void> {
    return undefined;
  }
  public canUseStates(): boolean {
    return true;
  }
}

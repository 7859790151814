import { ComponentConfig } from '../../../interfaces';
import template from './template.pug';

import AdditionalCheckboxFieldViewModel from './AdditionalCheckboxFieldViewModel';

const config: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new AdditionalCheckboxFieldViewModel(deps, params)
  }
};

export default config;

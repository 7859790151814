import { ComponentConfig } from '../../interfaces';

import template from './template.pug';
import { RemainingCreditWarningViewModel } from './RemainingCreditWarningViewModel';

const config: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params, componentInfo) =>
      new RemainingCreditWarningViewModel(deps, params, componentInfo)
  }
};

export default config;

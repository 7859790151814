import i18next from 'i18next';
import { components, observable, Observable, pureComputed, PureComputed } from 'knockout';
import { ComponentDependencies } from '../../interfaces';
import { BaseWidgetViewModel } from '../BaseWidgetViewModel';

export interface QuantitySpinnerViewModelParams extends components.ViewModelParams {
  value$?: Observable<number>;
  disabled$?: Observable<boolean>;
  min?: number;
  max?: number;
  step?: number;
  readOnly?: boolean;
  allowTyping?: boolean;
  texts?: {
    label?: string;
    decreaseQuantity?: string;
    increaseQuantity?: string;
    minValidationMessage?: string;
    maxValidationMessage?: string;
    quantityDescription?: string;
  };
}

export class QuantitySpinnerViewModel extends BaseWidgetViewModel {
  public readonly value$: Observable<number>;
  public readonly textValue$: Observable<string>;
  public readonly disabled$: Observable<boolean>;
  public canIncrease$: PureComputed<boolean>;
  public canDecrease$: PureComputed<boolean>;

  private min: number;
  private max: number;
  private step: number;
  public readOnly: boolean;
  public allowTyping: boolean;

  public texts: {
    label: string;
    decreaseQuantity: string;
    increaseQuantity: string;
    minValidationMessage: string;
    maxValidationMessage: string;
    quantityDescription: string;
  };

  constructor(deps: ComponentDependencies, params?: QuantitySpinnerViewModelParams) {
    super(deps);

    this.min = params?.min ?? 0;
    this.max = params?.max ?? Number.MAX_SAFE_INTEGER;
    this.step = params?.step ?? 1;
    this.readOnly = params?.readOnly ?? false;
    this.allowTyping = params?.allowTyping ?? false;

    this.texts = {
      label: i18next.t('components.quantityInput.label', 'Amount'),
      decreaseQuantity: i18next.t('components.quantityInput.decreaseQuantity', 'Less'),
      increaseQuantity: i18next.t('components.quantityInput.increaseQuantity', 'More'),
      minValidationMessage: i18next.t(
        'components.quantityInput.minValidationMessage',
        'Please enter a value greater than or equal to {0}.'
      ),
      maxValidationMessage: i18next.t(
        'components.quantityInput.maxValidationMessage',
        'Please enter a value less than or equal to {0}.'
      ),
      quantityDescription: '',
      ...params?.texts
    };

    this.value$ = params?.value$ ?? observable(this.min);

    // make sure that the incoming value is valid (or make it valid)
    this.value$(this.getCleanValue());
    this.textValue$ = observable(String(this.value$()));

    this.disabled$ = params?.disabled$ ?? observable(false);

    // make sure that when the value is updated externally, it will be made valid
    this.subscriptions.push(
      this.value$.subscribe(() => {
        const cleanValue = this.getCleanValue();
        this.value$(cleanValue);
        this.textValue$(String(cleanValue));
      }),

      this.textValue$.subscribe(() => {
        const textValue = this.textValue$();
        this.value$(Number.parseInt(textValue, 10));
      })
    );

    this.value$.extend({
      min: this.min,
      max: this.max
    });

    this.canIncrease$ = pureComputed(
      () => this.value$() + this.step <= this.max && !this.disabled$()
    );

    this.canDecrease$ = pureComputed(
      () => this.value$() - this.step >= this.min && !this.disabled$()
    );
  }

  private getCleanValue(): number {
    const value = this.value$();

    if (!Number.isSafeInteger(value)) {
      return this.min;
    }

    if (value < this.min) {
      return this.min;
    }

    if (value > this.max) {
      return this.max;
    }

    return value;
  }

  public increase(): void {
    this.value$(Math.min(this.getCleanValue() + this.step, this.max));
  }

  public decrease(): void {
    this.value$(Math.max(this.getCleanValue() - this.step, this.min));
  }
}

import { ActiveStageState, ComponentState, Routes } from '../../interfaces';
import { initialState } from './initialState';
import {
  AppActionTypes,
  DEFINE_ROUTES,
  DELETE_APP_COMPONENT_STATE,
  SET_APP_ACTIVE_CATEGORY,
  SET_APP_ACTIVE_STAGE,
  SET_APP_COMPONENT_STATE,
  SET_APP_READY_STATE,
  SET_VISIBILITY_OPTION_STATE
} from './actions';

export interface AppState {
  activeCategory: string | undefined;
  activeStage: ActiveStageState | undefined;
  ready: boolean;
  routes: Routes;
  componentStates: {
    [key: string]: ComponentState;
  };
  visibilityOptionGroups: {
    [key: string]: {
      [key: string]: boolean;
    };
  };
}

export function appReducer(state = initialState, action: AppActionTypes): AppState {
  switch (action.type) {
    case SET_APP_READY_STATE: {
      return {
        ...state,
        ready: action.payload
      };
    }

    case SET_APP_ACTIVE_CATEGORY: {
      return {
        ...state,
        activeCategory: action.payload
      };
    }

    case SET_APP_ACTIVE_STAGE: {
      return {
        ...state,
        activeStage: action.payload
      };
    }

    case DEFINE_ROUTES: {
      return {
        ...state,
        routes: action.payload
      };
    }

    case SET_APP_COMPONENT_STATE: {
      return {
        ...state,
        componentStates: {
          ...state.componentStates,
          [action.payload.id]: action.payload
        }
      };
    }

    case DELETE_APP_COMPONENT_STATE: {
      if (!state.componentStates[action.meta.identifier]) {
        return state;
      }

      const componentStates = { ...state.componentStates };

      delete componentStates[action.meta.identifier];

      return {
        ...state,
        componentStates
      };
    }

    case SET_VISIBILITY_OPTION_STATE: {
      const visibilityOptionGroupsState = {
        ...state.visibilityOptionGroups[action.payload.groupId]
      };

      if (action.payload.exclusive && action.payload.checked) {
        for (const optionId in visibilityOptionGroupsState) {
          visibilityOptionGroupsState[optionId] = false;
        }
      }

      return {
        ...state,
        visibilityOptionGroups: {
          ...state.visibilityOptionGroups,
          [action.payload.groupId]: {
            ...visibilityOptionGroupsState,
            [action.payload.optionId]: action.payload.checked
          }
        }
      };
    }

    default: {
      return state;
    }
  }
}

import { createSelector } from 'reselect';
import { State } from './reducers';
import { Product } from '../interfaces';
import { readPendingOrder } from '../helpers/readPendingOrder';

// root selectors

export const selectCategories = (state: State) => state.categories;

export const selectActiveCategoryId = (state: State) => state.app.activeCategory;

export const selectSkusInActiveCategory = createSelector(
  [selectCategories, selectActiveCategoryId],
  (categories, activeCategoryId) =>
    activeCategoryId !== undefined ? categories[activeCategoryId].skus : []
);

export const selectOrderItemsQueue = (state: State) => state.order._queues.orderItems;

export const selectOrder = (state: State) => state.order;

export const selectProducts = (state: State) => state.products;

export const selectProductId = (state: State, sku: string) => sku;

export const selectUser = (state: State) => state.user;
export const selectOrderItems = (state: State) => state.order.items;

export const selectOrderItemsList = createSelector([selectOrderItems], orderItems =>
  Object.values(orderItems)
);

// derived selectors
export const selectOrderIsPending = createSelector([selectOrderItemsQueue], s => s.hasPendingItems);

export const selectPendingOrder = createSelector([selectOrder], state => readPendingOrder(state));

export const selectProductList = createSelector([selectProducts], state => {
  const list = Object.values(state);
  list.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
  return list;
});

export const selectProductById = createSelector(
  [selectProducts, selectProductId],
  (state, productId) => state[productId] as Product
);

import { components, observable, Observable, pureComputed, PureComputed } from 'knockout';
import i18next from 'i18next';

import { ComponentDependencies } from '../../interfaces';
import { setUseContactAddressSetting } from '../../store/order/actions';

import { BaseComponentViewModel } from '../base-component';

export interface DeliveryAddressToggleViewModelParams extends components.ViewModelParams {
  context?: any;
  texts?: {
    label?: string;
  };
}

export class DeliveryAddressToggleViewModel extends BaseComponentViewModel {
  public readonly context: any;

  public readonly texts: {
    label: string;
  };

  public readonly deliveryAddressAllowed$: Observable<boolean> = observable(true);
  public readonly value$: Observable<boolean> = observable(false);

  public readonly useContactAddress$: PureComputed<boolean>;

  constructor(deps: ComponentDependencies, params?: DeliveryAddressToggleViewModelParams) {
    super(deps);

    this.context = params?.context;

    this.texts = {
      label: i18next.t('components.deliveryAddressToggle.label', 'Deliver at a different address'),
      ...params?.texts
    };

    this.useContactAddress$ = pureComputed({
      read(): boolean {
        return !this.value$();
      },
      write(value: boolean) {
        this.value$(!value);
      },
      owner: this
    });

    this.bindObservableToStore(this.deliveryAddressAllowed$, '$.user.features.deliveryAddress');
    this.bindObservableToStore(
      this.useContactAddress$,
      '$.order.useContactAddress',
      setUseContactAddressSetting
    );
  }
}

import i18next from 'i18next';
import {
  components,
  Observable,
  observable,
  PureComputed,
  pureComputed,
  validation
} from 'knockout';
import { ComponentDependencies } from '../../interfaces';
import { BaseWidgetViewModel } from '../BaseWidgetViewModel';

export interface DateInputViewModelParams extends components.ViewModelParams {
  value$?: Observable<string | undefined>;
  minDate?: string;
  maxDate?: string;
  readOnly?: boolean;
  required?: boolean;
  texts?: {
    label?: string;
  };
}

export class DateInputViewModel extends BaseWidgetViewModel {
  value$: Observable<string | undefined>;
  formattedValue$: PureComputed<string>;
  minDate?: Date;
  maxDate?: Date;
  required: boolean;
  readOnly: boolean;
  texts: {
    label: string;
    minDateValidationMessage: string;
    maxDateValidationMessage: string;
  };

  constructor(deps: ComponentDependencies, params?: DateInputViewModelParams) {
    super(deps);

    try {
      this.minDate = params?.minDate ? new Date(params.minDate) : undefined;
    } catch (err) {
      console.error(err);
    }

    try {
      this.maxDate = params?.maxDate ? new Date(params.maxDate) : undefined;
    } catch (err) {
      console.error(err);
    }

    this.required = typeof params?.required !== 'undefined' ? Boolean(params.required) : true;
    this.readOnly = Boolean(params?.readOnly);
    this.texts = {
      label: i18next.t('components.dateInput.label', 'Date'),
      minDateValidationMessage: i18next.t(
        'components.dateInput.minDateValidationMessage',
        'Date must be after {{minDate}}',
        { minDate: this.minDate?.toDateString() }
      ),
      maxDateValidationMessage: i18next.t(
        'components.dateInput.maxDateValidationMessage',
        'Date must be before {{maxDate}}',
        { maxDate: this.maxDate?.toDateString() }
      ),
      ...params?.texts
    };
    this.value$ = params?.value$ ?? observable();

    this.formattedValue$ = pureComputed(() => {
      const value = this.value$();

      let formattedValue = '';

      try {
        if (value) {
          formattedValue = new Date(value).toLocaleDateString();
        }
      } catch (err) {
        console.error(err);
      }

      return formattedValue;
    });

    this.value$.extend({
      required: this.required,
      date: true,
      minLength: 1
    });

    this.value$.extend({
      validation: {
        validator: value => {
          let date;

          try {
            date = new Date(value);
          } catch (err) {
            console.error(err);
          }

          if (date && this.minDate && date < this.minDate) {
            return false;
          }

          return true;
        },
        message: this.texts.minDateValidationMessage
      }
    });

    this.value$.extend({
      validation: {
        validator: value => {
          let date;

          try {
            date = new Date(value);
          } catch (err) {
            console.error(err);
          }

          if (date && this.maxDate && date > this.maxDate) {
            return false;
          }

          return true;
        },
        message: this.texts.maxDateValidationMessage
      }
    });
  }
}

import { components } from 'knockout';

import { ComponentDependencies } from '../../interfaces';
import { BaseComponentViewModel } from '../base-component';

export interface VimeoVideoViewModelParams extends components.ViewModelParams {
  videoId?: string;
  autoplay?: boolean;
}

export class VimeoVideoViewModel extends BaseComponentViewModel {
  public readonly url?: string;
  public readonly autoplay: boolean = false;

  constructor(deps: ComponentDependencies, params?: VimeoVideoViewModelParams) {
    super(deps);

    this.url = params?.videoId ? `https://player.vimeo.com/video/${params.videoId}` : undefined;

    if (this.url) {
      this.url += params?.autoplay ? '?autoplay=1' : '';
    }
  }
}

import i18next from 'i18next';

import {
  applyBindings,
  BindingHandler,
  bindingHandlers,
  components,
  options as knockoutOptions,
  validation
} from 'knockout';

import 'knockout.validation';
import { Unsubscribe } from 'redux';

import * as defaultBindingHandlers from './bindings';
import globalConfig from './config';
import { sendPageEvent, switchAppReadyStyles } from './helpers';
import {
  AppInterface,
  AppConfig,
  LinearRoute,
  Routes,
  StoreBackendService,
  Translations
} from './interfaces';
import {
  AppStore,
  defineRoutes,
  markOrderConfirmed,
  setAddress,
  setAppReadyState,
  setEmail,
  setName,
  setPhone,
  setProducts,
  setState,
  store as defaultStore,
  deleteProducts,
  setGender
} from './store';
import { initialState as contactInitialState } from './store/contact/initialState';
import { State } from './store/reducers';
import { clearState, persistState, restoreState } from './store/storage';
import defaultComponentList from './ui_components';
import defaultWidgetList from './ui_widgets';
import { BaseWidgetViewModel } from './ui_widgets/BaseWidgetViewModel';
import {
  LoggedOutEvent,
  OptInsSubmittedEvent,
  OrderItemMutationValidatedEvent,
  OrderSubmittedEvent,
  StageNavigationEvent,
  StoreProductsRefreshedEvent,
  ProductsOutOfStockEvent
} from './events';
import { showOrderItemMutationValidationDialog } from './helpers/showOrderItemMutationValidationDialog';
import { appEventManager as globalAppEventManager } from './services/event-manager/appEventManager';
import {
  AppEventManagerInterface,
  BackorderService,
  CartService,
  HtmlDataThemeConfigProvider,
  OptInService,
  StageNavigationService,
  StoreSelectors,
  TestModeService,
  ThemeConfigService,
  UserIdentificationService,
  NotificationService
} from './services';

class DefaultViewModel {}

export type Stages = (string | string[])[];

export class App implements AppInterface {
  public readonly service: StoreBackendService;
  public readonly store: AppStore;
  public readonly selectors: StoreSelectors;
  public readonly stageNavigationService: StageNavigationService;
  public readonly notificationService: NotificationService;
  public readonly cartService: CartService;
  public readonly optInService: OptInService;
  public readonly backorderService: BackorderService;
  public readonly testModeService: TestModeService;
  public readonly themeConfigService: ThemeConfigService;
  public readonly userIdentificationService: UserIdentificationService;
  private disposeStoreSubscription?: Unsubscribe;

  config: AppConfig;

  constructor(
    config: Partial<App['config']>,
    service: StoreBackendService,
    store: AppStore = defaultStore,
    readonly appEventManager: AppEventManagerInterface = globalAppEventManager
  ) {
    if (!service) {
      throw new Error(
        'No StoreBackendService set.\nPlease provide an instance of StoreBackendService as an argument to the App constructor call.'
      );
    }

    if (config.stages && config.routes) {
      throw new Error(
        'Both "stages" and "routes" app config options are given. Only one of these is allowed, ' +
          'because the "routes" option replaces the deprecated "stages" option.'
      );
    }

    if (
      config.useStateIfOrderConfirmed !== undefined &&
      config.clearStateWhenOrderSubmitted !== undefined
    ) {
      throw new Error(
        'Both "useStateIfOrderConfirmed" and "clearStateWhenOrderSubmitted" app config options are given. Only one of these is allowed, ' +
          'because the "useStateIfOrderConfirmed" option replaces the deprecated "clearStateWhenOrderSubmitted" option.'
      );
    }

    this.config = {
      // default values
      bindingElem: document.body,
      ViewModel: DefaultViewModel,
      prefillContactName: true,
      prefillGender: true,
      prefillContactPhoneNumber: true,
      prefillContactEmail: true,
      prefillContactAddress: true,
      storageIdentifier: 'state',
      language: undefined,
      translations: {},
      useStateIfOrderConfirmed:
        config.useStateIfOrderConfirmed !== undefined
          ? config.useStateIfOrderConfirmed
          : this.convertLegacy_ClearStateWhenOrderSubmitted_To_UseStateWhenOrderConfirmed(
              config.clearStateWhenOrderSubmitted
            ),
      clearStateWhenOrderSubmitted: false,
      enableReportProgress: true,
      postponeReportProgressUntilUserIdentified: false,
      removeProductsFromStockWhenOutOfStock: false,
      // given config
      ...config,
      stages: [],
      routes: config.routes ? config.routes : this.convertLegacyStagesToRoutes(config.stages),
      componentDictionary: {
        ...defaultComponentList,
        ...defaultWidgetList,
        ...(config.componentDictionary ?? {})
      },
      bindingHandlers: {
        ...defaultBindingHandlers,
        ...(config.bindingHandlers ?? {})
      },
      queueingBehavior: {
        queueOrderItemMutations: true,
        ...config.queueingBehavior
      },
      optInHandling: {
        autoSubmitOnChoice: false,
        targetStageAfterSubmit: undefined,
        targetStageAfterSubmitFailure: undefined,
        ...config.optInHandling
      },
      enableOrderValidationDialogs: true
    };

    this.service = service;
    this.store = store;
    this.selectors = new StoreSelectors(store);
    this.cartService = new CartService(
      store,
      service,
      appEventManager,
      this.config.queueingBehavior.queueOrderItemMutations,
      this.config.enableReportProgress,
      this.config.postponeReportProgressUntilUserIdentified
    );
    this.optInService = new OptInService(
      store,
      service,
      appEventManager,
      this.config.optInHandling.autoSubmitOnChoice
    );
    this.backorderService = new BackorderService();
    this.testModeService = new TestModeService(store);
    this.themeConfigService = new ThemeConfigService(
      new HtmlDataThemeConfigProvider(config.themeConfigNormalizer),
      config.defaultThemeConfig
    );
    this.stageNavigationService = new StageNavigationService(store, appEventManager);
    this.notificationService = new NotificationService(store, appEventManager);
    this.userIdentificationService = new UserIdentificationService(
      store,
      service,
      appEventManager,
      this.notificationService
    );
  }

  private convertLegacy_ClearStateWhenOrderSubmitted_To_UseStateWhenOrderConfirmed(
    clearStateWhenOrderSubmitted: AppConfig['clearStateWhenOrderSubmitted'] | undefined
  ): AppConfig['useStateIfOrderConfirmed'] {
    if (clearStateWhenOrderSubmitted === undefined) {
      return false; // use the default for the new option
    }

    return !clearStateWhenOrderSubmitted;
  }

  private convertLegacyStagesToRoutes(stages: Stages | undefined): Routes {
    const toRoute = (stageIdentifier: unknown): LinearRoute => {
      if (typeof stageIdentifier !== 'string') {
        throw new Error(
          `Invalid stages config. StageIdentifier extected to be a string, but got an ${typeof stageIdentifier}`
        );
      }
      return {
        name: stageIdentifier,
        component: {
          default: `${stageIdentifier}-page`
        }
      };
    };

    if (stages instanceof Array) {
      return stages.map(stageOrArray =>
        stageOrArray instanceof Array ? stageOrArray.map(toRoute) : toRoute(stageOrArray)
      );
    }
    return [];
  }

  async start(): Promise<void> {
    try {
      knockoutOptions.deferUpdates = true;

      await this.restoreState();

      this.initTranslations([globalConfig.i18n, this.config.translations]);

      this.testModeService.determineTestMode();

      this.initValidations();
      this.initBindingHandlers(this.config.bindingHandlers);
      this.initBindings(this.config.ViewModel, this.config.bindingElem);
      this.initStageNavigation();
      this.initNotifications();

      this.cartService.enforceRestrictionsOnOrder();

      void this.userIdentificationService.tryUseVipcardFromBackend();

      this.store.dispatch(setAppReadyState(true));
    } finally {
      switchAppReadyStyles();
    }
  }

  private initTranslations(translationBundles: Translations[]) {
    i18next.init({
      fallbackLng: 'en',
      lng: this.getLanguage(),
      defaultNS: 'translation',
      initImmediate: false
    });

    translationBundles.forEach(translationBundle => {
      for (const languageCode in translationBundle) {
        for (const namespace in translationBundle[languageCode]) {
          i18next.addResourceBundle(
            languageCode,
            namespace,
            translationBundle[languageCode][namespace],
            true,
            true
          );
        }
      }
    });
  }

  private initBindingHandlers(handlers: { [key: string]: BindingHandler }) {
    for (const bindingName in handlers) {
      bindingHandlers[bindingName] = handlers[bindingName];
    }
  }

  private initBindings(ViewModel: new (...args: any[]) => any, elem: HTMLElement) {
    const viewModel = new ViewModel();

    for (const tagName in this.config.componentDictionary) {
      components.register(tagName, {
        ...this.config.componentDictionary[tagName],
        viewModel: {
          /* eslint-disable-next-line no-loop-func */
          createViewModel: (
            params: components.ViewModelParams,
            componentInfo: components.ComponentInfo
          ): BaseWidgetViewModel => {
            const componentViewModel: BaseWidgetViewModel = this.config.componentDictionary[
              tagName
            ].viewModel.createViewModel(
              {
                cart: this.cartService,
                backorder: this.backorderService,
                stageNavigation: this.stageNavigationService,
                appEventManager: this.appEventManager,
                notifications: this.notificationService,
                optIns: this.optInService,
                selectors: this.selectors,
                service: this.service,
                store: this.store,
                themeConfig: this.themeConfigService,
                userIdentification: this.userIdentificationService
              },
              params,
              componentInfo
            );

            const componentElement = componentInfo.element as HTMLElement;

            if (componentViewModel.id && componentElement.dataset) {
              componentElement.dataset.componentId = componentViewModel.id;
            }

            return componentViewModel;
          }
        }
      });
    }

    applyBindings(viewModel, elem);
  }

  private async restoreState() {
    this.appEventManager.on(StoreProductsRefreshedEvent, ev => {
      this.store.dispatch(setProducts(ev.products));
    });

    if (this.config.removeProductsFromStockWhenOutOfStock) {
      this.appEventManager.on(ProductsOutOfStockEvent, ev => {
        this.store.dispatch(deleteProducts(ev.skus));
      });
    }

    this.appEventManager.on(LoggedOutEvent, () => {
      clearState(this.config.storageIdentifier);
    });

    if (this.config.enableOrderValidationDialogs) {
      this.appEventManager.on(OrderItemMutationValidatedEvent, ev => {
        showOrderItemMutationValidationDialog(ev.validationResult, this.notificationService);
      });
    }

    this.appEventManager.on(OrderSubmittedEvent, event => {
      if (event.succesfullySubmitted) {
        this.store.dispatch(markOrderConfirmed());
        if (this.config.clearStateWhenOrderSubmitted) {
          clearState(this.config.storageIdentifier);
        }
      }
    });

    this.appEventManager.on(OptInsSubmittedEvent, event => {
      const stageIdentifier = event.succesfullySubmitted
        ? this.config.optInHandling.targetStageAfterSubmit
        : this.config.optInHandling.targetStageAfterSubmitFailure;

      if (!stageIdentifier) {
        return;
      }

      this.stageNavigationService.navigate(stageIdentifier);
    });

    this.beforeStateLoad();

    if (this.disposeStoreSubscription) {
      this.disposeStoreSubscription();
    }

    const restoredState = restoreState(this.config.storageIdentifier);
    const serviceState = await this.service.getInitialState();

    if (
      restoredState &&
      Object.keys(restoredState.products).length &&
      (this.config.useStateIfOrderConfirmed || !restoredState.order.confirmed) &&
      this.service.canUseStates(restoredState, serviceState)
    ) {
      this.store.dispatch(setState(this.cleanRestoredState(restoredState)));
    } else {
      const state: State = {
        ...serviceState,
        app: {
          activeStage: undefined,
          activeCategory: undefined,
          ready: false,
          routes: [],
          componentStates: {},
          visibilityOptionGroups: {}
        },
        notifications: []
      };

      const categoryIdentifiers = Object.keys(serviceState.categories);

      if (categoryIdentifiers.length) {
        state.app.activeCategory = categoryIdentifiers[0];
      }

      this.store.dispatch(setState(state));

      if (!this.config.prefillContactName) {
        this.store.dispatch(setName(contactInitialState.name));
      }

      if (this.config.prefillGender === false) {
        this.store.dispatch(setGender(contactInitialState.name.gender));
      } else if (typeof this.config.prefillGender === 'string') {
        this.store.dispatch(setGender(this.config.prefillGender));
      }

      if (!this.config.prefillContactPhoneNumber) {
        this.store.dispatch(setPhone(contactInitialState.phoneNumber));
      }

      if (!this.config.prefillContactEmail) {
        this.store.dispatch(setEmail(contactInitialState.emailAddress));
      }

      if (!this.config.prefillContactAddress) {
        this.store.dispatch(setAddress(contactInitialState.address));
      }

      if (this.service.persist) {
        persistState(this.store.getState(), this.config.storageIdentifier);
      }
    }

    this.disposeStoreSubscription = this.store.subscribe(() => {
      if (this.service.persist) {
        persistState(this.store.getState(), this.config.storageIdentifier);
      }
    });

    this.onStateLoaded();
  }

  private cleanRestoredState(restoredState: State): State {
    restoredState.app.ready = false;
    restoredState.notifications = [];

    return restoredState;
  }

  private beforeStateLoad() {
    this.config.bindingElem.dataset.stateLoaded = '0';
  }

  private onStateLoaded() {
    this.config.bindingElem.dataset.stateLoaded = '1';
  }

  private initStageNavigation() {
    this.store.dispatch(defineRoutes(this.config.routes));

    this.stageNavigationService.init();

    // send events for analytics
    const activeRouteName = this.stageNavigationService.getActiveRouteName();
    if (activeRouteName) {
      sendPageEvent(activeRouteName);
    }
    this.appEventManager.on(StageNavigationEvent, ev => sendPageEvent(ev.targetRoute));
  }

  private initValidations() {
    this.initKnockoutValidationTranslations();

    validation.init({
      errorMessageClass: 'validation-message',
      errorElementClass: 'validation-failed',
      errorsAsTitle: false,
      messagesOnModified: true
    });

    const locale = this.getLocale();

    if (locale) {
      try {
        validation.locale(locale);
      } catch (err) {
        console.error(err);
      }
    }
  }

  private initKnockoutValidationTranslations() {
    if (!i18next.exists('knockoutValidation')) {
      return;
    }

    const translations = Object.keys(validation.rules).reduce(
      (result: { [key: string]: string }, key) => {
        result[key] = i18next.t(`knockoutValidation.${key}`) ?? validation.rules[key].message ?? '';

        return result;
      },
      {}
    );

    validation.defineLocale(this.getLocale(), translations);
  }

  private initNotifications() {
    this.notificationService.initialize();

    this.notificationService.onNotification(
      this.notificationService.showNotificationInDefaultDialog,
      {
        priority: 0,
        clearImmediately: false
      }
    );
  }

  private getLocale(): string {
    return this.config.language ? this.config.language : 'nl-NL';
  }

  private getLanguage() {
    return this.getLocale().substring(0, 2);
  }
}

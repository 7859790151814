import { ThemeConfigProviderInterface } from './provider/ThemeConfigProviderInterface';
import { ThemeConfig } from './ThemeConfig';

export class ThemeConfigService<T extends ThemeConfig = ThemeConfig> {
  private cachedThemeConfig: T | undefined;
  private defaultThemeConfig: T;
  private provider: ThemeConfigProviderInterface<T>;

  constructor(provider: ThemeConfigProviderInterface<T>, defaultThemeConfig?: T) {
    this.provider = provider;
    this.defaultThemeConfig = defaultThemeConfig ?? ({} as T);
  }

  public getThemeConfig(): T {
    if (!this.cachedThemeConfig) {
      this.cachedThemeConfig = {
        ...this.defaultThemeConfig,
        ...this.provider?.provideThemeConfig()
      };
    }

    return this.cachedThemeConfig;
  }
}

import { components, observable, PureComputed, pureComputed } from 'knockout';
import { ComponentDependencies, OptIn, OptInType } from '../../../interfaces';
import { BaseComponentViewModel } from '../../base-component';

export interface OptInViewModelParams extends components.ViewModelParams {
  optInType?: OptInType[];
  filterType?: 'include' | 'exclude';
}

export class OptInListViewModel extends BaseComponentViewModel {
  public optInsToDisplay$: PureComputed<OptIn[]>;

  constructor(deps: ComponentDependencies, params: OptInViewModelParams) {
    super(deps);

    const optInTypes = params?.optInType instanceof Array ? params.optInType : [];

    const optInsData$ = observable<OptIn[]>([]);
    this.bindObservableToStore(optInsData$, '$.optIns');

    const shouldInclude = params?.filterType !== 'exclude';

    this.optInsToDisplay$ = pureComputed(() => {
      return (Object.values(optInsData$()) as unknown as OptIn[]).filter(
        optIn => !optInTypes.length || optInTypes.includes(optIn.optInType) === shouldInclude
      );
    });
  }
}

import {
  IdentifyUserErrorResult,
  UserIdentityWithValidationState
} from '../interfaces/UserIdentity';
import { AppEvent } from '../services';

export class VipcardValidationFailedEvent implements AppEvent {
  public static readonly EVENT_NAME = 'vipcard_validation_failed';
  public readonly eventName = VipcardValidationFailedEvent.EVENT_NAME;

  public constructor(
    readonly identity: UserIdentityWithValidationState,
    readonly result: IdentifyUserErrorResult
  ) {}
}

import { Product } from '../../interfaces';
import { ProductsActionTypes, ADD_PRODUCT, SET_PRODUCTS, DELETE_PRODUCTS } from './actions';
import { initialState } from './initialState';

export interface ProductsState {
  [index: string]: Product;
}

export function productsReducer(state = initialState, action: ProductsActionTypes): ProductsState {
  switch (action.type) {
    case SET_PRODUCTS: {
      return action.payload;
    }

    case ADD_PRODUCT: {
      if (state[action.payload.sku]) {
        return state;
      }

      return {
        ...state,
        [action.payload.sku]: action.payload
      };
    }

    case DELETE_PRODUCTS: {
      const newState = { ...state };
      for (const sku of action.payload.skus) {
        delete newState[sku];
      }
      return newState;
    }

    default: {
      return state;
    }
  }
}

import { ComponentConfig } from '../../interfaces';

import template from './template.pug';
import { CollapsibleContentViewModel } from './CollapsibleContentViewModel';

const config: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new CollapsibleContentViewModel(deps, params)
  }
};

export default config;

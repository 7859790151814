import { Order } from '../interfaces';
import { State } from '../store';

export function quantityValidator(state: State): boolean {
  const userMax = state.user.restrictions.quantity ?? Infinity;
  const orderQuantity: number = calculateOrderQuantity(state.order);

  if (orderQuantity > userMax) {
    return false;
  }

  for (const orderItem of Object.values(state.order.items)) {
    const productMax = state.products[orderItem.sku].maximums.quantity ?? Infinity;
    const productMin = state.products[orderItem.sku].minimums.quantity ?? 0;

    if (orderItem.quantity > productMax) {
      return false;
    }

    if (orderItem.quantity < productMin) {
      return false;
    }
  }

  return true;
}

function calculateOrderQuantity(order: Order): number {
  let totalQuantity = 0;

  for (const orderItem of Object.values(order.items)) {
    totalQuantity += orderItem.quantity;
  }

  return totalQuantity;
}

import { BindingHandler, utils } from 'knockout';

export const latinRegex = /^[\p{Script=Latin}\d\s,\-\\:@[~!#$%^&*()_+\]"/`'{}|<>=?;.]*$/u;

export const restrictInput: BindingHandler = {
  init: (element: HTMLInputElement | HTMLTextAreaElement, valueAccessor) => {
    if (!(element instanceof HTMLInputElement || element instanceof HTMLTextAreaElement)) {
      const actualTagName = (element as HTMLElement).tagName.toLowerCase();
      throw new Error(
        `The restrictInput binding can only be used on <input> or <textarea> elements, but got <${actualTagName}>.`
      );
    }

    const arg = valueAccessor();
    let regex;
    let attributes: Record<string, string> = {};

    switch (arg) {
      case 'numeric':
        regex = /^-?[0-9]*$/;
        attributes = {
          inputmode: 'numeric',
          pattern: '[0-9]*'
        };
        break;
      case 'latin':
        regex = latinRegex;
        break;
      default:
        throw new Error(
          `The restrictInput binding can only be used with "latin" or "numeric" but got "${arg}" as value.`
        );
    }

    if (!arg) return; // nothing to do

    let prevValue = element.value;
    let prevSelStart = element.selectionStart || 0;

    function onSelectionChange() {
      // remember the current selection, so that we can retain it when we
      // revert changes to the input field in onInput.
      prevSelStart = element.selectionStart || 0;
    }

    function onInput(event: Event) {
      if (regex.test(element.value)) {
        // value is valid, allow it (and remember)
        prevValue = element.value;
      } else {
        // value is invalid, so block the change by reverting to the previous value and restoring the cursor position
        element.value = prevValue;
        element.selectionStart = prevSelStart;
        element.selectionEnd = prevSelStart;
        event.stopPropagation();
      }
    }

    // Set extra attributes on the element
    for (const attribute in attributes) {
      if (!element.getAttribute(attribute)) {
        element.setAttribute(attribute, attributes[attribute]);
      }
    }

    // event handlers are added in the capture phase so they can block the action before actual event listeners are invoked
    element.addEventListener('selectionchange', onSelectionChange, { capture: true });
    element.addEventListener('input', onInput, { capture: true });

    utils.domNodeDisposal.addDisposeCallback(element, () => {
      element.removeEventListener('selectionchange', onSelectionChange, { capture: true });
      element.removeEventListener('input', onInput, { capture: true });
    });
  }
};

export type RouteName = string;
export const NEXT_ROUTE: RouteName = '#next';
export const PREVIOUS_ROUTE: RouteName = '#previous';

type ComponentTagName = string;

type RouteSlotComponents = {
  [slotName: string]: ComponentTagName;
};

export type RouteProgressIndicatorProps = {
  include: boolean;
  label: string;
};

interface Route {
  name: RouteName;
  component: ComponentTagName | RouteSlotComponents;
  progressIndicator?: Partial<RouteProgressIndicatorProps>;
}

export type LinearRoute = Route;
export type BranchingRoute = LinearRoute[];

export type Routes = Array<LinearRoute | BranchingRoute>;

import { Order, OrderLineMutation } from '../../interfaces';
import { QueueItem } from '../../interfaces/Queue';
import {
  SET_PRODUCTS,
  SetProductsAction,
  DeleteProductsAction,
  DELETE_PRODUCTS
} from '../products/actions';
import {
  BULK_ORDER_ITEMS_MUTATION,
  BulkOrderItemsMutationAction,
  CLEAR_ORDER_ITEMS,
  CLEAR_PAYMENT_OPTION_CODE,
  ClearOrderItemsAction,
  FINALIZE_PENDING_MUTATION,
  FinalizePendingMutationAction,
  MARK_ORDER_CONFIRMED,
  MARK_SUBMITTING,
  MARK_SUBMITTING_DONE,
  MarkOrderConfirmedAction,
  MarkSubmittingAction,
  MarkSubmittingDoneAction,
  OrderActionTypes,
  PROCESS_ORDER_ITEM_MUTATION,
  ProcessOrderItemMutationAction,
  SET_ADDITIONAL_FIELD,
  SET_CHOICE,
  SET_DELIVERY_ADDRESS,
  SET_OPT_IN_SELECTION,
  SET_ORDER_REMARKS,
  SET_PAYMENT_OPTION_CODE,
  SET_RECIPIENT_NAME,
  SET_USE_CONTACT_ADDRESS_SETTING,
  SetAdditionalFieldAction,
  SetChoiceAction,
  SetDeliveryAddressAction,
  SetOptInSelectionAction,
  SetOrderRemarksAction,
  SetPaymentOptionCodeAction,
  SetRecipientNameAction,
  SetUseContactAddressSettingAction
} from './actions';
import { initialState } from './initialState';

export interface AdditionalField {
  key: string;
  value: string | number | boolean;
}

export interface Choice {
  key: keyof Order['choices'];
  value: null | boolean;
}

export function orderReducer(state: Order = initialState, action: OrderActionTypes): Order {
  /* eslint complexity: ["warn", 30] */

  switch (action.type) {
    case SET_ADDITIONAL_FIELD: {
      return setAdditionalFieldState(state, action);
    }

    case SET_CHOICE: {
      return setChoiceState(state, action);
    }

    case FINALIZE_PENDING_MUTATION: {
      return finalizePendingMutationState(state, action);
    }

    case BULK_ORDER_ITEMS_MUTATION: {
      return bulkOrderItemsMutationState(state, action);
    }

    case PROCESS_ORDER_ITEM_MUTATION: {
      return processOrderItemMutationState(state, action);
    }

    case SET_OPT_IN_SELECTION: {
      return setOptInSelectionState(state, action);
    }

    case CLEAR_ORDER_ITEMS: {
      return clearOrderItemsState(state, action);
    }

    case SET_USE_CONTACT_ADDRESS_SETTING: {
      return setUseContactAddressSettingState(state, action);
    }

    case SET_RECIPIENT_NAME: {
      return setRecipientNameState(state, action);
    }

    case SET_DELIVERY_ADDRESS: {
      return setDeliveryAddressState(state, action);
    }

    case SET_ORDER_REMARKS: {
      return setOrderRemarksState(state, action);
    }

    case SET_PAYMENT_OPTION_CODE: {
      return setPaymentOptionCodeState(state, action);
    }

    case CLEAR_PAYMENT_OPTION_CODE: {
      return clearPaymentOptionCodeState(state);
    }

    case SET_PRODUCTS: {
      return setProducts(state, action);
    }

    case DELETE_PRODUCTS: {
      return deleteProducts(state, action);
    }

    case MARK_ORDER_CONFIRMED: {
      return markOrderConfirmed(state, action);
    }

    case MARK_SUBMITTING: {
      return markSubmitting(state, action);
    }

    case MARK_SUBMITTING_DONE: {
      return markSubmittingDone(state, action);
    }

    default: {
      return state;
    }
  }
}

export function setAdditionalFieldState(state: Order, action: SetAdditionalFieldAction): Order {
  const additionalFieldsState = { ...state.additionalFields };

  if (action.payload.value === null || action.payload.value === undefined) {
    delete additionalFieldsState[action.payload.key];
  } else {
    additionalFieldsState[action.payload.key] = action.payload.value;
  }

  return {
    ...state,
    additionalFields: additionalFieldsState
  };
}

export function setChoiceState(state: Order, action: SetChoiceAction): Order {
  return {
    ...state,
    choices: {
      ...state.choices,
      [action.payload.key]: action.payload.value
    }
  };
}

function mutateOrderLine(
  orderItemsState: Order['items'],
  { sku, quantity: mutationQuantity, operation }: OrderLineMutation
): void {
  const currentQuantity = orderItemsState[sku]?.quantity ?? 0;

  let newQuantity = currentQuantity;

  if (mutationQuantity >= 0) {
    switch (operation) {
      case 'ADD': {
        newQuantity = currentQuantity + mutationQuantity;
        break;
      }

      case 'REMOVE': {
        newQuantity = currentQuantity - mutationQuantity;
        break;
      }

      case 'SET': {
        newQuantity = mutationQuantity;
        break;
      }
    }
  }

  if (!orderItemsState[sku] && newQuantity > 0) {
    orderItemsState[sku] = {
      quantity: newQuantity,
      sku
    };
  } else if (orderItemsState[sku] && newQuantity > 0) {
    orderItemsState[sku] = {
      ...orderItemsState[sku],
      quantity: newQuantity
    };
  } else if (orderItemsState[sku] && newQuantity <= 0) {
    delete orderItemsState[sku];
  }
}

export function processOrderItemMutationState(
  state: Order,
  action: ProcessOrderItemMutationAction
): Order {
  const orderItemsState = {
    ...(state._queues.orderItems.hasPendingItems
      ? state._queues.orderItems.pendingItems[state._queues.orderItems.pendingItems.length - 1].data
      : state.items)
  };

  mutateOrderLine(orderItemsState, action.payload);

  return {
    ...state,
    _queues: {
      ...state._queues,
      orderItems: {
        ...state._queues.orderItems,
        hasPendingItems: true,
        lastItemSequenceNumber: (state._queues.orderItems.lastItemSequenceNumber ?? 0) + 1,
        pendingItems: [
          ...(state._queues.orderItems.pendingItems ?? []),
          {
            sequenceNumber: (state._queues.orderItems.lastItemSequenceNumber ?? 0) + 1,
            data: orderItemsState
          }
        ]
      }
    }
  };
}

export function bulkOrderItemsMutationState(
  state: Order,
  action: BulkOrderItemsMutationAction
): Order {
  const orderItemsState = {
    ...(state._queues.orderItems.hasPendingItems
      ? state._queues.orderItems.pendingItems[state._queues.orderItems.pendingItems.length - 1].data
      : state.items)
  };

  for (const sku of action.payload.skus) {
    mutateOrderLine(orderItemsState, {
      sku,
      quantity: action.payload.quantity,
      operation: action.payload.operation
    });
  }

  if (action.payload.remainingOrderItems) {
    const skusInScope =
      action.payload.remainingOrderItems.limitToSkus ?? Object.keys(orderItemsState);

    const remainingskus = skusInScope.filter(sku => !action.payload.skus.includes(sku));

    for (const sku of remainingskus) {
      mutateOrderLine(orderItemsState, {
        sku,
        quantity: action.payload.remainingOrderItems.quantity,
        operation: action.payload.remainingOrderItems.operation
      });
    }
  }

  return {
    ...state,
    _queues: {
      ...state._queues,
      orderItems: {
        ...state._queues.orderItems,
        hasPendingItems: true,
        lastItemSequenceNumber: (state._queues.orderItems.lastItemSequenceNumber ?? 0) + 1,
        pendingItems: [
          ...(state._queues.orderItems.pendingItems ?? []),
          {
            sequenceNumber: (state._queues.orderItems.lastItemSequenceNumber ?? 0) + 1,
            data: orderItemsState
          }
        ]
      }
    }
  };
}

export function finalizePendingMutationState(
  state: Order,
  action: FinalizePendingMutationAction
): Order {
  if (!state._queues.orderItems.hasPendingItems) {
    return state;
  }

  const effects = (item: Order['_queues']['orderItems']['pendingItems'][0]) =>
    action.payload.commit
      ? item.sequenceNumber <= action.payload.sequenceNumber
        ? 'commit'
        : 'keep'
      : item.sequenceNumber >= action.payload.sequenceNumber
      ? 'discard'
      : 'keep';

  const committed = state._queues.orderItems.pendingItems.filter(x => effects(x) === 'commit');
  const kept = state._queues.orderItems.pendingItems.filter(x => effects(x) === 'keep');

  const finalCommit: QueueItem<Order['items']> = committed[committed.length - 1] ?? {
    data: state.items,
    sequenceNumber: state._queues.orderItems.lastProcessedItemSequenceNumber
  };

  return {
    ...state,
    items: finalCommit.data,
    _queues: {
      ...state._queues,
      orderItems: {
        ...state._queues.orderItems,
        lastProcessedItemSequenceNumber: finalCommit.sequenceNumber,
        hasPendingItems: kept.length > 0,
        pendingItems: kept
      }
    }
  };
}

export function setOptInSelectionState(state: Order, action: SetOptInSelectionAction): Order {
  const shouldBeSelected = action.payload.isSelected;
  const isCurrentlySelected = state.selectedOptIns.includes(action.payload.optInId);

  if (shouldBeSelected === isCurrentlySelected) {
    return state; // nothing to do
  }

  const newOptinsState = shouldBeSelected
    ? [...state.selectedOptIns, action.payload.optInId]
    : state.selectedOptIns.filter(x => x !== action.payload.optInId);

  return {
    ...state,
    selectedOptIns: newOptinsState
  };
}

export function clearOrderItemsState(state: Order, action: ClearOrderItemsAction): Order {
  if (action.meta.skus) {
    const orderItemsState = { ...state.items };

    action.meta.skus.forEach(sku => {
      delete orderItemsState[sku];
    });

    return {
      ...state,
      items: orderItemsState
    };
  }

  return {
    ...state,
    items: {}
  };
}

export function setUseContactAddressSettingState(
  state: Order,
  action: SetUseContactAddressSettingAction
): Order {
  return {
    ...state,
    useContactAddress: action.payload
  };
}

export function setRecipientNameState(state: Order, action: SetRecipientNameAction): Order {
  if (!action.payload) {
    const order = { ...state };

    delete order.recipientName;

    return order;
  }

  return {
    ...state,
    recipientName: {
      ...action.payload
    }
  };
}

export function setDeliveryAddressState(state: Order, action: SetDeliveryAddressAction): Order {
  return {
    ...state,
    deliveryAddress: {
      ...action.payload
    }
  };
}

export function setOrderRemarksState(state: Order, action: SetOrderRemarksAction): Order {
  return {
    ...state,
    remarks: action.payload === '' ? null : action.payload
  };
}

export function setPaymentOptionCodeState(state: Order, action: SetPaymentOptionCodeAction): Order {
  return {
    ...state,
    payment: {
      ...state.payment,
      paymentOptionCode: action.payload
    }
  };
}

export function clearPaymentOptionCodeState(state: Order): Order {
  return {
    ...state,
    payment: {
      ...state.payment,
      paymentOptionCode: null
    }
  };
}

function markOrderConfirmed(state: Order, action: MarkOrderConfirmedAction): Order {
  return {
    ...state,
    confirmed: true
  };
}

function markSubmitting(state: Order, action: MarkSubmittingAction): Order {
  return {
    ...state,
    _submitting: {
      ...state._submitting,
      [action.payload.subject]: true
    }
  };
}

function markSubmittingDone(state: Order, action: MarkSubmittingDoneAction): Order {
  return {
    ...state,
    _submitting: {
      ...state._submitting,
      [action.payload.subject]: false
    }
  };
}

function deleteProducts(state: Order, action: DeleteProductsAction): Order {
  const order = state;
  if (action.payload.skus.length === 0) {
    return order; //nothing to do
  }

  const newItems = { ...order.items };
  for (const skuToRemove of action.payload.skus) {
    delete newItems[skuToRemove];
  }

  return {
    ...order,
    items: newItems,
    _queues: {
      ...state._queues,
      orderItems: {
        ...state._queues.orderItems,
        hasPendingItems: false,
        pendingItems: []
      }
    }
  };
}

function setProducts(state: Order, action: SetProductsAction): Order {
  const order = state;
  const skusToKeep = Object.keys(action.payload);

  const skusInOrder = Object.keys(order.items);
  const skusToRemove = skusInOrder.filter(sku => !skusToKeep.includes(sku));

  if (skusToRemove.length === 0) {
    return order; //nothing to do
  }

  const newItems = { ...order.items };
  for (const skuToRemove of skusToRemove) {
    delete newItems[skuToRemove];
  }

  return {
    ...order,
    items: newItems,
    _queues: {
      ...state._queues,
      orderItems: {
        ...state._queues.orderItems,
        hasPendingItems: false,
        pendingItems: []
      }
    }
  };
}

import {
  components,
  Observable,
  observable,
  PureComputed,
  pureComputed,
  Subscribable
} from 'knockout';
import { DialogToggleRequestedEvent } from '../../../events';

import {
  ComponentDependencies,
  OrderLine,
  OrderLineMutation,
  Product,
  User,
  OrderLines
} from '../../../interfaces';
import { AppEventManagerInterface, CartService } from '../../../services';

import { BaseComponentViewModel } from '../../base-component';

interface DisplayedProductPropertyData {
  key: string;
  value: unknown;
  label: string;
}

type ElementClickAction =
  | 'none'
  | 'add-to-order'
  | 'open-dialog'
  | 'close-dialog'
  | 'toggle-dialog';

export interface ProductSummaryViewModelParams extends components.ViewModelParams {
  sku?: string;
  variantSkus?: string[];
  locationLineDelimiter?: string;
  displayedProductProperties?: (string | [string, string])[];
  addVariantDropdownPlaceholder?: boolean;
  orderQuantityValidationEnabled?: boolean;
  productSummaryClickAction?: ElementClickAction;
  productImageClickAction?: ElementClickAction;
  texts?: {
    addToCart?: string;
    removeFromCart?: string;
    readMore?: string;
    closeDialog?: string;
    variantLabel?: string;
    variantDropdownPlaceholder?: string;
    priceLabel?: string;
    tokenAmountLabel?: string;
    productActionsLabel?: string;
    quantityDescription?: string;
    quantityLabel?: string;
  };
}

export class ProductSummaryViewModel extends BaseComponentViewModel {
  public readonly sku?: string;
  public readonly variantSkus?: string[];
  public readonly locationLineDelimiter?: string;
  public readonly displayedProductProperties: [string, string][];
  public readonly productImageClickAction: ElementClickAction;
  public readonly productSummaryClickAction: ElementClickAction;
  public readonly orderQuantityValidationEnabled: boolean;
  public readonly addVariantDropdownPlaceholder: boolean;

  public readonly texts: {
    addToCart: string;
    removeFromCart: string;
    readMore: string;
    closeDialog: string;
    variantLabel: string;
    variantDropdownPlaceholder: string;
    priceLabel: string;
    tokenAmountLabel: string;
    productActionsLabel: string;
    quantityDescription?: string;
    quantityLabel: string;
    quantityDescription$: PureComputed<string>;
  };

  public readonly user$: Subscribable<User | undefined>;
  public readonly products$: Subscribable<Record<string, Product>>;
  public readonly orderItems$: Subscribable<OrderLines>;

  public readonly selectedVariantSku$: Observable<string | undefined>;

  public readonly isChosen$: PureComputed<boolean>;
  public readonly interactiveChildHovered$: Observable<boolean> = observable(false);
  public readonly singleChoice$: PureComputed<boolean>;
  public readonly mainProduct$: PureComputed<Product | undefined>;
  public readonly variants$: PureComputed<{ [key: string]: Product }>;
  public readonly variantsList$: PureComputed<Product[]>;
  public readonly selectedVariant$: PureComputed<Product | undefined>;
  public readonly splitLocations$: PureComputed<{
    [key: string]: string[] | undefined;
  }>;

  public readonly orderIsPending$: Subscribable<boolean>;
  public variantOptionsCaption: string | undefined;

  private cart: CartService;
  private singleSkuMaxOne$: PureComputed<boolean> | undefined;
  private appEventManager: AppEventManagerInterface;

  public dialogId: string;

  constructor(deps: ComponentDependencies, params?: ProductSummaryViewModelParams) {
    super(deps);
    this.user$ = deps.selectors.user$;
    this.products$ = deps.selectors.products$;
    this.orderItems$ = deps.selectors.orderItems$;

    this.dialogId = this.id + '-product-summary';
    this.cart = deps.cart;
    this.appEventManager = deps.appEventManager;

    this.sku = params?.sku;
    this.variantSkus = params?.variantSkus;
    this.addVariantDropdownPlaceholder = params?.addVariantDropdownPlaceholder ?? false;
    this.locationLineDelimiter = params?.locationLineDelimiter ?? undefined;
    this.orderQuantityValidationEnabled = params?.orderQuantityValidationEnabled ?? false;
    this.productImageClickAction = params?.productImageClickAction ?? 'none';
    this.productSummaryClickAction = params?.productSummaryClickAction ?? 'none';

    this.texts = {
      addToCart: this.i18next.t('components.productSummary.addToCart', 'Add to cart'),
      removeFromCart: this.i18next.t(
        'components.productSummary.removeFromCart',
        'Remove from cart'
      ),
      readMore: this.i18next.t('components.productSummary.readMore', 'Read more'),
      closeDialog: this.i18next.t('components.productSummary.closeDialog', 'Close'),
      variantLabel: this.i18next.t('components.productSummary.variantLabel', 'Variant'),
      variantDropdownPlaceholder: this.i18next.t(
        'components.productSummary.variantDropdownPlaceholder',
        '-'
      ),
      productActionsLabel: this.i18next.t('components.productSummary.productActionsLabel', ''),
      quantityLabel: this.i18next.t('components.productSummary.quantityLabel', 'Quantity'),
      priceLabel: this.i18next.t('components.productSummary.priceLabel', 'Price'),
      tokenAmountLabel: this.i18next.t('components.productSummary.tokenAmountLabel'),
      ...params?.texts,
      quantityDescription$: pureComputed(() => {
        if (params?.texts?.quantityDescription) {
          return params.texts.quantityDescription;
        }

        const selectedVariantSku = this.selectedVariantSku$();
        if (selectedVariantSku) {
          const quantity = this.orderItems$()?.[selectedVariantSku]?.quantity;

          return this.i18next.t('components.productSummary.quantityDescription', '', {
            count: quantity ?? 0
          });
        }

        return this.i18next.t('components.productSummary.quantityDescription', '', {
          count: 0
        });
      })
    };

    this.orderIsPending$ = deps.selectors.orderIsPending$;

    this.displayedProductProperties =
      params?.displayedProductProperties
        ?.map(item => {
          if (typeof item === 'string') {
            return [
              item,
              this.i18next.t(`components.productSummary.productPropertyLabels.${item}`, {
                defaultValue: null
              }) ?? item
            ];
          } else if (Array.isArray(item)) {
            return [
              item[0],
              item[1] ??
                this.i18next.t(`components.productSummary.productPropertyLabels.${item[0]}`, {
                  defaultValue: null
                }) ??
                item[0]
            ];
          }

          return undefined;
        })
        .filter((item): item is [string, string] => {
          return (
            typeof item !== 'undefined' &&
            typeof item[0] === 'string' &&
            typeof item[1] === 'string'
          );
        }) ?? [];

    this.mainProduct$ = pureComputed(() => {
      const products = this.products$();

      if (this.sku) {
        return products[this.sku];
      }

      return undefined;
    });

    this.variants$ = pureComputed(() => {
      const products = this.products$(),
        variants = {};

      if (this.sku && products[this.sku]) {
        variants[this.sku] = products[this.sku];
      }

      if (this.variantSkus) {
        this.variantSkus.forEach(variantSku => {
          if (products[variantSku]) {
            variants[variantSku] = products[variantSku];
          }
        });
      }

      return variants;
    });

    this.variantsList$ = pureComputed(() => {
      return Object.values(this.variants$()).sort((a, b) => a.sequenceNumber - b.sequenceNumber);
    });

    this.isChosen$ = pureComputed(() => {
      const orderItems = this.orderItems$(),
        variantsList = this.variantsList$();

      return variantsList.some(variant => orderItems[variant.sku]);
    });

    this.selectedVariantSku$ = observable(this.mainProduct$()?.sku);

    this.selectedVariant$ = pureComputed(() => {
      let selectedVariant;

      const products = this.products$(),
        sku = this.selectedVariantSku$();

      if (sku && products[sku]) {
        selectedVariant = products[sku];
      } else {
        selectedVariant = this.mainProduct$();
      }

      return selectedVariant;
    });

    this.splitLocations$ = pureComputed(() => {
      const variants = this.variants$(),
        splitLocations = {};

      for (const sku in variants) {
        splitLocations[sku] = this.locationLineDelimiter
          ? variants[sku].location?.split(this.locationLineDelimiter)
          : variants[sku].location
          ? [variants[sku].location]
          : undefined;
      }

      return splitLocations;
    });

    // Used as placeholder in the variant dropdown.
    //   undefined means no placeholder is added (so first item is automatically selected).
    // For more info, see the optionsCaption parameter of the knockout options binding:
    // https://knockoutjs.com/documentation/options-binding.html
    this.variantOptionsCaption = params?.addVariantDropdownPlaceholder
      ? this.texts.variantDropdownPlaceholder
      : undefined;

    this.singleChoice$ = pureComputed(() => {
      const user = this.user$();

      return user?.restrictions.quantity === 1 ?? false;
    });

    if (this.orderQuantityValidationEnabled) {
      this.initializeOrderQuantityValidations();
      this.initializeStateUpdates();
    }

    if (this.store.getState().user.restrictions.rules.singleSku) {
      //Boolean for checking if current campaign is singleSku and current selection has max one quantity.
      this.singleSkuMaxOne$ = pureComputed(() => {
        return Boolean(this.selectedVariant$()?.maximums.quantity === 1);
      });

      this.selectedVariantSku$.extend({
        required: true
      });

      //If there is only one choice and there is no dropdown then add 0 to the cart, to enforce single Sku.
      if (!this.selectedVariantSku$()) {
        const productKeys = Object.keys(this.store.getState().products);
        if (productKeys.length === 1) {
          this.cart.mutateOrderItem({
            sku: productKeys[0] as string,
            quantity: 0,
            operation: 'ADD'
          });
        }
      }

      this.subscriptions.push(
        this.selectedVariantSku$.subscribe(sku => {
          if (sku === undefined) {
            // Nothing selected in the dropdown. Since we're in singleSku mode, this means
            // that the old selection should be removed from the order.
            this.removeFirstItemFromOrder();
          } else {
            // Mutate the newly selected SKU in the cart (even with quantity 0)
            // This will trigger the CartService to enforce SingleSku mode and remove
            // the previous sku from the order.
            this.cart.mutateOrderItem({
              sku: sku as string,
              quantity: 0,
              operation: 'ADD'
            });
          }
        }),
        this.orderItems$.subscribe(items => {
          const [item] = Object.values(items);
          if (item) {
            this.selectedVariantSku$(item.sku);
          }
        })
      );
    }
  }

  public getDisplayedProductPropertyData(product: Product): DisplayedProductPropertyData[] {
    const result: DisplayedProductPropertyData[] = [];

    for (const [key, label] of this.displayedProductProperties) {
      const value = product.content.texts[key] ?? product.content.customTexts[key] ?? undefined;
      if (value !== undefined) {
        result.push({
          key,
          value,
          label
        });
      }
    }

    return result;
  }

  public addOrderItem(data: Product) {
    if (this.orderIsPending$()) {
      return;
    }

    const mutationData: OrderLineMutation = {
      operation: 'ADD',
      quantity: 1,
      sku: data.sku
    };

    this.cart.mutateOrderItem(mutationData);
  }

  public removeOrderItem(data: Product) {
    if (this.orderIsPending$()) {
      return;
    }

    const mutationData: OrderLineMutation = {
      operation: 'REMOVE',
      quantity: 1,
      sku: data.sku
    };

    this.cart.mutateOrderItem(mutationData);
  }

  public removeFirstItemFromOrder() {
    const [orderItem] = Object.values<OrderLine>(this.orderItems$());
    if (orderItem) {
      this.cart.mutateOrderItem({
        sku: orderItem.sku,
        quantity: 0,
        operation: 'SET'
      });
    }
  }

  public initializeOrderQuantityValidations(): void {
    this.orderItems$.extend({
      validation: {
        validator: (obj: Record<string, unknown>) => {
          for (const key in obj) {
            return true;
          }

          return false;
        },
        message: 'The object must have at least {0} value(s)'
      }
    });
  }

  public onProductVariantSelectorClick: (data: Product, ev: MouseEvent) => void = (data, ev) => {
    ev.stopPropagation();
  };

  public onAddButtonClick: (data: Product, ev: MouseEvent) => void = (data, ev) => {
    ev.stopPropagation();
    this.addOrderItem(data);
  };

  public onRemoveButtonClick: (data: Product, ev: MouseEvent) => void = (data, ev) => {
    ev.stopPropagation();
    this.removeOrderItem(data);
  };

  public onProductImageClick: (data: Product, ev: MouseEvent) => void = (data, ev) => {
    this.handleElementClickAction(data, ev, this.productImageClickAction);
  };

  public onProductSummaryClick: (data: Product, ev: MouseEvent) => void = (data, ev) => {
    this.handleElementClickAction(data, ev, this.productSummaryClickAction);
  };

  public onEnterInteractiveEl: (data: Product, ev: MouseEvent) => void = (data, ev) => {
    this.interactiveChildHovered$(true);
  };

  public onLeaveInteractiveEl: (data: Product, ev: MouseEvent) => void = (data, ev) => {
    this.interactiveChildHovered$(false);
  };

  private handleElementClickAction(
    data: Product,
    ev: MouseEvent,
    action: ElementClickAction
  ): void {
    if (action === 'none') {
      return;
    }

    ev.stopPropagation();

    if (action === 'add-to-order') {
      this.addOrderItem(data);
      return;
    }

    if (['open-dialog', 'close-dialog', 'toggle-dialog'].includes(action)) {
      const force = {
        'open-dialog': true,
        'close-dialog': false,
        'toggle-dialog': undefined
      }[action];
      this.appEventManager.emit(new DialogToggleRequestedEvent(this.dialogId, force));
    }
  }
}

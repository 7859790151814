import { components, observable, Observable, PureComputed, pureComputed } from 'knockout';
import { ComponentDependencies } from '../../interfaces';
import { BaseWidgetViewModel } from '../BaseWidgetViewModel';

interface SegmentConfig {
  id: string;
  size: number;
  label?: string;
  placeholder?: string;
}

export interface SegmentedTextInputViewModelParams extends components.ViewModelParams {
  value$?: Observable<string | null | undefined>;
  isValid$?: Observable<boolean>;
  readOnly?: boolean;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  preferNumericKeyboard?: boolean;
  texts?: {
    label?: string;
  };
  segments?: Partial<SegmentConfig>[];
}

export class SegmentedTextInputViewModel extends BaseWidgetViewModel {
  public readonly readOnly: boolean;
  public readonly required: boolean;
  public readonly segments: SegmentConfig[];
  public readonly minLength?: number;
  public readonly maxLength?: number;

  // attributes for the input element, to request mobile browsers to open a specific keyboard
  public readonly inputmode: string | null = null;
  public readonly pattern: string | null = null;

  public readonly texts: {
    label: string;
  };

  public readonly segmentStartIndexes: number[];
  public readonly value$: PureComputed<string | null | undefined>;
  public readonly segmentValues: Observable<string>[];

  private domElement: Element;

  constructor(
    deps: ComponentDependencies,
    params?: SegmentedTextInputViewModelParams,
    componentInfo?: components.ComponentInfo
  ) {
    super(deps);

    this.domElement = componentInfo?.element as Element;

    this.readOnly = typeof params?.readOnly === 'boolean' ? params.readOnly : false;

    this.required = typeof params?.required === 'boolean' ? params.required : false;

    if (params?.preferNumericKeyboard) {
      this.inputmode = 'numeric';
      this.pattern = '[0-9]*';
    }

    this.segments = params?.segments
      ? params.segments.map((segment, index) => ({
          id: `${this.id}-${index}`,
          size: segment.size && segment.size >= 1 ? segment.size : 1,
          label: segment.label ?? undefined,
          placeholder: segment.placeholder ?? undefined
        }))
      : [];

    const sumOfSegmentSizes: number = this.segments.reduce((acc, { size }) => acc + (size ?? 0), 0);

    this.maxLength =
      typeof params?.maxLength === 'number' && params.maxLength > 0
        ? params.maxLength
        : sumOfSegmentSizes;

    if (this.maxLength > sumOfSegmentSizes) {
      // if maxLength is given and exceeds segment size, increase the size of the final segment to accomodate
      // for the extra characters
      this.segments[this.segments.length - 1].size += this.maxLength - sumOfSegmentSizes;
    }

    this.minLength = typeof params?.minLength === 'number' ? params.minLength : this.maxLength; // default same as max length

    this.texts = {
      label: '',
      ...params?.texts
    };

    this.segmentValues = this.segments.map(() => observable(''));
    this.segmentStartIndexes = [
      0,
      ...this.segments.reduce((acc: number[], segment, index) => {
        if (index === this.segments.length - 1) {
          return acc;
        }

        acc = [...acc, (acc[Math.max(index - 1, 0)] ?? 0) + segment.size];

        return acc;
      }, [])
    ];

    this.value$ = pureComputed({
      read(): string | null | undefined {
        return this.segmentValues.reduce((acc: string | null, segmentValue$) => {
          if (!segmentValue$()) {
            return acc;
          }

          return (acc ?? '') + segmentValue$();
        }, null);
      },
      write(value: string | null | undefined) {
        let remainingValue = value ?? '';

        for (let i = 0; i < this.segments.length; i++) {
          const segmentValue = remainingValue.substring(0, this.segments[i].size);
          this.segmentValues[i](segmentValue);
          remainingValue = remainingValue.slice(segmentValue.length);
        }
      },
      owner: this
    });

    if (!this.readOnly) {
      this.initializeValidations();
    }

    if (params?.value$) {
      this.syncObservables(params.value$, this.value$);
    }

    if (params?.isValid$) {
      this.syncObservables(this.isValid$, params.isValid$);
    }

    for (let index = 0; index < this.segmentValues.length; index++) {
      const segmentValue$ = this.segmentValues[index];
      segmentValue$.subscribe(newValue => {
        if (newValue?.length >= this.segments[index].size) {
          this.focusSegment(index + 1);
        } else if (!newValue?.length) {
          this.focusSegment(index - 1);
        }
      });
    }
  }

  private koDescendantsComplete(): void {
    if (this.domElement.nodeType !== Node.ELEMENT_NODE) {
      this.domElement = document.querySelector(
        `[data-segmented-text-input-id="${this.id}"]`
      ) as Element;
    }
  }

  protected initializeValidations(): void {
    this.value$.extend({
      required: this.required,
      minLength: this.minLength,
      maxLength: this.maxLength
    });
  }

  private focusSegment(segmentIndex: number): void {
    this.domElement.getElementsByTagName('input').item(segmentIndex)?.focus();
  }
}

import { Action } from 'redux';
import { AdditionalContact, AdditionalContactType } from '../../interfaces/AdditionalContact';
import { AdditionalContactRef } from './reducers';

export const ADD_ADDITIONAL_CONTACT = 'ADD_ADDITIONAL_CONTACT',
  DELETE_ADDITIONAL_CONTACT = 'DELETE_ADDITIONAL_CONTACT',
  SET_ADDITIONAL_CONTACT_NAME = 'SET_ADDITIONAL_CONTACT_NAME',
  SET_ADDITIONAL_CONTACT_EMAIL = 'SET_ADDITIONAL_CONTACT_EMAIL',
  SET_ADDITIONAL_CONTACT_PHONE = 'SET_ADDITIONAL_CONTACT_PHONE',
  SET_ADDITIONAL_CONTACT_MOBILE_PHONE = 'SET_ADDITIONAL_CONTACT_MOBILE_PHONE',
  SET_ADDITIONAL_CONTACT_BIRTH_DATE = 'SET_ADDITIONAL_CONTACT_BIRTH_DATE';

export interface AddAdditionalContactAction extends Action<typeof ADD_ADDITIONAL_CONTACT> {
  type: typeof ADD_ADDITIONAL_CONTACT;
  meta: {
    type: AdditionalContactType;
  };
}

export interface DeleteAdditionalContactAction extends Action<typeof DELETE_ADDITIONAL_CONTACT> {
  type: typeof DELETE_ADDITIONAL_CONTACT;
  meta: {
    ref: AdditionalContactRef;
  };
}

export interface SetAdditionalContactNameAction extends Action<typeof SET_ADDITIONAL_CONTACT_NAME> {
  type: typeof SET_ADDITIONAL_CONTACT_NAME;
  payload: AdditionalContact['name'];
  meta: {
    ref: AdditionalContactRef;
  };
}

export interface SetAdditionalContactEmailAction
  extends Action<typeof SET_ADDITIONAL_CONTACT_EMAIL> {
  type: typeof SET_ADDITIONAL_CONTACT_EMAIL;
  payload: AdditionalContact['emailAddress'];
  meta: {
    ref: AdditionalContactRef;
  };
}

export interface SetAdditionalContactPhoneAction
  extends Action<typeof SET_ADDITIONAL_CONTACT_PHONE> {
  type: typeof SET_ADDITIONAL_CONTACT_PHONE;
  payload: AdditionalContact['phoneNumber'];
  meta: {
    ref: AdditionalContactRef;
  };
}

export interface SetAdditionalContactMobilePhoneAction
  extends Action<typeof SET_ADDITIONAL_CONTACT_MOBILE_PHONE> {
  type: typeof SET_ADDITIONAL_CONTACT_MOBILE_PHONE;
  payload: AdditionalContact['phoneNumberMobile'];
  meta: {
    ref: AdditionalContactRef;
  };
}

export interface SetAdditionalContactBirthdate
  extends Action<typeof SET_ADDITIONAL_CONTACT_BIRTH_DATE> {
  type: typeof SET_ADDITIONAL_CONTACT_BIRTH_DATE;
  payload: AdditionalContact['birthDate'];
  meta: {
    ref: AdditionalContactRef;
  };
}

export type AdditionalContactsActionTypes =
  | AddAdditionalContactAction
  | DeleteAdditionalContactAction
  | SetAdditionalContactNameAction
  | SetAdditionalContactEmailAction
  | SetAdditionalContactPhoneAction
  | SetAdditionalContactMobilePhoneAction
  | SetAdditionalContactBirthdate;

export function addAdditionalContact(
  additionalContactType: AdditionalContactType
): AddAdditionalContactAction {
  return {
    type: ADD_ADDITIONAL_CONTACT,
    meta: {
      type: additionalContactType
    }
  };
}

export function deleteAdditionalContact(ref: AdditionalContactRef): DeleteAdditionalContactAction {
  return {
    type: DELETE_ADDITIONAL_CONTACT,
    meta: {
      ref
    }
  };
}

export function setAdditionalContactName(
  ref: AdditionalContactRef,
  name: AdditionalContact['name']
): SetAdditionalContactNameAction {
  return {
    type: SET_ADDITIONAL_CONTACT_NAME,
    payload: name,
    meta: {
      ref
    }
  };
}

export function setAdditionalContactEmail(
  ref: AdditionalContactRef,
  emailAddress: AdditionalContact['emailAddress']
): SetAdditionalContactEmailAction {
  return {
    type: SET_ADDITIONAL_CONTACT_EMAIL,
    payload: emailAddress,
    meta: {
      ref
    }
  };
}

export function setAdditionalContactPhone(
  ref: AdditionalContactRef,
  phoneNumber: AdditionalContact['phoneNumber']
): SetAdditionalContactPhoneAction {
  return {
    type: SET_ADDITIONAL_CONTACT_PHONE,
    payload: phoneNumber,
    meta: {
      ref
    }
  };
}

export function setAdditionalContactMobilePhone(
  ref: AdditionalContactRef,
  phoneNumberMobile: AdditionalContact['phoneNumberMobile']
): SetAdditionalContactMobilePhoneAction {
  return {
    type: SET_ADDITIONAL_CONTACT_MOBILE_PHONE,
    payload: phoneNumberMobile,
    meta: {
      ref
    }
  };
}

export function setAdditionalContactBirthDate(
  ref: AdditionalContactRef,
  birthDate: AdditionalContact['birthDate']
): SetAdditionalContactBirthdate {
  return {
    type: SET_ADDITIONAL_CONTACT_BIRTH_DATE,
    payload: birthDate,
    meta: {
      ref
    }
  };
}

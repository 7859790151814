/*
 * Debounce
 *
 * A debounced function, when called, will delay execution until it no subsequent calls to it have
 * been made for <given> time. Then, the most recent call will be executed and its result returned.
 *
 * Example usage:
 *
 *   Given the this function:
 *     function f(x: string): string {
 *       return "Value from f: " + x;
 *     }
 *
 *  You can create a debounced version using (3000 is 3 seconds):
 *   const f2: (x: string): Promise<string> = debounce(outputValue, 3000);
 */
type AnyFunction = (...args: never[]) => unknown;

export function debounce<T extends AnyFunction>(
  fn: T,
  timeout: number
): (...args: Parameters<T>) => Promise<Awaited<ReturnType<T>>> {
  let timer;
  const cancelFn = () => clearTimeout(timer);

  let promise: Promise<ReturnType<T>> | undefined;
  let resolver: (value: ReturnType<T>) => void;

  return (...args) => {
    promise =
      promise ??
      new Promise(resolve => {
        resolver = resolve;
      });

    cancelFn();
    timer = setTimeout(() => {
      promise = undefined;
      resolver(fn(...args) as ReturnType<T>);
    }, timeout);

    return promise as Promise<Awaited<ReturnType<T>>>;
  };
}

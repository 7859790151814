import i18next from 'i18next';
import { components, observable, PureComputed, pureComputed } from 'knockout';
import { ComponentDependencies } from '../../../interfaces';
import { AdditionalContactType } from '../../../interfaces/AdditionalContact';
import {
  addAdditionalContact,
  deleteAdditionalContact
} from '../../../store/additionalContacts/actions';
import {
  AdditionalContactRef,
  AdditionalContactsState
} from '../../../store/additionalContacts/reducers';
import { BaseComponentViewModel } from '../../base-component';

export interface AdditionalContactsViewModelParams extends components.ViewModelParams {
  type?: AdditionalContactType;
  minNumberOfContacts?: number;
  maxNumberOfContacts?: number;

  readOnly?: boolean;

  texts?: {
    addContactButtonLabel?: string;
    removeContactButtonLabel?: string;
  };
}

export class AdditionalContactsViewModel extends BaseComponentViewModel {
  readonly contacts$: PureComputed<AdditionalContactRef[]>;

  readonly numberOfContacts$: PureComputed<number>;
  readonly minNumberOfContacts: number;
  readonly maxNumberOfContacts: number;
  readonly type: AdditionalContactType;

  readonly readOnly?: boolean;

  readonly texts: {
    addContact: string;
    removeContact: string;
  };

  constructor(deps: ComponentDependencies, params?: AdditionalContactsViewModelParams) {
    super(deps);
    this.minNumberOfContacts = params?.minNumberOfContacts ?? 0;
    this.maxNumberOfContacts = params?.maxNumberOfContacts ?? Math.max(1, this.minNumberOfContacts);
    this.type = params?.type ?? 'participant';

    this.readOnly = params?.readOnly ?? false;

    this.texts = {
      addContact: i18next.t('components.additionalContacts.addContact', 'Add contact'),
      removeContact: i18next.t('components.additionalContacts.removeContact', 'Remove contact'),
      ...(params?.texts ?? {})
    };

    const allAdditionalContacts$ = observable<AdditionalContactsState>({});
    this.bindObservableToStore(allAdditionalContacts$, '$.additionalContacts');

    this.contacts$ = pureComputed(() => {
      const additionalContacts = allAdditionalContacts$();
      return Object.keys(additionalContacts).filter(
        ref => additionalContacts[ref].type === this.type
      );
    });

    this.numberOfContacts$ = pureComputed(() => Object.keys(this.contacts$()).length);

    this.numberOfContacts$.extend({
      min: this.minNumberOfContacts,
      max: this.maxNumberOfContacts
    });

    while (this.numberOfContacts$() < this.minNumberOfContacts) {
      this.addContact();
    }

    this.initializeStateUpdates();
  }

  addContact(): void {
    this.store.dispatch(addAdditionalContact(this.type));
  }

  removeContact(ref: AdditionalContactRef): void {
    this.store.dispatch(deleteAdditionalContact(ref));
  }
}

import { components, observable, Observable, pureComputed, PureComputed } from 'knockout';
import { ComponentDependencies } from '../../interfaces';

import { BaseComponentViewModel } from '../base-component';

export interface StateContextViewModelParams extends components.ViewModelParams {
  context?: Record<string, any>;
  statePath?: string;
}

export class StateContextViewModel extends BaseComponentViewModel {
  public readonly stateContext$: Observable<any> = observable();

  public readonly context$: PureComputed<{
    [key: string]: any;
    stateContext: any;
  }>;

  constructor(deps: ComponentDependencies, params?: StateContextViewModelParams) {
    super(deps);

    if (params?.statePath) {
      this.bindObservableToStore(this.stateContext$, `$.${params.statePath}`);
    }

    this.context$ = pureComputed(() => {
      return {
        ...params?.context,
        stateContext: this.stateContext$()
      };
    });
  }
}

import template from './template.html';
import { ComponentConfig } from '../../../interfaces';
import { OptInButtonsViewModel } from './OptInButtonsViewModel';

const config: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new OptInButtonsViewModel(deps, params)
  }
};

export default config;

import { Address, HumanName, OptIn, OptInSource } from '../../../../..';
import { HumanNameHelper, TieredPricingHelper } from '../../../../../helpers';
import { SelectedOptins } from '../../../../../interfaces/Order';
import { State } from '../../../../../store/reducers';
import {
  AnyContact,
  GameShowContact,
  Participant,
  RegistrationContact,
  ShopContact
} from '../../interfaces';
import { OptInOrOutAnswer, OptInOrOutAnswers } from '../../interfaces/OptInOrOutChoice';
import { WEBFLOW_TYPE_GAMESHOW, WEBFLOW_TYPE_SHOP } from '../../interfaces/WebflowTypeID';
import { IzziWebflowController } from '../../IzziService';

export function contactTranslator(
  state: State,
  config: AnyContact,
  controller: IzziWebflowController
): Partial<AnyContact> {
  /* eslint complexity: off */
  state = JSON.parse(JSON.stringify(state));

  const izziState = { ...config };

  const contactIdentifiers = state.contact.identifiers;
  const userIdentities = state.user.identities;
  const additionalFields = state.order.additionalFields;
  const items = state.order.items;
  const choices = state.order.choices;
  const allowedEvents: any[] = [];

  delete izziState.AllowedEvent;
  delete izziState.Campagne;
  delete izziState.CampagneCategories;
  delete izziState.CustomTexts;
  delete izziState.IDCampagneEvent;
  delete izziState.ShipperSchedule;
  izziState.IDCampagneEvent = null;

  if (config.Campagne && [1, 2, 'registratie', 'shop'].includes(config.Campagne.WebflowType)) {
    delete (izziState as RegistrationContact | ShopContact).IsAddressChangedCheck;
  }

  if (config.Campagne && [WEBFLOW_TYPE_SHOP, 'shop'].includes(config.Campagne.WebflowType)) {
    (izziState as ShopContact).IsTest = state.contact.isTest ?? false;
  }

  if (state.contact.name) {
    Object.assign(izziState, getHumanName(state.contact.name));
    izziState.BirthDate = state.contact.birthDate ?? undefined;
  }

  if (state.contact.organization?.name) {
    izziState.NameOrganisation = state.contact.organization.name;
  }

  //region Contact Address & Delivery Address
  izziState.UseDeliveryAddress = false;

  let contactAddress: Address;
  let deliveryAddress: Address | undefined;

  const userIdentity = state.user.identities?.vipcardnumber;
  const useContactAddress = state.order.useContactAddress;

  if (
    controller === 'shop' &&
    !useContactAddress &&
    userIdentity?.identifier &&
    userIdentity?.verificationChallenge1 === state.order.deliveryAddress.postalCode &&
    userIdentity?.verificationChallenge2 === state.order.deliveryAddress.houseNumber
  ) {
    contactAddress = state.order.deliveryAddress;
    deliveryAddress = undefined;
  } else {
    contactAddress = state.contact.address;
    if (!useContactAddress && state.order.deliveryAddress) {
      deliveryAddress = state.order.deliveryAddress;
    }
  }

  if (controller === 'shop') {
    setContactAddress(contactAddress);
  }

  if (deliveryAddress) {
    setDeliveryAddress(deliveryAddress);
  }
  //endregion

  if (state.contact.emailAddress) {
    izziState.RegistrationEmailAddress = state.contact.emailAddress;
  }

  if (state.contact.phoneNumber) {
    izziState.PhoneNumber1 = state.contact.phoneNumber;
  }

  if (state.contact.phoneNumberMobile) {
    izziState.PhoneNumberMobile = state.contact.phoneNumberMobile;
  }

  Object.assign(izziState, getParticipants(state.additionalContacts));
  Object.assign(izziState, getGuest(state.additionalContacts));
  Object.assign(izziState, getReplacement(state.additionalContacts));

  if (contactIdentifiers) {
    if (contactIdentifiers.primaryIdentifier) {
      izziState.OOIDCU = contactIdentifiers.primaryIdentifier;
    }

    if (isShopContact(config)) {
      if (contactIdentifiers.ref1) {
        (izziState as ShopContact).RefId1 = contactIdentifiers.ref1;
      }
      if (contactIdentifiers.ref2) {
        (izziState as ShopContact).RefId2 = contactIdentifiers.ref2;
      }
      if (contactIdentifiers.ref3) {
        (izziState as ShopContact).RefId3 = contactIdentifiers.ref3;
      }
      if (contactIdentifiers.ref4) {
        (izziState as ShopContact).RefId4 = contactIdentifiers.ref4;
      }
      if (contactIdentifiers.ref5) {
        (izziState as ShopContact).RefId5 = contactIdentifiers.ref5;
      }
    }
  }

  if (userIdentities && isShopContact(config)) {
    const setVipcardNumber = (): void => {
      const vipcardIdentity = userIdentities.vipcardnumber;
      if (!vipcardIdentity) return;

      const vipcardNumber = vipcardIdentity.identifier;
      const postalCode = vipcardIdentity.verificationChallenge1;
      const houseNumber =
        Number.parseInt(vipcardIdentity.verificationChallenge2 || '', 10) || undefined;

      if (vipcardNumber) {
        const shopContact = izziState as ShopContact;
        shopContact.ExtraIdentifier = vipcardNumber;
        if (postalCode) {
          shopContact.ZipCode = postalCode;
        }
        if (houseNumber) {
          shopContact.HouseNumber = houseNumber;
        }
      }
    };

    const validateMaxPerArrangementOn = config.Campagne?.Settings.find(
      x => x.Key === 'ValidateMaxPerArrangementOn'
    )?.Value;
    if (
      validateMaxPerArrangementOn === 'VipcardNumber' ||
      validateMaxPerArrangementOn === 'VipcardAndZipcode'
    ) {
      setVipcardNumber();
    }
  }

  if (additionalFields) {
    if (!Array.isArray(izziState.AdditionalValues)) {
      izziState.AdditionalValues = [];
    }

    for (const key in additionalFields) {
      const existingField = izziState.AdditionalValues.find(x => x.Key === key);
      if (existingField) {
        existingField.Value = additionalFields[key];
      } else {
        izziState.AdditionalValues.push({
          Key: key,
          Value: additionalFields[key]
        });
      }
    }
  }

  const itemQuantities: {
    [sku: string]: number;
  } = Object.keys(items).reduce((acc, sku) => {
    if (acc[sku]) {
      acc[sku] += items[sku].quantity;
    } else {
      acc[sku] = items[sku].quantity;
    }

    return acc;
  }, {});

  for (const optIn of Object.values(state.optIns)) {
    if (optIn.source === OptInSource.FROM_ALLOWED_EVENT) {
      if (state.order.selectedOptIns.includes(optIn.optInId)) {
        // for opt-ins coming from allowedEvents, the optInId is the sku (idCampagneEvent)
        itemQuantities[optIn.optInId] = 1;
      }
    }
  }

  Object.keys(itemQuantities).forEach(sku => {
    const event = config.AllowedEvents?.find(item => String(item.IDCampagneEvent) === sku);
    const isBackorder = state.products[sku]?.backorder;

    if (event) {
      allowedEvents.push({
        IDCampagneEvent: event.IDCampagneEvent,
        NumberOfItems: itemQuantities[sku],
        Details: event.Details.map(detail => {
          const newDetail = { ...detail };
          delete newDetail.ShipperSchedule;
          return newDetail;
        }),
        IsOnWaitingList: Boolean(event.IsOnWaitingList || isBackorder),
        Price: event.Price,
        PointsValue: event.PointsValue,
        ItemDate: event.ItemDate,
        ItemDescription: event.ItemDescription,
        TierPrices: event.TierPrices
      });
    }
  });

  if (typeof choices['1'] === 'boolean') {
    izziState.CheckBox1 = choices['1'];
  }

  if (typeof choices['2'] === 'boolean') {
    izziState.CheckBox2 = choices['2'];
  }

  if (typeof choices['3'] === 'boolean') {
    izziState.CheckBox3 = choices['3'];
  }

  izziState.AllowedEvents = allowedEvents;
  izziState.PaymentAmount = calculateTotalPrice(izziState);

  if (state.order.payment.paymentOptionCode) {
    izziState.PaymentMethod = state.order.payment.paymentOptionCode;
  }

  if (
    config.Campagne?.AllowAdditionalInformation &&
    [1, 2, 'registratie', 'shop'].includes(config.Campagne.WebflowType) &&
    state.order.remarks
  ) {
    (izziState as RegistrationContact | ShopContact).AdditionalInformation = state.order.remarks;
  }

  // Set opt-ins for iZZi version 146 onwards
  izziState.OptInOrOutAnswers = getOptInOrOutAnswers(state.order.selectedOptIns, state.optIns);

  // Support old api style for gameshows
  if (
    config.Campagne &&
    [WEBFLOW_TYPE_GAMESHOW, 'gameshow'].includes(config.Campagne.WebflowType)
  ) {
    if (allowedEvents.length === 1) {
      izziState.IDCampagneEvent = allowedEvents[0].IDCampagneEvent;
      (izziState as GameShowContact).IsOnWaitingList = allowedEvents[0].IsOnWaitingList;
    }
  }

  function setContactAddress(address: Address) {
    izziState.HouseNumberExtension = address.houseNumberExtension || '';
    izziState.HouseNumber = Number.parseInt(address.houseNumber, 10);
    izziState.Street = address.street;
    izziState.ZipCode = address.postalCode;
    izziState.City = address.city;
    izziState.IDCountry =
      config.Campagne?.AllowedCountriesForResidence?.find(
        country => country.Code === address['countryCodeISO3166-1']
      )?.Id ??
      config.IDCountry ??
      undefined;

    izziState.Address = [izziState.Street, izziState.HouseNumber, izziState.HouseNumberExtension]
      .filter(Boolean)
      .join(' ');
    izziState.ZipCodeCity = [izziState.ZipCode, izziState.City].filter(Boolean).join('  ');
  }

  function setDeliveryAddress(value: Address) {
    izziState.UseDeliveryAddress = true;
    izziState.HouseNumberExtension2 = value.houseNumberExtension || '';
    izziState.HouseNumber2 = Number.parseInt(value.houseNumber, 10);
    izziState.Street2 = value.street;
    izziState.ZipCode2 = value.postalCode;
    izziState.City2 = value.city;
    izziState.IDCountry2 =
      config.Campagne?.AllowedCountriesForDelivery?.find(
        country => country.Code === value['countryCodeISO3166-1']
      )?.Id ??
      config.IDCountry2 ??
      undefined;
  }

  return izziState;
}

function getOptInOrOutAnswers(
  selectedOptIns: SelectedOptins,
  allOptIns: State['optIns']
): OptInOrOutAnswers {
  const toOptInOrOutAnswer = (optIn: OptIn): OptInOrOutAnswer => ({
    Code: optIn.optInId,
    OptedIn: selectedOptIns.includes(optIn.optInId)
  });

  return Object.values(allOptIns)
    .filter(optIn => optIn.source === OptInSource.FROM_CHOICE)
    .map(toOptInOrOutAnswer);
}

export function calculateTotalPrice(izziState: Partial<AnyContact>): number {
  return (
    izziState.AllowedEvents?.map(item => {
      const tierPrice = TieredPricingHelper.calculate(
        0,
        item.NumberOfItems,
        item.TierPrices.map(tier => {
          return {
            minQuantity: tier.Quantity,
            value: tier.Price
          };
        })
      );

      return tierPrice * item.NumberOfItems;
    }).reduce((total, itemPrice) => total + itemPrice, 0) ?? 0
  );
}

function getHumanName(name: HumanName): Partial<AnyContact> {
  const result: Partial<AnyContact> = {};

  result.IDTitle = ['male', 'female'].indexOf(name.gender) + 1 || 11;
  result.Gender = ['male', 'female'].indexOf(name.gender) + 1 || null;
  result.FirstName = name.givenName;
  result.Initials = HumanNameHelper.formatInitials(name);
  result.NamePrefix = name.familyNamePrefix;
  result.LastName = name.familyName;

  switch (result.IDTitle) {
    case 1:
      result.AttentionOf = ['De heer', result.Initials, result.NamePrefix, result.LastName]
        .filter(Boolean)
        .join(' ');
      break;
    case 2:
      result.AttentionOf = ['Mevrouw', result.Initials, result.NamePrefix, result.LastName]
        .filter(Boolean)
        .join(' ');
      break;
    default:
      result.AttentionOf = [result.Initials, result.NamePrefix, result.LastName]
        .filter(Boolean)
        .join(' ');
  }

  return result;
}

function getGuest(state: State['additionalContacts']): Partial<AnyContact> {
  const guest = Object.values(state).find(c => c.type === 'guest');

  return guest
    ? {
        GuestFirstName: guest.name.givenName,
        GuestGender: translateGender(guest.name),
        GuestLastName: guest.name.familyName,
        GuestName: guest.formattedName,
        GuestNamePrefix: guest.name.familyNamePrefix
      }
    : {};
}

function getReplacement(state: State['additionalContacts']): Partial<AnyContact> {
  const replacement = Object.values(state).find(c => c.type === 'replacement');

  return replacement
    ? {
        ContactType: 3, // MEDEREKENINGHOUDER
        ...getHumanName(replacement.name),
        ...(replacement.emailAddress ? { RegistrationEmailAddress: replacement.emailAddress } : {}),
        ...(replacement.phoneNumber ? { PhoneNumber1: replacement.phoneNumber } : {}),
        ...(replacement.phoneNumberMobile
          ? { PhoneNumberMobile: replacement.phoneNumberMobile }
          : {}),
        ...(replacement.birthDate ? { BirthDate: replacement.birthDate } : {})
      }
    : {};
}

function getParticipants(state: State['additionalContacts']): Partial<AnyContact> {
  const participants = Object.values(state).filter(c => c.type === 'participant');

  return {
    Participants: participants.map(
      (participant): Partial<Participant> => ({
        Id: undefined,
        OOIDCU: undefined,
        IDTitle: undefined,
        FirstName: participant.name.givenName,
        Initials: HumanNameHelper.formatInitials(participant.name),
        LastName: participant.name.familyName,
        NamePrefix: participant.name.familyNamePrefix,
        PhoneNumber: participant.phoneNumber ?? '',
        EmailAddress: participant.emailAddress ?? '',
        NameOrganisation: undefined,
        JobTitle: undefined,
        BirthDate: undefined,
        FullName: participant.formattedName,
        Street: undefined,
        HouseNumber: undefined,
        HouseNumberExtension: undefined,
        ZipCode: undefined,
        Address: undefined,
        City: undefined,
        CountryCode: participant['countryCodeISO3166-1'],
        LanguageCode: undefined
      })
    ) as Participant[]
  };
}

function translateGender(name: HumanName): number {
  return ['male', 'female'].indexOf(name.gender) + 1 || 1;
}

function isShopContact(config: AnyContact): config is ShopContact {
  return (
    config.Campagne?.WebflowType === WEBFLOW_TYPE_SHOP || config.Campagne?.WebflowType === 'shop'
  );
}

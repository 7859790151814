import { AllowedEventText } from './AllowedEventText';
import { CampagneEventDetail } from './CampagneEventDetail';
import { ProductGrouping } from './ProductGrouping';
import { Tag } from './Tag';
import { TierPrice } from './TierPrice';

export const EVENT_TYPE_OPT_IN = 10;

export interface AllowedEvent {
  IDCampagneEvent: number;
  NumberOfItems: number;
  NumberOfAdults: number;
  NumberOfChildren: number;
  Details: CampagneEventDetail[];
  Description: string;
  LongDescription: string;
  Location: string | null;
  EventDate: string | null;
  EventNumber: number;
  Code: string;
  DescriptionUsingTemplate: string;
  IsOnWaitingList: boolean;
  MinNumberOfAdults: number;
  MinNumberOfChildren: number;
  MaxNumberOfAdults: number;
  MaxNumberOfChildren: number;
  EventType: number;
  InputType: number;
  Price: number | null;
  IDProduct: number | null;
  TabSequenceNumber: number | null;
  TabName: string | null;
  Remarks: string | null;
  ThumbnailUrl: string;
  ContentUrl: string;
  VariantDescription: string | null;
  OnlyAvailableAsDescription: string | null;
  OriginalDescription: string | null;
  IDCampagneEventParent: number | null;
  ShowAdultsAndChildren: boolean;
  ShowTickets: boolean;
  MinNumberPerRegistration: number;
  MaxNumberPerRegistration: number;
  PointsValue: number;
  MinContactPointsValue: number;
  MaxContactPointsValue: number;
  DisplayDescription: string;
  ContentDescription: string;
  ProductPageDescription: string;
  IDSubscriptionType: number;
  ItemDate: string | null;
  ItemDescription: string | null;
  TierPrices: TierPrice[];
  Tags: Tag[];
  Texts: AllowedEventText[];
  ProductGrouping?: ProductGrouping | null;
  WillTriggerShipment: boolean;
  MinDeliveryDate: string | null;
  MaxDeliveryDate: string | null;
}

import i18next from 'i18next';
import { OrderItemMutationValidationResult } from '../interfaces';
import type { NotificationService } from '../services';

export function showOrderItemMutationValidationDialog(
  validationResult: OrderItemMutationValidationResult,
  notificationService: NotificationService
) {
  const validationErrorNotificationHeading = i18next.t(
    'validations.orderItemMutationValidation.validationError',
    'You cannot select this product'
  );
  const quantityValidationErrorNotification = i18next.t(
    'validations.orderItemMutationValidation.quantityValidationError',
    'You have selected the maximum number of products'
  );
  const tokenValidationErrorNotification = i18next.t(
    'validations.orderItemMutationValidation.tokenValidationError',
    'You do not have enough points to select this product'
  );
  const restrictionValidationErrorNotification = i18next.t(
    'validations.orderItemMutationValidation.restrictionValidationError',
    'Your selection is not allowed'
  );

  if (!validationResult.quantityResult) {
    notificationService.notify({
      title: validationErrorNotificationHeading,
      message: quantityValidationErrorNotification,
      extraData: validationResult
    });
    return;
  }

  if (!validationResult.tokenResult) {
    notificationService.notify({
      title: validationErrorNotificationHeading,
      message: tokenValidationErrorNotification,
      extraData: validationResult
    });
    return;
  }

  if (!validationResult.restrictionRulesResult) {
    notificationService.notify({
      title: validationErrorNotificationHeading,
      message: restrictionValidationErrorNotification,
      extraData: validationResult
    });
  }
}

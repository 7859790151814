import { LoginRequirements } from '../../interfaces/LoginRequirements';
import { initialState } from './initialState';
import { Contact } from '../../interfaces';

export interface StoreBackendState {
  izzi?: {
    IDCampaign?: number;
  };
  loginRequirements: LoginRequirements;
  initialContact: Contact;
}

export function storeBackendReducer(state: StoreBackendState = initialState): StoreBackendState {
  return state;
}

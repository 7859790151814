import { ComponentConfig } from '../../interfaces';

import template from './template.pug';
import { QuantityCounterViewModel } from './QuantityCounterViewModel';

const config: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new QuantityCounterViewModel(deps, params)
  }
};

export default config;

import { Action } from 'redux';

import { Category } from '../../interfaces';
import { CategoriesState } from './reducers';

export const SET_CATEGORIES = 'SET_CATEGORIES',
  ADD_CATEGORY = 'ADD_CATEGORY',
  DELETE_CATEGORY = 'DELETE_CATEGORY';

export interface SetCategoriesAction extends Action<typeof SET_CATEGORIES> {
  type: typeof SET_CATEGORIES;
  payload: CategoriesState;
}

export interface AddCategoryAction extends Action<typeof ADD_CATEGORY> {
  type: typeof ADD_CATEGORY;
  payload: Category;
}

export interface DeleteCategoryAction extends Action<typeof DELETE_CATEGORY> {
  type: typeof DELETE_CATEGORY;
  meta: { identifier: string };
}

export type CategoriesActionTypes = SetCategoriesAction | AddCategoryAction | DeleteCategoryAction;

export function setCategories(categories: CategoriesState): SetCategoriesAction {
  return {
    type: SET_CATEGORIES,
    payload: categories
  };
}

export function addCategory(category: Category): AddCategoryAction {
  return {
    type: ADD_CATEGORY,
    payload: category
  };
}

export function deleteCategory(identifier: string): DeleteCategoryAction {
  return {
    type: DELETE_CATEGORY,
    meta: { identifier }
  };
}

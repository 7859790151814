import { components, observable, pureComputed, PureComputed } from 'knockout';
import { ComponentDependencies, OptIn, OptInId, OptInType } from '../../../interfaces';
import { BaseComponentViewModel } from '../../base-component';

export interface OptInViewModelParams extends components.ViewModelParams {
  optInId?: OptInId;
  optInType?: OptInType;
}

export class OptInViewModel extends BaseComponentViewModel {
  public optIn$ = observable<OptIn>();
  public value$: PureComputed<boolean>;

  constructor(deps: ComponentDependencies, params: OptInViewModelParams) {
    super(deps);

    const optInId: OptInId =
      params?.optInId ?? deps.optIns.firstIdOfType(params?.optInType ?? '') ?? '';

    const selectedOptIns$ = observable<OptInId[]>([]);

    this.value$ = pureComputed({
      read() {
        return selectedOptIns$().includes(optInId);
      },
      write(value: boolean) {
        deps.optIns.setOptInSelection(optInId, value);
      },
      owner: this
    });

    this.bindObservableToStore(this.optIn$, '$.optIns.' + optInId);
    this.bindObservableToStore(selectedOptIns$, '$.order.selectedOptIns');
  }
}

import i18next from 'i18next';
import { observable } from 'knockout';
import { ComponentDependencies } from '../../../interfaces';
import { EmailTextInputViewModelParams } from '../../../ui_widgets/email-text-input';
import { BaseComponentViewModel } from '../../base-component';
import { setEmailConfirmation } from '../../../store/contact/actions';

export interface ContactEmailConfirmationViewModelParams {
  texts?: EmailTextInputViewModelParams['texts'] & {
    valuesDifferMessage?: string;
  };
}

export class ContactEmailConfirmationViewModel extends BaseComponentViewModel {
  readonly value$ = observable(null);
  readonly widgetParams: EmailTextInputViewModelParams;

  readonly texts: EmailTextInputViewModelParams['texts'] & {
    valuesDifferMessage: string;
  };

  constructor(deps: ComponentDependencies, params?: ContactEmailConfirmationViewModelParams) {
    super(deps);

    this.texts = {
      label: i18next.t('components.emailConfirmationTextInput.label', '[label]'),
      valuesDifferMessage: i18next.t(
        'components.emailConfirmationTextInput.valuesDifferMessage',
        '[valuesDifferMessage]'
      ),
      ...params?.texts
    };

    this.widgetParams = {
      ...params,
      value$: this.value$,
      required: true,
      texts: this.texts,
      readOnly: false
    };

    const compareToValue$ = observable<string>();
    this.bindObservableToStore(compareToValue$, '$.contact.emailAddress');
    this.bindObservableToStore(this.value$, '$.contact.emailConfirmation', setEmailConfirmation);

    this.value$.extend({
      required: true,
      email: true,
      validation: {
        validator: value => value?.toLowerCase() === compareToValue$()?.toLowerCase(),
        message: this.texts.valuesDifferMessage
      }
    });

    this.initializeStateUpdates();
  }
}

import i18next from 'i18next';
import phone from 'phone';
import { Observable, observable, pureComputed, PureComputed } from 'knockout';
import { ComponentDependencies, CountryCode } from '../../interfaces';

import { TextInputViewModel, TextInputViewModelParams } from '../text-input';

export interface TelephoneTextInputViewModelParams extends Omit<TextInputViewModelParams, 'type'> {
  countryCodes$?: Observable<CountryCode[]>;
  validateMobile?: boolean;
}

export class TelephoneTextInputViewModel extends TextInputViewModel {
  public readonly countryCodes$: Observable<CountryCode[]>;
  public readonly validateMobile: boolean = false;

  public readonly textParams: {
    validationErrorMessage: string;
    validationErrorMessageMobile: string;
  };

  constructor(deps: ComponentDependencies, params?: TelephoneTextInputViewModelParams) {
    super(deps, {
      ...params,
      type: 'tel',
      required: typeof params?.required !== 'undefined' ? Boolean(params.required) : true
    });

    if (!params?.countryCodes$) {
      throw new Error('countryCodes$ is a required parameter in TelephoneTextInput widget.');
    }
    this.countryCodes$ = params?.countryCodes$;
    this.validateMobile = params?.validateMobile ?? false;

    this.textParams = {
      validationErrorMessage: i18next.t(
        this.validateMobile
          ? 'components.telephoneTextInput.validationErrorMobile'
          : 'components.telephoneTextInput.validationError',
        this.validateMobile ? 'Mobile phone is invalid' : 'Phone is invalid'
      ),
      ...params?.texts
    };

    const isValidPhoneNumber = (
      phoneNumber: string,
      countryCodes: CountryCode[],
      validateMobile: boolean
    ) => {
      if (!phoneNumber || countryCodes?.length === 0) {
        return false;
      }

      for (const countryCode of countryCodes) {
        const isValid = phone(phoneNumber, {
          country: countryCode,
          validateMobilePrefix: validateMobile
        }).isValid;

        if (isValid) {
          return true;
        }
      }
      return false;
    };

    this.value$.extend({
      required: this.required,
      // allow only digits, a plus sign can only appear at the start, at most one dash, must end with a digit
      // the rest of the validation is delegated to the phone library
      pattern: {
        params: /^\+?\d*-?\d+$/,
        message: this.textParams.validationErrorMessage
      },
      validation: {
        validator: value => {
          if (!this.required && !value) {
            return true;
          }

          return isValidPhoneNumber(value, this.countryCodes$(), this.validateMobile);
        },
        message: this.textParams.validationErrorMessage
      }
    });
  }
}

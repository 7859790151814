import { VipcardValidatedEvent, VipcardValidationFailedEvent } from '../events';
import { Err, Ok, Result } from '../helpers';
import {
  IdentifyUserError,
  IdentifyUserErrorResult,
  StoreBackendService,
  UserIdentificationMode
} from '../interfaces';
import { StoreBackendError } from '../interfaces/StoreBackendService';
import { UserIdentity } from '../interfaces/UserIdentity';
import { AppStore } from '../store';
import { markSubmitting, markSubmittingDone } from '../store/order/actions';
import { deleteVipcard, setVipcard, setVipcardValidated } from '../store/user/actions';
import { AppEventManagerInterface } from './event-manager/EventManagerInterface';
import { NotificationService } from './NotificationService';
import i18next from 'i18next';

export interface LoginCredentials extends UserIdentity {
  xsrfToken?: string;
}

export class UserIdentificationService {
  public constructor(
    private store: AppStore,
    private service: StoreBackendService,
    private appEventManager: AppEventManagerInterface,
    private notificationService: NotificationService
  ) {}

  public async identifyUser(
    mode: UserIdentificationMode,
    credentials: LoginCredentials
  ): Promise<Result<undefined, IdentifyUserErrorResult>> {
    switch (mode) {
      case 'login': {
        const storeBackendResult = await this.storeBackendLogin(credentials);
        const result = storeBackendResult.mapErr(storeBackendError => ({
          storeBackendError,
          identifyUserResult: IdentifyUserError.INVALID
        }));
        result.isOk || this.notifyErrorResult(mode, result.error);
        return result;
      }

      case 'vipcardnumber': {
        this.store.dispatch(setVipcard(credentials));
        return this.validateVipcard();
      }

      default: {
        const result = Err({ identifyUserResult: IdentifyUserError.INVALID });
        this.notifyErrorResult(mode, result.error);
        return result;
      }
    }
  }

  public hasVipcardFromBackend(): boolean {
    return this.store.getState().user.identities.vipcardnumber?.identityOrigin === 'backend';
  }

  public async tryUseVipcardFromBackend(): Promise<void> {
    const { vipcardnumber } = this.store.getState().user.identities;

    if (vipcardnumber?.identityOrigin !== 'backend') {
      return; // nothing to do, not from backend
    }

    if (vipcardnumber.validated) {
      return; // nothing to do, already validated
    }

    const result = await this.validateVipcard();

    if (!result.isOk) {
      this.store.dispatch(deleteVipcard());
    }
  }

  public getPredefinedIdentifier(): string | null {
    return new URLSearchParams(window.location.search).get('ooidcu');
  }

  private async storeBackendLogin(
    credentials: LoginCredentials
  ): Promise<Result<undefined, StoreBackendError>> {
    const redirectUrlResult = await this.service.login(credentials);
    if (redirectUrlResult.isOk && redirectUrlResult.value) {
      window.location.replace(redirectUrlResult.value.toString());
      return Ok(undefined);
    }

    return redirectUrlResult as Result<never, StoreBackendError>;
  }

  /*
   * Validate the vipcard that's currently in the store.
   */
  public async validateVipcard(): Promise<Result<undefined, IdentifyUserErrorResult>> {
    const identity = this.store.getState().user.identities.vipcardnumber;

    if (!identity) {
      return Err({
        identifyUserResult: IdentifyUserError.INVALID
      });
    }

    const emitErr = (err: IdentifyUserErrorResult) => {
      this.notifyErrorResult('vipcardnumber', err);
      this.appEventManager.emit(new VipcardValidationFailedEvent(identity, err));
      return Err(err);
    };

    try {
      this.store.dispatch(markSubmitting('vipcard'));

      // first, check for valid vip card number / postalcode / house number combinations
      const validationResult = await this.service.validateVipCardNumber(
        identity.identifier,
        identity.verificationChallenge1,
        identity.verificationChallenge2
      );

      if (!validationResult.isOk) {
        return emitErr({
          identifyUserResult: IdentifyUserError.INVALID,
          storeBackendError: validationResult.error
        });
      }

      // second, check if the vipcard does not exceed max usage for this campaign
      const checkVipcardUsageResult = await this.service.validateExtraIdentifier(
        this.store.getState()
      );
      if (!checkVipcardUsageResult.isOk) {
        return emitErr({
          identifyUserResult: IdentifyUserError.ALREADY_USED,
          storeBackendError: checkVipcardUsageResult.error
        });
      }

      // finally, if all checks passed, this vip card must be valid
      this.store.dispatch(setVipcardValidated(true));
      this.appEventManager.emit(new VipcardValidatedEvent());

      return Ok(undefined);
    } finally {
      this.store.dispatch(markSubmittingDone('vipcard'));
    }
  }

  private notifyErrorResult(mode: UserIdentificationMode, result: IdentifyUserErrorResult) {
    let message = i18next.t('components.login.errorNotification');

    const { storeBackendError, identifyUserResult } = result;

    if (storeBackendError?.message && storeBackendError?.forEndUser) {
      message = storeBackendError.message;
    } else if (mode === 'vipcardnumber' && identifyUserResult === IdentifyUserError.INVALID) {
      message = i18next.t('components.login.vipcardnumberInvalidNotification');
    } else if (mode === 'vipcardnumber' && identifyUserResult === IdentifyUserError.ALREADY_USED) {
      message = i18next.t('components.login.vipcardnumberAlreadyUsedNotification');
    } else {
      message = i18next.t('components.login.errorNotification');
    }

    this.notificationService.notify({
      message,
      actions: [
        {
          code: 'close',
          label: i18next.t('components.login.errorNotificationButtonLabel')
        }
      ],
      extraData: {
        source: 'UserIdentificationService',
        mode,
        err: result
      }
    });
  }
}

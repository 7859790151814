import { ActiveStageState, ComponentState, Routes } from '../../interfaces';

import { Action } from 'redux';
import { StageIdentifier } from '../../interfaces/ActiveStageState';

export const SET_APP_READY_STATE = 'SET_APP_READY_STATE',
  SET_APP_ACTIVE_CATEGORY = 'SET_APP_ACTIVE_CATEGORY',
  SET_APP_ACTIVE_STAGE = 'SET_APP_ACTIVE_STAGE',
  SET_APP_COMPONENT_STATE = 'SET_APP_COMPONENT_STATE',
  DELETE_APP_COMPONENT_STATE = 'DELETE_APP_COMPONENT_STATE',
  SET_VISIBILITY_OPTION_STATE = 'SET_VISIBILITY_OPTION_STATE',
  DEFINE_ROUTES = 'DEFINE_ROUTES';

export interface SetAppReadyStateAction extends Action<typeof SET_APP_READY_STATE> {
  type: typeof SET_APP_READY_STATE;
  payload: boolean;
}

export interface SetAppActiveCategoryAction extends Action<typeof SET_APP_ACTIVE_CATEGORY> {
  type: typeof SET_APP_ACTIVE_CATEGORY;
  payload: string | undefined;
}

export interface SetAppActiveStageAction extends Action<typeof SET_APP_ACTIVE_STAGE> {
  type: typeof SET_APP_ACTIVE_STAGE;
  payload: ActiveStageState | undefined;
}

export interface SetAppComponentStateAction extends Action<typeof SET_APP_COMPONENT_STATE> {
  type: typeof SET_APP_COMPONENT_STATE;
  payload: ComponentState;
}

export interface DeleteAppComponentStateAction extends Action<typeof DELETE_APP_COMPONENT_STATE> {
  type: typeof DELETE_APP_COMPONENT_STATE;
  meta: { identifier: string };
}

export interface SetVisibilityOptionStateAction extends Action<typeof SET_VISIBILITY_OPTION_STATE> {
  type: typeof SET_VISIBILITY_OPTION_STATE;
  payload: {
    groupId: string;
    optionId: string;
    checked: boolean;
    exclusive: boolean;
  };
}

export interface DefineRoutesAction extends Action<typeof DEFINE_ROUTES> {
  type: typeof DEFINE_ROUTES;
  payload: Routes;
}

export type AppActionTypes =
  | SetAppReadyStateAction
  | SetAppActiveCategoryAction
  | SetAppActiveStageAction
  | SetAppComponentStateAction
  | DeleteAppComponentStateAction
  | SetVisibilityOptionStateAction
  | DefineRoutesAction;

export function setAppReadyState(readyState: boolean): SetAppReadyStateAction {
  return {
    type: SET_APP_READY_STATE,
    payload: readyState
  };
}

export function setAppActiveCategory(
  categoryIdentifier: string | undefined
): SetAppActiveCategoryAction {
  return {
    type: SET_APP_ACTIVE_CATEGORY,
    payload: categoryIdentifier || undefined
  };
}

export function setAppActiveStage(
  stageIdentifier: StageIdentifier | undefined
): SetAppActiveStageAction {
  return {
    type: SET_APP_ACTIVE_STAGE,
    payload: stageIdentifier
      ? {
          stageIdentifier
        }
      : undefined
  };
}

export function setAppComponentState(componentState: ComponentState): SetAppComponentStateAction {
  return {
    type: SET_APP_COMPONENT_STATE,
    payload: componentState
  };
}

export function deleteAppComponentState(
  componentIdentifier: ComponentState['id']
): DeleteAppComponentStateAction {
  return {
    type: DELETE_APP_COMPONENT_STATE,
    meta: { identifier: componentIdentifier }
  };
}

export function setVisibilityOptionState(visibilityOptionValue: {
  groupId: string;
  optionId: string;
  checked: boolean;
  exclusive: boolean;
}): SetVisibilityOptionStateAction {
  return {
    type: SET_VISIBILITY_OPTION_STATE,
    payload: visibilityOptionValue
  };
}

export function defineRoutes(routes: Routes): DefineRoutesAction {
  return {
    type: DEFINE_ROUTES,
    payload: routes
  };
}

import { ComponentConfig } from '../../../interfaces';
import { IfVipcardSubmittingViewModel } from './IfVipcardSubmittingViewModel';

import template from './template.html';

export const ifVipcardSubmittingComponent: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new IfVipcardSubmittingViewModel(deps, params)
  }
};

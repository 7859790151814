import { Action } from 'redux';

import { PaymentOption } from '../../interfaces';

export const ADD_PAYMENT_OPTION = 'ADD_PAYMENT_OPTION',
  DELETE_PAYMENT_OPTION = 'DELETE_PAYMENT_OPTION';

export interface AddPaymentOptionAction extends Action<typeof ADD_PAYMENT_OPTION> {
  type: typeof ADD_PAYMENT_OPTION;
  payload: PaymentOption;
}

export interface DeletePaymentOptionAction extends Action<typeof DELETE_PAYMENT_OPTION> {
  type: typeof DELETE_PAYMENT_OPTION;
  meta: { code: string };
}

export type PaymentActionTypes = AddPaymentOptionAction | DeletePaymentOptionAction;

export function addPaymentOption(paymentOption: PaymentOption): AddPaymentOptionAction {
  return {
    type: ADD_PAYMENT_OPTION,
    payload: paymentOption
  };
}

export function deletePaymentOption(code: string): DeletePaymentOptionAction {
  return {
    type: DELETE_PAYMENT_OPTION,
    meta: { code }
  };
}

import { components, observable, PureComputed, pureComputed } from 'knockout';
import { ComponentDependencies } from '../../../interfaces';
import { setAdditionalField } from '../../../store/order/actions';
import { BaseComponentViewModel } from '../../base-component';

export interface AdditionalCheckboxFieldViewModelParams extends components.ViewModelParams {
  additionalFieldKey?: string;
  valueChecked?: string | number | boolean;
  valueUnchecked?: string | number | boolean;
  defaultValue?: string | number | boolean;
  required?: boolean;
}

class AdditionalCheckboxFieldViewModel extends BaseComponentViewModel {
  public value$: PureComputed<boolean>;
  private readonly required: boolean;

  public constructor(deps: ComponentDependencies, params?: AdditionalCheckboxFieldViewModelParams) {
    super(deps);

    this.required = params?.required ?? false;

    const valueChecked = params?.valueChecked ?? true;
    const valueUnchecked = params?.valueUnchecked ?? false;
    const defaultValue = params?.defaultValue ?? valueUnchecked;

    const valueInStore$ = observable<string | number | boolean>(valueUnchecked);

    if (params?.additionalFieldKey) {
      const pathInStore = '$.order.additionalFields.' + params.additionalFieldKey;
      this.bindObservableToStore(valueInStore$, pathInStore, value =>
        setAdditionalField({
          key: params.additionalFieldKey as string,
          value
        })
      );
    }

    if (valueInStore$() === undefined) {
      setTimeout(() => valueInStore$(defaultValue));
    }

    this.value$ = pureComputed({
      read() {
        return valueInStore$() === valueChecked;
      },
      write(checked) {
        valueInStore$(checked ? valueChecked : valueUnchecked);
      },
      owner: this
    });

    this.initializeValidations();
    this.initializeStateUpdates();
  }

  protected initializeValidations(): void {
    if (this.required) {
      this.value$.extend({
        equal: {
          params: true,
          message: this.i18next.t('knockoutValidation.required', 'This field is required')
        }
      } as never);
    }
  }
}

export default AdditionalCheckboxFieldViewModel;

import { ComponentDictionary } from '../interfaces';
import activeCategoryComponent from './products/active-category';
import additionalContactsComponent from './additional-contacts/additional-contacts';
import additionalContactEmailComponent from './additional-contacts/additional-contact-email';
import additionalContactEmailConfirmationComponent from './additional-contacts/additional-contact-email-confirmation';
import additionalContactNameComponent from './additional-contacts/additional-contact-name';
import additionalContactTelephoneComponent from './additional-contacts/additional-contact-telephone';
import additionalContactBirthdate from './additional-contacts/additional-contact-birthdate';
import additionalCheckboxField from './additional-fields/additional-checkbox-field';
import additionalDropdownField from './additional-fields/additional-dropdown-field';
import additionalQuantityField from './additional-fields/additional-quantity-field';
import additionalTextField from './additional-fields/additional-text-field';
import addToCartButtonComponent from './add-to-cart-button';
import browserWarningComponent from './browser-warning';
import cartComponent from './cart/cart';
import cartNotificationComponent from './cart/cart-notification';
import categoryComponent from './products/category';
import categorySelectorComponent from './products/category-selector';
import checkboxComponent from './checkbox';
import choiceRadioToggleComponent from './choice-radio-toggle';
import collapsibleContentComponent from './collapsible-content';
import contactAddressComponent from './contact/contact-address';
import contactBirthDateComponent from './contact/contact-birth-date';
import contactEmailComponent from './contact/contact-email';
import contactEmailConfirmationComponent from './contact/contact-email-confirmation';
import contactNameComponent from './contact/contact-name';
import contactMetaData from './contact/contact-metadata';
import contactOrganizationComponent from './contact/contact-organization';
import contactTelephoneComponent from './contact/contact-telephone';
import creditCounterComponent from './credit-counter';
import deliveryAddressComponent from './delivery-address';
import deliveryAddressToggleComponent from './delivery-address-toggle';
import dialogLink from './dialog-link';
import displayAdditionalField from './additional-fields/display-additional-field';
import elementClassToggleComponent from './element-class-toggle';
import ifBackorder from './if-backorder';
import ifCartEmpty from './cart/if-cart-empty';
import ifOptedInComponent from './opt-ins/if-opted-in';
import ifStageComponent from './if-stage';
import IfVisibilityOptionComponent from './if-visibility-option';
import loginComponent from './login';
import logoutButtonComponent from './logout-button';
import navigationButtonComponent from './navigation-button';
import optInComponent from './opt-ins/opt-in';
import optInButtonsComponent from './opt-ins/opt-in-buttons';
import optInListComponent from './opt-ins/opt-in-list';
import ifOptInsSubmittingComponent from './opt-ins/if-optins-submitting';
import orderRemarksComponent from './order-remarks';
import paymentOptionSelectorComponent from './payment-option-selector';
import productDropdownComponent from './products/product-dropdown';
import { productFilterComponent } from './products/product-filter';
import productModalContentComponent from './products/product-modal-content';
import { productCartButtonsComponent } from './products/product-cart-buttons';
import productSelectorComponent from './products/product-selector';
import productSummaryComponent from './products/product-summary';
import progressIndicatorComponent from './progress-indicator';
import quantityCounterComponent from './quantity-counter';
import remainingCreditWarningComponent from './remaining-credit-warning';
import removeFromCartButton from './remove-from-cart-button';
import selectedProducts from './products/selected-products';
import stateContextComponent from './state-context';
import submitButtonComponent from './submit-button';
import vimeoVideoComponent from './vimeo-video';
import { ifVipcardSubmittingComponent } from './vipcard/if-vipcard-submitting';
import { ifVipcardValidatedComponent } from './vipcard/if-vipcard-validated';
import { vipcardInputComponent } from './vipcard/vipcard-input';
import visibilityToggleComponent from './visibility-toggle';
import withNotificationsComponent from './notifications/with-notifications';
import withProductComponent from './products/with-product';
import withThemeConfigComponent from './theme-config/with-theme-config';
import { routerOutletComponent } from './router-outlet';
import { cartElementClassToggleComponent } from './cart/cart-element-class-toggle';
import { vipcardSubmitButton } from './vipcard/vipcard-submit-button';

const components: ComponentDictionary = {
  'active-category': activeCategoryComponent,
  'additional-contacts': additionalContactsComponent,
  'additional-contact-email': additionalContactEmailComponent,
  'additional-contact-email-confirmation': additionalContactEmailConfirmationComponent,
  'additional-contact-name': additionalContactNameComponent,
  'additional-contact-telephone': additionalContactTelephoneComponent,
  'additional-contact-birthdate': additionalContactBirthdate,
  'additional-checkbox-field': additionalCheckboxField,
  'additional-text-field': additionalTextField,
  'additional-dropdown-field': additionalDropdownField,
  'additional-quantity-field': additionalQuantityField,
  'add-to-cart-button': addToCartButtonComponent,
  'browser-warning': browserWarningComponent,
  cart: cartComponent,
  'cart-element-class-toggle': cartElementClassToggleComponent,
  'cart-notification': cartNotificationComponent,
  category: categoryComponent,
  'category-selector': categorySelectorComponent,
  checkbox: checkboxComponent,
  'choice-radio-toggle': choiceRadioToggleComponent,
  'collapsible-content': collapsibleContentComponent,
  'contact-address': contactAddressComponent,
  'contact-birth-date': contactBirthDateComponent,
  'contact-email': contactEmailComponent,
  'contact-email-confirmation': contactEmailConfirmationComponent,
  'contact-name': contactNameComponent,
  'contact-organization': contactOrganizationComponent,
  'contact-telephone': contactTelephoneComponent,
  'contact-metadata': contactMetaData,
  'credit-counter': creditCounterComponent,
  'delivery-address': deliveryAddressComponent,
  'delivery-address-toggle': deliveryAddressToggleComponent,
  'dialog-link': dialogLink,
  'display-additional-field': displayAdditionalField,
  'element-class-toggle': elementClassToggleComponent,
  'if-backorder': ifBackorder,
  'if-cart-empty': ifCartEmpty,
  'if-opted-in': ifOptedInComponent,
  'if-opt-ins-submitting': ifOptInsSubmittingComponent,
  'if-stage': ifStageComponent,
  'if-vipcard-submitting': ifVipcardSubmittingComponent,
  'if-vipcard-validated': ifVipcardValidatedComponent,
  'if-visibility-option': IfVisibilityOptionComponent,
  login: loginComponent,
  'logout-button': logoutButtonComponent,
  'navigation-button': navigationButtonComponent,
  'opt-in': optInComponent,
  'opt-in-buttons': optInButtonsComponent,
  'opt-in-list': optInListComponent,
  'order-remarks': orderRemarksComponent,
  'product-dropdown': productDropdownComponent,
  'product-filter': productFilterComponent,
  'payment-option-selector': paymentOptionSelectorComponent,
  'product-modal-content': productModalContentComponent,
  'product-selector': productSelectorComponent,
  'product-summary': productSummaryComponent,
  'product-cart-buttons': productCartButtonsComponent,
  'progress-indicator': progressIndicatorComponent,
  'quantity-counter': quantityCounterComponent,
  'remaining-credit-warning': remainingCreditWarningComponent,
  'remove-from-cart-button': removeFromCartButton,
  'router-outlet': routerOutletComponent,
  'selected-products': selectedProducts,
  'state-context': stateContextComponent,
  'submit-button': submitButtonComponent,
  'vimeo-video': vimeoVideoComponent,
  'vipcard-input': vipcardInputComponent,
  'vipcard-submit-button': vipcardSubmitButton,
  'visibility-toggle': visibilityToggleComponent,
  'with-product': withProductComponent,
  'with-notifications': withNotificationsComponent,
  'with-theme-config': withThemeConfigComponent
};

export default components;

import { State } from '../store/reducers';
import { PaymentOption } from '../interfaces';

export function paymentOptionCodeValidator(
  state: State,
  paymentOptionCode: PaymentOption['code']
): boolean {
  return Boolean(
    state.payment.paymentOptions.find(paymentOption => {
      return paymentOption.code.toLowerCase() === paymentOptionCode.toLowerCase();
    })
  );
}

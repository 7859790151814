import { components } from 'knockout';
import { ComponentDependencies } from '../../../interfaces';
import { BaseComponentViewModel } from '../../base-component';

type WithThemeConfigViewModelParams = components.ViewModelParams;

export class WithThemeConfigViewModel extends BaseComponentViewModel {
  public context: unknown;

  public constructor(deps: ComponentDependencies, params?: WithThemeConfigViewModelParams) {
    super(deps);
    this.context = deps.themeConfig.getThemeConfig();
  }
}

import { Observable, PureComputed } from 'knockout';
import { Store, Action } from 'redux';
import jsonpath from 'jsonpath';

export type DisposalFunction = () => void;

export type GetObservableT<T extends Observable | PureComputed> = T extends Observable<infer O>
  ? O
  : T extends PureComputed<infer P>
  ? P
  : never;

export class StoreDataBindingHelper {
  static bind<T, A extends Action>(
    store: Store,
    observable$: Observable<T> | PureComputed<T>,
    storeDataJsonPath: string,
    actionCreator?: (value: GetObservableT<typeof observable$>) => A | undefined
  ): DisposalFunction {
    const observableValue = observable$(),
      state = store.getState(),
      jsonPathResult = jsonpath.query(state, storeDataJsonPath);

    if (jsonPathResult.length > 1) {
      throw new Error('The provided JSONPath expression returns more than 1 element.');
    }

    const value = jsonPathResult[0];

    if (JSON.stringify(value) !== JSON.stringify(observableValue)) {
      observable$(value);
    }

    const unsubStore = store.subscribe(() => {
      const currentObservableValue = observable$(),
        currentState = store.getState(),
        currentValue = jsonpath.query(currentState, storeDataJsonPath)[0];

      if (
        currentValue !== undefined &&
        JSON.stringify(currentValue) !== JSON.stringify(currentObservableValue)
      ) {
        observable$(currentValue);
      }
    });

    const obsSubscription = observable$.subscribe((currentValue: T) => {
      if (!actionCreator) {
        return;
      }

      const currentState = store.getState(),
        storeValue = jsonpath.query(currentState, storeDataJsonPath)[0];

      if (JSON.stringify(storeValue) !== JSON.stringify(currentValue)) {
        const action = actionCreator(currentValue);

        if (action) {
          store.dispatch(action);
        }
      }
    });

    return () => {
      obsSubscription.dispose();
      unsubStore();
    };
  }
}

import { StoreBackendServiceState } from '../../../interfaces';
import { initialState as storeBackendInitialState } from '../../../store/storeBackend/initialState';

export const emptyState: StoreBackendServiceState = {
  additionalContacts: {},
  categories: {},
  contact: {
    identifiers: {},
    name: {
      gender: 'unknown',
      prefixedTitles: [],
      initials: [],
      givenName: '',
      middleNames: [],
      familyNamePrefix: '',
      familyName: '',
      suffixedTitles: []
    },
    formattedName: '',
    address: {
      houseNumber: '',
      street: '',
      postalCode: '',
      city: '',
      'countryCodeISO3166-1': 'NL'
    },
    emailAddress: null,
    emailConfirmation: null,
    phoneNumber: null,
    phoneNumberMobile: null,
    isTest: false
  },
  order: {
    additionalFields: {},
    items: {},
    selectedOptIns: [],
    choices: {
      '1': null,
      '2': null,
      '3': null
    },
    useContactAddress: true,
    deliveryAddress: {
      houseNumber: '',
      street: '',
      postalCode: '',
      city: '',
      'countryCodeISO3166-1': 'NL'
    },
    remarks: null,
    payment: {
      paymentOptionCode: null
    },
    supportsTestMode: false,
    _queues: {
      orderItems: {
        hasPendingItems: false,
        lastProcessedItemSequenceNumber: 0,
        lastItemSequenceNumber: 0,
        pendingItems: []
      }
    },
    _submitting: {
      optIns: false,
      vipcard: false
    },
    confirmed: false
  },
  payment: {
    paymentOptions: []
  },
  products: {},
  storeBackend: JSON.parse(JSON.stringify(storeBackendInitialState)),
  optIns: {},
  user: {
    identities: {},
    restrictions: {
      amounts: {
        tokens: {},
        currency: {}
      },
      rules: {
        allowedCountries: {
          contact: ['NL'],
          delivery: ['NL'],
          phone: ['NL']
        }
      }
    },
    features: {
      orderRemarks: true,
      deliveryAddress: true
    }
  }
};

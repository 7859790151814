import { ComponentConfig } from '../../../interfaces';

import template from './template.html';
import { CategorySelectorViewModel } from './CategorySelectorViewModel';

const config: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new CategorySelectorViewModel(deps, params)
  }
};

export default config;

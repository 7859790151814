import { ComponentConfig } from '../../../interfaces';
import template from './template.pug';

import { DisplayAdditionalFieldViewModel } from './DisplayAdditionalFieldViewModel';

const config: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new DisplayAdditionalFieldViewModel(deps, params)
  }
};

export default config;

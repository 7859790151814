import { ComponentConfig } from '../../../interfaces';
import template from './template.pug';
import { WithThemeConfigViewModel } from './WithThemeConfigViewModel';

const config: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new WithThemeConfigViewModel(deps, params)
  }
};

export default config;

import { observable, Observable } from 'knockout';

import { BaseComponentViewModel } from '../../base-component';

import { AddressInputViewModelParams } from '../../../ui_widgets/address-input/AddressInputViewModel';
import { Address, ComponentDependencies, CountryCode } from '../../../interfaces';
import { setAddress } from '../../../store/contact/actions';

export type ContactAddressViewModelParams = Omit<AddressInputViewModelParams, 'value$'>;

export class ContactAddressViewModel extends BaseComponentViewModel {
  public readonly readOnly: boolean;
  public readonly value$: Observable<Address>;
  public readonly widgetIsValid$ = observable(false);
  public readonly widgetParams: AddressInputViewModelParams;
  public readonly allowedCountryCodes$ = observable<CountryCode[]>([]);

  constructor(deps: ComponentDependencies, params?: ContactAddressViewModelParams) {
    super(deps);

    this.bindObservableToStore(
      this.allowedCountryCodes$,
      '$.user.restrictions.rules.allowedCountries.delivery'
    );

    this.readOnly = typeof params?.readOnly === 'boolean' ? params.readOnly : false;

    this.widgetIsValid$.extend({ equal: true } as any);

    this.value$ = observable() as Observable<Address>;

    this.widgetParams = {
      ...params,
      value$: this.value$,
      isValid$: this.widgetIsValid$,
      allowedCountryCodes: this.allowedCountryCodes$()
    };

    if (this.readOnly) {
      this.bindObservableToStore(this.value$, '$.contact.address');
    } else {
      this.initializeStateUpdates();
      this.bindObservableToStore(this.value$, '$.contact.address', setAddress);
    }
  }
}

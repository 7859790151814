import { StoreBackendState } from './reducers';
import { initialState as contactInitialState } from '../contact/initialState';

export const initialState: StoreBackendState = {
  initialContact: contactInitialState,
  loginRequirements: {
    requestVerificationChallenge1: false,
    requestVerificationChallenge2: false,
    verificationChallenge1Format: undefined,
    verificationChallenge2Format: undefined,
    forceVerificationChallenge1Uppercase: false
  }
};

import { components, PureComputed, pureComputed } from 'knockout';
import { readPendingOrder } from '../../../helpers';
import { ComponentDependencies } from '../../../interfaces';
import { CartService } from '../../../services';
import { BaseComponentViewModel } from '../../base-component';

interface Texts {
  addToCart: string;
  removeFromCart: string;
}

export interface ProductCardButtonsViewModelParams extends components.ViewModelParams {
  sku?: string;
  texts?: Partial<Texts>;
}

export class ProductCartButtonsViewModel extends BaseComponentViewModel {
  public quantityInStore$: PureComputed<number>;
  public enableRemoveButton$: PureComputed<boolean>;
  public enableAddButton$: PureComputed<boolean>;

  private sku: string;
  private cart: CartService;

  public readonly texts: Texts;

  constructor(deps: ComponentDependencies, params?: ProductCardButtonsViewModelParams) {
    super(deps);

    if (!params?.sku) {
      throw new Error('Sku is required in ProductQuantitySpinner component');
    }

    this.texts = {
      addToCart: this.i18next.t('components.productSummary.addToCart', 'Add to cart'),
      removeFromCart: this.i18next.t(
        'components.productSummary.removeFromCart',
        'Remove from cart'
      ),
      ...params?.texts
    };

    this.sku = params.sku;
    this.cart = deps.cart;

    const products$ = deps.selectors.products$;

    this.quantityInStore$ = pureComputed(() => {
      void deps.selectors.order$();
      return this.getQuantityInStore();
    });

    this.enableRemoveButton$ = pureComputed(() => {
      const minQuantity = products$()[this.sku]?.minimums.quantity ?? 0;
      return !deps.selectors.orderIsPending$() && this.quantityInStore$() > minQuantity;
    });

    this.enableAddButton$ = pureComputed(() => {
      const maxQuantity = products$()[this.sku]?.maximums.quantity ?? Infinity;
      return !deps.selectors.orderIsPending$() && this.quantityInStore$() < maxQuantity;
    });
  }

  public onAddButtonClick = () => {
    this.cart.mutateOrderItem({
      sku: this.sku,
      quantity: 1,
      operation: 'ADD'
    });
  };

  public onRemoveButtonClick = () => {
    this.cart.mutateOrderItem({
      sku: this.sku,
      quantity: 1,
      operation: 'REMOVE'
    });
  };

  private getQuantityInStore(): number {
    const pendingOrder = readPendingOrder(this.store.getState().order);
    return pendingOrder.items[this.sku]?.quantity ?? 0;
  }
}

function getPatternRegex(countryCode: string): RegExp {
  switch (countryCode) {
    case 'NL':
      return /^[0-9]{4} ?[A-Z]{2}$/;
    case 'BE':
      return /^[0-9]{4}$/;
    case 'DE':
      return /^[0-9]{5}$/;
    default:
      return /[A-Z0-9]+/;
  }
}

export function isValidPostalCodeFormat(postalCode: string, countryCode: string): boolean {
  return getPatternRegex(countryCode?.toUpperCase()).test(postalCode?.toUpperCase());
}

import { AppStore } from '../store';
import { AppEventManagerInterface, RemoveListenerFn } from './event-manager/EventManagerInterface';
import { Notification } from '../interfaces';
import { DefaultDialog } from '../lib/native-dialog-extensions';
import { NewNotificationEvent } from '../events/NewNotificationEvent';
import { Unsubscribe } from 'redux';
import {
  notify,
  NotifyAction,
  clearNotifications,
  notificationEmitted
} from '../store/notifications/actions';
import { DEFAULT_PRIORITY } from './event-manager/EventManager';
import { NotificationsState } from '../store/notifications/reducers';

export type NotificationHandler = (notification: Notification) => void;
export type NotificationFilter = (notification: Notification) => boolean;

export class NotificationService {
  private unsubscribeFromStore: Unsubscribe | undefined;

  public constructor(private store: AppStore, private eventManager: AppEventManagerInterface) {}

  public initialize() {
    let lastSeenNotifications: NotificationsState = this.store.getState().notifications;

    const checkNotifications = () => {
      const notifications = this.store.getState().notifications;

      // Since this function is called for *any* store update, first check if this update
      // actually applies to the notifications slice.
      if (notifications === lastSeenNotifications || notifications.length === 0) {
        return;
      }
      lastSeenNotifications = notifications;

      const first = notifications[0];
      if (!first.emitted) {
        this.store.dispatch(notificationEmitted(first.timestamp));
        setTimeout(() => {
          this.eventManager.emit(new NewNotificationEvent(first));
        });
      }
    };

    setTimeout(() => {
      // subscribe later, to give other event listeners a chance to subscribe before the first notification
      this.unsubscribeFromStore = this.store.subscribe(checkNotifications);
      checkNotifications();
    });
  }

  public deinitialize() {
    this.unsubscribeFromStore && this.unsubscribeFromStore();
  }

  public notify(props: NotifyAction['payload']) {
    this.store.dispatch(notify(props));
  }

  public clearNotification(notification: Pick<Notification, 'timestamp'>) {
    this.store.dispatch(clearNotifications([notification.timestamp]));
  }

  public clearMultipleNotifications(filter?: NotificationFilter) {
    const timestamps = filter
      ? this.store
          .getState()
          .notifications.filter(filter)
          .map(x => x.timestamp)
      : null;

    this.store.dispatch(clearNotifications(timestamps));
  }

  public onNotification(
    handler: NotificationHandler,
    options?: {
      shouldHandle?: NotificationFilter;
      clearImmediately?: boolean;
      priority?: number;
    }
  ): RemoveListenerFn {
    const shouldHandle = options?.shouldHandle || (() => true);
    const priority = options?.priority ?? DEFAULT_PRIORITY;
    const clearImmediately = options?.clearImmediately ?? true;

    return this.eventManager.on(
      NewNotificationEvent,
      (event, controller) => {
        if (shouldHandle(event.notification)) {
          handler(event.notification);
          controller.stopPropagation();
          if (clearImmediately) {
            this.clearNotification(event.notification);
          }
        }
      },
      priority
    );
  }

  public showNotificationInDefaultDialog = (notification: Notification) => {
    void DefaultDialog.fire({
      classes: [`${notification.class}-dialog`],
      titleHtml: notification.title ?? undefined,
      contentHtml: notification.message,
      actions: notification.actions.map(action => [action.code, action.label]),
      hooks: {
        afterClose: async () => this.clearNotification(notification)
      }
    });
  };
}

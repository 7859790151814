import { components, observable, Observable } from 'knockout';

import { ComponentDependencies } from '../../interfaces';
import { BaseComponentViewModel } from '../base-component';

export interface CollapsibleContentViewModelParams extends components.ViewModel {
  context?: any;
  title?: string;
}

export class CollapsibleContentViewModel extends BaseComponentViewModel {
  context: any;
  title: string;

  open$: Observable<boolean> = observable(false);

  constructor(deps: ComponentDependencies, params: CollapsibleContentViewModelParams) {
    super(deps);

    this.title = params?.title ?? '';
  }

  toggleOpenState = (): void => {
    this.open$(!this.open$());
  };
}

import { observable, Observable } from 'knockout';

import { ComponentDependencies, HumanName } from '../../../interfaces';
import { setName } from '../../../store/contact/actions';
import { HumanNameViewModelParams } from '../../../ui_widgets/human-name/HumanNameViewModel';

import { BaseComponentViewModel } from '../../base-component';

export type ContactNameViewModelParams = Omit<HumanNameViewModelParams, 'value$'>;

export class ContactNameViewModel extends BaseComponentViewModel {
  public readonly readOnly: boolean;
  public readonly value$: Observable<HumanName>;

  public readonly widgetIsValid$ = observable(false);
  public readonly widgetParams: HumanNameViewModelParams;

  constructor(deps: ComponentDependencies, params?: ContactNameViewModelParams) {
    super(deps);

    this.readOnly = typeof params?.readOnly === 'boolean' ? params.readOnly : false;

    this.widgetIsValid$.extend({ equal: true } as any);

    this.value$ = observable() as Observable<HumanName>;

    this.widgetParams = {
      ...params,
      value$: this.value$,
      isValid$: this.widgetIsValid$
    };

    if (this.readOnly) {
      this.bindObservableToStore(this.value$, '$.contact.name');
    } else {
      this.initializeStateUpdates();
      this.bindObservableToStore(this.value$, '$.contact.name', setName);
    }
  }
}

import { Observable, PureComputed } from 'knockout';

import { Action } from 'redux';
import { GetObservableT, StoreDataBindingHelper } from '../../helpers/StoreDataBindingHelper';

import { ComponentDependencies, StoreBackendService } from '../../interfaces';
import { AppStore } from '../../store';
import { deleteAppComponentState, setAppComponentState } from '../../store/app/actions';
import { BaseWidgetViewModel } from '../../ui_widgets/BaseWidgetViewModel';

export class BaseComponentViewModel extends BaseWidgetViewModel {
  public readonly service: StoreBackendService;
  public readonly store: AppStore;

  constructor(deps: ComponentDependencies) {
    super(deps);
    this.service = deps.service;
    this.store = deps.store;

    this.store.dispatch(
      setAppComponentState({
        id: this.id,
        type: this.constructor.name,
        state: 'ok'
      })
    );
  }

  protected initializeStateUpdates(): void {
    this.store.dispatch(
      setAppComponentState({
        id: this.id,
        type: this.constructor.name,
        state: 'pending'
      })
    );

    const isValidSubscription = this.isValid$.subscribe(isValid => {
      this.store.dispatch(
        setAppComponentState({
          id: this.id,
          type: this.constructor.name,
          state: isValid ? 'ok' : 'error'
        })
      );
    });

    this.disposalFunctions.push(() => isValidSubscription.dispose());

    this.store.dispatch(
      setAppComponentState({
        id: this.id,
        type: this.constructor.name,
        state: this.isValid$() ? 'ok' : 'error'
      })
    );
  }

  public bindObservableToStore<T, A extends Action>(
    observable$: Observable<T> | PureComputed<T>,
    storeDataJsonPath: string,
    storeActionCreator?: (value: GetObservableT<typeof observable$>) => A | undefined
  ): void {
    this.disposalFunctions.push(
      StoreDataBindingHelper.bind<T, A>(
        this.store,
        observable$,
        storeDataJsonPath,
        storeActionCreator
      )
    );
  }

  public dispose(): void {
    super.dispose();
    this.store.dispatch(deleteAppComponentState(this.id));
  }
}

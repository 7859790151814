import { BindingHandler } from 'knockout';

export const captureEvent: BindingHandler = {
  init: (element: HTMLElement, valueAccessor) => {
    const arg = valueAccessor();

    for (const eventName in arg) {
      element.addEventListener(eventName, arg[eventName], { capture: true });
    }
  }
};

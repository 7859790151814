import { ProductCartButtonsViewModel } from './ProductCartButtonsViewModel';
import template from './template.html';
import { ComponentConfig } from '../../../interfaces';

export const productCartButtonsComponent: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params?) => new ProductCartButtonsViewModel(deps, params)
  }
};

import { ComponentConfig } from '../../interfaces';

import template from './template.pug';
import { ProgressIndicatorViewModel } from './ProgressIndicatorViewModel';

const config: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new ProgressIndicatorViewModel(deps, params)
  }
};

export default config;

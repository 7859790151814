import { ComponentConfig } from '../../interfaces';

import template from './template.pug';
import { QuantitySpinnerViewModel } from './QuantitySpinnerViewModel';
export {
  QuantitySpinnerViewModel,
  QuantitySpinnerViewModelParams
} from './QuantitySpinnerViewModel';

const config: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params, componentInfo) => new QuantitySpinnerViewModel(deps, params)
  }
};

export default config;

import { components, observable, Observable, pureComputed, PureComputed } from 'knockout';
import i18next from 'i18next';

import { ComponentDependencies, ComponentState } from '../../interfaces';
import { State } from '../../store/reducers';
import { BaseComponentViewModel } from '../base-component';
import { AppEventManagerInterface } from '../../services';
import { BeforeOrderSubmittedEvent } from '../../events';

export const SubmitButtonNotificationSource = 'SubmitButton';

export interface SubmitButtonViewModelParams extends components.ViewModelParams {
  texts?: {
    errorNotification?: string;
    orderLimitErrorNotification?: string;
    productQuantityLimitErrorNotification?: string;
    generalValidationErrorNotification?: string;
  };
}

export class SubmitButtonViewModel extends BaseComponentViewModel {
  public readonly context: any;

  public readonly texts: {
    errorNotification: string;
    orderLimitErrorNotification: string;
    productQuantityLimitErrorNotification: string;
    generalValidationErrorNotification: string;
  };

  public readonly componentStates$: Observable<{
    [key: string]: ComponentState;
  }> = observable({});
  public readonly busy$: Observable<boolean> = observable(false);
  public readonly disabled$: PureComputed<boolean>;
  private eventManager: AppEventManagerInterface;

  constructor(deps: ComponentDependencies, params?: SubmitButtonViewModelParams) {
    super(deps);

    this.eventManager = deps.appEventManager;

    this.texts = {
      errorNotification:
        i18next.t('components.submitButton.errorNotification') ?? 'An error occurred.',
      orderLimitErrorNotification:
        i18next.t('components.submitButton.orderLimitErrorNotification') ??
        'You cannot place any more orders.',
      productQuantityLimitErrorNotification:
        i18next.t('components.submitButton.productQuantityLimitErrorNotification') ??
        "You have exceeded a product's quantity limits.",
      generalValidationErrorNotification:
        i18next.t('components.submitButton.generalValidationErrorNotification') ??
        'The order is invalid and cannot be processed.',
      ...params?.texts
    };

    this.bindObservableToStore(this.componentStates$, '$.app.componentStates');

    this.disabled$ = pureComputed(() => Boolean(this.busy$() || deps.selectors.orderIsPending$()));
  }

  private allComponentsAreValid() {
    const componentStates = this.componentStates$();

    for (const id in componentStates) {
      if (componentStates[id].state !== 'ok') {
        return false;
      }
    }

    return true;
  }

  public submit: () => void = () => {
    if (this.busy$()) {
      return;
    }

    this.eventManager.emit(new BeforeOrderSubmittedEvent());

    if (!this.allComponentsAreValid()) {
      return;
    }

    (async () => {
      this.busy$(true);

      const state: State = this.store.getState();

      const validationErrorCode = await this.service.validateOrder(state);

      if (validationErrorCode) {
        switch (validationErrorCode) {
          case 'ORDER_LIMIT': {
            this.notifications.notify({
              message: this.texts.orderLimitErrorNotification,
              extraData: {
                source: SubmitButtonNotificationSource,
                validationErrorCode
              }
            });
            break;
          }
          case 'PRODUCT_QUANTITY_LIMIT': {
            this.notifications.notify({
              message: this.texts.productQuantityLimitErrorNotification,
              extraData: {
                source: SubmitButtonNotificationSource,
                validationErrorCode
              }
            });
            break;
          }
          default:
            this.notifications.notify({
              message: this.texts.generalValidationErrorNotification,
              extraData: {
                source: SubmitButtonNotificationSource,
                validationErrorCode
              }
            });
        }

        return;
      }

      const redirectUrl = await this.service.submitOrder(state);

      if (redirectUrl) {
        /*
         * Use location.replace() instead of location.assign() to disable
         * returning to the registration phase using the browser's 'back'
         * button.
         */
        location.replace(redirectUrl.toString());
      }
    })()
      .catch(err => {
        console.error(err);
        this.notifications.notify({
          message: this.texts.errorNotification,
          extraData: {}
        });
      })
      .finally(() => {
        this.busy$(false);
      });
  };
}

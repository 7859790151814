import { BaseComponentViewModel } from '../base-component';
import { components, pureComputed, PureComputed } from 'knockout';
import { CartService } from '../../services';
import { ComponentDependencies } from '../../interfaces';

export interface RemoveFromCartButtonViewModelParams extends components.ViewModelParams {
  skus?: string[];
  disabled?: boolean;
  label?: string;
}

export class RemoveFromCartButtonViewModel extends BaseComponentViewModel {
  private skus: string[];
  private cart: CartService;
  public readonly disabled$: PureComputed<boolean>;
  public label: string;

  public constructor(deps: ComponentDependencies, params?: RemoveFromCartButtonViewModelParams) {
    super(deps);

    this.cart = deps.cart;
    this.skus = params?.skus ?? [];
    this.label =
      params?.label ?? this.i18next.t('components.removeFromCartButton.label', 'Remove from cart');

    this.disabled$ = pureComputed(() => params?.disabled || deps.selectors.orderIsPending$());
  }

  public removeProductFromCart() {
    this.cart.bulkMutateOrderItem({
      skus: this.skus,
      quantity: 0,
      operation: 'SET'
    });
  }
}

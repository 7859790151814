import { observable, PureComputed, pureComputed } from 'knockout';
import { ComponentDependencies, CountryCode } from '../../../interfaces';
import { setMobilePhone, setPhone } from '../../../store';
import { TelephoneTextInputViewModelParams } from '../../../ui_widgets/telephone-text-input';
import { BaseComponentViewModel } from '../../base-component';

type RequiredFieldsType = 'all' | 'any' | 'none';

export interface ContactTelephoneViewModelParams {
  readOnly?: boolean;
  phoneReadOnly?: boolean;
  mobileReadOnly?: boolean;
  phoneEnabled?: boolean;
  mobileEnabled?: boolean;
  required?: RequiredFieldsType | boolean;
  mobileTexts?: {
    label?: string;
  };
  texts?: {
    label?: string;
    neitherFilledErrorMessage?: string;
  };
}

export class ContactTelephoneViewModel extends BaseComponentViewModel {
  readonly phoneNumber$ = observable(null);
  readonly phoneNumberMobile$ = observable(null);

  public readonly mobileEnabled: boolean;
  public readonly phoneEnabled: boolean;
  public readonly phoneReadOnly: boolean;
  public readonly mobileReadOnly: boolean;

  public readonly eitherFilledValidationActive: boolean = false;
  public readonly eitherFilledValidation$: PureComputed<boolean> | undefined;

  public readonly texts: {
    neitherFilledErrorMessage: string;
  };

  readonly phoneWidgetParams: TelephoneTextInputViewModelParams;
  readonly mobileWidgetParams: TelephoneTextInputViewModelParams;

  constructor(deps: ComponentDependencies, params?: ContactTelephoneViewModelParams) {
    super(deps);

    this.mobileReadOnly = Boolean(params?.mobileReadOnly) || Boolean(params?.readOnly);
    this.phoneReadOnly = Boolean(params?.phoneReadOnly) || Boolean(params?.readOnly);

    this.phoneEnabled = params?.phoneEnabled ?? true;
    this.mobileEnabled = params?.mobileEnabled ?? false;

    let required: RequiredFieldsType =
      typeof params?.required === 'boolean'
        ? params?.required
          ? 'all'
          : 'none' // for backwards compatibility: map true/false to all/none
        : params?.required ?? 'none';

    // special case: if required is set to 'any', and exactly one field is enabled,
    // then change it to 'all' so that the required property is set to true on the widget
    if (required === 'any' && this.phoneEnabled !== this.mobileEnabled) {
      required = 'all';
    }

    this.texts = {
      neitherFilledErrorMessage:
        params?.texts?.neitherFilledErrorMessage ??
        this.i18next.t(
          'components.contactTelephone.neitherFilledErrorMessage',
          'Fill any phone field'
        )
    };

    const countryCodesForPhone$ = observable([] as CountryCode[]);
    this.bindObservableToStore(
      countryCodesForPhone$,
      '$.user.restrictions.rules.allowedCountries.phone'
    );

    this.phoneWidgetParams = {
      value$: this.phoneNumber$,
      countryCodes$: countryCodesForPhone$,
      required: required === 'all',
      readOnly: this.phoneReadOnly,
      texts: {
        label: this.i18next.t('components.contactTelephone.label', 'Phone'),
        ...params?.texts
      },
      validateMobile: false
    };

    this.mobileWidgetParams = {
      value$: this.phoneNumberMobile$,
      countryCodes$: countryCodesForPhone$,
      required: required === 'all',
      readOnly: this.mobileReadOnly,
      texts: {
        label: this.i18next.t('components.contactTelephone.mobileLabel', 'Mobile'),
        ...params?.mobileTexts
      },
      validateMobile: true
    };

    if (this.phoneReadOnly || this.phoneReadOnly) {
      this.bindObservableToStore(this.phoneNumber$, '$.contact.phoneNumber');
      this.bindObservableToStore(this.phoneNumberMobile$, '$.contact.phoneNumberMobile');
    } else {
      if (this.phoneEnabled) {
        this.bindObservableToStore(this.phoneNumber$, '$.contact.phoneNumber', setPhone);
      }

      if (this.mobileEnabled) {
        this.bindObservableToStore(
          this.phoneNumberMobile$,
          '$.contact.phoneNumberMobile',
          setMobilePhone
        );
      }

      if (required === 'any') {
        this.eitherFilledValidation$ = pureComputed(() =>
          Boolean(this.phoneNumber$() || this.phoneNumberMobile$())
        );
        this.eitherFilledValidation$.extend({
          equal: {
            params: true,
            message: this.texts.neitherFilledErrorMessage
          }
        } as never);
        this.eitherFilledValidationActive = true;
      }

      this.initializeStateUpdates();
    }
  }
}

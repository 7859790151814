import i18next from 'i18next';
import { observable, Observable, pureComputed, PureComputed, components } from 'knockout';

import { User, OrderLine, ComponentDependencies } from '../../interfaces';
import { BaseComponentViewModel } from '../base-component';

export interface QuantityCounterViewModelParams extends components.ViewModelParams {
  texts?: {
    prefix?: string;
    suffix?: string;
  };
}

export class QuantityCounterViewModel extends BaseComponentViewModel {
  public readonly texts: {
    prefix?: string;
    suffix?: string;
    prefix$: PureComputed<string>;
    suffix$: PureComputed<string>;
  };

  public readonly orderItems$: Observable<{ [key: string]: OrderLine }> = observable({});
  public readonly user$: Observable<User | undefined> = observable();

  public readonly quantity$: PureComputed<number>;
  public readonly displayQuantity$: PureComputed<string>;

  constructor(deps: ComponentDependencies, params?: QuantityCounterViewModelParams) {
    super(deps);

    this.texts = {
      ...params?.texts,
      prefix$: pureComputed(() => {
        return (
          params?.texts?.prefix ??
          i18next.t('components.quantityCounter.prefix', 'You have got', {
            count: this.quantity$()
          }) ??
          'You have got'
        );
      }),
      suffix$: pureComputed(() => {
        return (
          params?.texts?.suffix ??
          i18next.t('components.quantityCounter.suffix', 'choices left', {
            count: this.quantity$()
          }) ??
          'choices left'
        );
      })
    };

    this.displayQuantity$ = pureComputed(() => {
      return i18next.t('components.quantityCounter.value', '{{count}}', {
        count: this.quantity$()
      });
    });

    this.bindObservableToStore(this.user$, '$.user');
    this.bindObservableToStore(this.orderItems$, '$.order.items');

    this.quantity$ = pureComputed(() => {
      const orderItems = this.orderItems$(),
        user = this.user$();

      let allowedQuantity = 0;

      if (!user || typeof user.restrictions.quantity !== 'number') {
        return allowedQuantity;
      }

      allowedQuantity = user.restrictions.quantity;

      for (const sku in orderItems) {
        allowedQuantity -= orderItems[sku].quantity;
      }

      return allowedQuantity;
    });
  }
}

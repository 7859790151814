import { components } from 'knockout';

import { ComponentDependencies } from '../../interfaces';
import { BaseComponentViewModel } from '../base-component';

export interface BrowserWarningViewModelParams extends components.ViewModelParams {
  [name: string]: undefined;
}

export class BrowserWarningViewModel extends BaseComponentViewModel {
  public readonly context: any;

  public readonly oldBrowser: boolean;

  constructor(deps: ComponentDependencies, params?: BrowserWarningViewModelParams) {
    super(deps);

    this.oldBrowser = this.isOldBrowser();
  }

  isOldBrowser(): boolean {
    if (
      /* eslint-disable-next-line no-console */
      typeof console.time === 'undefined'
    ) {
      return true;
    }

    return false;
  }
}

import { applyMiddleware, compose, createStore, Store } from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';

import { rootReducer, State } from './reducers';
import { ActionTypes } from './actions';

let composeEnhancers = compose;

if (typeof window !== 'undefined') {
  composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true
      })
    : compose;
}

const middleware = process.env.NODE_ENV !== 'production' ? [reduxImmutableStateInvariant()] : [];

export type AppStore = Store<State, ActionTypes>;

export const store: AppStore = createStore(
  rootReducer,
  undefined,
  composeEnhancers(applyMiddleware(...middleware))
);

export * from './actions';
export * from './additionalContacts/actions';
export * from './app/actions';
export * from './categories/actions';
export * from './contact/actions';
// export * from './optIns/actions'; // No actions for opt-ins
export * from './order/actions';
export * from './payment/actions';
export * from './products/actions';
// export * from './storeBackend/actions'; // No actions for store backend
export * from './user/actions';
export { State } from './reducers';

import { debounce } from '../../helpers/debounce';
import { postponeReportProgress } from '../../helpers/postponeReportProgress';
import { throttle } from '../../helpers/throttle';
import { OrderLineMutation, StoreBackendService, Order } from '../../interfaces';
import {
  AppStore,
  bulkOrderItemsMutation,
  finalizePendingMutation,
  processOrderItemMutation,
  State
} from '../../store';
import { MinimumOrderAmountRestriction } from './restrictions/MinimumOrderAmountRestriction';
import { SingleSkuRestriction } from './restrictions/SingleSkuRestriction';
import { SingleSkuSingleItemRestriction } from './restrictions/SingleSkuSingleItemRestriction';
import { SingleSkuTransferQuantityRestriction } from './restrictions/SingleSkuTransferQuantityRestriction';
import { combinedOrderValidator } from '../../validators';
import { readPendingOrder } from '../../helpers';
import { AppEventManagerInterface } from '../event-manager/EventManagerInterface';
import { OrderItemMutatedEvent, OrderItemMutationValidatedEvent } from '../../events';
import { BulkOrderItemMutation } from '../../interfaces/BulkOrderItemMutation';
import {
  processOrderItemMutationState,
  bulkOrderItemsMutationState
} from '../../store/order/reducers';

const UPDATE_ORDER_MIN_TIME_BETWEEN = 500;

export class CartService {
  private singleSkuRestriction: SingleSkuRestriction;
  private minimumOrderAmountRestriction: MinimumOrderAmountRestriction;
  private singleSkuSingleItem: SingleSkuSingleItemRestriction;
  private singleSkuTransferQuantity: SingleSkuTransferQuantityRestriction;
  private readyForReportProgress: Promise<void>;

  constructor(
    private store: AppStore,
    private service: StoreBackendService,
    private eventManager: AppEventManagerInterface,
    private queueOrderItemMutations: boolean,
    private enableReportProgress: boolean,
    postponeReportProgressUntilUserIdentified: boolean
  ) {
    this.singleSkuRestriction = new SingleSkuRestriction(store);
    this.singleSkuSingleItem = new SingleSkuSingleItemRestriction(store);
    this.minimumOrderAmountRestriction = new MinimumOrderAmountRestriction(store);
    this.singleSkuTransferQuantity = new SingleSkuTransferQuantityRestriction(store);
    this.readyForReportProgress = postponeReportProgress(
      store,
      postponeReportProgressUntilUserIdentified
    );

    this.reportProgressThenCommit = throttle(
      this.reportProgressThenCommit.bind(this),
      UPDATE_ORDER_MIN_TIME_BETWEEN,
      { leading: true, trailing: true }
    );
    this.updateOrderDebounced = debounce(
      this.service.updateOrder.bind(this.service),
      UPDATE_ORDER_MIN_TIME_BETWEEN
    );
  }

  public enforceRestrictionsOnOrder(): void {
    const actions = [
      ...this.singleSkuRestriction.enforceSingleSku(),
      ...this.minimumOrderAmountRestriction.enforceMinimumOrderAmounts()
    ];

    if (actions.length) {
      for (const action of actions) {
        this.store.dispatch(action);
      }
      this.reportProgress();
    }
  }

  public mutateOrderItem(mutationData: OrderLineMutation, validate = true): void {
    mutationData = this.singleSkuSingleItem.enforce(mutationData);
    mutationData = this.singleSkuTransferQuantity.enforce(mutationData);

    // pre-mutation actions
    const preMutationActions = [...this.singleSkuRestriction.enforceSingleSku(mutationData.sku)];
    preMutationActions.forEach(action => this.store.dispatch(action));

    if (validate && !this.validateMutation(mutationData)) {
      return;
    }

    // current mutation action
    this.store.dispatch(processOrderItemMutation(mutationData));

    // post mutation actions (these rely on the mutated store)
    const postMutationActions = [
      ...this.minimumOrderAmountRestriction.enforceMinimumOrderAmounts()
    ];
    postMutationActions.forEach(action => this.store.dispatch(action));

    // report the new state to the backend
    this.reportProgress();

    this.eventManager.emit(new OrderItemMutatedEvent(mutationData));
  }

  public bulkMutateOrderItem(bulkMutationData: BulkOrderItemMutation, validate = true): void {
    if (validate && !this.validateBulkMutation(bulkMutationData)) {
      return;
    }

    this.store.dispatch(bulkOrderItemsMutation(bulkMutationData));
    this.reportProgress();
  }

  public reportProgress(): void {
    if (this.queueOrderItemMutations) {
      this.reportProgressThenCommit();
    } else {
      this.commitThenReportProgress();
    }
  }

  private reportProgressThenCommit(): void {
    (async () => {
      let success = false;

      const state = this.store.getState();
      const pendingVersion = state.order._queues.orderItems.lastItemSequenceNumber;
      try {
        if (this.enableReportProgress) {
          await this.readyForReportProgress;
          success = await this.service.updateOrder(state);
        } else {
          success = true;
        }
      } catch (err) {
        console.error(err);
      }
      this.store.dispatch(finalizePendingMutation(success, pendingVersion));
    })();
  }

  private updateOrderDebounced: (state: State) => Promise<boolean>;

  private commitThenReportProgress(): void {
    (async () => {
      const state = this.store.getState();
      const pendingVersion = state.order._queues.orderItems.lastItemSequenceNumber;
      this.store.dispatch(finalizePendingMutation(true, pendingVersion));
      try {
        if (this.enableReportProgress) {
          await this.readyForReportProgress;
          await this.updateOrderDebounced(state);
        }
      } catch (err) {
        console.error(err);
      }
    })();
  }

  private validateMutation(mutationData: OrderLineMutation): boolean {
    const orderBeforeMutation = this.store.getState().order;
    const action = processOrderItemMutation(mutationData);
    const orderAfterMutation = readPendingOrder(
      processOrderItemMutationState(orderBeforeMutation, action)
    );

    return this.validateOrderAfterMutation(orderAfterMutation);
  }

  private validateBulkMutation(mutationData: BulkOrderItemMutation): boolean {
    const orderBeforeMutation = this.store.getState().order;
    const action = bulkOrderItemsMutation(mutationData);
    const orderAfterMutation = readPendingOrder(
      bulkOrderItemsMutationState(orderBeforeMutation, action)
    );

    return this.validateOrderAfterMutation(orderAfterMutation);
  }

  private validateOrderAfterMutation(orderAfterMutation: Order): boolean {
    const validationResult = combinedOrderValidator({
      ...this.store.getState(),
      order: orderAfterMutation
    });

    if (!validationResult.result) {
      this.eventManager.emit(new OrderItemMutationValidatedEvent(validationResult));
    }

    return validationResult.result;
  }
}

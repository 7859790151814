import { components, observable, Observable, pureComputed, PureComputed } from 'knockout';

import { Category, ComponentDependencies } from '../../../interfaces';
import { BaseComponentViewModel } from '../../base-component';

export interface CategoryViewModelParams extends components.ViewModelParams {
  categoryIdentifier?: string;
  categorySequenceNumber?: number;
}

export class CategoryViewModel extends BaseComponentViewModel {
  public readonly requestedCategoryIdentifier?: string;
  public readonly requestedCategorySequenceNumber?: number;

  public readonly categories$: Observable<{ [key: string]: Category }> = observable({});
  public readonly category$: PureComputed<Category | undefined>;

  constructor(deps: ComponentDependencies, params?: CategoryViewModelParams) {
    super(deps);

    this.requestedCategoryIdentifier = params?.categoryIdentifier;
    this.requestedCategorySequenceNumber = params?.categorySequenceNumber;

    this.bindObservableToStore(this.categories$, '$.categories');

    this.category$ = pureComputed(() => {
      let category;

      if (this.requestedCategoryIdentifier) {
        category = this.categories$()[this.requestedCategoryIdentifier] ?? undefined;
      }

      if (!category && this.requestedCategorySequenceNumber) {
        category =
          Object.values(this.categories$()).find(
            cat => cat.sequenceNumber === this.requestedCategorySequenceNumber
          ) ?? undefined;
      }

      return category;
    });
  }
}

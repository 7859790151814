import { readPendingOrder } from '../../../helpers';
import { Product } from '../../../interfaces';
import { AppStore } from '../../../store';
import {
  processOrderItemMutation,
  ProcessOrderItemMutationAction
} from '../../../store/order/actions';

export class MinimumOrderAmountRestriction {
  constructor(private store: AppStore) {}

  public enforceMinimumOrderAmounts(): ProcessOrderItemMutationAction[] {
    return Object.values<Product>(this.store.getState().products)
      .filter(product => product.minimums?.quantity > 0)
      .map(product => this.enforceMinimumOrderAmountForProduct(product.sku))
      .filter(action => action !== undefined) as ProcessOrderItemMutationAction[];
  }

  private enforceMinimumOrderAmountForProduct(
    sku: Product['sku']
  ): ProcessOrderItemMutationAction | undefined {
    const state = this.store.getState();
    const minimumQuantity = state.products[sku]?.minimums?.quantity ?? 0;
    const currentQuantity = readPendingOrder(state.order).items[sku]?.quantity ?? 0;

    if (minimumQuantity <= currentQuantity) {
      return undefined; // nothing to do
    }

    return processOrderItemMutation({
      sku,
      quantity: minimumQuantity,
      operation: 'SET'
    });
  }
}

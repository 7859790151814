import { ComponentConfig } from '../../../interfaces';

import template from './template.pug';
import { ContactOrganizationViewModel } from './ContactOrganizationViewModel';

const config: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new ContactOrganizationViewModel(deps, params)
  }
};

export default config;

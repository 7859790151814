import { webtextTypeMeta, webtextIdMeta, TranslateWebtextFn, WebtextType } from './webtexts';
import { getDefinedTranslations } from './translate';
import { arrayToObject } from '../arrayHelpers';

const translationKey = Symbol('TranslationKey');
type KeyedTranslateWebtextFn = TranslateWebtextFn & { [translationKey]: string };

function isWebtextTranslateFn(value: unknown): value is TranslateWebtextFn {
  return typeof value === 'function' && webtextTypeMeta in value && webtextIdMeta in value;
}

function getWebtextFetchers(): KeyedTranslateWebtextFn[] {
  const fetchers: TranslateWebtextFn[] = [];

  for (const [key, translation] of Object.entries(getDefinedTranslations())) {
    if (isWebtextTranslateFn(translation)) {
      translation[translationKey] = key;
      fetchers.push(translation);
    }
  }

  // sort by webtextid
  fetchers.sort((a, b) => a[webtextIdMeta] - b[webtextIdMeta]);

  return fetchers as KeyedTranslateWebtextFn[];
}

function getWebtextFetchersByType(): Record<WebtextType, KeyedTranslateWebtextFn[]> {
  return {
    plain: [],
    html: [],
    ...arrayToObject(f => f[webtextTypeMeta], getWebtextFetchers())
  };
}

export function dumpWebtextsForRazor(): string {
  const fetchersByType = getWebtextFetchersByType();

  const toRazor = (fn: string, fetchers: KeyedTranslateWebtextFn[]) => {
    return fetchers.length > 0
      ? `${fn}(\n${fetchers
          .map(x => `    ${x[webtextIdMeta]} @* ${x[translationKey]} *@`)
          .join(',\n')}\n)`
      : '';
  };

  return [
    toRazor('@GetPlainWebtextScripts', fetchersByType.plain),
    toRazor('@GetHtmlWebtextScripts', fetchersByType.html)
  ]
    .filter(Boolean)
    .join('\n\n');
}

export function dumpWebtextsForDev(): string {
  const fetchers = getWebtextFetchers();
  const prefixByType = { plain: 'T', html: 'H' };

  const results = fetchers.map(fetcher => {
    const webtextId = fetcher[webtextIdMeta];
    const scriptIdPrefix = prefixByType[fetcher[webtextTypeMeta]];
    const scriptId = `${scriptIdPrefix}_${webtextId}`;

    return `<script id="${scriptId}" type="text/webtext">
    ${fetcher(fetcher[translationKey])}
</script>`;
  });

  return results.join('\n\n');
}

window.$dumpWebtexts = target => {
  switch (target) {
    case 'razor':
      return dumpWebtextsForRazor();
    case 'dev':
      return dumpWebtextsForDev();
    default:
      return undefined;
  }
};

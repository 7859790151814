import { components, observable, PureComputed, pureComputed } from 'knockout';
import { BaseComponentViewModel } from '../../base-component';
import { ComponentDependencies, NotificationQueue, Notification } from '../../../interfaces';

export interface WithNotificationsParams extends components.ViewModelParams {
  filter?: (notification: Notification) => boolean;
  limit?: number;
}

type ClearableNotification = Notification & { clear: () => void };

export class WithNotificationsViewModel extends BaseComponentViewModel {
  public notifications$: PureComputed<ClearableNotification[]>;

  public constructor(deps: ComponentDependencies, params?: WithNotificationsParams) {
    super(deps);

    const notificationQueue$ = observable<NotificationQueue>([]);

    this.notifications$ = pureComputed(() => {
      let queue = notificationQueue$();

      if (params?.filter) {
        queue = queue.filter(params.filter);
      }

      if (params?.limit) {
        queue = queue.slice(params.limit);
      }

      return queue.map<ClearableNotification>(notification => ({
        ...notification,
        clear: () => deps.notifications.clearNotification(notification)
      }));
    });

    this.bindObservableToStore(notificationQueue$, '$.notifications');
  }
}

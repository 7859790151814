import { StageIdentifier } from '../interfaces/ActiveStageState';
import { RouteName } from '../interfaces/Routes';
import { AppEvent } from '../services';
import { NavigationDirection } from '../services/StageNavigationService';

export class StageNavigationEvent implements AppEvent {
  public static readonly EVENT_NAME = 'stage_navigation';
  public readonly eventName = StageNavigationEvent.EVENT_NAME;

  public readonly activeRoute: RouteName | undefined;
  public readonly targetRoute: RouteName;
  public readonly direction: NavigationDirection;

  public constructor(args: {
    activeRoute: RouteName | undefined;
    targetRoute: RouteName;
    direction: NavigationDirection;
  }) {
    this.activeRoute = args.activeRoute;
    this.targetRoute = args.targetRoute;
    this.direction = args.direction;
  }

  /** @deprecated Use activeRoute instead */
  public get activeStage(): StageIdentifier | undefined {
    return this.activeRoute;
  }

  /** @deprecated Use targetRoute instead */
  public get targetStage(): StageIdentifier {
    return this.targetRoute;
  }
}

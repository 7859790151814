import i18next, { i18n } from 'i18next';
import {
  Computed,
  isWritableObservable,
  Observable,
  ObservableArray,
  pureComputed,
  PureComputed,
  Subscribable,
  Subscription,
  validatedObservable,
  validation,
  isSubscribable
} from 'knockout';
import { v4 as uuidv4 } from 'uuid';
import { BeforeStageNavigationEvent, BeforeOrderSubmittedEvent } from '../events';
import { DisposalFunction } from '../helpers/StoreDataBindingHelper';
import { ComponentDependencies } from '../interfaces';
import { NotificationService } from '../services';
import { filterObject } from '../helpers/objectHelpers';
import { CREATED_BY_STORESELECTOR } from '../services/StoreSelectors';

export class BaseWidgetViewModel {
  private uuid = uuidv4();
  protected readonly notifications: NotificationService;

  public get id(): string {
    return this.uuid;
  }

  public readonly i18next: i18n = i18next;

  private _isValid$!: PureComputed<boolean>;

  protected disposalFunctions: DisposalFunction[] = [];
  protected subscriptions: Subscription[] = [];
  protected timeouts: number[] = [];

  constructor(private readonly deps: ComponentDependencies) {
    deps.appEventManager.on(BeforeStageNavigationEvent, () => this.showAllValidationMessages());
    deps.appEventManager.on(BeforeOrderSubmittedEvent, () => this.showAllValidationMessages());
    this.notifications = deps.notifications;
  }

  /*
   * Sync two observables.
   * Initially, both observables will be set to the current value of obs1$
   */
  protected syncObservables<T>(obs1$: Subscribable<T>, obs2$: Subscribable<T>): void {
    // Sync obs1$ changes into obs2$ (including initial value)
    if (isWritableObservable(obs2$)) {
      obs2$(obs1$());
      this.subscriptions.push(obs1$.subscribe(obs2$));
    }

    // Sync obs2$ changes back into obs1$
    if (isWritableObservable(obs1$)) {
      this.subscriptions.push(obs2$.subscribe(obs1$));
    }
  }

  protected get isValid$(): PureComputed<boolean> {
    if (!this._isValid$) {
      this._isValid$ = this.createIsValidObservable();
    }
    return this._isValid$;
  }

  private validatableProperties(): Partial<this> {
    return filterObject(
      this,
      (key, value) => isSubscribable(value) && !value[CREATED_BY_STORESELECTOR]
    );
  }

  protected showAllValidationMessages(): void {
    const group = validation.group(this.validatableProperties(), { deep: true });
    group.showAllMessages(true);
  }

  private createIsValidObservable(): PureComputed<boolean> {
    const propertiesToValidate = this.validatableProperties();

    const validatedViewModel$: validation.ValidationObservable<this> = validatedObservable(
      propertiesToValidate
    ) as unknown as validation.ValidationObservable<this>;

    const validationGroup: validation.ValidationGroup = validation.group(propertiesToValidate, {
      deep: true
    });

    const asyncValidatedObservables: ((Observable<any> | ObservableArray<any> | Computed<any>) &
      Partial<validation.ObservableValidationExtension>)[] = validationGroup.filter(
      (
        obs: (Observable<any> | ObservableArray<any> | Computed<any>) &
          Partial<validation.ObservableValidationExtension>
      ) => {
        if (obs.rules) {
          return Boolean(
            obs.rules().find(rule => {
              return (
                (
                  rule as Partial<validation.ValidationAsyncRuleDefinition> &
                    validation.ValidationRule
                ).async ?? false
              );
            })
          );
        }

        return false;
      }
    );

    const isValid$ = pureComputed(() => {
      const isValid = validatedViewModel$.isValid();

      const isValidating = Boolean(asyncValidatedObservables.find(obs => obs.isValidating?.()));

      return Boolean(isValid && !isValidating);
    });

    this.disposalFunctions.push(() => isValid$.dispose());

    return isValid$;
  }

  public dispose(): void {
    this.subscriptions.forEach(subscription => {
      subscription.dispose();
    });

    this.disposalFunctions.forEach(func => {
      func();
    });

    this.timeouts.forEach(timeoutId => {
      window.clearTimeout(timeoutId);
    });
  }
}

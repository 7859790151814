import { readPendingOrder } from '../../../helpers';
import { AppStore } from '../../../store';
import {
  processOrderItemMutation,
  ProcessOrderItemMutationAction
} from '../../../store/order/actions';

export class SingleSkuRestriction {
  constructor(private store: AppStore) {}

  public enforceSingleSku(skuToKeep?: string): ProcessOrderItemMutationAction[] {
    const singleSku = this.store.getState().user.restrictions.rules.singleSku ?? false;
    const skusInOrder = Object.keys(readPendingOrder(this.store.getState().order).items);

    if (!singleSku) {
      return []; // nothing to do
    }

    // if not specified explicitly, just keep the first sku in the order
    skuToKeep = skuToKeep ?? (skusInOrder.length ? skusInOrder[0] : undefined);

    return skusInOrder.filter(sku => sku !== skuToKeep).map(sku => this.removeSkuFromOrder(sku));
  }

  private removeSkuFromOrder(sku: string): ProcessOrderItemMutationAction {
    return processOrderItemMutation({
      sku,
      quantity: 0,
      operation: 'SET'
    });
  }
}

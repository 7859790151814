import { ComponentConfig } from '../../../interfaces';

import template from './template.pug';
import { ContactAddressViewModel } from './ContactAddressViewModel';

const config: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new ContactAddressViewModel(deps, params)
  }
};

export default config;

import { observable } from 'knockout';
import { Action } from 'redux';
import { ComponentDependencies } from '../../../interfaces';
import { setAdditionalContactEmail } from '../../../store/additionalContacts/actions';
import { EmailTextInputViewModelParams } from '../../../ui_widgets/email-text-input/EmailTextInputViewModel';
import { BaseComponentViewModel } from '../../base-component';

export interface AdditionalContactEmailViewModelParams
  extends Omit<EmailTextInputViewModelParams, 'value$'> {
  additionalContactRef?: string;
}

export class AdditionalContactEmailViewModel extends BaseComponentViewModel {
  readonly value$ = observable(null);
  readonly widgetParams: EmailTextInputViewModelParams;

  constructor(deps: ComponentDependencies, params?: AdditionalContactEmailViewModelParams) {
    super(deps);

    const readOnly = params?.readOnly ?? false;

    this.widgetParams = {
      ...params,
      value$: this.value$
    };

    const additionalContactRef = params?.additionalContactRef;
    const storeDataJsonPath = `$.additionalContacts['${additionalContactRef}'].emailAddress`;
    const storeActionCreator: (email: string | null) => Action<unknown> = emailAddress =>
      setAdditionalContactEmail(additionalContactRef as string, emailAddress);

    if (readOnly) {
      this.bindObservableToStore(this.value$, storeDataJsonPath);
    } else {
      this.initializeStateUpdates();

      this.bindObservableToStore(this.value$, storeDataJsonPath, storeActionCreator);
    }
  }
}

import { OptIn, OptInSource } from '../../../../../interfaces';
import { State } from '../../../../../store/reducers';
import { AllowedEvent, AllowedEventText, AnyContact, OptInOrOutChoice } from '../../interfaces';
import { EVENT_TYPE_OPT_IN } from '../../interfaces/AllowedEvent';
import { OPT_IN_TEXT_CODE } from '../../interfaces/AllowedEventText';
import { indexArrayByKey } from './utils';

/*
  Multiple ways of potential opt-in marking in iZZi are supported (in order of priority):

  1. Using EventType
     - Event Type (ArrangementType in iZZi-Portal) is set to OptIn for marking
     - Event Code (ArrangementCode) is using to set the opt-in type (optional).

  2. Using custom event texts
     - A custom text is added to the event, where text type is OptIn
     - The text value is used to set the opt-in type (optional).

  3. Defined optIns in optInOrOutChoices
*/

export const GENERIC_OPT_IN_TYPE = 'Generic';

export function optInsStateTranslator(config: AnyContact): State['optIns'] {
  const optInsFromAllowedEvents =
    config.AllowedEvents?.filter(allowedEventIsOptIn).map(translateSingleOptInFromAllowedEvent) ??
    [];

  const optInsFromChoices: OptIn[] =
    config.OptInOrOutChoices?.map(translateSingleOptInFromChoice) ?? [];

  return indexArrayByKey([...optInsFromAllowedEvents, ...optInsFromChoices], 'optInId');
}

function translateSingleOptInFromAllowedEvent(allowedEvent: AllowedEvent): OptIn {
  const optIn: OptIn = {
    optInId: String(allowedEvent.IDCampagneEvent),
    optInType: getOptInTypeFromEvent(allowedEvent) as string,
    title: allowedEvent.DescriptionUsingTemplate
      ? allowedEvent.DescriptionUsingTemplate
      : allowedEvent.Description,
    sequenceNumber: allowedEvent.EventNumber,
    content: {
      texts: {},
      customTexts: {}
    },
    mustOptIn: false,
    source: OptInSource.FROM_ALLOWED_EVENT
  };

  if (allowedEvent.Texts) {
    for (const item of allowedEvent.Texts) {
      if (allowedEventTextIsOptIn(item)) {
        optIn.content.customTexts[item.Code] = item.Text;
      }
    }
  }

  if (allowedEvent.LongDescription) {
    optIn.content.texts.listing_description = allowedEvent.LongDescription;
  }

  if (allowedEvent.ContentDescription) {
    optIn.content.texts.long_description = allowedEvent.ContentDescription;
  }

  return optIn;
}

function translateSingleOptInFromChoice(
  optInChoice: OptInOrOutChoice,
  sequenceNumber: number
): OptIn {
  const optIn: OptIn = {
    optInId: optInChoice.Code,
    optInType: optInChoice.Code,
    title: optInChoice.Description,
    sequenceNumber,
    content: {
      texts: {},
      customTexts: {}
    },
    mustOptIn: optInChoice.MustOptIn,
    source: OptInSource.FROM_CHOICE
  };

  if (optInChoice.Explanation) {
    optIn.content.texts.listing_description = optInChoice.Explanation;
  }

  return optIn;
}

export function allowedEventIsOptIn(allowedEvent: AnyContact['AllowedEvents'][0]): boolean {
  // 1. When defined using EventType
  if (allowedEvent.EventType === EVENT_TYPE_OPT_IN) {
    return true;
  }

  // 2. When defined using TextType
  if (allowedEvent?.Texts?.findIndex(allowedEventTextIsOptIn) >= 0) {
    return true;
  }

  // not an opt-in
  return false;
}

function getOptInTypeFromEvent(allowedEvent: AllowedEvent): string | undefined {
  // 1. When defined using EventType
  if (allowedEvent.EventType === EVENT_TYPE_OPT_IN) {
    return allowedEvent.Code || GENERIC_OPT_IN_TYPE;
  }

  // 2. When defined using TextType
  const optInTextMarker = allowedEvent?.Texts?.find(allowedEventTextIsOptIn);
  if (optInTextMarker) {
    return optInTextMarker.Text || GENERIC_OPT_IN_TYPE;
  }

  // not an opt-in
  return undefined;
}

function allowedEventTextIsOptIn(text: AllowedEventText): boolean {
  return text.Code.toUpperCase() === OPT_IN_TEXT_CODE.toUpperCase();
}

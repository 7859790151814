import { User } from '../interfaces';
import { State } from '../store';

type TokenType = keyof User['restrictions']['amounts']['tokens'];

export function tokenValidator(state: State): boolean {
  const tokenRestrictions = state.user.restrictions.amounts.tokens;
  const tokensUsed = calculateTokensUsed(state);

  for (const [tokenType, maxAmount] of Object.entries(tokenRestrictions)) {
    const usedAmount = tokensUsed[tokenType] ?? 0;
    if (usedAmount > maxAmount) {
      return false;
    }
  }

  return true;
}

function calculateTokensUsed(state: State) {
  const tokensUsed: Partial<Record<TokenType, number>> = {};
  for (const orderItem of Object.values(state.order.items)) {
    for (const [tokenType, amountPerItem] of Object.entries(
      state.products[orderItem.sku].amounts.tokens
    )) {
      tokensUsed[tokenType] ??= 0;
      tokensUsed[tokenType] += amountPerItem * orderItem.quantity;
    }
  }
  return tokensUsed;
}

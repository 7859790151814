import { components, observable, Observable } from 'knockout';
import { ComponentDependencies } from '../../interfaces';
import { BaseWidgetViewModel } from '../BaseWidgetViewModel';

export interface TextInputViewModelParams extends components.ViewModelParams {
  value$?: Observable<string | null>;
  readOnly?: boolean;
  type?: 'text' | 'email' | 'tel';
  required?: boolean;
  texts?: {
    label?: string;
    placeholder?: string;
  };
}

export class TextInputViewModel extends BaseWidgetViewModel {
  public readonly readOnly: boolean;
  public readonly type: 'text' | 'email' | 'tel';
  public readonly required: boolean;

  public readonly texts: {
    label: string;
    placeholder: string;
  };

  public readonly value$: Observable<string | null>;

  constructor(deps: ComponentDependencies, params?: TextInputViewModelParams) {
    super(deps);
    this.value$ = params?.value$ ?? observable(null);

    this.readOnly = typeof params?.readOnly === 'boolean' ? params.readOnly : false;

    this.type = params?.type || 'text';
    this.required = typeof params?.required === 'boolean' ? params.required : false;

    this.texts = {
      label: '',
      placeholder: params?.texts?.label ?? '', // by default, use label as placeholder
      ...params?.texts
    };
  }
}

import { ThemeConfig, ThemeConfigNormalizer } from '../ThemeConfig';
import { ThemeConfigProviderInterface } from './ThemeConfigProviderInterface';
import { trimToJson } from '../../../helpers/StringFormatHelper';

export class HtmlDataThemeConfigProvider<T extends ThemeConfig>
  implements ThemeConfigProviderInterface<T>
{
  constructor(private normalizer?: ThemeConfigNormalizer<T>) {}

  public provideThemeConfig(): Partial<T> {
    const [htmlEl] = document.getElementsByTagName('html');

    let themeConfig: Partial<T> = {};

    if (htmlEl.dataset.themeConfig) {
      const themeConfigStr = htmlEl.dataset.themeConfig.trim();
      const themeConfigJsonStr = trimToJson(themeConfigStr);
      try {
        themeConfig = JSON.parse(themeConfigJsonStr);
      } catch {
        console.warn('Theme config could not be parsed.');
      }
    }

    return this.normalizer ? this.normalizer?.normalize(themeConfig) : themeConfig;
  }
}

import { Action } from 'redux';

import { Contact, GenderIdentifiers } from '../../interfaces';
import { ContactIdentifier } from './reducers';

export const SET_CONTACT_IDENTIFIER = 'SET_CONTACT_IDENTIFIER',
  DELETE_CONTACT_IDENTIFIER = 'DELETE_CONTACT_IDENTIFIER',
  SET_EMAIL = 'SET_EMAIL',
  SET_EMAIL_CONFIRMATION = 'SET_EMAIL_CONFIRMATION',
  SET_PHONE = 'SET_PHONE',
  SET_MOBILE_PHONE = 'SET_MOBILE_PHONE',
  SET_GENDER = 'SET_GENDER',
  SET_NAME = 'SET_NAME',
  SET_ADDRESS = 'SET_ADDRESS',
  SET_ORGANIZATION = 'SET_ORGANIZATION',
  DELETE_ORGANIZATION = 'DELETE_ORGANIZATION',
  SET_BIRTH_DATE = 'SET_BIRTH_DATE',
  SET_IS_TEST = 'SET_IS_TEST';

export interface SetContactIdentifierAction extends Action<typeof SET_CONTACT_IDENTIFIER> {
  type: typeof SET_CONTACT_IDENTIFIER;
  payload: ContactIdentifier;
}

export interface DeleteContactIdentifierAction extends Action<typeof DELETE_CONTACT_IDENTIFIER> {
  type: typeof DELETE_CONTACT_IDENTIFIER;
  meta: { key: keyof Contact['identifiers'] };
}

export interface SetEmailAction extends Action<typeof SET_EMAIL> {
  type: typeof SET_EMAIL;
  payload: Contact['emailAddress'];
}

export interface SetEmailConfirmationAction extends Action<typeof SET_EMAIL_CONFIRMATION> {
  type: typeof SET_EMAIL_CONFIRMATION;
  payload: Contact['emailConfirmation'];
}

export interface SetPhoneAction extends Action<typeof SET_PHONE> {
  type: typeof SET_PHONE;
  payload: Contact['phoneNumber'];
}

export interface SetMobilePhoneAction extends Action<typeof SET_MOBILE_PHONE> {
  type: typeof SET_MOBILE_PHONE;
  payload: Contact['phoneNumberMobile'];
}

export interface SetNameAction extends Action<typeof SET_NAME> {
  type: typeof SET_NAME;
  payload: Contact['name'];
}

export interface SetGenderAction extends Action<typeof SET_GENDER> {
  type: typeof SET_GENDER;
  payload: GenderIdentifiers;
}

export interface SetAddressAction extends Action<typeof SET_ADDRESS> {
  type: typeof SET_ADDRESS;
  payload: Contact['address'];
}

export interface SetOrganizationAction extends Action<typeof SET_ORGANIZATION> {
  type: typeof SET_ORGANIZATION;
  payload: Contact['organization'];
}

export interface DeleteOrganizationAction extends Action<typeof DELETE_ORGANIZATION> {
  type: typeof DELETE_ORGANIZATION;
}

export interface SetBirthDateAction extends Action<typeof SET_BIRTH_DATE> {
  type: typeof SET_BIRTH_DATE;
  payload: Contact['birthDate'];
}

export interface SetIsTestAction extends Action<typeof SET_IS_TEST> {
  type: typeof SET_IS_TEST;
  payload: Contact['isTest'];
}

export type ContactActionTypes =
  | SetContactIdentifierAction
  | DeleteContactIdentifierAction
  | SetEmailAction
  | SetEmailConfirmationAction
  | SetPhoneAction
  | SetMobilePhoneAction
  | SetNameAction
  | SetGenderAction
  | SetAddressAction
  | SetOrganizationAction
  | DeleteOrganizationAction
  | SetBirthDateAction
  | SetIsTestAction;

export function setContactIdentifier(identifier: ContactIdentifier): SetContactIdentifierAction {
  return {
    type: SET_CONTACT_IDENTIFIER,
    payload: identifier
  };
}

export function deleteContactIdentifier(
  key: keyof Contact['identifiers']
): DeleteContactIdentifierAction {
  return {
    type: DELETE_CONTACT_IDENTIFIER,
    meta: { key }
  };
}

export function setEmail(emailAddress: Contact['emailAddress']): SetEmailAction {
  return {
    type: SET_EMAIL,
    payload: emailAddress === '' ? null : emailAddress
  };
}

export function setEmailConfirmation(
  emailConfirmation: Contact['emailConfirmation']
): SetEmailConfirmationAction {
  return {
    type: SET_EMAIL_CONFIRMATION,
    payload: emailConfirmation === '' ? null : emailConfirmation
  };
}

export function setPhone(phoneNumber: Contact['phoneNumber']): SetPhoneAction {
  return {
    type: SET_PHONE,
    payload: phoneNumber === '' ? null : phoneNumber
  };
}

export function setMobilePhone(
  phoneNumberMobile: Contact['phoneNumberMobile']
): SetMobilePhoneAction {
  return {
    type: SET_MOBILE_PHONE,
    payload: phoneNumberMobile === '' ? null : phoneNumberMobile
  };
}

export function setName(name: Contact['name']): SetNameAction {
  return {
    type: SET_NAME,
    payload: name
  };
}

export function setGender(gender: GenderIdentifiers): SetGenderAction {
  return {
    type: SET_GENDER,
    payload: gender
  };
}

export function setAddress(address: Contact['address']): SetAddressAction {
  return {
    type: SET_ADDRESS,
    payload: address
  };
}

export function setOrganization(organization: Contact['organization']): SetOrganizationAction {
  return {
    type: SET_ORGANIZATION,
    payload: organization
  };
}

export function deleteOrganization(): DeleteOrganizationAction {
  return {
    type: DELETE_ORGANIZATION
  };
}

export function setBirthDate(birthDate: Contact['birthDate']): SetBirthDateAction {
  return {
    type: SET_BIRTH_DATE,
    payload: birthDate
  };
}

export function setIsTest(isTest: Contact['isTest']): SetIsTestAction {
  return {
    type: SET_IS_TEST,
    payload: isTest
  };
}

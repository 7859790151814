import { Product } from '../interfaces';

export type ProductCompareFn = (a: Product, b: Product) => number;
export type ProductSorting = 'sequenceNumber' | 'title' | ProductCompareFn;

export function createProductCompareFn(sorting?: ProductSorting): ProductCompareFn {
  sorting = sorting || 'sequenceNumber';

  if (typeof sorting === 'function') {
    // if a function is given, always use that
    return sorting;
  }

  if (sorting === 'title') {
    // default implementation for sorting by title
    return (a, b) => a.title.localeCompare(b.title);
  }

  // in all other cases, fall back to the default (sequenceNumber)
  return (a: Product, b: Product) => a.sequenceNumber - b.sequenceNumber;
}

import { ComponentConfig } from '../../interfaces';

import template from './template.pug';
import { ElementClassToggleViewModel } from './ElementClassToggleViewModel';

const config: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new ElementClassToggleViewModel(deps, params)
  }
};

export default config;

import { components, observable, Observable, pureComputed, PureComputed } from 'knockout';
import { ActiveStageState, ComponentDependencies, ComponentState } from '../../interfaces';
import { RouteName, Routes } from '../../interfaces/Routes';
import { StageNavigationService } from '../../services';
import { NavigationDirection } from '../../services/StageNavigationService';

import { BaseComponentViewModel } from '../base-component';

export interface NavigationButtonViewModelParams extends components.ViewModelParams {
  context?: any;
  targetStage?: RouteName | ActiveStageState; // ActiveStageState is deprecated, use RouteName
}

export class NavigationButtonViewModel extends BaseComponentViewModel {
  public readonly context: any;
  public readonly mode: string;
  public readonly targetRouteName: RouteName;

  public readonly activeStage$: Observable<ActiveStageState | undefined> = observable();
  public readonly routes$ = observable<Routes | undefined>([]);
  public readonly componentStates$ = observable<{ [key: string]: ComponentState } | undefined>({});
  public readonly canNavigate$: PureComputed<boolean>;
  public readonly direction$: PureComputed<NavigationDirection>;

  private stageNavigation: StageNavigationService;

  constructor(deps: ComponentDependencies, params?: NavigationButtonViewModelParams) {
    super(deps);
    this.stageNavigation = deps.stageNavigation;

    if (!params?.targetStage) {
      throw new Error('targetStage is a required parameter in NavigationButton component.');
    }

    this.context = params?.context;
    this.mode = params?.mode ?? 'button';

    this.targetRouteName =
      typeof params?.targetStage === 'object'
        ? params.targetStage.stageIdentifier
        : (params.targetStage as RouteName);

    this.bindObservableToStore(this.componentStates$, '$.app.componentStates');
    this.bindObservableToStore(this.activeStage$, '$.app.activeStage');
    this.bindObservableToStore(this.routes$, '$.app.routes');

    this.canNavigate$ = pureComputed(() => {
      // Make sure this computed observable is updated when dependencies change.
      void this.routes$();
      void this.activeStage$();
      void this.componentStates$();

      return this.stageNavigation.canNavigate(this.targetRouteName);
    });

    this.direction$ = pureComputed(() => {
      // Make sure this computed observable is updated when dependencies change.
      void this.routes$();
      void this.activeStage$();

      return this.stageNavigation.direction(this.targetRouteName);
    });
  }

  navigate: () => void = () => {
    this.stageNavigation.navigate(this.targetRouteName);
  };
}

(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("redux"), require("jsonpath"), require("qs"), require("knockout"), require("knockout.validation"));
	else if(typeof define === 'function' && define.amd)
		define("SidekixFlow", ["redux", "jsonpath", "qs", "knockout", "knockout.validation"], factory);
	else if(typeof exports === 'object')
		exports["SidekixFlow"] = factory(require("redux"), require("jsonpath"), require("qs"), require("knockout"), require("knockout.validation"));
	else
		root["SidekixFlow"] = factory(root["Redux"], root["jsonpath"], root["Qs"], root["ko"], root["ko"]["validation"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__79995__, __WEBPACK_EXTERNAL_MODULE__59954__, __WEBPACK_EXTERNAL_MODULE__38690__, __WEBPACK_EXTERNAL_MODULE__11287__, __WEBPACK_EXTERNAL_MODULE__84225__) {
return 
import { Action } from 'redux';

import { Product } from '../../interfaces';
import { ProductsState } from './reducers';

export const SET_PRODUCTS = 'SET_PRODUCTS',
  ADD_PRODUCT = 'ADD_PRODUCT',
  DELETE_PRODUCTS = 'DELETE_PRODUCTS';

export interface SetProductsAction extends Action<typeof SET_PRODUCTS> {
  type: typeof SET_PRODUCTS;
  payload: ProductsState;
}

export interface AddProductAction extends Action<typeof ADD_PRODUCT> {
  type: typeof ADD_PRODUCT;
  payload: Product;
}

export interface DeleteProductsAction extends Action<typeof DELETE_PRODUCTS> {
  type: typeof DELETE_PRODUCTS;
  payload: { skus: ReadonlyArray<string> };
}

export type ProductsActionTypes = SetProductsAction | AddProductAction | DeleteProductsAction;

export function setProducts(products: ProductsState): SetProductsAction {
  return { type: SET_PRODUCTS, payload: products };
}

export function addProduct(product: Product): AddProductAction {
  return {
    type: ADD_PRODUCT,
    payload: product
  };
}

export function deleteProducts(skus: ReadonlyArray<string>): DeleteProductsAction {
  return {
    type: DELETE_PRODUCTS,
    payload: { skus }
  };
}

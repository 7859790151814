import template from './RemoveFromCartButton.html';
import { ComponentConfig } from '../../interfaces';
import { RemoveFromCartButtonViewModel } from './RemoveFromCartButtonViewModel';

export const config: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new RemoveFromCartButtonViewModel(deps, params)
  }
};

export default config;

import { ComponentConfig } from '../../../interfaces';

import template from './template.pug';
import { ContactMetaDataViewModel } from './ContactMetaDataViewModel';

const config: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new ContactMetaDataViewModel(deps, params)
  }
};

export default config;

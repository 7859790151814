import { components, observable, Observable, computed, Computed } from 'knockout';

import { ComponentDependencies } from '../../interfaces';
import { BaseComponentViewModel } from '../base-component';
import { setVisibilityOptionState } from '../../store/app/actions';

export interface VisibilityToggleViewModelParams extends components.ViewModelParams {
  optionId?: string; // define the options in the group with an ID
  groupId?: string; // group multiple visibility toggles with an ID
  inputType?: 'radio' | 'checkbox';
  context?: any;
  texts?: {
    label?: string;
  };
}

export class VisibilityToggleViewModel extends BaseComponentViewModel {
  public readonly context: any;

  public readonly optionId?: string;
  public readonly groupId?: string;

  public readonly inputType: 'radio' | 'checkbox';

  public readonly texts: {
    label: string;
  };

  public readonly value$ = observable<'on' | false>(false);
  public readonly valueBoolean$: Computed<boolean>;

  constructor(deps: ComponentDependencies, params?: VisibilityToggleViewModelParams) {
    super(deps);

    this.context = params?.context;

    this.optionId = params?.optionId;
    this.groupId = params?.groupId;
    this.inputType = params?.inputType ?? 'checkbox';

    this.texts = {
      label: '',
      ...params?.texts
    };

    this.valueBoolean$ = computed({
      read: () => Boolean(this.value$()),
      write: value => this.value$(value ? 'on' : false)
    });

    this.disposalFunctions.push(() => {
      this.valueBoolean$.dispose();
    });

    if (this.optionId && this.groupId) {
      this.bindObservableToStore(
        this.valueBoolean$,
        `$.app.visibilityOptionGroups['${this.groupId}']['${this.optionId}']`,
        value => {
          return setVisibilityOptionState({
            optionId: this.optionId as string,
            groupId: this.groupId as string,
            checked: Boolean(value),
            exclusive: this.inputType === 'radio'
          });
        }
      );
    }
  }
}

export type TranslateFn = (src: string) => string;
export type TranslationMap = Record<string, TranslateFn | string>;

const translationMap: TranslationMap = {};

export function getDefinedTranslations(): TranslationMap {
  return { ...translationMap };
}

export function defineTranslations(map: TranslationMap) {
  Object.assign(translationMap, map);
}

export function translate(key: string): string {
  const text = translationMap[key];

  switch (typeof text) {
    case 'undefined':
      return `[${key}]`;
    case 'string':
      return text;
    case 'function':
      return String(text(key));
    default:
      return String(text);
  }
}

window.tr = translate;

import { BaseComponentViewModel } from '../../base-component';
import { components, observable, Observable, pureComputed, PureComputed } from 'knockout';
import { ComponentDependencies, Order } from '../../../interfaces';
import { CartService } from '../../../services';

export type IfCartEmptyViewModelParams = components.ViewModelParams;

export class IfCartEmptyViewModel extends BaseComponentViewModel {
  public readonly hasOrderItems$: PureComputed<boolean>;
  public readonly order$: Observable<Order | undefined> = observable();

  private cart: CartService;

  public constructor(deps: ComponentDependencies, params?: IfCartEmptyViewModelParams) {
    super(deps);
    this.cart = deps.cart;

    this.bindObservableToStore(this.order$, '$.order');

    this.hasOrderItems$ = pureComputed(() => {
      const orderItems = this.order$()?.items ?? {};

      return Object.keys(orderItems).length > 0;
    });
  }
}

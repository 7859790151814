import { PaymentMethod, AnyContact } from '../../interfaces';
import { State } from '../../../../../store/reducers';
import { PaymentOption } from '../../../../../interfaces';

export function paymentStateTranslator(config: AnyContact): State['payment'] {
  const paymentState: State['payment'] = { paymentOptions: [] };

  if (
    Array.isArray(config.Campagne?.AvaiblePaymentMethods) &&
    config.Campagne?.AvaiblePaymentMethods.length
  ) {
    const normalizedPaymentMethodCodes: Set<string> = new Set();

    config.Campagne.AvaiblePaymentMethods.forEach((paymentMethod: PaymentMethod) => {
      if (
        !paymentMethod.Code ||
        normalizedPaymentMethodCodes.has(paymentMethod.Code.toLowerCase())
      ) {
        return;
      }

      const paymentOption: PaymentOption = {
        code: paymentMethod.Code
      };

      paymentState.paymentOptions.push(paymentOption);

      normalizedPaymentMethodCodes.add(paymentMethod.Code.toLowerCase());
    });
  }

  return paymentState;
}

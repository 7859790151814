import {
  components,
  observable,
  Observable,
  pureComputed,
  PureComputed,
  validatedObservable,
  validation
} from 'knockout';
import i18next from 'i18next';
import { isValidPostalCodeFormat } from '../../helpers';

import { Address, ComponentDependencies, CountryCode, StoreBackendService } from '../../interfaces';
import { BaseWidgetViewModel } from '../BaseWidgetViewModel';

export interface AddressInputViewModelParams extends components.ViewModelParams {
  readOnly?: boolean;
  forcePrefilledAddress?: boolean;
  allowedCountryCodes?: CountryCode[];
  texts?: {
    postalCodeLabel?: string;
    houseNumberLabel?: string;
    houseNumberExtensionLabel?: string;
    streetLabel?: string;
    cityLabel?: string;
    countryLabel?: string;
    invalidPostalCodeHouseNumberMessage?: string;
    invalidHouseNumberMessage?: string;
    invalidPostalCodeMessage?: string;
  };
}

export class AddressInputViewModel extends BaseWidgetViewModel {
  public readonly readOnly: boolean;
  public readonly forcePrefilledAddress: boolean;
  public readonly countryLabels: { [key: string]: string };
  public readonly allowedCountryCodes: CountryCode[];

  public readonly texts: {
    postalCodeLabel: string;
    houseNumberLabel: string;
    houseNumberExtensionLabel: string;
    streetLabel: string;
    cityLabel: string;
    countryLabel: string;
    invalidPostalCodeHouseNumberMessage: string;
    invalidHouseNumberMessage: string;
    invalidPostalCodeMessage: string;
  };

  public readonly value$: PureComputed<Address>;

  public readonly postalCodeHouseNumber$: PureComputed<string[]>;
  public readonly allowedCountries$: PureComputed<{ code: CountryCode; label: string }[]>;
  public readonly formattedValueLines$: PureComputed<string[]>;
  public readonly streetAndCityFieldsEnabled$: PureComputed<boolean>;

  public readonly houseNumberExtension$: Observable<string | undefined> = observable();
  public readonly houseNumber$: Observable<string> = observable('');
  public readonly street$: Observable<string> = observable('');
  public readonly postalCode$: Observable<string> = observable('');
  public readonly city$: Observable<string> = observable('');
  public readonly country$ = observable<CountryCode>('NL');

  public readonly addressPrefilled$: Observable<boolean> = observable(true);

  public readonly service: StoreBackendService;

  public readonly postalCodeHouseNumberValidationStatus$: Observable<
    | {
        postalCode: Observable<string>;
        houseNumber: Observable<string>;
        houseNumberExtension: Observable<string | undefined>;
      }
    | Record<string, never>
  > & {
    errors: validation.ValidationGroupComputed;
    isValid: Observable<boolean>;
  };

  constructor(deps: ComponentDependencies, params?: AddressInputViewModelParams) {
    super(deps);

    this.service = deps.service;
    this.readOnly = typeof params?.readOnly === 'boolean' ? params.readOnly : false;

    this.forcePrefilledAddress = params?.forcePrefilledAddress ?? true;

    this.countryLabels = i18next.t('countries', {
      defaultValue: {},
      returnObjects: true
    });

    if (!Array.isArray(params?.allowedCountryCodes)) {
      throw new Error('Param `allowedCountryCodes` must be an array');
    }

    this.allowedCountryCodes = params!.allowedCountryCodes;

    this.texts = {
      postalCodeLabel: i18next.t('components.contactAddress.postalCodeLabel', 'Postal code'),
      houseNumberLabel: i18next.t('components.contactAddress.houseNumberLabel', 'House number'),
      houseNumberExtensionLabel: i18next.t(
        'components.contactAddress.houseNumberExtensionLabel',
        'House number extension'
      ),
      streetLabel: i18next.t('components.contactAddress.streetLabel', 'Street'),
      cityLabel: i18next.t('components.contactAddress.cityLabel', 'City'),
      countryLabel: i18next.t('components.contactAddress.countryLabel', 'Country'),
      invalidPostalCodeHouseNumberMessage: i18next.t(
        'components.contactAddress.invalidPostalCodeHouseNumberMessage',
        'Enter a valid postal code and house number.'
      ),
      invalidHouseNumberMessage: i18next.t(
        'components.contactAddress.invalidHouseNumberMessage',
        'Enter a valid house number.'
      ),
      invalidPostalCodeMessage: i18next.t(
        'components.contactAddress.invalidPostalCodeMessage',
        'Enter a valid postal code.'
      ),
      ...params?.texts
    };

    this.value$ = pureComputed({
      read(): Address {
        return {
          houseNumberExtension: this.houseNumberExtension$() || undefined,
          houseNumber: this.houseNumber$(),
          street: this.street$(),
          postalCode: this.postalCode$().toUpperCase().replace(/[ ]/g, ''),
          city: this.city$(),
          'countryCodeISO3166-1': this.country$()
        };
      },
      write(value: Address) {
        this.houseNumberExtension$(value.houseNumberExtension);
        this.houseNumber$(value.houseNumber);
        this.street$(value.street);
        this.postalCode$(value.postalCode);
        this.city$(value.city);
        this.country$(value['countryCodeISO3166-1']);
      },
      owner: this
    });

    this.formattedValueLines$ = pureComputed(() => {
      const value = this.value$();

      const formattedValueLines = [
        `${value.street} ${value.houseNumber}${
          value.houseNumberExtension ? '-' + value.houseNumberExtension : ''
        }`,
        `${value.postalCode} ${value.city}`
      ];

      if (value['countryCodeISO3166-1']) {
        formattedValueLines.push(`${this.countryLabels[value['countryCodeISO3166-1']]}`);
      }

      return formattedValueLines;
    });

    this.allowedCountries$ = pureComputed(() => {
      const allowedCountryCodes: CountryCode[] = this.allowedCountryCodes;

      const allowedCountries = allowedCountryCodes.map(countryCode => ({
        code: countryCode,
        label: i18next.t(`countries.${countryCode}`, { defaultValue: null }) ?? countryCode
      }));

      allowedCountries.sort((a, b) =>
        a.label.localeCompare(b.label, undefined, { ignorePunctuation: true })
      );

      return allowedCountries;
    });

    this.postalCodeHouseNumber$ = pureComputed(() => {
      return [this.postalCode$(), this.houseNumber$()];
    }).extend({
      rateLimit: { timeout: 1000, method: 'notifyWhenChangesStop' }
    });

    this.streetAndCityFieldsEnabled$ = pureComputed(() => {
      if (!this.forcePrefilledAddress) {
        return true;
      }

      const isValid = this.postalCodeHouseNumberValidationStatus$.isValid();
      return isValid && !this.addressPrefilled$();
    });

    if (params?.value$) {
      this.syncObservables(params.value$, this.value$);
    }

    if (params?.isValid$) {
      this.syncObservables(this.isValid$, params.isValid$);
    }

    if (!this.readOnly) {
      this.initializeValidations();
    }

    if (this.readOnly) {
      this.postalCodeHouseNumberValidationStatus$ = validatedObservable(
        {},
        { live: false, observable: true }
      );
    } else {
      this.postalCodeHouseNumberValidationStatus$ = validatedObservable(
        {
          postalCode: this.postalCode$,
          houseNumber: this.houseNumber$,
          houseNumberExtension: this.houseNumberExtension$
        },
        { live: true, observable: true, deep: true }
      );

      this.initializeAddressLookup();
    }
  }

  protected initializeValidations(): void {
    this.houseNumberExtension$.extend({
      required: false,
      minLength: 1,
      maxLength: 10
    });

    this.houseNumber$.extend({
      required: true,
      digit: {
        params: true,
        message: this.texts.invalidHouseNumberMessage
      }
    });

    this.street$.extend({
      required: true
    });

    this.postalCode$.extend({
      required: true,
      validation: {
        validator: value => isValidPostalCodeFormat(value, this.country$()),
        message: this.texts.invalidPostalCodeMessage
      }
    });

    this.city$.extend({
      required: true
    });
  }

  initializeAddressLookup(): void {
    let abortController;

    this.subscriptions.push(
      this.postalCodeHouseNumber$.subscribe(() => {
        if (abortController) {
          abortController.abort();
        }

        const isValid = this.postalCodeHouseNumberValidationStatus$.isValid();
        if (!isValid) {
          return undefined;
        }

        const result = this.service.searchAddress(this.value$());

        if (!result) {
          return;
        }

        abortController = result.abortController;

        result.promise
          .then(resultAddress => {
            if (typeof resultAddress !== 'undefined') {
              this.value$(resultAddress);

              this.addressPrefilled$(true);
            } else {
              this.addressPrefilled$(false);
              this.value$({
                ...this.value$(),
                street: '',
                city: ''
              });
            }
          })
          .catch(err => {
            this.addressPrefilled$(false);
            console.error(err);
          })
          .finally(() => {
            abortController = undefined;
          });
      })
    );
  }

  public onBlur(this: Observable<never>): void {
    validation.group(this).showAllMessages(true);
  }
}

import { ComponentConfig } from '../../../interfaces';

import template from './template.pug';
import { CategoryViewModel } from './CategoryViewModel';

const config: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new CategoryViewModel(deps, params)
  }
};

export default config;

import { ComponentConfig } from '../../../interfaces';
import template from './template.html';
import { ProductDropdownViewModel } from './ProductDropdownViewModel';

const config: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new ProductDropdownViewModel(deps, params)
  }
};

export default config;

import { BaseComponentViewModel } from '../base-component';
import { components, pureComputed, PureComputed } from 'knockout';
import { ComponentDependencies } from '../../interfaces';
import { CartService } from '../../services';

export interface AddToCartButtonViewModelParams extends components.ViewModelParams {
  sku?: string;
  disabled?: boolean;
  label?: string;
  quantity?: number;
}

export class AddToCartButtonViewModel extends BaseComponentViewModel {
  private sku: string;
  private quantity: number;
  private cart: CartService;
  public readonly disabled$: PureComputed<boolean>;
  public label: string;

  public constructor(deps: ComponentDependencies, params?: AddToCartButtonViewModelParams) {
    super(deps);

    this.cart = deps.cart;
    if (!params?.sku) {
      throw new Error("'sku' parameter is required in add-to-cart-button component");
    }
    this.sku = params?.sku;
    this.label = params?.label ?? this.i18next.t('components.addToCartButton.label', 'Add to cart');
    this.quantity = params?.quantity ?? 1;

    this.disabled$ = pureComputed(() => params?.disabled || deps.selectors.orderIsPending$());
  }

  public addToCart() {
    this.cart.mutateOrderItem({
      sku: this.sku,
      quantity: this.quantity,
      operation: 'ADD'
    });
  }
}

import { State } from './reducers';

export const restoreState: (storageIdentifier: string) => State | undefined = storageIdentifier => {
  try {
    const serializedState = sessionStorage.getItem(storageIdentifier);

    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (err) {
    console.error(err);

    return undefined;
  }
};

export const persistState: (state: State, storageIdentifier: string) => boolean = (
  state,
  storageIdentifier
) => {
  try {
    const serializedState = JSON.stringify(state);

    sessionStorage.setItem(storageIdentifier, serializedState);

    return true;
  } catch (err) {
    console.error(err);

    return false;
  }
};

export const clearState: (storageIdentifier: string) => boolean = storageIdentifier => {
  try {
    sessionStorage.removeItem(storageIdentifier);

    return true;
  } catch (err) {
    console.error(err);

    return false;
  }
};

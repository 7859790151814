import template from './RouterOutlet.html';
import { ComponentConfig } from '../../interfaces';
import { RouterOutletViewModel } from './RouterOutletAppViewModel';

export const routerOutletComponent: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params, componentInfo) =>
      new RouterOutletViewModel(deps, params, componentInfo)
  }
};

/* eslint-disable no-use-before-define */
interface OkResult<TOk, TErr> {
  isOk: true;
  value: TOk;
  mapValue<TTo>(fn: (value: TOk) => TTo): Result<TTo, TErr>;
  mapErr<TTo>(fn: (value: TErr) => TTo): Result<TOk, TTo>;
}

interface ErrResult<TOk, TErr> {
  isOk: false;
  error: TErr;
  mapValue<TTo>(fn: (value: TOk) => TTo): ErrResult<TTo, TErr>;
  mapErr<TTo>(fn: (value: TErr) => TTo): Result<TOk, TTo>;
}

export type Result<TOk, TErr = unknown> = OkResult<TOk, TErr> | ErrResult<TOk, TErr>;

class OkResultImpl<T> implements OkResult<T, never> {
  public constructor(private _value: T) {}

  public get isOk(): true {
    return true;
  }

  public get value(): T {
    return this._value;
  }

  public get error(): never {
    throw new Error('Cannot access error of non-error result.');
  }

  public mapValue<TTo>(fn: (value: T) => TTo): OkResult<TTo, never> {
    return Ok(fn(this._value));
  }

  public mapErr(): OkResult<T, never> {
    return this;
  }
}

class ErrResultImpl<T> implements ErrResult<never, T> {
  public constructor(private _error: T) {}

  public get isOk(): false {
    return false;
  }

  public get value(): never {
    throw new Error(String(this._error));
  }

  public get error(): T {
    return this._error;
  }

  public mapValue(): ErrResult<never, T> {
    return this;
  }

  public mapErr<TTo>(fn: (error: T) => TTo): ErrResult<never, TTo> {
    return Err(fn(this._error));
  }
}

export function Ok<T>(value: T): OkResult<T, never> {
  return new OkResultImpl(value);
}

export function Err<T>(err: T): ErrResult<never, T> {
  return new ErrResultImpl(err);
}

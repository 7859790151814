import i18next from 'i18next';
import { ComponentDependencies } from '../../interfaces';
import { TextInputViewModel, TextInputViewModelParams } from '../text-input';

export type EmailTextInputViewModelParams = Omit<TextInputViewModelParams, 'type'>;

export class EmailTextInputViewModel extends TextInputViewModel {
  constructor(deps: ComponentDependencies, params?: EmailTextInputViewModelParams) {
    super(deps, {
      ...params,
      type: 'email',
      required: typeof params?.required !== 'undefined' ? Boolean(params.required) : true,
      texts: {
        label: i18next.t('components.emailTextInput.label', 'E-mail'),
        ...params?.texts
      }
    });

    this.value$.extend({
      required: this.required,
      email: true,
      minLength: 1
    });
  }
}

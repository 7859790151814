import { TranslateFn } from './translate';

export const webtextTypeMeta = Symbol('webtextType');
export const webtextIdMeta = Symbol('webtextId');

export type WebtextType = 'html' | 'plain';

export type TranslateWebtextFn = TranslateFn & {
  [webtextTypeMeta]: WebtextType;
  [webtextIdMeta]: number;
};

const importedWebtexts = new Map<string, string>();
for (const el of document.querySelectorAll('script[id][type="text/webtext"]')) {
  importedWebtexts.set(el.id, el.innerHTML.trim());
  el.remove();
}

export const htmlWebtext = (id: number): TranslateWebtextFn => {
  const fetcher = (key =>
    importedWebtexts.get('H_' + id) ?? `[${key}:H(${id})]`) as TranslateWebtextFn;
  fetcher[webtextTypeMeta] = 'html';
  fetcher[webtextIdMeta] = id;
  return fetcher;
};

export const plainWebtext = (id: number): TranslateWebtextFn => {
  const fetcher = (key =>
    importedWebtexts.get('T_' + id) ?? `[${key}:T(${id})]`) as TranslateWebtextFn;
  fetcher[webtextTypeMeta] = 'plain';
  fetcher[webtextIdMeta] = id;
  return fetcher;
};

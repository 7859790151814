import { observable } from 'knockout';
import { ComponentDependencies } from '../../../interfaces';
import { setEmail } from '../../../store/contact/actions';
import { EmailTextInputViewModelParams } from '../../../ui_widgets/email-text-input/EmailTextInputViewModel';
import { BaseComponentViewModel } from '../../base-component';

export type ContactEmailViewModelParams = Omit<EmailTextInputViewModelParams, 'value$'>;

export class ContactEmailViewModel extends BaseComponentViewModel {
  readonly value$ = observable(null);
  readonly widgetParams: EmailTextInputViewModelParams;

  constructor(deps: ComponentDependencies, params?: ContactEmailViewModelParams) {
    super(deps);

    const readOnly = params?.readOnly ?? false;

    this.widgetParams = {
      ...params,
      value$: this.value$
    };

    if (readOnly) {
      this.bindObservableToStore(this.value$, '$.contact.emailAddress');
    } else {
      this.initializeStateUpdates();

      this.bindObservableToStore(this.value$, '$.contact.emailAddress', setEmail);
    }
  }
}

import { AppStore } from '../store';

export function postponeReportProgress(
  store: AppStore,
  postponeReportProgressUntilUserIdentified: boolean
): Promise<void> {
  if (!postponeReportProgressUntilUserIdentified) {
    return Promise.resolve();
  }

  return new Promise(resolve => {
    const unsubscribe = store.subscribe(() => {
      const state = store.getState();
      if (state.user.identities.vipcardnumber?.validated) {
        resolve();
        unsubscribe();
      }
    });
  });
}

import { ComponentConfig } from '../../../interfaces';

import template from './template.pug';
import { ActiveCategoryViewModel } from './ActiveCategoryViewModel';

const config: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new ActiveCategoryViewModel(deps, params)
  }
};

export default config;

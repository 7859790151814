import { BindingHandler, unwrap } from 'knockout';

export const currency: BindingHandler = {
  update: (element, valueAccessor) => {
    const value: {
      currencyCode: string;
      value: number;
    } = unwrap(valueAccessor());

    if (typeof value !== 'object' || typeof value.currencyCode !== 'string') {
      element.innerText = '';

      return;
    }

    let amount = 0;

    if (typeof value.value === 'number' && !Number.isNaN(value.value)) {
      amount = value.value;
    }

    element.innerText = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: value.currencyCode
    }).format(amount);
  }
};

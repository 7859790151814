import { BaseComponentViewModel } from '../../base-component';
import {
  components,
  observable,
  Observable,
  pureComputed,
  PureComputed,
  Subscribable
} from 'knockout';
import {
  ComponentDependencies,
  Order,
  OrderLine,
  OrderLineMutation,
  Product
} from '../../../interfaces';
import {
  OrderTotalsCalculationHelper,
  OrderTotalsResult
} from '../../../helpers/OrderTotalsCalculationHelper';
import { CartService } from '../../../services';

export interface CartViewModelParams extends components.ViewModelParams {
  skuFilter?: string[];
  locationLineDelimiter?: string;
  displayedProductProperties?: (string | [string, string])[];
  texts?: {
    cartEmpty?: string;
    removeFromCart?: string;
    decreaseQuantity?: string;
    increaseQuantity?: string;
    quantityLabel?: string;
    variantLabel?: string;
    priceTotalLabel?: string;
    tokenAmountTotalLabel?: string;
  };
}

export class CartViewModel extends BaseComponentViewModel {
  public readonly locationLineDelimiter?: string;
  public readonly displayedProductProperties: [string, string][];
  public readonly texts: {
    cartEmpty: string;
    removeFromCart: string;
    decreaseQuantity: string;
    increaseQuantity: string;
    quantityLabel: string;
    variantLabel: string;
    priceTotalLabel: string;
    tokenAmountTotalLabel: string;
  };

  private readonly skuFilter?: string[];
  public readonly filteredProducts$: Subscribable<Record<string, Product>>;
  public readonly order$: Observable<Order | undefined> = observable();

  public readonly orderItemList$: PureComputed<OrderLine[]>;
  public readonly totalAmounts$: PureComputed<OrderTotalsResult>;
  public readonly splitLocations$: PureComputed<{
    [key: string]: string[] | undefined;
  }>;
  public readonly orderIsPending$: Subscribable<boolean>;

  private cart: CartService;

  constructor(deps: ComponentDependencies, params?: CartViewModelParams) {
    super(deps);
    this.cart = deps.cart;

    this.skuFilter = params?.skuFilter;

    this.texts = {
      cartEmpty: this.i18next.t('components.cart.cartEmpty', 'The cart is empty.'),
      removeFromCart: this.i18next.t('components.cart.removeFromCart', 'Remove'),
      decreaseQuantity: this.i18next.t('components.cart.decreaseQuantity', 'Decrease quantity'),
      increaseQuantity: this.i18next.t('components.cart.increaseQuantity', 'Increase quantity'),
      quantityLabel: this.i18next.t('components.cart.quantityLabel', 'Quantity'),
      variantLabel: this.i18next.t('components.cart.variantLabel', 'Variant'),
      priceTotalLabel: this.i18next.t('components.cart.priceTotalLabel', 'Total'),
      tokenAmountTotalLabel: this.i18next.t('components.cart.tokenAmountTotalLabel', 'Total'),
      ...params?.texts
    };

    this.displayedProductProperties =
      params?.displayedProductProperties
        ?.map(item => {
          if (typeof item === 'string') {
            return [
              item,
              this.i18next.t(`components.cart.productPropertyLabels.${item}`, {
                defaultValue: null
              }) ?? item
            ];
          } else if (Array.isArray(item)) {
            return [
              item[0],
              item[1] ??
                this.i18next.t(`components.cart.productPropertyLabels.${item[0]}`, {
                  defaultValue: null
                }) ??
                item[0]
            ];
          }

          return undefined;
        })
        .filter((item): item is [string, string] => {
          return (
            typeof item !== 'undefined' &&
            typeof item[0] === 'string' &&
            typeof item[1] === 'string'
          );
        }) ?? [];

    this.locationLineDelimiter = params?.locationLineDelimiter ?? undefined;

    this.bindObservableToStore(this.order$, '$.order');
    this.orderIsPending$ = deps.selectors.orderIsPending$;

    if (Array.isArray(this.skuFilter)) {
      this.filteredProducts$ = pureComputed(() => {
        const products = deps.selectors.products$();
        const filteredProducts = {};
        for (const sku of this.skuFilter as string[]) {
          filteredProducts[sku] = products[sku];
        }
        return filteredProducts;
      });
    } else {
      this.filteredProducts$ = deps.selectors.products$;
    }

    this.orderItemList$ = pureComputed(() => {
      const filteredProducts = Object.keys(this.filteredProducts$());
      const orderItems = this.order$()?.items ?? {};

      return Object.keys(orderItems)
        .filter(sku => filteredProducts.includes(sku))
        .map(sku => orderItems[sku]);
    });

    this.splitLocations$ = pureComputed(() => {
      const products = deps.selectors.products$(),
        orderItems = this.order$()?.items ?? {},
        splitLocations = {};

      for (const sku in orderItems) {
        const product = products[sku];

        if (product.location) {
          splitLocations[sku] = this.locationLineDelimiter
            ? product.location.split(this.locationLineDelimiter)
            : [product.location];
        }
      }

      return splitLocations;
    });

    this.totalAmounts$ = pureComputed(() => {
      const products = this.filteredProducts$(),
        order = this.order$();

      return order && products
        ? OrderTotalsCalculationHelper.calculate(order, products)
        : {
            quantity: 0,
            tokens: {},
            currency: {}
          };
    });

    this.order$.extend({
      validation: {
        validator: (order: Order | undefined, minLength: number) => {
          return Object.values(order?.items ?? {}).length >= minLength;
        },
        message: 'The object must have at least {0} value(s)',
        params: 1
      }
    });

    this.initializeStateUpdates();
  }

  public increaseOrderItemQuantity: (orderItem: OrderLine) => void = orderItem => {
    const mutationData: OrderLineMutation = {
      operation: 'ADD',
      sku: orderItem.sku,
      quantity: 1
    };

    this.cart.mutateOrderItem(mutationData);
  };

  public decreaseOrderItemQuantity: (orderItem: OrderLine) => void = orderItem => {
    const mutationData: OrderLineMutation = {
      operation: 'REMOVE',
      sku: orderItem.sku,
      quantity: 1
    };

    this.cart.mutateOrderItem(mutationData);
  };

  public removeOrderItem: (orderItem: OrderLine) => void = orderItem => {
    const mutationData: OrderLineMutation = {
      operation: 'SET',
      quantity: 0,
      sku: orderItem.sku
    };

    this.cart.mutateOrderItem(mutationData);
  };
}

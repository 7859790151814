import { ComponentConfig } from '../../interfaces';

import template from './template.pug';
import { VimeoVideoViewModel } from './VimeoVideoViewModel';

const config: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new VimeoVideoViewModel(deps, params)
  }
};

export default config;

import { CountryCode } from '../../../../../interfaces';
import {
  UserIdentities,
  UserIdentityWithValidationState
} from '../../../../../interfaces/UserIdentity';
import { State } from '../../../../../store/reducers';
import { AnyContact, GameShowContact, RegistrationContact, ShopContact } from '../../interfaces';
import { WEBFLOW_TYPE_GAMESHOW, WEBFLOW_TYPE_REGISTRATION } from '../../interfaces/WebflowTypeID';
import { IzziCampaignSettingsFromApp } from '../../IzziService';

export function userStateTranslator(
  config: AnyContact,
  campaignSettingsFromApp: IzziCampaignSettingsFromApp
): State['user'] {
  const user: State['user'] = {
    identities: getIdentitiesFromContactData(config),
    restrictions: {
      amounts: {
        tokens: {},
        currency: {}
      },
      rules: {
        minimumAge: config.Campagne?.MinimumAgeForRegistration || undefined,
        allowedCountries: {
          contact: config.Campagne?.AllowedCountriesForResidence.map(
            country => country.Code as CountryCode
          ) ?? ['NL'],
          delivery: config.Campagne?.AllowedCountriesForDelivery.map(
            country => country.Code as CountryCode
          ) ?? ['NL'],
          phone: [...new Set(['NL', config.CountryCode || 'NL'] as CountryCode[])]
        }
      }
    },
    features: {
      orderRemarks: config.Campagne?.AllowAdditionalInformation ?? true,
      deliveryAddress: config.Campagne?.AllowDeliveryAddress ?? true
    }
  };

  if (user.restrictions.rules.allowedCountries.contact.length < 1) {
    user.restrictions.rules.allowedCountries.contact = ['NL'];
  }

  if (user.restrictions.rules.allowedCountries.delivery.length < 1) {
    user.restrictions.rules.allowedCountries.delivery = ['NL'];
  }

  user.restrictions.rules.singleSku = campaignSettingsFromApp.singleSku;

  if (
    config.Campagne &&
    [1, 2, 'registratie', 'shop'].includes(config.Campagne.WebflowType) &&
    ((config as RegistrationContact | ShopContact).PointsValue ||
      (config as RegistrationContact | ShopContact).PointsValue === 0)
  ) {
    user.restrictions.amounts.tokens.izzi_points = (
      config as RegistrationContact | ShopContact
    ).PointsValue;
  }

  if (
    config.Campagne &&
    [WEBFLOW_TYPE_REGISTRATION, 'registratie', WEBFLOW_TYPE_GAMESHOW, 'gameshow'].includes(
      config.Campagne.WebflowType
    ) &&
    (config as RegistrationContact | GameShowContact).NumberOfEventTickets >= 0
  ) {
    user.restrictions.quantity = (config as RegistrationContact).NumberOfEventTickets;
  }

  return user;
}

function getIdentitiesFromContactData(config: AnyContact): UserIdentities {
  const vipcard = config.AdditionalValues?.find(
    additionalValue => additionalValue.Key === 'VipcardNumber'
  );

  if (vipcard && config.ZipCode && config.HouseNumber) {
    const vipcardnumber: UserIdentityWithValidationState = {
      identifier: vipcard.Value as string,
      verificationChallenge1: config.ZipCode,
      verificationChallenge2: String(config.HouseNumber),
      validated: false,
      identityOrigin: 'backend'
    };
    return {
      vipcardnumber
    };
  }
  return {};
}

import { ComponentConfig } from '../../../interfaces';

import template from './template.html';
import { ProductSelectorViewModel } from './ProductSelectorViewModel';

const config: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new ProductSelectorViewModel(deps, params)
  }
};

export default config;

import { AppStore } from '../store';
import { setIsTest } from '../store/contact/actions';

export class TestModeService {
  constructor(private store: AppStore) {}

  public isTestModeSupported(): boolean {
    return this.store.getState().order.supportsTestMode;
  }

  public determineTestMode(): void {
    if (!this.isTestModeSupported()) {
      return;
    }

    const isTest = new URLSearchParams(window.location.search).get('isTest');

    if (isTest !== null) {
      this.store.dispatch(setIsTest(isTest === 'true'));
    }

    if (this.store.getState().contact.isTest) {
      this.showTestBanner();
    }
  }

  private showTestBanner(): void {
    const testBanner = document.createElement('div');
    testBanner.innerHTML = 'LET OP JE ZIT IN TESTMODUS';
    testBanner.style.zIndex = '9999999';
    testBanner.style.background = 'red';
    testBanner.style.color = 'white';
    testBanner.style.fontWeight = '600';
    testBanner.style.position = 'fixed';
    testBanner.style.width = '100%';
    testBanner.style.padding = '1em';
    testBanner.style.textAlign = 'center';
    testBanner.style.marginTop = '-0.5em';
    testBanner.style.marginLeft = '-0.5em';

    // HIDE BANNER
    const hideLink = document.createElement('a');
    hideLink.innerHTML = 'VERBERG MIJ';
    hideLink.style.cursor = 'pointer';
    hideLink.style.marginLeft = '5em';
    hideLink.style.textDecoration = 'underline';
    hideLink.style.color = 'blue';
    hideLink.addEventListener('click', () => testBanner.remove());
    testBanner.appendChild(hideLink);

    // DISABLE TEST MODE
    const disableTestModeLink = document.createElement('a');
    disableTestModeLink.innerHTML = 'TESTMODUS UITSCHAKELEN';
    disableTestModeLink.style.cursor = 'pointer';
    disableTestModeLink.style.marginLeft = '5em';
    disableTestModeLink.style.textDecoration = 'underline';
    disableTestModeLink.style.color = 'blue';

    disableTestModeLink.addEventListener('click', () => {
      const redirectUrl = new URL(window.location.href);

      redirectUrl.searchParams.set('isTest', 'false');

      window.location.href = redirectUrl.toString();
    });

    testBanner.appendChild(disableTestModeLink);

    const body = document.getElementsByTagName('body')[0];

    if (body.firstChild) {
      body.insertBefore(testBanner, body.firstChild);
    } else {
      body.appendChild(testBanner);
    }
  }
}

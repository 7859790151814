import { components } from 'knockout';

import { ComponentDependencies } from '../../../interfaces';
import { BaseComponentViewModel } from '../../base-component';

type ContactMetaDataViewModelParams = components.ViewModelParams;

export class ContactMetaDataViewModel extends BaseComponentViewModel {
  public context: unknown;

  public constructor(deps: ComponentDependencies, params?: ContactMetaDataViewModelParams) {
    super(deps);

    const initialContact = this.store.getState().storeBackend.initialContact;

    this.context = {
      initialContact: {
        houseNumberKnown: Boolean(initialContact.address.houseNumber),
        houseNumberExtensionKnown: Boolean(initialContact.address.houseNumberExtension),
        streetKnown: Boolean(initialContact.address.street),
        postalCodeKnown: Boolean(initialContact.address.postalCode),
        countryCodeKnown: Boolean(initialContact.address['countryCodeISO3166-1']),
        genderKnown: Boolean(initialContact.name.gender),
        initialsKnown: Boolean(initialContact.name.initials.length),
        givenNameKnown: Boolean(initialContact.name.givenName),
        familyNamePrefixKnown: Boolean(initialContact.name.familyNamePrefix),
        familyNameKnown: Boolean(initialContact.name.familyName),
        emailAddressKnown: Boolean(initialContact.emailAddress),
        phoneNumberKnown: Boolean(initialContact.phoneNumber),
        phoneNumberMobileKnown: Boolean(initialContact.phoneNumberMobile)
      }
    };
  }
}

import { OptInSelectionChangedEvent, OptInsSubmittedEvent } from '../events';
import { findQueryParamCaseInsensitive } from '../helpers/queryParamHelper';
import { OptIn, OptInId, OptInType, StoreBackendService } from '../interfaces';
import { AppStore } from '../store';
import { markSubmitting, markSubmittingDone, setOptInSelection } from '../store/order/actions';
import { AppEventManagerInterface } from './event-manager/EventManagerInterface';

export class OptInService {
  constructor(
    private store: AppStore,
    private storeBackendService: StoreBackendService,
    private eventManager: AppEventManagerInterface,
    private autoSubmitOnChoice: boolean
  ) {}

  public firstOptInOfType(optInType: OptInType): OptIn | undefined {
    return Object.values(this.store.getState().optIns).find(optIn => optIn.optInType === optInType);
  }

  public firstIdOfType(optInType: OptInType): OptInId | undefined {
    return this.firstOptInOfType(optInType)?.optInId;
  }

  public setOptInSelection(optInId: OptInId, isSelected: boolean): void {
    this.store.dispatch(setOptInSelection(optInId, isSelected));
    this.eventManager.emit(new OptInSelectionChangedEvent(optInId, isSelected));
    if (this.autoSubmitOnChoice) {
      void this.submitOptInsToBackend();
    }
  }

  public setOptInChoiceFromUrl(optInId: OptInId, queryParamName = 'optIn'): boolean {
    const choiceFromUrl = this.getOptInChoiceFromUrl(queryParamName);

    if (choiceFromUrl === undefined) {
      return false;
    }

    this.setOptInSelection(optInId, choiceFromUrl);
    return true;
  }

  public getOptInChoiceFromUrl(queryParamName: string): boolean | undefined {
    const matchedUrlParam = findQueryParamCaseInsensitive(queryParamName);

    if (matchedUrlParam?.value.toLowerCase() === 'true') {
      return true;
    }

    if (matchedUrlParam?.value.toLowerCase() === 'false') {
      return false;
    }

    return undefined;
  }

  public async submitOptInsToBackend(): Promise<void> {
    this.store.dispatch(markSubmitting('optIns'));

    try {
      await this.storeBackendService.saveOptInOrOutChoices(this.store.getState());
      this.eventManager.emit(new OptInsSubmittedEvent(true));
    } catch (err) {
      this.eventManager.emit(new OptInsSubmittedEvent(false));
    } finally {
      this.store.dispatch(markSubmittingDone('optIns'));
    }
  }
}

import { components, Subscribable } from 'knockout';
import { BaseComponentViewModel } from '../../base-component';
import { ComponentDependencies, Product } from '../../../interfaces';

export interface WithProductViewModelParams extends components.ViewModelParams {
  sku?: string;
}

export class WithProductViewModel extends BaseComponentViewModel {
  private product$: Subscribable<Product>;

  public constructor(deps: ComponentDependencies, params?: WithProductViewModelParams) {
    super(deps);

    if (!params?.sku) {
      throw new Error("'sku' parameter is required in with-product component");
    }

    this.product$ = deps.selectors.productById$(params.sku);
  }
}

import { BaseComponentViewModel } from '../../base-component';
import { ComponentDependencies, OrderLine } from '../../../interfaces';
import { components, observable, Observable, PureComputed, pureComputed } from 'knockout';

export interface CartElementClassToggleViewModelParams extends components.ViewModelParams {
  context?: any;
  elementId?: string;
  className?: string;
}

export class CartElementClassToggleViewModel extends BaseComponentViewModel {
  public readonly context = undefined;
  public readonly elementId: string;
  public readonly className: string = 'open';
  public readonly orderItemsQuantity$: PureComputed<number>;

  constructor(deps: ComponentDependencies, params?: CartElementClassToggleViewModelParams) {
    super(deps);

    if (!params?.elementId) {
      throw new Error('elementId is a required parameter in CartElementClassToggle widget.');
    }

    this.context = params?.context;
    this.elementId = params.elementId;
    this.className = params?.className ?? this.className;

    const orderItems$: Observable<{ [key: string]: OrderLine }> = observable({});

    this.orderItemsQuantity$ = pureComputed(() => {
      let quantity = 0;
      const orderItems = orderItems$();
      for (const sku in orderItems) {
        quantity += orderItems[sku].quantity;
      }
      return quantity;
    });

    this.subscriptions.push(
      this.orderItemsQuantity$.subscribe(quantity => {
        if (quantity > 0) {
          this.toggle(true);
        }
      })
    );

    this.bindObservableToStore(orderItems$, '$.order.items');
  }

  public toggle(value?: boolean) {
    // Push the toggle onto the event loop by setTimeout, so that knockout
    // can render any required dom element before the toggle is performed.
    setTimeout(() => {
      const element = document.getElementById(this.elementId);
      element?.classList.toggle(this.className, value);
    });
  }

  public clickHandler = () => this.toggle();
}

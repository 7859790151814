import i18next from 'i18next';
import { components, pureComputed, PureComputed, Subscribable } from 'knockout';
import { DialogCloseRequestedEvent } from '../../../events';
import {
  ComponentDependencies,
  OrderLineMutation,
  Product,
  User,
  OrderLines
} from '../../../interfaces';
import { AppEventManagerInterface, CartService } from '../../../services';

import { BaseComponentViewModel } from '../../base-component';

interface DisplayedProductPropertyData {
  key: string;
  value: unknown;
  label: string;
}

export interface ProductModalContentViewModelParams extends components.ViewModelParams {
  sku?: string;
  locationLineDelimiter?: string;
  displayedProductProperties?: (string | [string, string])[];
  closeDialogAfterAddProduct?: boolean;
  dialogId?: string;
  texts?: {
    addToCart?: string;
    variantLabel?: string;
  };
}

export class ProductModalContentViewModel extends BaseComponentViewModel {
  private appEventManager: AppEventManagerInterface;
  public readonly sku?: string;
  public readonly locationLineDelimiter?: string;
  public readonly displayedProductProperties: [string, string][];
  private dialogId?: string;
  closeDialogAfterAddProduct: boolean;

  public readonly texts: {
    addToCart: string;
    variantLabel: string;
  };

  public readonly user$: Subscribable<User>;
  public readonly products$: Subscribable<Record<string, Product>>;
  public readonly orderItems$: Subscribable<OrderLines>;

  public readonly orderIsPending$: Subscribable<boolean>;

  public readonly singleChoice$: PureComputed<boolean>;
  public readonly product$: PureComputed<Product | undefined>;
  public readonly externalUrl$: PureComputed<string | undefined>;
  public readonly splitLocation$: PureComputed<string[] | undefined>;

  private cart: CartService;

  constructor(deps: ComponentDependencies, params?: ProductModalContentViewModelParams) {
    super(deps);
    this.orderIsPending$ = deps.selectors.orderIsPending$;
    this.user$ = deps.selectors.user$;
    this.products$ = deps.selectors.products$;
    this.orderItems$ = deps.selectors.orderItems$;

    this.appEventManager = deps.appEventManager;
    this.cart = deps.cart;

    this.dialogId = params?.dialogId;
    this.closeDialogAfterAddProduct = params?.closeDialogAfterAddProduct ?? false;

    this.sku = params?.sku;
    this.locationLineDelimiter = params?.locationLineDelimiter ?? undefined;

    this.texts = {
      addToCart: i18next.t('components.productModalContent.addToCart', 'Add to cart'),
      variantLabel: i18next.t('components.productModalContent.variantLabel', 'Variant'),
      ...params?.texts
    };

    this.displayedProductProperties =
      params?.displayedProductProperties
        ?.map(item => {
          if (typeof item === 'string') {
            return [
              item,
              i18next.t(`components.productModalContent.productPropertyLabels.${item}`, {
                defaultValue: null
              }) ?? item
            ];
          } else if (Array.isArray(item)) {
            return [
              item[0],
              item[1] ??
                i18next.t(`components.productModalContent.productPropertyLabels.${item[0]}`, {
                  defaultValue: null
                }) ??
                item[0]
            ];
          }

          return undefined;
        })
        .filter((item): item is [string, string] => {
          return (
            typeof item !== 'undefined' &&
            typeof item[0] === 'string' &&
            typeof item[1] === 'string'
          );
        }) ?? [];

    this.singleChoice$ = pureComputed(() => {
      const user = this.user$();

      return user?.restrictions.quantity === 1 ?? false;
    });

    this.product$ = pureComputed(() => {
      if (!this.sku) {
        return undefined;
      }

      return this.products$()[this.sku];
    });

    this.externalUrl$ = pureComputed(() => {
      return this.product$()?.content.pages.find(page => page.type === 'modal')?.url;
    });

    this.splitLocation$ = pureComputed(() => {
      const product = this.product$();

      return this.locationLineDelimiter
        ? product?.location?.split(this.locationLineDelimiter)
        : product?.location
        ? [product.location]
        : undefined;
    });
  }

  public addOrderItem: (data: Product) => void = (data: Product) => {
    const mutationData: OrderLineMutation = {
      operation: 'ADD',
      quantity: 1,
      sku: data.sku
    };

    this.cart.mutateOrderItem(mutationData);

    if (this.closeDialogAfterAddProduct && this.dialogId) {
      this.appEventManager.emit(new DialogCloseRequestedEvent(this.dialogId));
    }
  };

  public removeOrderItem: (data: Product) => void = (data: Product) => {
    const mutationData: OrderLineMutation = {
      operation: 'REMOVE',
      quantity: 1,
      sku: data.sku
    };

    this.cart.mutateOrderItem(mutationData);
  };

  public getDisplayedProductPropertyData(product: Product): DisplayedProductPropertyData[] {
    const result: DisplayedProductPropertyData[] = [];

    for (const [key, label] of this.displayedProductProperties) {
      const value = product.content.texts[key] ?? product.content.customTexts[key] ?? undefined;
      if (value !== undefined) {
        result.push({
          key,
          value,
          label
        });
      }
    }

    return result;
  }
}

import { BindingHandler, unwrap } from 'knockout';
import DOMPurify from 'dompurify';

export const sanitizedHtml: BindingHandler = {
  update: (element, valueAccessor) => {
    const value = unwrap(valueAccessor());
    let valueString = '';

    if (typeof value !== 'string' && typeof value !== 'number' && typeof value !== 'boolean') {
      element.innerText = valueString;
      return;
    }

    if (typeof value !== 'string') {
      valueString = String(value);
    } else {
      valueString = value;
    }

    element.innerHTML = DOMPurify.sanitize(valueString, {
      USE_PROFILES: { html: true }
    });
  }
};

import { NotificationQueue, Notification } from '../../interfaces';
import {
  NotificationsActionTypes,
  NOTIFY,
  CLEAR_NOTIFICATIONS,
  NOTIFICATION_EMITTED
} from './actions';
import { initialState } from './initialState';

export type NotificationsState = NotificationQueue;

export function notificationsReducer(
  state = initialState,
  action: NotificationsActionTypes
): NotificationsState {
  switch (action.type) {
    case NOTIFY: {
      const notification: Notification = {
        class: action.payload.class || 'error',
        title: action.payload.title || null,
        message: action.payload.message || 'Unknown message',
        actions: action.payload.actions || [{ code: 'close', label: 'OK' }],
        timestamp: Date.now(),
        emitted: false,
        extraData: action.payload.extraData || {}
      };

      return state.concat([notification]);
    }

    case NOTIFICATION_EMITTED: {
      const index = state.findIndex(n => n.timestamp === action.payload);
      if (index < 0) {
        return state;
      }
      const notification = state[index];

      const newState = [
        ...state.slice(0, index),
        { ...notification, emitted: true },
        ...state.slice(index + 1)
      ];

      return newState;
    }

    case CLEAR_NOTIFICATIONS: {
      if (action.payload !== null) {
        return state.filter(x => !action.payload?.includes(x.timestamp));
      }
      return [];
    }

    default:
      return state;
  }
}

import {
  components,
  isObservable,
  observable,
  Observable,
  PureComputed,
  pureComputed,
  Subscribable
} from 'knockout';
import {
  readPendingOrder,
  createProductCompareFn,
  ProductCompareFn,
  ProductSorting
} from '../../../helpers';
import { ComponentDependencies, Order, Product } from '../../../interfaces';
import { BaseComponentViewModel } from '../../base-component';
import { ProductsState } from '../../../store/products/reducers';

type ProductDisplayProp = ((product: Product) => string) | keyof Product;

export interface ProductDropdownViewModelParams extends components.ViewModelParams {
  skus?: string[];
  sorting?: ProductSorting | 'input';
  exclusivity?: 'skus' | 'order';
  quantity$?: number | Observable<number>;
  addPlaceholder?: boolean;
  disabled?: boolean;
  texts?: {
    placeholder?: string;
  };
  display?: ProductDisplayProp;
  selectedProduct$?: Observable<Product | null>;
  mutateStore?: boolean;
}

export class ProductDropdownViewModel extends BaseComponentViewModel {
  public readonly products$: PureComputed<Product[]>;
  public readonly selectedProduct$: Observable<Product | null>;
  public readonly optionsCaption: string | undefined;
  public readonly display: ProductDisplayProp;
  public readonly disabled$: PureComputed<boolean>;
  private readonly allProducts$: Subscribable<ProductsState>;

  public constructor(deps: ComponentDependencies, params?: ProductDropdownViewModelParams) {
    super(deps);
    this.allProducts$ = deps.selectors.products$;

    this.display = params?.display ?? 'title';

    this.optionsCaption =
      params?.addPlaceholder === true ? params.texts?.placeholder || '' : undefined;

    const productCompareFn =
      params?.sorting !== 'input'
        ? createProductCompareFn(params?.sorting ?? 'sequenceNumber')
        : undefined;
    this.products$ = this.createProductsObservable(params?.skus, productCompareFn);

    if (params?.selectedProduct$) {
      this.selectedProduct$ = params.selectedProduct$;
    } else {
      const initialProduct = this.findProductInOrder(this.products$(), deps.store.getState().order);
      this.selectedProduct$ = observable(initialProduct);
    }

    const limitRemainingToSkus =
      params?.skus && params.exclusivity !== 'order' ? params.skus : undefined;

    const mutateStore = params?.mutateStore ?? true;
    const setProductQuantity = (quantity: Observable<number>) => (product: Product | null) => {
      if (!mutateStore) return;
      deps.cart.bulkMutateOrderItem({
        skus: product ? [product.sku] : [],
        quantity: quantity(),
        operation: 'SET',
        remainingOrderItems: {
          limitToSkus: limitRemainingToSkus,
          quantity: 0,
          operation: 'SET'
        }
      });
    };

    const quantity$ = isObservable(params?.quantity$)
      ? (params?.quantity$ as Observable<number>)
      : observable((params?.quantity$ as number) ?? 1);

    this.subscriptions.push(
      this.selectedProduct$.subscribe(setProductQuantity(quantity$)),
      quantity$.subscribe(() => setProductQuantity(quantity$)(this.selectedProduct$()))
    );

    this.disabled$ = pureComputed(() => params?.disabled || deps.selectors.orderIsPending$());
  }

  private createProductsObservable(
    skus: string[] | undefined,
    compareFn: ProductCompareFn | undefined
  ): PureComputed<Product[]> {
    return pureComputed((): Product[] => {
      const allProducts = this.allProducts$();

      let result: Product[];

      if (skus instanceof Array) {
        result = skus.map(sku => allProducts[sku]);
      } else {
        result = Object.values(allProducts);
      }

      if (compareFn) {
        result.sort(compareFn);
      }

      return result;
    });
  }

  private findProductInOrder(products: Product[], order: Order) {
    const skusInOrder = Object.keys(readPendingOrder(order).items);
    return products.find(pr => skusInOrder.includes(pr.sku)) ?? null;
  }
}

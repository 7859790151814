import { components, observable, PureComputed, pureComputed } from 'knockout';

import { ComponentDependencies } from '../../../interfaces';
import { BaseComponentViewModel } from '../../base-component';

export interface IfVipcardValidatedViewModelParams extends components.ViewModelParams {
  behaviorIfValidated?: 'show' | 'hide';
}

export class IfVipcardValidatedInputViewModel extends BaseComponentViewModel {
  public visible$: PureComputed<boolean>;
  public behaviorIfAuthenticated: 'show' | 'hide';

  constructor(deps: ComponentDependencies, params?: IfVipcardValidatedViewModelParams) {
    super(deps);

    this.behaviorIfAuthenticated = params?.behaviorIfValidated ?? 'show';

    const identities$ = observable();
    this.bindObservableToStore(identities$, '$.user.identities');

    this.visible$ = pureComputed(() => {
      const userIdentities = identities$();
      const isAuthenticated = Boolean(userIdentities.vipcardnumber?.validated);
      return (this.behaviorIfAuthenticated === 'show') === isAuthenticated;
    });
  }
}

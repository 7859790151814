import { AppState } from './reducers';

export const initialState: AppState = {
  activeCategory: undefined,
  activeStage: undefined,
  ready: false,
  routes: [],
  componentStates: {},
  visibilityOptionGroups: {}
};

import { LoginRequirements } from '../../../../../interfaces/LoginRequirements';
import { State } from '../../../../../store/reducers';
import { initialState } from '../../../../../store/storeBackend/initialState';
import { AnyContact, LogOnModel } from '../../interfaces';
import { contactStateTranslator } from './contactStateTranslator';

export function storeBackendTranslator(
  config: AnyContact | undefined,
  loginConfig: LogOnModel | undefined
): State['storeBackend'] {
  return {
    izzi: config && {
      IDCampaign: config.Campagne?.IDCampagne
    },
    loginRequirements: loginConfig
      ? getLoginRequirements(loginConfig)
      : initialState.loginRequirements,
    initialContact: config ? contactStateTranslator(config) : initialState.initialContact
  };
}

function getLoginRequirements(loginConfig: LogOnModel): LoginRequirements {
  const requirePostalCode =
    loginConfig.UseIDCampagneFieldAndZipCodeAndHouseNumberForLogin ||
    loginConfig.UseIDCampagneFieldAndZipCodeForLogin ||
    loginConfig.UseCustomerContactIdForLogin ||
    loginConfig.UseIDContactForLogin;

  const requireHouseNumber = loginConfig.UseIDCampagneFieldAndZipCodeAndHouseNumberForLogin;

  return {
    requestVerificationChallenge1: requirePostalCode,
    requestVerificationChallenge2: requireHouseNumber,
    verificationChallenge1Format: requirePostalCode ? 'postalcode' : undefined,
    verificationChallenge2Format: requireHouseNumber ? 'housenumber' : undefined,
    forceVerificationChallenge1Uppercase: requirePostalCode
  };
}

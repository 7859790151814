import { components, observable, pureComputed, PureComputed } from 'knockout';
import { ComponentDependencies, Order } from '../../interfaces';
import { ProductsState } from '../../store/products/reducers';

import { BaseComponentViewModel } from '../base-component';

type RuleType = 'some' | 'some-not' | 'all' | 'none';

export interface IfBackorderViewModelParams extends components.ViewModelParams {
  context?: Record<string, unknown>;
  rule?: RuleType;
  ifOrderEmpty?: boolean | undefined;
}

export class IfBackorderViewModel extends BaseComponentViewModel {
  public context: Record<string, unknown>;
  public value$: PureComputed<boolean>;

  constructor(deps: ComponentDependencies, params?: IfBackorderViewModelParams) {
    super(deps);

    this.context = params?.context ?? {};
    const rule: RuleType = params?.rule ?? 'some';
    const ifOrderEmpty = params?.ifOrderEmpty;

    const products$ = observable<ProductsState>({});
    const order$ = observable<Order>();

    this.value$ = pureComputed(() => {
      const order = order$() as Order;
      const products = products$();
      return deps.backorder.checkIfBackorder(order, products, rule, ifOrderEmpty);
    });

    this.bindObservableToStore(products$, '$.products');
    this.bindObservableToStore(order$, '$.order');
  }
}

export function findQueryParamCaseInsensitive(
  queryParamName: string
): { name: string; value: string } | undefined {
  const urlParams = new URLSearchParams(window.location.search);

  for (const [actualName, value] of urlParams.entries()) {
    if (actualName.toLowerCase() === queryParamName.toLowerCase()) {
      return { name: actualName, value };
    }
  }

  return undefined;
}

import { ComponentConfig } from '../../interfaces';
import { LoginViewModel } from './LoginViewModel';

import template from './template.pug';

const config: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new LoginViewModel(deps, params)
  }
};

export default config;

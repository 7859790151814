import { AnyContact, RegistrationContact, ShopContact } from '../../interfaces';
import { State } from '../../../../../store/reducers';
import { WEBFLOW_TYPE_SHOP } from '../../interfaces/WebflowTypeID';
import { IzziCampaignSettingsFromApp } from '../../IzziService';

export function orderStateTranslator(
  config: AnyContact,
  campaignSettingsFromApp: IzziCampaignSettingsFromApp
): State['order'] {
  const additionalFieldsToSet = campaignSettingsFromApp.additionalFields;

  const orderState: State['order'] = {
    additionalFields: {},
    items: {},
    selectedOptIns:
      config.OptInOrOutChoices?.filter(optIn => optIn.OptedIn).map(optIn => optIn.Code) ?? [],
    choices: {
      '1': typeof config.CheckBox1 === 'boolean' ? config.CheckBox1 : null,
      '2': typeof config.CheckBox2 === 'boolean' ? config.CheckBox2 : null,
      '3': typeof config.CheckBox3 === 'boolean' ? config.CheckBox3 : null
    },
    useContactAddress: true,
    deliveryAddress: {
      houseNumber: '',
      houseNumberExtension: undefined,
      street: '',
      postalCode: '',
      city: '',
      'countryCodeISO3166-1': 'NL'
    },
    remarks: null,
    payment: {
      paymentOptionCode:
        config.PaymentMethod || config.Campagne?.AvaiblePaymentMethods?.[0]?.Code || null
    },
    supportsTestMode:
      (config.Campagne && [WEBFLOW_TYPE_SHOP, 'shop'].includes(config.Campagne.WebflowType)) ||
      false,
    _queues: {
      orderItems: {
        hasPendingItems: false,
        lastProcessedItemSequenceNumber: 0,
        lastItemSequenceNumber: 0,
        pendingItems: []
      }
    },
    _submitting: {
      optIns: false,
      vipcard: false
    },
    confirmed: false
  };
  if (
    config.Campagne &&
    [1, 2, 'registratie', 'shop'].includes(config.Campagne.WebflowType) &&
    typeof (config as RegistrationContact | ShopContact).AdditionalInformation === 'string'
  ) {
    orderState.remarks = (config as RegistrationContact | ShopContact).AdditionalInformation;
  }

  // Set additional values depending on campaignSettingsFromApp.additionalFields
  for (const field of additionalFieldsToSet) {
    if (config[field] !== null && config[field] !== undefined) {
      orderState.additionalFields[field] = config[field];
    }
  }

  return orderState;
}

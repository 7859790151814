import { Action } from 'redux';
import { State } from './reducers';
import { AppActionTypes } from './app/actions';
import { CategoriesActionTypes } from './categories/actions';
import { ContactActionTypes } from './contact/actions';
import { OrderActionTypes } from './order/actions';
import { ProductsActionTypes } from './products/actions';
import { UserActionTypes } from './user/actions';
import { AdditionalContactsActionTypes } from './additionalContacts/actions';
import { NotificationsActionTypes } from './notifications/actions';

export const SET_STATE = 'SET_STATE';

export interface SetStateAction extends Action<typeof SET_STATE> {
  type: typeof SET_STATE;
  payload: State;
}

export type RootActionTypes = SetStateAction;

export type SliceActionTypes =
  | AdditionalContactsActionTypes
  | AppActionTypes
  | CategoriesActionTypes
  | ContactActionTypes
  | NotificationsActionTypes
  | OrderActionTypes
  | ProductsActionTypes
  | UserActionTypes;

export type ActionTypes = RootActionTypes | SliceActionTypes;

export function setState(state: State): SetStateAction {
  return {
    type: SET_STATE,
    payload: state
  };
}

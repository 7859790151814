import { components, observable } from 'knockout';
import { ComponentDependencies } from '../../../interfaces';
import { setAdditionalField } from '../../../store/order/actions';
import { TextInputViewModelParams } from '../../../ui_widgets/text-input';
import { BaseComponentViewModel } from '../../base-component';

export interface AdditionalTextFieldViewModelParams extends components.ViewModelParams {
  additionalFieldKey?: string;
  readOnly?: boolean;
  required?: boolean;
  texts?: {
    label?: string;
  };
}

export class AdditionalTextFieldViewModel extends BaseComponentViewModel {
  readonly value$ = observable<string | null>(null);
  readonly widgetParams: TextInputViewModelParams;

  private required: boolean;
  private readOnly: boolean;

  constructor(deps: ComponentDependencies, params?: AdditionalTextFieldViewModelParams) {
    super(deps);

    this.required = params?.required ?? false;
    this.readOnly = params?.readOnly ?? false;

    this.widgetParams = {
      value$: this.value$,
      readOnly: this.readOnly,
      type: 'text',
      required: this.required,
      texts: params?.texts
    };

    if (params?.additionalFieldKey) {
      const pathInStore = '$.order.additionalFields.' + params.additionalFieldKey;
      const storeActionCreator = value =>
        setAdditionalField({
          key: params.additionalFieldKey as string,
          value
        });

      if (this.readOnly) {
        this.bindObservableToStore(this.value$, pathInStore);
      } else {
        this.bindObservableToStore(this.value$, pathInStore, storeActionCreator);
      }
    }
    this.initializeValidations();
    this.initializeStateUpdates();
  }

  protected initializeValidations(): void {
    if (this.required) {
      this.value$.extend({
        required: true
      });
    }
  }
}

import { components } from 'knockout';

import { ComponentDependencies } from '../../interfaces';
import { BaseComponentViewModel } from '../base-component';

export interface ElementClassToggleViewModelParams extends components.ViewModelParams {
  context?: any;
  elementId?: string;
  className?: string;
}

export class ElementClassToggleViewModel extends BaseComponentViewModel {
  public readonly context;
  public readonly elementId?: string;
  public readonly className: string = 'skx-element-class-toggle-open';

  constructor(deps: ComponentDependencies, params?: ElementClassToggleViewModelParams) {
    super(deps);

    this.context = params?.context ?? undefined;
    this.elementId = params?.elementId ?? this.elementId;
    this.className = params?.className ?? this.className;
  }

  public toggle = (): void => {
    if (this.elementId) {
      const element = document.getElementById(this.elementId);

      if (element) {
        element.classList.toggle(this.className);
      }
    }
  };
}

import { Category } from '../../interfaces';
import { initialState } from './initialState';
import { ADD_CATEGORY, CategoriesActionTypes, DELETE_CATEGORY, SET_CATEGORIES } from './actions';

export interface CategoriesState {
  [index: string]: Category;
}

export function categoriesReducer(
  state = initialState,
  action: CategoriesActionTypes
): CategoriesState {
  switch (action.type) {
    case SET_CATEGORIES: {
      return action.payload;
    }

    case ADD_CATEGORY: {
      if (state[action.payload.identifier]) {
        return state;
      }

      return {
        ...state,
        [action.payload.identifier]: action.payload
      };
    }

    case DELETE_CATEGORY: {
      if (!state[action.meta.identifier]) {
        return state;
      }

      const newState = { ...state };

      delete newState[action.meta.identifier];

      return newState;
    }

    default: {
      return state;
    }
  }
}

import { Action } from 'redux';
import { Notification } from '../../interfaces';

export const NOTIFY = 'NOTIFY';
export const NOTIFICATION_EMITTED = 'NOTIFICATION_EMITTED';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';

export interface NotifyAction extends Action<typeof NOTIFY> {
  type: typeof NOTIFY;
  payload: {
    class?: Notification['class'];
    title?: Notification['title'];
    message: Notification['message'];
    actions?: Notification['actions'];
    extraData: Record<string, unknown>;
  };
}

export interface ClearNotificationsAction extends Action<typeof CLEAR_NOTIFICATIONS> {
  type: typeof CLEAR_NOTIFICATIONS;
  payload: Array<Notification['timestamp']> | null;
}

export interface NotificationEmittedAction extends Action<typeof NOTIFICATION_EMITTED> {
  type: typeof NOTIFICATION_EMITTED;
  payload: Notification['timestamp'];
}

export type NotificationsActionTypes =
  | NotifyAction
  | NotificationEmittedAction
  | ClearNotificationsAction;

export function notify(props: NotifyAction['payload']): NotifyAction {
  return {
    type: NOTIFY,
    payload: props
  };
}

export function clearNotifications(
  onlyTimestamps: Array<Notification['timestamp']> | null
): ClearNotificationsAction {
  return {
    type: CLEAR_NOTIFICATIONS,
    payload: onlyTimestamps
  };
}

export function notificationEmitted(
  timestamp: Notification['timestamp']
): NotificationEmittedAction {
  return {
    type: NOTIFICATION_EMITTED,
    payload: timestamp
  };
}

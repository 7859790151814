import { observable, Observable } from 'knockout';

import { BaseComponentViewModel } from '../base-component';

import { AddressInputViewModelParams } from '../../ui_widgets/address-input/AddressInputViewModel';
import { Address, ComponentDependencies } from '../../interfaces';
import { setDeliveryAddress, setUseContactAddressSetting } from '../../store/order/actions';

export type DeliveryAddressViewModelParams = Omit<AddressInputViewModelParams, 'value$'>;

export class DeliveryAddressViewModel extends BaseComponentViewModel {
  public readonly readOnly: boolean;
  public readonly value$: Observable<Address>;
  public readonly widgetIsValid$ = observable(false);
  public readonly widgetParams: AddressInputViewModelParams;
  public readonly deliveryAddressAllowed$: Observable<boolean> = observable(true);
  public readonly allowedCountryCodes$: Observable = observable([]);

  constructor(deps: ComponentDependencies, params?: DeliveryAddressViewModelParams) {
    super(deps);

    this.bindObservableToStore(
      this.allowedCountryCodes$,
      '$.user.restrictions.rules.allowedCountries.delivery'
    );

    this.readOnly = typeof params?.readOnly === 'boolean' ? params.readOnly : false;

    this.widgetIsValid$.extend({ equal: true } as any);

    this.value$ = observable() as Observable<Address>;

    this.widgetParams = {
      ...params,
      value$: this.value$,
      isValid$: this.widgetIsValid$,
      allowedCountryCodes: this.allowedCountryCodes$()
    };

    this.bindObservableToStore(this.deliveryAddressAllowed$, '$.user.features.deliveryAddress');

    if (this.deliveryAddressAllowed$()) {
      this.store.dispatch(setUseContactAddressSetting(false));
      this.initializeStateUpdates();

      if (this.readOnly) {
        this.bindObservableToStore(this.value$, '$.order.deliveryAddress');
      } else {
        this.bindObservableToStore(this.value$, '$.order.deliveryAddress', setDeliveryAddress);
      }
    }
  }
}

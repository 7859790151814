import template from './IfCartEmpty.html';
import { ComponentConfig } from '../../../interfaces';
import { IfCartEmptyViewModel } from './IfCartEmptyViewModel';

export const config: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new IfCartEmptyViewModel(deps, params)
  }
};

export default config;

import { ComponentConfig } from '../../../interfaces';

import template from './template.pug';
import { ProductSummaryViewModel } from './ProductSummaryViewModel';

const config: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new ProductSummaryViewModel(deps, params)
  }
};

export default config;

import { components, observable, Observable } from 'knockout';
import { ComponentDependencies } from '../../interfaces';
import { BaseComponentViewModel } from '../base-component';

type LogoutButtonViewModelParams = components.ViewModelParams;

export class LogoutButtonViewModel extends BaseComponentViewModel {
  public readonly busy$: Observable<boolean> = observable(false);

  constructor(deps: ComponentDependencies, params?: LogoutButtonViewModelParams) {
    super(deps);
  }

  public doLogout(): void {
    if (this.busy$()) {
      return;
    }

    this.busy$(true);

    (async () => {
      try {
        const redirectUrl = await this.service.logout();
        location.replace(redirectUrl.toString());
      } finally {
        this.busy$(false);
      }
    })();
  }
}

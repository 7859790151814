import { OrderItemMutationValidationResult } from '../interfaces';
import { State } from '../store';

import { quantityValidator } from './quantityValidator';
import { restrictionRulesValidator } from './restrictionRulesValidator';
import { tokenValidator } from './tokenValidator';

export function combinedOrderValidator(state: State): OrderItemMutationValidationResult {
  const results = {
    result: true,
    quantityResult: quantityValidator(state),
    restrictionRulesResult: restrictionRulesValidator(state),
    tokenResult: tokenValidator(state)
  };

  // check that every value in the results object is truthy
  results.result = Object.values(results).every(value => value);

  return results;
}

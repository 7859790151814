import { Observable, observable, pureComputed, PureComputed } from 'knockout';
import { ComponentDependencies, User } from '../../../interfaces';
import { setAdditionalContactBirthDate } from '../../../store/additionalContacts/actions';
import { DateInputViewModelParams } from '../../../ui_widgets/date-input/DateInputViewModel';
import { BaseComponentViewModel } from '../../base-component';
import { Action } from 'redux';

export interface AdditionalContactBirthDateViewModelParams
  extends Omit<DateInputViewModelParams, 'value$' | 'minDate' | 'maxDate'> {
  readOnly?: boolean;
  required?: boolean;
  texts?: {
    label?: string;
    maxDateValidationMessage: string;
  };
}

export class AdditionalContactBirthDateViewModel extends BaseComponentViewModel {
  readonly widgetParams$: PureComputed<DateInputViewModelParams>;
  readonly value$ = observable(null);
  readonly user$: Observable<User | undefined> = observable();

  constructor(deps: ComponentDependencies, params?: AdditionalContactBirthDateViewModelParams) {
    super(deps);
    const readOnly = params?.readOnly ?? false;

    this.widgetParams$ = pureComputed(() => {
      const minDate: Date = new Date('01-01-1900');
      const maxDate: Date = new Date();

      const minimumAge = this.user$()?.restrictions.rules.minimumAge;
      if (minimumAge) {
        maxDate.setUTCFullYear(maxDate.getUTCFullYear() - minimumAge);
      }

      const widgetParams: DateInputViewModelParams = {
        value$: this.value$,
        readOnly,
        required: params?.required,
        maxDate: maxDate?.toISOString().split('T')[0],
        minDate: minDate.toISOString().split('T')[0],
        texts: {
          ...params?.texts
        }
      };

      return widgetParams;
    });

    const additionalContactRef = params?.additionalContactRef;
    const storeDataJsonPath = `$.additionalContacts['${additionalContactRef}'].birthDate`;
    const storeActionCreator: (birthDate: string | null) => Action<unknown> = birthDate =>
      setAdditionalContactBirthDate(additionalContactRef as string, birthDate);

    this.bindObservableToStore(this.user$, '$.user');

    if (readOnly) {
      this.bindObservableToStore(this.value$, storeDataJsonPath);
    } else {
      this.initializeStateUpdates();

      this.bindObservableToStore(this.value$, storeDataJsonPath, storeActionCreator);
    }
  }
}

import {
  AppEvent,
  AppEventConstructor,
  AppEventListener,
  AppEventManagerInterface,
  RemoveListenerFn,
  EventController
} from './EventManagerInterface';

let nextId = 1;
const nextListenerId = (): string => String(nextId++);

type Registrations<T extends AppEvent> = Array<{
  listenerId: string;
  priority: number;
  listener: AppEventListener<T>;
}>;

export const DEFAULT_PRIORITY = 10;

export class AppEventManager implements AppEventManagerInterface {
  private registrations: { [eventName: string]: Registrations<AppEvent> } = {};

  public on<T extends AppEvent>(
    eventClass: AppEventConstructor<T>,
    listener: AppEventListener<T>,
    priority = DEFAULT_PRIORITY
  ): RemoveListenerFn {
    const listenerId = nextListenerId();
    const eventName: string = eventClass.EVENT_NAME;
    this.registrations[eventName] ??= [];
    (this.registrations[eventName] as Registrations<T>).push({
      listenerId,
      priority,
      listener
    });

    // sort listeners by: priority (descending), then listenerId (ascending)
    this.registrations[eventName].sort((a, b) => {
      if (a.priority !== b.priority) return b.priority - a.priority; // higher priority = earlier in order
      if (a.listenerId < b.listenerId) return -1;
      if (a.listenerId > b.listenerId) return 1;
      return 0;
    });

    return () => {
      const index = this.registrations[eventName]?.findIndex(x => x.listenerId === listenerId);
      if (index >= 0) {
        this.registrations[eventName].splice(index, 1);
      }
    };
  }

  public once<T extends AppEvent>(
    eventClass: AppEventConstructor<T>,
    listener: AppEventListener<T>,
    priority = DEFAULT_PRIORITY
  ): void {
    const removeListener = this.on(
      eventClass,
      (event, controller) => {
        removeListener();
        listener(event, controller);
      },
      priority
    );
  }

  public emit<T extends AppEvent>(event: T): void {
    const listenersForEvent: Registrations<T> = this.registrations[event.eventName] ?? [];

    const controller = this.createEventController();

    for (const { listener } of listenersForEvent) {
      listener(event, controller);
      if (controller.isPropagationStopped) break;
    }
  }

  private createEventController(): EventController {
    let propagationStopped = false;
    return {
      get isPropagationStopped() {
        return propagationStopped;
      },
      stopPropagation: () => {
        propagationStopped = true;
      }
    };
  }
}

import { components, observable, pureComputed, PureComputed } from 'knockout';
import { ComponentDependencies } from '../../../interfaces';
import { BaseComponentViewModel } from '../../base-component';

export interface DisplayAdditionalFieldViewModelParams extends components.ViewModelParams {
  additionalFieldKey?: string;
  displayValues?: Array<{ code: unknown; display: string }>;
  displayWhenEmpty?: string;
}

export class DisplayAdditionalFieldViewModel extends BaseComponentViewModel {
  public displayValue$: PureComputed<string>;

  public constructor(deps: ComponentDependencies, params?: DisplayAdditionalFieldViewModelParams) {
    super(deps);

    const value$ = observable<unknown>();
    const displayValues = params?.displayValues ?? [];

    this.displayValue$ = pureComputed(() => {
      const value = value$();
      if (value === undefined || value === null || value === '') {
        return params?.displayWhenEmpty ?? '';
      }
      return displayValues.find(x => x.code === value$())?.display ?? String(value);
    });

    if (params?.additionalFieldKey) {
      const pathInStore = '$.order.additionalFields.' + params.additionalFieldKey;
      this.bindObservableToStore(value$, pathInStore);
    }
  }
}

import { BindingHandler, unwrap } from 'knockout';

import { TieredPricingHelper } from '../helpers';

export const tierPrice: BindingHandler = {
  update: (element, valueAccessor) => {
    const value: {
      currencyCode?: string;
      base?: number;
      quantity?: number;
      tiers?: {
        minQuantity?: number;
        value?: number;
      };
      displayTotal?: boolean;
    } = unwrap(valueAccessor());

    if (
      typeof value !== 'object' ||
      typeof value.currencyCode !== 'string' ||
      typeof value.quantity !== 'number' ||
      Number.isNaN(value.quantity)
    ) {
      element.innerText = '';

      return;
    }

    let amount = 0;

    if (typeof value.base === 'number' && !Number.isNaN(value.base)) {
      const tiers = Array.isArray(value.tiers)
        ? value.tiers.filter(tier => {
            return (
              typeof tier.minQuantity === 'number' &&
              !Number.isNaN(tier.minQuantity) &&
              typeof tier.value === 'number' &&
              !Number.isNaN(tier.value)
            );
          })
        : [];

      amount = TieredPricingHelper.calculate(value.base, value.quantity, tiers);

      if (value.displayTotal) {
        amount = amount * value.quantity;
      }
    }

    element.innerText = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: value.currencyCode
    }).format(amount);
  }
};

import { AnyContact } from '../../interfaces';
import { State } from '../../../../../store/reducers';
import { CountryCode, GenderIdentifiers, HumanName } from '../../../../../interfaces';
import { v4 as uuidv4 } from 'uuid';
import { HumanNameHelper } from '../../../../../helpers';
import { AdditionalContact as GenericAdditionalContact } from '../../../../../interfaces/AdditionalContact';

type ParticipantContacts = Pick<AnyContact, 'Participants'>;

type ReplacementContact = Pick<
  AnyContact,
  | 'PartnerBirthDate'
  | 'PartnerEmailAddress'
  | 'PartnerFirstName'
  | 'PartnerGender'
  | 'PartnerInitials'
  | 'PartnerLastName'
  | 'PartnerName'
  | 'PartnerNamePrefix'
  | 'PartnerPhoneNumber'
  | 'CountryCode'
>;

type GuestContact = Pick<
  AnyContact,
  | 'GuestFirstName'
  | 'GuestGender'
  | 'GuestLastName'
  | 'GuestName'
  | 'GuestNamePrefix'
  | 'CountryCode'
>;

export function additionalContactsStateTranslator(config: AnyContact): State['additionalContacts'] {
  return [
    ...participantsStateTranslator(config),
    ...guestStateTranslator(config),
    ...replacementStateTranslator(config)
  ].reduce((acc, contact) => {
    acc[uuidv4()] = contact;
    return acc;
  }, {});
}

function participantsStateTranslator(config: ParticipantContacts): GenericAdditionalContact[] {
  return (
    config.Participants?.map(participant => {
      return {
        type: 'participant',
        name: {
          gender: ([1, 2].includes(participant.IDTitle)
            ? ['male', 'female'][participant.IDTitle - 1]
            : 'unknown') as GenderIdentifiers,
          prefixedTitles: [],
          initials:
            participant.Initials?.split('.')
              .map(item => item.trim())
              .filter(Boolean) ?? [],
          givenName: participant.FirstName || '',
          middleNames: [],
          familyNamePrefix: participant.NamePrefix || '',
          familyName: participant.LastName || '',
          suffixedTitles: []
        },
        formattedName: participant.FullName || '',
        emailAddress: participant.EmailAddress || null,
        phoneNumber: participant.PhoneNumber || null,
        phoneNumberMobile: null,
        'countryCodeISO3166-1': (participant.CountryCode as CountryCode) ?? 'NL',
        birthDate: participant.BirthDate || null
      };
    }) ?? []
  );
}

function guestStateTranslator(config: GuestContact): GenericAdditionalContact[] {
  if (!config.GuestName) {
    return [];
  }

  const name: HumanName = {
    gender: ([1, 2].includes(config.GuestGender)
      ? ['male', 'female'][config.GuestGender - 1]
      : 'unknown') as GenderIdentifiers,
    prefixedTitles: [],
    initials: [],
    givenName: config.GuestFirstName || '',
    middleNames: [],
    familyNamePrefix: config.GuestNamePrefix || '',
    familyName: config.GuestLastName || '',
    suffixedTitles: []
  };

  return [
    {
      type: 'guest',
      name,
      formattedName: HumanNameHelper.formatName(name) || '',
      emailAddress: null,
      phoneNumber: null,
      phoneNumberMobile: null,
      'countryCodeISO3166-1': (config.CountryCode as CountryCode) ?? 'NL',
      birthDate: null
    }
  ];
}

function replacementStateTranslator(config: ReplacementContact): GenericAdditionalContact[] {
  if (!config.PartnerName) {
    return [];
  }

  const name: HumanName = {
    gender: ([1, 2].includes(config.PartnerGender)
      ? ['male', 'female'][config.PartnerGender - 1]
      : 'unknown') as GenderIdentifiers,
    prefixedTitles: [],
    initials: [],
    givenName: config.PartnerFirstName || '',
    middleNames: [],
    familyNamePrefix: config.PartnerNamePrefix || '',
    familyName: config.PartnerLastName || '',
    suffixedTitles: []
  };

  return [
    {
      type: 'replacement',
      name,
      formattedName: HumanNameHelper.formatName(name) || '',
      emailAddress: config.PartnerEmailAddress || null,
      phoneNumber: config.PartnerPhoneNumber || null,
      phoneNumberMobile: null,
      'countryCodeISO3166-1': (config.CountryCode as CountryCode) ?? 'NL',
      birthDate: config.PartnerBirthDate
    }
  ];
}

import template from './template.html';
import { CartElementClassToggleViewModel } from './CartElementClassToggleViewModel';
import { ComponentConfig } from '../../../interfaces';

export const cartElementClassToggleComponent: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new CartElementClassToggleViewModel(deps, params)
  }
};

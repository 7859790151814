import i18next from 'i18next';
import { components, observable, Observable } from 'knockout';

import { setOrderRemarks } from '../../store/order/actions';
import { ComponentDependencies } from '../../interfaces';
import { BaseComponentViewModel } from '../base-component';

export interface OrderRemarksViewModelParams extends components.ViewModelParams {
  readOnly?: boolean;
  required?: boolean;
  texts?: {
    label?: string;
    placeholder?: string;
  };
}

export class OrderRemarksViewModel extends BaseComponentViewModel {
  public readonly readOnly: boolean;
  public readonly required: boolean;

  public readonly texts: {
    label: string;
    placeholder: string;
  };

  public readonly orderRemarksAllowed$: Observable<boolean> = observable(true);
  public readonly value$: Observable<string | null> = observable(null);

  constructor(deps: ComponentDependencies, params?: OrderRemarksViewModelParams) {
    super(deps);

    this.readOnly = typeof params?.readOnly === 'boolean' ? params.readOnly : false;

    this.required = typeof params?.required === 'boolean' ? params.required : false;

    this.texts = {
      label: i18next.t('components.orderRemarks.label', 'Remarks'),
      placeholder: i18next.t('components.orderRemarks.placeholder', 'Remarks'),
      ...params?.texts
    };

    this.bindObservableToStore(this.orderRemarksAllowed$, '$.user.features.orderRemarks');
    this.bindObservableToStore(this.value$, '$.order.remarks', setOrderRemarks);
  }
}

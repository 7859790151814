interface Tier {
  minQuantity: number;
  value: number;
}

export class TieredPricingHelper {
  static calculate(base: number, quantity: number, tiers: Tier[]): number {
    let tier: Tier | undefined;

    tiers.sort((a, b) => a.minQuantity - b.minQuantity);

    if (tiers.length) {
      // If quantity is lower than the first tier, use the price from the first tier, because
      // otherwise the price would be unspecified.
      if (!base) {
        quantity = Math.max(quantity, tiers[0].minQuantity);
      }

      for (let i = tiers.length - 1; i >= 0; i--) {
        if (tiers[i].minQuantity <= quantity) {
          tier = tiers[i];
          break;
        }
      }
    }

    return tier?.value ?? base;
  }
}

import DateInputWidget from './date-input';
import EmailTextInputWidget from './email-text-input';
import HumanNameWidget from './human-name';
import QuantitySpinnerWidget from './quantity-spinner';
import TelephoneTextInputWidget from './telephone-text-input';
import TextInputWidget from './text-input';
import SegmentedTextInputWidget from './segmented-text-input';
import AddressInputWidget from './address-input';

import { ComponentDictionary } from '../interfaces';

export const widgets: ComponentDictionary = {
  'wid-date-input': DateInputWidget,
  'wid-email-text-input': EmailTextInputWidget,
  'wid-human-name': HumanNameWidget,
  'wid-quantity-spinner': QuantitySpinnerWidget,
  'wid-segmented-text-input': SegmentedTextInputWidget,
  'wid-telephone-text-input': TelephoneTextInputWidget,
  'wid-text-input': TextInputWidget,
  'wid-address-input': AddressInputWidget
};

export default widgets;

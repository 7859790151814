import { components, observable, Observable } from 'knockout';
import i18next from 'i18next';
import { DialogCloseRequestedEvent } from '../../events';
import { DialogToggleRequestedEvent } from '../../events/DialogToggleRequestedEvent';

import { BaseComponentViewModel } from '../base-component';
import { ComponentDependencies } from '../../interfaces';
import { NativeDialogExtensions } from '../../lib/native-dialog-extensions';

export type RequestDialogToggle = Observable<boolean | undefined>;

export interface DialogLinkViewModelParams extends components.ViewModelParams {
  _templateNodes?: Node[];
  requestDialogToggle?: RequestDialogToggle;
  dialogClassName?: string;
  dialogContext?: any;
  texts?: {
    linkText?: string;
    close?: string;
  };
}

export class DialogLinkViewModel extends BaseComponentViewModel {
  public readonly _templateNodes?: Node[];

  public readonly dialogClassName?: string;
  public readonly dialogContext?: any;

  public readonly texts: {
    linkText: string;
    close: string;
  };

  public readonly open$: Observable<boolean> = observable(false);

  private componentInfo?: components.ComponentInfo;
  private dialog?: HTMLDialogElement;

  constructor(
    deps: ComponentDependencies,
    params?: DialogLinkViewModelParams,
    componentInfo?: components.ComponentInfo
  ) {
    super(deps);

    const dialogId = params?.dialogContext?.dialogId ?? this.id;
    this.dialogClassName = params?.dialogClassName;
    this.dialogContext = {
      ...params,
      dialogId
    }?.dialogContext;

    this.texts = {
      linkText: i18next.t('components.dialogLink.linkText', 'Read more'),
      close: i18next.t('components.dialogLink.close', 'Close'),
      ...params?.texts
    };

    this._templateNodes = params?._templateNodes ?? componentInfo?.templateNodes;

    if (componentInfo) {
      this.componentInfo = componentInfo;
    }

    // Because the dialog link is typically nested inside other components inside the DOM,
    // prevent click events from propagating past this component's boundary to the owning component.
    this.componentInfo?.element.addEventListener('click', ev => ev.stopPropagation());

    this.disposalFunctions.push(
      deps.appEventManager.on(DialogCloseRequestedEvent, event => {
        if (event.dialogId === dialogId) {
          this.closeDialog();
        }
      }),
      deps.appEventManager.on(DialogToggleRequestedEvent, event => {
        if (event.dialogId === dialogId) {
          this.toggleDialog(event.force);
        }
      })
    );
  }

  openDialog() {
    if (this.dialog) {
      try {
        NativeDialogExtensions.getProxy(this.dialog)?.showModal();
        this.open$(true);
      } catch (err) {
        console.error(err);
      }
    }
  }

  toggleDialog(force?: boolean) {
    const currentState = this.open$();
    force = force ?? !currentState;

    if (force === currentState) {
      return; // nothing to do
    }

    if (force) {
      this.openDialog();
    } else {
      this.closeDialog();
    }
  }

  closeDialog() {
    if (this.dialog) {
      try {
        NativeDialogExtensions.getProxy(this.dialog)?.close();
      } catch (err) {
        console.error(err);
      }
    }

    this.open$(false);
  }

  onOpenButtonClick: (data: never, ev: MouseEvent) => void = (data, ev) => {
    ev.stopPropagation();
    this.openDialog();
  };

  onCloseButtonClick: (data: never, ev: MouseEvent) => void = (data, ev) => {
    ev.stopPropagation();
    this.closeDialog();
  };

  onDialogClose: (data: never, ev: never) => void = (data, ev) => {
    this.open$(false); // sync this components state with the actual dialog
  };

  koDescendantsComplete(): void {
    if (this.componentInfo?.element?.nodeType === Node.ELEMENT_NODE) {
      this.dialog =
        (this.componentInfo?.element as Element)?.querySelector<HTMLDialogElement>('dialog') ??
        undefined;
    }
  }

  dispose(): void {
    /*
     * Move the <dialog> element back into the component on disposal, otherwise
     * it will pollute the <body> element with orphaned <dialog> elements.
     */
    if (this.componentInfo?.element && this.dialog) {
      this.componentInfo.element.appendChild(this.dialog);
    }

    super.dispose();
  }
}

import { BaseComponentViewModel } from '../../base-component';
import {
  components,
  observable,
  Observable,
  PureComputed,
  pureComputed,
  validation
} from 'knockout';
import { ValidationObservable } from 'knockout.validation';

import { ComponentDependencies } from '../../../interfaces';
import { setVipcard } from '../../../store/user/actions';
import { SegmentedTextInputViewModelParams } from '../../../ui_widgets/segmented-text-input/SegmentedTextInputViewModel';
import i18next from 'i18next';
import { UserIdentity } from '../../../interfaces/UserIdentity';

export interface VipcardInputViewModelParams extends components.ViewModelParams {
  prefix?: string;
  prefill?: string;
  texts?: {
    identifierLabel?: string;
    identifierPlaceholder?: string;
    postalCodeLabel?: string;
    postalCodePlaceholder?: string;
    postalCodeError?: string;
    houseNumberLabel?: string;
    houseNumberPlaceholder?: string;
    houseNumberError?: string;
  };
}

export class VipcardInputViewModel extends BaseComponentViewModel {
  public readonly texts: {
    identifierLabel: string;
    identifierPlaceholder: string;
    postalCodeLabel: string;
    postalCodePlaceholder: string;
    postalCodeError: string;
    houseNumberLabel: string;
    houseNumberPlaceholder: string;
    houseNumberError: string;
  };
  public readonly userIdentity$: PureComputed<UserIdentity>;
  public readonly prefix: string;

  public readonly prefill: string;
  public readonly identifier$: Observable<string | null | undefined> = observable();

  public readonly postalCode$: PureComputed<string | undefined> &
    validation.ObservableValidationExtension;
  public readonly houseNumber$: Observable<string | undefined> &
    validation.ObservableValidationExtension;

  public readonly widgetIsValid$: ValidationObservable<boolean>;
  public readonly widgetParams;

  constructor(deps: ComponentDependencies, params?: VipcardInputViewModelParams) {
    super(deps);

    if (params && 'requestPostalCode' in params) {
      throw new Error(
        '<vipcard-input> component no longer supports explicit requestPostalCode param.'
      );
    }

    this.texts = {
      identifierLabel: i18next.t('components.vipcardInput.identifierLabel'),
      identifierPlaceholder: i18next.t('components.vipcardInput.identifierPlaceholder'),
      postalCodeLabel: i18next.t('components.vipcardInput.postalCodeLabel'),
      postalCodePlaceholder: i18next.t('components.vipcardInput.postalCodePlaceholder'),
      postalCodeError: i18next.t('components.vipcardInput.postalCodeError'),
      houseNumberLabel: i18next.t('components.vipcardInput.houseNumberLabel'),
      houseNumberPlaceholder: i18next.t('components.vipcardInput.houseNumberPlaceholder'),
      houseNumberError: i18next.t('components.vipcardInput.houseNumberError'),
      ...params?.texts
    };

    this.prefix = params?.prefix ?? '';
    this.prefill = params?.prefill ?? '';

    this.widgetIsValid$ = observable(false).extend({ equal: true } as never);
    this.widgetParams = this.createWidgetParams();
    this.initializeStateUpdates();

    const postalCodeRaw$ = observable();
    this.postalCode$ = pureComputed<string | undefined>({
      read(): string {
        return postalCodeRaw$();
      },
      write(value) {
        postalCodeRaw$(value?.toUpperCase());
      },
      owner: this
    }).extend({
      required: true,
      pattern: {
        params: /^[0-9]{4}[ ]?[A-Z]{2}$/,
        message: this.texts.postalCodeError
      }
    });

    this.houseNumber$ = observable().extend({
      required: true,
      digit: true
    });

    this.userIdentity$ = pureComputed({
      read(): UserIdentity {
        return {
          identifier: this.identifier$() ?? '',
          verificationChallenge1: this.postalCode$(),
          verificationChallenge2: this.houseNumber$()
        };
      },
      write(value: UserIdentity) {
        this.identifier$(value?.identifier);
        this.postalCode$(value?.verificationChallenge1);
        this.houseNumber$(value?.verificationChallenge2);
      },
      owner: this
    });

    this.bindObservableToStore(this.userIdentity$, '$.user.identities.vipcardnumber', value =>
      setVipcard({
        identifier: value.identifier ?? '',
        verificationChallenge1: value.verificationChallenge1 ?? undefined,
        verificationChallenge2: value.verificationChallenge2 ?? undefined
      })
    );

    if (this.prefill) {
      setTimeout(() => {
        const currentIdentifier = this.identifier$() ?? '';
        if (!currentIdentifier || currentIdentifier === this.prefix) {
          this.identifier$(this.prefix + this.prefill);
        }
      }, 0);
    }
  }

  private createWidgetParams(): SegmentedTextInputViewModelParams {
    const segments = [
      {
        size: 4,
        placeholder: this.texts.identifierPlaceholder
      },
      {
        size: 4
      },
      {
        size: 4
      }
    ];

    const sumOfSegmentSizes = segments.reduce((acc, { size }) => acc + (size ?? 0), 0);

    const addPrefix = (identifier: string) => this.prefix + (identifier ?? '');

    const stripPrefix = (identifier: string) =>
      this.prefix && identifier?.startsWith(this.prefix)
        ? identifier.substring(this.prefix.length)
        : identifier ?? '';

    const identifierWithoutPrefix$ = pureComputed({
      read(): string {
        return stripPrefix(this.identifier$() as string);
      },
      write(value: string) {
        this.identifier$(addPrefix(value));
      },
      owner: this
    });

    return {
      value$: identifierWithoutPrefix$ as unknown as Observable<string | null | undefined>,
      isValid$: this.widgetIsValid$,
      readOnly: false,
      required: true,
      segments,
      minLength: sumOfSegmentSizes,
      maxLength: sumOfSegmentSizes,
      preferNumericKeyboard: true,
      texts: {
        label: this.texts.identifierLabel
      }
    };
  }
}

import { components, observable, Observable, pureComputed, PureComputed } from 'knockout';
import { ComponentDependencies } from '../../interfaces';

import { BaseComponentViewModel } from '../base-component';

/* Option to pass data to the next stage  */
export interface IfVisibilityOptionViewModelParams extends components.ViewModelParams {
  context?: Record<string, any>;
  groupId?: string;
  optionId?: string;
}

export class IfVisibilityOptionViewModel extends BaseComponentViewModel {
  public readonly context$: PureComputed<{
    [key: string]: any;
  }>;

  public readonly visible$: Observable<boolean> = observable(false);

  public readonly optionId?: string;
  public readonly groupId?: string;

  constructor(deps: ComponentDependencies, params?: IfVisibilityOptionViewModelParams) {
    super(deps);

    this.optionId = params?.optionId ?? undefined;
    this.groupId = params?.groupId ?? undefined;

    if (this.optionId && this.groupId) {
      this.bindObservableToStore(
        this.visible$,
        `$.app.visibilityOptionGroups['${this.groupId}']['${this.optionId}']`
      );
    }

    this.context$ = pureComputed(() => {
      return {
        ...params?.context
      };
    });
  }
}

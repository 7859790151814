import { AdditionalContact } from '../../interfaces/AdditionalContact';
import {
  ADD_ADDITIONAL_CONTACT,
  AdditionalContactsActionTypes,
  DELETE_ADDITIONAL_CONTACT,
  SET_ADDITIONAL_CONTACT_BIRTH_DATE,
  SET_ADDITIONAL_CONTACT_EMAIL,
  SET_ADDITIONAL_CONTACT_MOBILE_PHONE,
  SET_ADDITIONAL_CONTACT_NAME,
  SET_ADDITIONAL_CONTACT_PHONE
} from './actions';
import { initialState } from './initialState';
import { v4 as uuidv4 } from 'uuid';
import { HumanNameHelper } from '../../helpers/HumanNameHelper';

export type AdditionalContactRef = string;
export type AdditionalContactsState = {
  [ref: string]: AdditionalContact;
};

export function additionalContactsReducer(
  state = initialState,
  action: AdditionalContactsActionTypes
): AdditionalContactsState {
  switch (action.type) {
    case ADD_ADDITIONAL_CONTACT: {
      const ref = uuidv4();

      const contact: AdditionalContact = {
        type: action.meta.type,
        name: {
          gender: 'unknown',
          prefixedTitles: [],
          initials: [],
          givenName: '',
          middleNames: [],
          familyNamePrefix: '',
          familyName: '',
          suffixedTitles: []
        },
        formattedName: '',
        emailAddress: null,
        phoneNumber: null,
        phoneNumberMobile: null,
        'countryCodeISO3166-1': 'NL',
        birthDate: null
      };

      return {
        ...state,
        [ref]: contact
      };
    }

    case DELETE_ADDITIONAL_CONTACT: {
      state = { ...state };
      delete state[action.meta.ref];
      return state;
    }

    case SET_ADDITIONAL_CONTACT_NAME: {
      const contact: AdditionalContact = {
        ...state[action.meta.ref],
        name: action.payload,
        formattedName: HumanNameHelper.formatName(action.payload)
      };
      return {
        ...state,
        [action.meta.ref]: contact
      };
    }

    case SET_ADDITIONAL_CONTACT_EMAIL: {
      const contact: AdditionalContact = {
        ...state[action.meta.ref],
        emailAddress: action.payload
      };
      return {
        ...state,
        [action.meta.ref]: contact
      };
    }

    case SET_ADDITIONAL_CONTACT_PHONE: {
      const contact: AdditionalContact = {
        ...state[action.meta.ref],
        phoneNumber: action.payload
      };
      return {
        ...state,
        [action.meta.ref]: contact
      };
    }

    case SET_ADDITIONAL_CONTACT_MOBILE_PHONE: {
      const contact: AdditionalContact = {
        ...state[action.meta.ref],
        phoneNumberMobile: action.payload
      };
      return {
        ...state,
        [action.meta.ref]: contact
      };
    }

    case SET_ADDITIONAL_CONTACT_BIRTH_DATE: {
      const contact: AdditionalContact = {
        ...state[action.meta.ref],
        birthDate: action.payload
      };
      return {
        ...state,
        [action.meta.ref]: contact
      };
    }
    default:
      return state;
  }
}

import { BeforeStageNavigationEvent, StageNavigationEvent } from '../events';
import { ActiveStageState, NEXT_ROUTE, PREVIOUS_ROUTE } from '../interfaces';
import { StageIdentifier } from '../interfaces/ActiveStageState';
import { BranchingRoute, LinearRoute, RouteName, Routes } from '../interfaces/Routes';
import { AppStore } from '../store';
import { setAppActiveStage } from '../store/app/actions';
import { State } from '../store/reducers';
import { componentsValidator } from '../validators';
import { AppEventManagerInterface } from './event-manager/EventManagerInterface';

export type NavigationDirection = 'none' | 'sideways' | 'back' | 'forward';

interface HistoryState {
  routeName: RouteName;
}

export class StageNavigationService {
  private store: AppStore;
  private eventManager: AppEventManagerInterface;
  private initialized = false;

  public constructor(store: AppStore, eventManager: AppEventManagerInterface) {
    this.store = store;
    this.eventManager = eventManager;
  }

  public init(): void {
    if (this.initialized) {
      return;
    }

    this.initialized = true;

    const state: State = this.store.getState();
    let activeRouteName = state.app.activeStage?.stageIdentifier;

    if (!activeRouteName || !this.routeExists(activeRouteName)) {
      activeRouteName = this.getInitialRoute();
    }

    this.store.dispatch(setAppActiveStage(activeRouteName));

    if (activeRouteName) {
      this.setHistoryState(activeRouteName);
      this.setDataActiveStage(activeRouteName);
    }

    window.scrollTo(0, 0);
    window.onpopstate = this.handleHistoryPopState.bind(this);
  }

  private setHistoryState(routeName: RouteName): void {
    if (history.state?.routeName !== routeName) {
      history.pushState({ routeName }, '');
    }
  }

  public navigate(routeName: RouteName): void;
  /** @deprecated Use navigate(routeName: RouteName) instead. **/
  public navigate(targetStage: ActiveStageState | undefined): void;
  public navigate(routeNameOrTargetStage?: RouteName | ActiveStageState | undefined): void {
    if (typeof routeNameOrTargetStage === 'object') {
      this.navigate(routeNameOrTargetStage.stageIdentifier);
      return;
    }

    const targetRoute = this.getRouteByName(routeNameOrTargetStage as string)?.name ?? '';

    if (!targetRoute) {
      return;
    }

    const activeRoute = this.getActiveRouteName();
    const direction = this.direction(targetRoute);
    const canNavigate = this.canNavigate(targetRoute);

    this.eventManager.emit(
      new BeforeStageNavigationEvent({
        activeRoute,
        targetRoute,
        direction,
        canNavigate
      })
    );

    if (!canNavigate) {
      return;
    }

    this.eventManager.emit(
      new StageNavigationEvent({
        activeRoute,
        targetRoute,
        direction
      })
    );

    if (direction !== 'none') {
      this.setHistoryState(targetRoute);
    }

    this.store.dispatch(setAppActiveStage(targetRoute));

    this.setDataActiveStage(targetRoute);

    window.scrollTo(0, 0);
  }

  public direction(targetRoute: RouteName): NavigationDirection {
    const activeRoute = this.getActiveRouteName();
    targetRoute = this.getRouteByName(targetRoute)?.name ?? '';

    if (!activeRoute || !targetRoute) {
      return 'none';
    }

    if (activeRoute === targetRoute) {
      return 'none';
    }

    const stageDelta = this.getStageDelta(activeRoute, targetRoute);

    if (stageDelta === false) {
      return 'none';
    }

    if (stageDelta < 0) {
      return 'back';
    }

    if (stageDelta === 0) {
      return 'sideways';
    }

    if (stageDelta > 0) {
      return 'forward';
    }

    return 'none';
  }

  public getRouteIndex(routeName?: RouteName): number {
    if (!routeName) {
      return -1;
    }

    const state: State = this.store.getState();
    const routes: Routes = state.app.routes;

    return routes.findIndex(route => {
      if (Array.isArray(route)) {
        return route.some(nestedRoute => nestedRoute.name === routeName);
      }

      return route.name === routeName;
    });
  }

  public canNavigate(routeName: RouteName): boolean;
  /** @deprecated Use navigate(stageIdentifier: StageIdentifier) instead. **/
  public canNavigate(targetStage?: ActiveStageState): boolean;
  public canNavigate(routeNameOrActiveStage?: RouteName | ActiveStageState | undefined): boolean {
    // check for deprecated method call, forward to the new call if needed
    if (typeof routeNameOrActiveStage === 'object') {
      return this.canNavigate(routeNameOrActiveStage.stageIdentifier);
    }

    const routeName = this.getRouteByName(routeNameOrActiveStage as string)?.name;
    const activeRoute = this.getActiveRouteName();

    if (!activeRoute || !routeName) {
      return false;
    }

    const stageDelta = this.getStageDelta(activeRoute, routeName);

    if (stageDelta === false) {
      return false;
    }

    if (stageDelta <= 0) {
      return true; // navigating backwards or sidewards is always allowed
    }

    if (stageDelta !== 1) {
      // navigating forward is only allowed if it's exactly one step
      return false;
    }

    // allow navigation if all components on the current page are valid
    const state: State = this.store.getState();
    return componentsValidator(state);
  }

  public getStageDelta(fromRoute: RouteName, toRoute: RouteName): number | false {
    const fromIndex = this.getRouteIndex(fromRoute);
    const toIndex = this.getRouteIndex(toRoute);

    if (fromIndex === -1 || toIndex === -1) {
      return false;
    }

    return toIndex - fromIndex;
  }

  private handleHistoryPopState = (event: PopStateEvent): void => {
    const state: HistoryState = event.state;

    if (typeof state?.routeName === 'string') {
      this.navigate(state.routeName);
    }
  };

  public getActiveRouteName(): RouteName | undefined {
    return this.store.getState().app.activeStage?.stageIdentifier;
  }

  private routeExists(routeName: RouteName): boolean {
    return this.getRouteIndex(routeName) >= 0;
  }

  private getInitialRoute(): RouteName | undefined {
    let [initialRoute] = this.getRoutes();

    if (initialRoute instanceof Array) {
      [initialRoute] = initialRoute;
    }

    return initialRoute?.name;
  }

  public getRoutes(): Routes {
    return this.store.getState().app.routes;
  }

  public getRouteByName(routeName: RouteName): LinearRoute | undefined {
    function findInRoutes(routes: Routes): LinearRoute | undefined {
      for (const route of routes) {
        const routeToCheck =
          route instanceof Array ? findInRoutes(route as BranchingRoute) : (route as LinearRoute);

        if (routeToCheck?.name === routeName) {
          return routeToCheck;
        }
      }
      return undefined;
    }

    if (routeName === NEXT_ROUTE || routeName === PREVIOUS_ROUTE) {
      const activeRouteName = this.getActiveRouteName();
      const activeRouteIndex = this.getRouteIndex(activeRouteName);
      const routeDelta = routeName === NEXT_ROUTE ? 1 : -1;
      return this.getRouteByIndex(activeRouteIndex + routeDelta);
    }

    return findInRoutes(this.getRoutes());
  }

  private getRouteByIndex(index: number): LinearRoute | undefined {
    const routes = this.getRoutes();
    if (index < 0) {
      return undefined;
    }

    if (index >= routes.length) {
      return undefined;
    }

    const route = routes[index];
    return route instanceof Array ? route[0] : route;
  }

  private setDataActiveStage(routeName: RouteName): void {
    document.documentElement.dataset.activeStage = routeName;
  }

  /** @deprecated Use getRouteIndex(routeName) instead */
  public getStageIndex(stageIdentifier?: StageIdentifier): number {
    return this.getRouteIndex(stageIdentifier);
  }

  /** @deprecated Use getActiveRouteName() instead. */
  public getActiveStage(): StageIdentifier | undefined {
    return this.getActiveRouteName();
  }

  /** @deprecated Use getRouteIndex(getActiveRouteName()) instead. */
  public getActiveStageIndex(): number {
    return this.getRouteIndex(this.getActiveRouteName());
  }
}

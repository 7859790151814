import { components } from 'knockout';
import { ComponentDependencies, OptInId, OptInType } from '../../../interfaces';
import { OptInService } from '../../../services';
import { BaseComponentViewModel } from '../../base-component';

export interface OptInButtonViewModelParams extends components.ViewModelParams {
  optInId?: OptInId;
  optInType?: OptInType;
  texts?: {
    optInLabel?: string;
    optOutLabel?: string;
  };
}

export class OptInButtonsViewModel extends BaseComponentViewModel {
  private optInId: OptInId;
  private optInService: OptInService;

  public texts: {
    optInLabel: string;
    optOutLabel: string;
  };

  constructor(deps: ComponentDependencies, params: OptInButtonViewModelParams) {
    super(deps);
    this.optInService = deps.optIns;

    this.texts = {
      optInLabel: this.i18next.t('components.optInOrOptOut.optInLabel', 'Opt in'),
      optOutLabel: this.i18next.t('components.optInOrOptOut.optOutLabel', 'Opt out'),
      ...params?.texts
    };

    this.optInId = params?.optInId ?? deps.optIns.firstIdOfType(params?.optInType ?? '') ?? '';

    if (!this.optInId) {
      throw new Error(
        'Either optInId or optInType param is required in OptInButtonViewModel, and must point to an existing opt-in.'
      );
    }
  }

  async onButtonClick(choice: boolean) {
    this.optInService.setOptInSelection(this.optInId, choice);
  }
}

import { components, observable, Observable, pureComputed, PureComputed } from 'knockout';

import { Category, ComponentDependencies } from '../../../interfaces';
import { BaseComponentViewModel } from '../../base-component';

export interface ActiveCategoryViewModelParams extends components.ViewModelParams {
  [name: string]: undefined;
}

export class ActiveCategoryViewModel extends BaseComponentViewModel {
  public readonly activeCategoryIdentifier$: Observable<string | undefined> = observable();
  public readonly categories$: Observable<{ [key: string]: Category }> = observable({});

  public readonly activeCategory$: PureComputed<Category | undefined>;

  constructor(deps: ComponentDependencies, params?: ActiveCategoryViewModelParams) {
    super(deps);

    this.bindObservableToStore(this.activeCategoryIdentifier$, '$.app.activeCategory');
    this.bindObservableToStore(this.categories$, '$.categories');

    this.activeCategory$ = pureComputed(() => {
      const activeCategoryIdentifier = this.activeCategoryIdentifier$();

      return activeCategoryIdentifier ? this.categories$()[activeCategoryIdentifier] : undefined;
    });
  }
}

import { CurrencyCode, Order, Product, TokenIdentifier, User } from '../interfaces';
import { TieredPricingHelper } from './TieredPricingHelper';
import { ProductsState } from '../store/products/reducers';

export interface OrderTotalsResult {
  quantity: number;
  tokens: {
    [K in TokenIdentifier]?: number;
  };
  currency: {
    [K in CurrencyCode]?: number;
  };
}

export class OrderTotalsCalculationHelper {
  static calculate(order: Order, products: { [sku: string]: Product }): OrderTotalsResult {
    return {
      quantity: OrderTotalsCalculationHelper.calculateQuantityTotal(order),
      tokens: OrderTotalsCalculationHelper.calculateTokenTotals(order, products),
      currency: OrderTotalsCalculationHelper.calculateCurrencyTotals(order, products)
    };
  }

  static calculateQuantityTotal(order: Order): number {
    return Object.values(order.items).reduce((total, orderItem) => {
      if (orderItem.quantity > 0) {
        total += orderItem.quantity;
      }

      return total;
    }, 0);
  }

  static calculateTokenTotals(
    order: Order,
    products: { [sku: string]: Product }
  ): OrderTotalsResult['tokens'] {
    const orderTokenTotals = {};

    if (order?.items) {
      for (const sku in order.items) {
        const tokenValues = products[sku]?.amounts.tokens ?? {};

        for (const tokenType in tokenValues) {
          const tokenQuantity: number = tokenValues[tokenType] ?? 0,
            orderItemQuantity = order.items[sku].quantity;

          if (orderTokenTotals[tokenType]) {
            orderTokenTotals[tokenType] += tokenQuantity * orderItemQuantity;
          } else {
            orderTokenTotals[tokenType] = tokenQuantity * orderItemQuantity;
          }
        }
      }
    }

    return orderTokenTotals;
  }

  static calculateCurrencyTotals(
    order: Order,
    products: { [sku: string]: Product }
  ): OrderTotalsResult['currency'] {
    return Object.values(order?.items ?? {}).reduce((totals, orderItem) => {
      const product = products[orderItem.sku];

      if (product) {
        for (const currencyCode in product.amounts.currency) {
          const tierPrice = TieredPricingHelper.calculate(
            product.amounts.currency[currencyCode].base,
            orderItem.quantity,
            product.amounts.currency[currencyCode].tiers
          );

          if (totals[currencyCode]) {
            totals[currencyCode] += tierPrice * orderItem.quantity;
          } else {
            totals[currencyCode] = tierPrice * orderItem.quantity;
          }
        }
      }

      return totals;
    }, {});
  }

  static userHasCredit(
    order: Order | undefined,
    products: ProductsState,
    user: User | undefined
  ): boolean {
    const orderTokenTotals = order && products ? this.calculateTokenTotals(order, products) : {};

    const credit: {
      tokens: {
        [key: string]: number;
      };
      quantity: number | undefined;
    } = {
      tokens: {},
      quantity: undefined
    };

    if (!user) {
      return false;
    }

    credit.quantity = user.restrictions.quantity;

    for (const tokenType in user.restrictions.amounts.tokens) {
      credit.tokens[tokenType] = user.restrictions.amounts.tokens[tokenType];

      if (orderTokenTotals[tokenType]) {
        credit.tokens[tokenType] -= orderTokenTotals[tokenType];
      }
    }

    if (typeof credit.quantity !== 'undefined' && credit.quantity < 1) {
      return false;
    }

    return Object.values(credit.tokens).findIndex(value => value < 1) === -1;
  }
}

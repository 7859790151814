import { pureComputed, PureComputed, components, Subscribable } from 'knockout';

import { Product, ComponentDependencies, OrderLines } from '../../../interfaces';
import { BaseComponentViewModel } from '../../base-component';

type NestedSkuList = {
  sku: string;
  variantSkus: string[];
}[];

export interface ProductSelectorViewModelParams extends components.ViewModelParams {
  skuFilter?: string[];
  nestSkus?: boolean;
  orderQuantityValidationEnabled?: boolean;
}

export class ProductSelectorViewModel extends BaseComponentViewModel {
  public readonly skuFilter?: string[];
  public readonly nestSkus: boolean = true;
  public readonly orderQuantityValidationEnabled: boolean;

  public readonly orderItems$: Subscribable<OrderLines>;

  public readonly nestedSkuList$: PureComputed<NestedSkuList>;

  /**
   * @param {ComponentDependencies} deps
   * @param {Object} params
   * @param {string[]} params.skuFilter
   */
  constructor(deps: ComponentDependencies, params?: ProductSelectorViewModelParams) {
    super(deps);

    this.orderQuantityValidationEnabled = params?.orderQuantityValidationEnabled ?? true;

    if (params?.skuFilter && Array.isArray(params.skuFilter)) {
      this.skuFilter = params.skuFilter;
    }

    this.nestedSkuList$ = pureComputed(() => {
      const products = deps.selectors.products$();

      if (this.nestSkus) {
        return (this.constructor as typeof ProductSelectorViewModel).buildNestedSkuList(
          products,
          this.skuFilter
        );
      }

      return Object.keys(products).map(sku => ({ sku, variantSkus: [] }));
    });

    this.orderItems$ = deps.selectors.orderItems$.clone();

    if (this.orderQuantityValidationEnabled) {
      this.initializeOrderQuantityValidations();
      this.initializeStateUpdates();
    }
  }

  public initializeOrderQuantityValidations(): void {
    this.orderItems$.extend({
      validation: {
        validator: (obj: Record<string, unknown>) => Object.keys(obj).length > 0,
        message: 'The object must have at least {0} value(s)'
      }
    });
  }

  /* eslint-disable complexity */
  public static buildNestedSkuList(
    products: { [key: string]: Product },
    skuFilter?: string[]
  ): NestedSkuList {
    const result: NestedSkuList = [],
      nestingLimit = 10,
      skus: Set<string> = new Set(
        Object.keys(products).sort(
          (skuA, skuB) => products[skuA].sequenceNumber - products[skuB].sequenceNumber
        )
      ),
      childSkus: Set<string> = new Set(),
      parentChildSkuMap: Map<string, Set<string>> = new Map(),
      parentSkuSubstitutionMap: Map<string, string> = new Map();

    skus.forEach(sku => {
      const product = products[sku];

      if (!skuFilter || skuFilter.includes(sku)) {
        if (!product.parentSku || product.parentSku === sku) {
          parentChildSkuMap.set(sku, new Set());
        } else if (
          !skus.has(product.parentSku) &&
          !parentSkuSubstitutionMap.has(product.parentSku)
        ) {
          parentSkuSubstitutionMap.set(product.parentSku, sku);
          parentChildSkuMap.set(sku, new Set());
        } else {
          childSkus.add(sku);
        }
      } else {
        childSkus.add(sku);
      }
    });

    childSkus.forEach(sku => {
      let nextParentSku = products[sku].parentSku;

      if (!nextParentSku) {
        return;
      }

      let i = 0,
        topLevelParentSku = nextParentSku;

      // Find the SKU of the first product without a parent
      while (
        i < nestingLimit &&
        nextParentSku &&
        !parentChildSkuMap.has(nextParentSku) &&
        !parentSkuSubstitutionMap.has(nextParentSku)
      ) {
        nextParentSku = products[nextParentSku].parentSku;
        if (nextParentSku) {
          topLevelParentSku = nextParentSku;
        }
        i++;
      }

      if (!skuFilter || skuFilter.includes(sku)) {
        let parent = parentChildSkuMap.get(topLevelParentSku);

        if (!parent) {
          const substituteParentSku = parentSkuSubstitutionMap.get(topLevelParentSku);

          if (substituteParentSku) {
            parent = parentChildSkuMap.get(substituteParentSku);
          }
        }

        parent?.add(sku);
      }
    });

    parentChildSkuMap.forEach((value, key) => {
      result.push({ sku: key, variantSkus: Array.from(value) });
    });

    return result;
  }
  /* eslint-enable complexity */
}

import { OrderLineMutation } from '../../../interfaces';
import { AppStore } from '../../../store';

export class SingleSkuSingleItemRestriction {
  constructor(private store: AppStore) {}

  public enforce(mutationData: OrderLineMutation): OrderLineMutation {
    const storeState = this.store.getState();

    if (storeState.user.restrictions.rules.singleSku !== true) {
      return mutationData; // nothing to do, only applicable in single sku mode
    }

    if (storeState.user.restrictions.quantity !== 1) {
      return mutationData; // nothing to do, only enforce when quantity is 1
    }

    if (mutationData.quantity === 1) {
      return mutationData; // nothing to do, quantity is already fine
    }

    return {
      ...mutationData,
      operation: 'SET',
      quantity: 1
    };
  }
}

import { components, observable, pureComputed, PureComputed } from 'knockout';
import { ComponentDependencies, RouteName } from '../../interfaces';
import { BaseComponentViewModel } from '../base-component';
import { StageNavigationService } from '../../services';

interface RouterOutletViewModelParams extends components.ViewModelParams {
  slot?: string;
}

export class RouterOutletViewModel extends BaseComponentViewModel {
  public slot: string;
  public pageComponent$: PureComputed<string | undefined>;
  private stageNavigation: StageNavigationService;

  constructor(
    deps: ComponentDependencies,
    params: RouterOutletViewModelParams,
    componentInfo: components.ComponentInfo
  ) {
    super(deps);
    this.slot = params?.slot || 'default';
    this.stageNavigation = deps.stageNavigation;

    const domElement = componentInfo.element as Element;
    const stage$ = observable('');

    this.pageComponent$ = pureComputed(() => {
      const componentName = this.getComponentFromRoute(stage$());
      return componentName && components.isRegistered(componentName) ? componentName : undefined;
    });

    domElement.setAttribute('data-slot', this.slot);

    this.subscriptions.push(
      stage$.subscribe(name => {
        if (name) {
          domElement.setAttribute('data-active-route', name);
        } else {
          domElement.removeAttribute('data-active-route');
        }
      })
    );

    this.bindObservableToStore(stage$, '$.app.activeStage.stageIdentifier');
  }

  private getComponentFromRoute(routeName: RouteName): string | undefined {
    const route = this.stageNavigation.getRouteByName(routeName);

    if (!route?.component) {
      return undefined;
    }

    if (typeof route.component === 'string') {
      return this.slot === 'default' ? route.component : undefined;
    }

    return route.component[this.slot];
  }
}

import { observable, Observable, pureComputed, PureComputed } from 'knockout';
import { ComponentDependencies, User, Contact } from '../../../interfaces';
import { setBirthDate } from '../../../store/contact/actions';
import { DateInputViewModelParams } from '../../../ui_widgets/date-input/DateInputViewModel';
import { BaseComponentViewModel } from '../../base-component';

export interface ContactBirthDateViewModelParams
  extends Omit<DateInputViewModelParams, 'value$' | 'minDate' | 'maxDate'> {
  readOnly?: boolean;
  required?: boolean;
  texts?: {
    label?: string;
    maxDateValidationMessage: string;
  };
}

export class ContactBirthDateViewModel extends BaseComponentViewModel {
  readonly value$: Observable<Contact['birthDate'] | undefined> = observable();
  readonly user$: Observable<User | undefined> = observable();
  readonly widgetParams$: PureComputed<DateInputViewModelParams>;

  constructor(deps: ComponentDependencies, params?: ContactBirthDateViewModelParams) {
    super(deps);

    const readOnly = params?.readOnly ?? false;

    this.widgetParams$ = pureComputed(() => {
      const minDate: Date = new Date('01-01-1900');
      const maxDate: Date = new Date();

      const minimumAge = this.user$()?.restrictions.rules.minimumAge;
      if (minimumAge) {
        maxDate.setUTCFullYear(maxDate.getUTCFullYear() - minimumAge);
      }

      const widgetParams: DateInputViewModelParams = {
        value$: this.value$,
        readOnly,
        required: params?.required,
        maxDate: maxDate?.toISOString().split('T')[0],
        minDate: minDate.toISOString().split('T')[0],
        texts: {
          ...params?.texts
        }
      };

      return widgetParams;
    });

    this.bindObservableToStore(this.user$, '$.user');

    if (readOnly) {
      this.bindObservableToStore(this.value$, '$.contact.birthDate');
    } else {
      this.initializeStateUpdates();

      this.bindObservableToStore(this.value$, '$.contact.birthDate', setBirthDate);
    }
  }
}

import { Order } from '../../interfaces';

export const initialState: Order = {
  additionalFields: {},
  items: {},
  selectedOptIns: [],
  choices: {
    1: null,
    2: null,
    3: null
  },
  useContactAddress: true,
  deliveryAddress: {
    houseNumber: '',
    street: '',
    postalCode: '',
    city: '',
    'countryCodeISO3166-1': 'NL'
  },
  remarks: '',
  payment: {
    paymentOptionCode: null
  },
  supportsTestMode: false,
  _queues: {
    orderItems: {
      hasPendingItems: false,
      lastItemSequenceNumber: 0,
      lastProcessedItemSequenceNumber: 0,
      pendingItems: []
    }
  },
  _submitting: {
    optIns: false,
    vipcard: false
  },
  confirmed: false
};

import { components, observable, Observable, pureComputed, PureComputed } from 'knockout';
import i18next from 'i18next';

import { ComponentDependencies, Contact, Organization } from '../../../interfaces';
import { setAppComponentState } from '../../../store/app/actions';
import { setOrganization } from '../../../store/contact/actions';

import { BaseComponentViewModel } from '../../base-component';

export interface ContactOrganizationViewModelParams extends components.ViewModelParams {
  readOnly?: boolean;
  fieldConfiguration?: {
    name?: { enabled?: boolean; required?: boolean };
    vatNumber?: { enabled?: boolean; required?: boolean };
  };
  texts?: {
    nameLabel?: string;
    vatNumberLabel?: string;
  };
}

export class ContactOrganizationViewModel extends BaseComponentViewModel {
  public readonly readOnly: boolean;

  public readonly fieldConfiguration: {
    name: { enabled: boolean; required: boolean };
    vatNumber: { enabled: boolean; required: boolean };
  };

  public readonly texts: {
    nameLabel: string;
    vatNumberLabel: string;
  };

  public readonly value$: PureComputed<Contact['organization']>;

  public readonly name$: Observable<Organization['name'] | undefined> = observable();
  public readonly vatNumber$: Observable<Organization['vatNumber'] | undefined> = observable();

  constructor(deps: ComponentDependencies, params?: ContactOrganizationViewModelParams) {
    super(deps);

    this.readOnly = typeof params?.readOnly === 'boolean' ? params.readOnly : false;

    this.fieldConfiguration = {
      name: {
        enabled: true,
        required: true,
        ...params?.fieldConfiguration?.name
      },
      vatNumber: {
        enabled: false,
        required: false,
        ...params?.fieldConfiguration?.vatNumber
      }
    };

    this.texts = {
      nameLabel: i18next.t('components.contactOrganization.nameLabel', 'Organization'),
      vatNumberLabel: i18next.t('components.contactOrganization.vatNumberLabel', 'VAT number'),
      ...params?.texts
    };

    this.value$ = pureComputed({
      read(): Contact['organization'] {
        const name = this.name$(),
          vatNumber = this.vatNumber$();

        if (typeof name === 'undefined') {
          return undefined;
        }

        return { name, vatNumber: vatNumber || null };
      },
      write(value: Contact['organization'] | undefined) {
        if (typeof value === 'undefined') {
          this.name$(undefined);
          this.vatNumber$(undefined);
        } else {
          this.name$(value.name);
          this.vatNumber$(value.vatNumber);
        }
      },
      owner: this
    });

    if (this.readOnly) {
      this.bindObservableToStore(this.value$, '$.contact.organization');
    } else {
      this.initializeValidations();
      this.initializeStateUpdates();

      this.bindObservableToStore(this.value$, '$.contact.organization', setOrganization);
    }
  }

  initComponentState(): void {
    this.store.dispatch(
      setAppComponentState({
        id: this.id,
        type: this.constructor.name,
        state: 'pending'
      })
    );
  }

  protected initializeValidations(): void {
    if (this.fieldConfiguration.name.enabled) {
      this.name$.extend({
        required: this.fieldConfiguration.name.required,
        minLength: 1
      });
    }

    if (this.fieldConfiguration.vatNumber.enabled) {
      this.vatNumber$.extend({
        required: this.fieldConfiguration.vatNumber.required,
        minLength: 1
      });
    }
  }
}

import { AppStore, State } from '../store';
import {
  selectOrderIsPending,
  selectProductList,
  selectProducts,
  selectOrder,
  selectOrderItems,
  selectUser,
  selectCategories,
  selectActiveCategoryId,
  selectSkusInActiveCategory,
  selectOrderItemsList,
  selectPendingOrder,
  selectProductById
} from '../store/selectors';
import { Selector } from 'reselect';
import { Subscribable, observable, PureComputed, pureComputed } from 'knockout';

export const CREATED_BY_STORESELECTOR = Symbol('createdByStoreSelector');

export type SubscribableSelector<T> = Subscribable<T> & {
  [CREATED_BY_STORESELECTOR]: true;
  extend(): never;
  clone(): PureComputed<T>;
};

export class StoreSelectors {
  private wrappedSelectors = new Map<string, SubscribableSelector<any>>();

  public constructor(private readonly store: AppStore) {}

  private createObservable<T>(selector: Selector<State, T>) {
    const initial = selector(this.store.getState());
    let currentAsStr = JSON.stringify(initial);

    const obs = observable(initial);

    this.store.subscribe(() => {
      const newValue = selector(this.store.getState());
      const newAsStr = JSON.stringify(newValue);

      if (currentAsStr !== newAsStr) {
        currentAsStr = newAsStr;
        obs(newValue);
      }
    });

    return obs;
  }

  private observe<T>(
    key: string,
    inputSelector: Selector<State, T>,
    ...args: any[]
  ): SubscribableSelector<T> {
    // if arguments for inputSelector are provided, wrap the input selector to provide the arguments
    const selector = args.length ? (state: State) => inputSelector(state, ...args) : inputSelector;

    let obs = this.wrappedSelectors.get(key) as SubscribableSelector<T> | undefined;
    if (!obs) {
      obs = this.createObservable(selector) as unknown as SubscribableSelector<T>;
      obs[CREATED_BY_STORESELECTOR] = true;
      obs.extend = () => {
        throw new Error(
          `It's not allowed to call .extend on a shared observable from StoreSelectors (deps.selectors). Suggestion: call deps.selectors.${key}.clone() before calling extend to wrap the observable in a PureComputed.`
        );
      };
      obs.clone = () => pureComputed(() => obs!());
      this.wrappedSelectors.set(key, obs);
    }
    return obs;
  }

  public get activeCategoryId$() {
    return this.observe('activeCategoryId$', selectActiveCategoryId);
  }
  public get categories$() {
    return this.observe('categories$', selectCategories);
  }

  public get skusInActiveCategory$() {
    return this.observe('skusInActiveCategory$', selectSkusInActiveCategory);
  }

  public get order$() {
    return this.observe('order$', selectOrder);
  }

  public get pendingOrder$() {
    return this.observe('pendingOrder$', selectPendingOrder);
  }

  public get orderItems$() {
    return this.observe('orderItems$', selectOrderItems);
  }

  public get orderItemsList$() {
    return this.observe('orderItemsList$', selectOrderItemsList);
  }

  public get orderIsPending$() {
    return this.observe('orderIsPending$', selectOrderIsPending);
  }

  public get products$() {
    return this.observe('products$', selectProducts);
  }

  public get productList$() {
    return this.observe('productList$', selectProductList);
  }

  public get user$() {
    return this.observe('user$', selectUser);
  }

  public productById$(id: string) {
    return this.observe(`productById['${id}']$`, selectProductById, id);
  }
}
